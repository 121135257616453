import React, { useContext, useState } from 'react';
import { Checkbox, Button, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { AuthContext } from '../../state/contexts/AuthContext';
import { fetchUpdateGoalTemplateMembers } from '../../services/goal';

export const GoalTemplateMembers = ({
  setOpen,
  handleUpdateMembers,
  userData,
  template,
}) => {
  const [members, setMembers] = useState(template.members || []);
  const { user, isOnline } = useContext(AuthContext);

  const handleChange = (contact) => {
    const tmpMembers = [...members];
    if (!members.some((c) => c.userId === contact.userId)) {
      tmpMembers.push({ userId: contact.userId, userName: contact.userName });
      setMembers(tmpMembers);
    } else {
      const contactIndex = members.findIndex(
        (c) => c.userId === contact.userId
      );
      tmpMembers.splice(contactIndex, 1);
      setMembers(tmpMembers);
    }
  };

  const handleSave = async () => {
    if (isOnline()) {
      await fetchUpdateGoalTemplateMembers(
        members,
        template,
        userData.userInfo,
        user.sessionToken
      );
      handleUpdateMembers(members);
    }
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          padding: 10,
          backgroundColor: '#ffb11e',
        }}
      >
        <Typography
          variant={'subtitle1'}
          style={{ fontWeight: 'bold', color: 'white' }}
        >
          Vorlage freigeben für:
        </Typography>
        <Close
          style={{ marginLeft: 'auto', color: 'white' }}
          onClick={() => setOpen(false)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          padding: 20,
        }}
      >
        {userData.contacts.map((contact) => (
          <div style={{ marginBottom: 10 }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              {contact.userName}{' '}
              <Checkbox
                color={'primary'}
                checked={members.some((c) => c.userId === contact.userId)}
                onChange={() => handleChange(contact)}
              />
            </div>
            <div
              style={{ width: '100%', height: 1, backgroundColor: '#e7e7e7' }}
            />
          </div>
        ))}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={handleSave} variant={'contained'} color={'primary'}>
            Ok
          </Button>
        </div>
      </div>
    </div>
  );
};
