import React, { useContext } from 'react';
import { userHasPermission } from '../../utils/handleRolePermissions';
import { permissions } from '../../utils/constants';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { SurveysCounselor } from './SurveysCounselor';
import { SurveysClient } from './SurveysClient';

export const Surveys = () => {
  const { userData } = useContext(UserDataContext);
  return userHasPermission(userData, permissions.COUNSELOR) ? (
    <SurveysCounselor />
  ) : (
    <SurveysClient />
  );
};
