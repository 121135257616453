import { IconButton } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import React, { useState } from 'react';
import { ArrowForwardIos } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

export const PrivateGoals = () => {
  let history = useHistory();

  return (
    <Card variant={'outlined'} style={{ margin: 5, marginTop: 10 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
        onClick={() =>
          history.push('/clientdetails', {
            system: { private: true, name: 'Sonstige Ziele' },
          })
        }
      >
        <div
          style={{
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 500,
            padding: 10,
          }}
        >
          Sonstige Ziele
        </div>
        <IconButton style={{ marginLeft: 'auto' }}>
          <ArrowForwardIos />
        </IconButton>
      </div>
    </Card>
  );
};
