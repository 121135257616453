import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  styled,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { WinTendency } from './WinTendency';
import {
  Delete,
  Edit,
  EditTwoTone,
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';
import { GoalOverviewMembers } from './GoalOverviewMembers';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { useTheme } from '@material-ui/core/styles';
import { AuthContext } from '../../state/contexts/AuthContext';
import { ButtonEditCircle } from '../elements/ButtonEditCircle';

const styles = (theme) => ({
  root: {
    minWidth: 300,
    padding: 0,
    /*'& .MuiExpansionPanelSummary-expandIcon': {
      padding: 0,
    },*/
    '& .MuiAccordionSummary-content.Mui-expanded': {
      padding: 0,
      margin: 0,
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  expanded: {
    margin: 0,
    paddingLeft: 0,
  },
  content: {
    //width: '100%',
    margin: 0,
    padding: 0,
    /*'&$expanded': {
            margin: 0,
        },*/
  },
});

function importAll(icons, r) {
  let images = {};
  icons.forEach((key) => {
    images[key] = r(key);
  });
  return images;
}

export const Accordeon = withStyles(styles)((props) => {
  const { deleteArchivedGoalFromUser } = useContext(UserDataContext);
  const { user } = useContext(AuthContext);
  const { classes, editable = true, type, system } = props;
  const [showGoalDetails, setShowGoalDetails] = useState();
  let history = useHistory();
  const theme = useTheme();

  const getProgress = () => {
    let progress = 0;
    if (props.data.steps) {
      Object.values(props.data.steps).forEach((step) => {
        if (step.done === 'done') {
          progress += 1;
        }
      });
      progress = (progress / Object.keys(props.data.steps).length) * 100;
    }
    return progress;
  };

  const getImage = (icons) => {
    icons = icons ? [icons] : [];
    const images = importAll(
      icons,
      require.context('../../res/iconCollection/', false, /\.(png|jpe?g|svg)$/)
    );
    return Object.values(images)[0];
  };

  const getBackgroundColor = () => {
    let bgColor =
      getProgress() === 100 ? 'rgba(247,255,236,0.6)' : 'rgba(255, 249, 240)';
    if (type === 'supportedGoals') {
      bgColor = theme.palette.secondary.main;
    }
    return bgColor;
  };

  const getElevation = () => {
    let elevation = Math.round(Math.abs(getProgress() / 10 - 9));
    if (type === 'supportedGoals') {
      elevation = 0;
    }
    return elevation;
  };

  const deleteArchivedGoal = (event, userId, goalId, sessionToken) => {
    event.stopPropagation();
    deleteArchivedGoalFromUser(userId, goalId, sessionToken);
  };

  return (
    <StyledViewport progress={getProgress()}>
      <Accordion
        elevation={getElevation()}
        style={{
          borderRadius: 10,
          backgroundColor: 'white',
          boxShadow: '0px 0px 21px 1px rgba(0,0,0,0.2)',
        }}
        defaultExpanded={props.expanded}
        onChange={() => setShowGoalDetails(!showGoalDetails)}
      >
        <StyledAccordionSummary
          bgcolor={getBackgroundColor()}
          //expandIcon={<StyledIcon />}
          classes={{
            root: classes.root,
            content: classes.content,
            /*expanded: classes.expanded,
                                    content: classes.content,*/
          }}
        >
          <StyledSummaryContent>
            <div style={{ display: 'flex' }}>
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  getProgress() !== 100 &&
                    editable &&
                    history.push('/editgoal', { id: props.id, system: system });
                }}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {getImage(props.data.image) ? (
                  <Image image={getImage(props.data.image)} />
                ) : (
                  <div
                    style={{
                      width: 50,
                      height: 50,
                      flexShrink: 0,
                      margin: 10,
                      borderStyle: 'solid',
                      borderRadius: '50%',
                    }}
                  ></div>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 5,
                    overflow: 'hidden',
                    maxHeight: 60,
                    minHeight: 50,
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontSize: 14,
                      lineHeight: 'unset',
                      overflow: 'overlay',
                    }}
                    variant={'body1'}
                    color='textPrimary'
                  >
                    {props.data.title}
                  </Typography>
                </div>
                <GoalOverviewMembers goal={props.data} />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  padding: 5,
                }}
              >
                {getProgress() !== 100 && editable && (
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={() =>
                      history.push('/editgoal', {
                        id: props.id,
                        system: system,
                      })
                    }
                  >
                    <ButtonEditCircle />
                  </IconButton>
                )}
                {getProgress() === 100 && !editable && (
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={(event) =>
                      deleteArchivedGoal(
                        event,
                        user.userId,
                        props.id,
                        user.sessionToken
                      )
                    }
                  >
                    <Delete
                      style={{ color: useTheme().palette.primary.light }}
                    />
                  </IconButton>
                )}
                {showGoalDetails ? (
                  <ExpandLess color={'primary'} />
                ) : (
                  <ExpandMore color={'primary'} />
                )}
              </div>
            </div>
            <WinTendency winTendency={props.winTendency} />
          </StyledSummaryContent>
        </StyledAccordionSummary>
        <StyledAccordionDetails classes={{ root: classes.content }}>
          {props.children}
        </StyledAccordionDetails>
      </Accordion>
    </StyledViewport>
  );
});

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  //backgroundColor: theme.palette.secondary.light,
}));

const Image = styled('div')(({ theme, progress, image }) => ({
  width: 60,
  height: 60,
  marginLeft: 5,
  flexShrink: 0,
  backgroundImage: `url(${image})`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
}));

const StyledViewport = styled('div')(({ progress }) => ({
  paddingBottom: '5px',
  marginBottom: 7,
}));

const StyledAccordionSummary = styled(AccordionSummary)(
  ({ theme, bgcolor }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    //backgroundColor: bgcolor, //theme.palette.button.done
    background: `linear-gradient(90deg, ${bgcolor} 0%, rgba(255,255,255,1) 100%)`,
    borderRadius: '10px 10px 5px 5px',
    height: '100%',
  })
);

const StyledSummaryContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const ProgressBarWrapper = styled('div')({
  height: 50,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  margin: 10,
  //width: "100%"
});

const ProgressBar = styled('div')(({ theme, progress, color }) => ({
  width: progress + '%',
  height: 50,
  backgroundColor: color
    ? color
    : progress === 100
    ? 'rgba(0,0,0,0.03)'
    : theme.palette.progress.main,
  borderRadius: 25,
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
}));
