import { GoalDetails } from './GoalDetails';
import StepList from './StepList';
import { styled, Typography } from '@material-ui/core';
import { Accordeon } from './Accordeon';
import React, { useContext, useEffect, useState } from 'react';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { ClientsContext } from '../../state/contexts/ClientsContext';
import { getClientGoals, getPrivateGoals } from '../../utils/selectGoals';

export const GoalArchive = ({ system }) => {
  const { userData } = useContext(UserDataContext);
  const { clientSystems } = useContext(ClientsContext);

  const [goals, setGoals] = useState();

  useEffect(() => {
    if (userData.goalArchive && Object.keys(userData.goalArchive).length > 0) {
      let goals = userData.goalArchive;
      if (system && system.private && clientSystems) {
        goals = getPrivateGoals(goals, clientSystems);
      }
      if (system && system.systemId) {
        goals = getClientGoals(goals, system);
      }
      if (Object.keys(goals).length > 0) {
        setGoals(goals);
      }
    } else {
      setGoals(undefined);
    }
  }, [userData, clientSystems, system]);

  return (
    <div style={{ width: '100%', padding: 15 }}>
      {goals ? (
        Object.values(goals).map((goal) => {
          const id = goal.id;
          return (
            <Accordeon
              key={id}
              expanded={false}
              id={id}
              data={goals[id]}
              editable={false}
            >
              <ExpansionDetailsViewport>
                <GoalDetails
                  goal={goal}
                  id={id}
                  progress={100}
                  editable={false}
                />
                {goals[id].steps && (
                  <StepsViewport>
                    <StepList goal={goal} editable={false} />
                  </StepsViewport>
                )}
              </ExpansionDetailsViewport>
            </Accordeon>
          );
        })
      ) : (
        <div style={{ marginTop: 100, width: '100%', textAlign: 'center' }}>
          <Typography variant={'h6'}>Das ist das Belohnungszentrum!</Typography>
          <br /> Hier erscheinen die Ziele, die du schon geschafft hast - und
          später kannst du hier Punkte sammeln, um weitere Features
          freizuschalten.
        </div>
      )}
    </div>
  );
};

const ExpansionDetailsViewport = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const StepsViewport = styled('div')({
  display: 'flex',
  width: '100%',
});
