import styled from '@material-ui/core/styles/styled';

export const Divider = styled('div')(
  ({
    theme,
    width = '100%',
    color = 'white',
    marginLR = 0,
    marginTop = 10,
    marginBottom = 10,
  }) => ({
    backgroundColor: color,
    width: width,
    height: 1,
    marginTop: marginTop,
    marginBottom: marginBottom,
    marginLeft: marginLR,
    marginRight: marginLR,
  })
);
