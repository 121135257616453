import Parse from '../utils/initParse';

export const fetchCheckCode = async (code) => {
  return await Parse.Cloud.run('checkCode', { code });
};

export const fetchLogin = (data) => {
  return fetch('/accounts/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then(async (response) => {
    if (response.ok) {
      return response.json();
    } else {
      return Promise.reject(await response.json());
    }
  });
};

export const fetchRegister = (credentials) => {
  return fetch('/accounts/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  }).then(async (response) => {
    if (response.status >= 400) {
      return Promise.reject(await response.json());
    }

    return response.json();
  });
};

export const fetchLogout = (currentFCMToken) => {
  return fetch('/accounts/logout', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ currentFCMToken }),
  });
};

export const fetchDeleteUser = (userId, password) => {
  return fetch('/accounts/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId, password }),
  }).then(async (response) => {
    if (response.status >= 400) {
      return Promise.reject(await response.json());
    }
    return response.json();
  });
};

export const fetchCurrentUser = () => {
  return fetch('/accounts/currentuser').then((response) => {
    if (response.status >= 400) {
      return Promise.resolve(null);
    }

    return response.json();
  });
};

export const fetchRequestPasswordReset = (email) => {
  return fetch('/accounts/request_pw_reset', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  }).then(async (response) => {
    if (response.ok) {
      return response.json();
    } else {
      return Promise.reject(await response.json());
    }
  });
};

export default {
  fetchRegister,
  fetchLogin,
  fetchLogout,
  fetchCurrentUser,
};
