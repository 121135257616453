import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@material-ui/core';
import {
  ArrowForwardIos,
  Delete,
  Edit,
  PlaylistAddCheck,
  AddCircleOutlined,
  TextFields,
  Share,
  MoreVert,
  Send,
  AssessmentOutlined,
  Add,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import {
  fetchGetActiveSurveys,
  fetchRemoveActiveSurvey,
  fetchRemoveSurveyTemplate,
  fetchRenameActiveSurvey,
  fetchSetActiveSurvey,
  fetchGetSurveyTemplates,
  fetchRemoveUserFromSurvey,
} from '../../services/methods';
import { AuthContext } from '../../state/contexts/AuthContext';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import { SurveyMembers } from './SurveyMembers';
import { DialogRenameActiveSurvey } from './DialogRenameActiveSurvey';
import { BackNavigation } from '../elements/BackNavigation';
import { DefaultIconButton } from '../elements/DefaultIconButton';
import { DefaultButton } from '../elements/DefaultButton';

export const SurveysCounselor = () => {
  const { user } = useContext(AuthContext);
  const { userData } = useContext(UserDataContext);
  let history = useHistory();
  const theme = useTheme();
  const [surveyTemplates, setSurveyTemplates] = useState([]);
  const [activeSurveys, setActiveSurveys] = useState([]);
  const [activeOpenSurveys, setActiveOpenSurveys] = useState([]);
  const [tab, setTab] = useState(0);
  const [openMembersDialog, setOpenMembersDialog] = useState();
  const [openRenameDialog, setOpenRenameDialog] = useState();
  const [anchorElActiveSurveys, setAnchorElActiveSurveys] = useState(null);
  const [anchorElTemplates, setAnchorElTemplates] = useState(null);

  useEffect(() => {
    loadSurveys();
  }, [user, userData]);

  const loadSurveys = () => {
    getActiveSurveys();
    getSurveyTemplates();
  };

  const getSurveyTemplates = async () => {
    const templates = await fetchGetSurveyTemplates(user.sessionToken);
    setSurveyTemplates(templates);
  };

  const getActiveSurveys = async () => {
    const surveys = await fetchGetActiveSurveys(user.sessionToken);
    const activeSurveys = [];
    const openSurveys = [];
    surveys.forEach((survey) => {
      if (survey.author.userId === user.userId) {
        activeSurveys.push(survey);
      } else {
        openSurveys.push(survey);
      }
    });
    setActiveOpenSurveys(openSurveys);
    setActiveSurveys(activeSurveys);
  };

  const addSurvey = () => {
    history.push('/editsurvey');
  };

  const removeSurveyTemplate = async (event, index) => {
    if (surveyTemplates[index].author.userId === user.userId) {
      await fetchRemoveSurveyTemplate(
        user.userId,
        surveyTemplates[index].id,
        user.sessionToken
      );
    } else {
      await fetchRemoveUserFromSurvey(
        surveyTemplates[index],
        user.userId,
        user.sessionToken,
        'SurveyTemplate'
      );
    }
    const currentSurveys = [...surveyTemplates];
    currentSurveys.splice(index, 1);
    setSurveyTemplates(currentSurveys);
  };

  const removeActiveSurvey = async (event, index) => {
    await fetchRemoveActiveSurvey(
      user.userId,
      activeSurveys[index].id,
      user.sessionToken
    );
    const currentSurveys = [...activeSurveys];
    currentSurveys.splice(index, 1);
    setActiveSurveys(currentSurveys);
  };

  const removeActiveOpenSurvey = async (event, index) => {
    await fetchRemoveUserFromSurvey(
      activeOpenSurveys[index],
      user.userId,
      user.sessionToken,
      'Survey'
    );
    const currentSurveys = [...activeOpenSurveys];
    currentSurveys.splice(index, 1);
    setActiveOpenSurveys(currentSurveys);
  };

  const handleOpenMembersDialog = (event, survey, type) => {
    setOpenMembersDialog({ survey: survey, type: type });
  };

  const handleActivateSurvey = async (event, template) => {
    await fetchSetActiveSurvey(userData.userInfo, template, user.sessionToken);
    loadSurveys();
    setTab(0);
  };

  const handleRenameActiveSurvey = async (title, survey) => {
    await fetchRenameActiveSurvey(
      survey,
      title,
      userData.userInfo,
      user.sessionToken
    );
    loadSurveys();
  };

  const handleUpdateMembers = () => {
    setOpenMembersDialog(false);
    loadSurveys();
  };

  const handleActiveSurveysMenu = (event, survey) => {
    event.stopPropagation();
    setAnchorElActiveSurveys(survey);
  };

  const handleTemplatesMenu = (event, template) => {
    event.stopPropagation();
    setAnchorElTemplates(template);
  };

  return (
    <div style={{ width: '100%' }}>
      <BackNavigation title={'Umfragen'} />
      <AppBar
        position='static'
        style={{ background: theme.palette.primary.baseGradient }}
        elevation={0}
      >
        <Tabs
          value={tab}
          onChange={(event, value) => setTab(value)}
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.palette.primary.indicator,
              height: 3,
            },
          }}
          variant='fullWidth'
        >
          <Tab label='Aktiv' />
          <Tab label='Vorlagen' />
        </Tabs>
      </AppBar>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {tab === 0 && (
          <div>
            <Typography
              style={{ marginLeft: 15, marginTop: 20, fontWeight: 'bold' }}
              variant={'body2'}
            >
              Fragen von dir
            </Typography>
            {activeSurveys.length > 0 ? (
              activeSurveys.map((survey, index) => (
                <Card
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: 10,
                    margin: 5,
                  }}
                  key={index}
                  onClick={() => history.push('/surveyresults', { survey })}
                >
                  <DefaultIconButton
                    style={{ marginLeft: -5, marginRight: 10 }}
                    onClick={(e) => handleActiveSurveysMenu(e, survey)}
                  >
                    <MoreVert color={'primary'} />
                  </DefaultIconButton>
                  <Menu
                    open={anchorElActiveSurveys === survey}
                    onClose={(e) => {
                      e.stopPropagation();
                      setAnchorElActiveSurveys(null);
                    }}
                  >
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenRenameDialog(survey);
                        setAnchorElActiveSurveys(null);
                      }}
                    >
                      <DefaultIconButton
                        style={{ marginRight: 10 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenRenameDialog(survey);
                          setAnchorElActiveSurveys(null);
                        }}
                      >
                        <TextFields
                          fontSize={'small'}
                          style={{ color: theme.palette.primary.main }}
                        />
                      </DefaultIconButton>
                      Umbenennen
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        removeActiveSurvey(e, index);
                        setAnchorElActiveSurveys(null);
                      }}
                    >
                      <DefaultIconButton
                        style={{ marginRight: 10 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          removeActiveSurvey(e, index);
                          setAnchorElActiveSurveys(null);
                        }}
                      >
                        <Delete
                          fontSize={'small'}
                          style={{ color: theme.palette.primary.main }}
                        />
                      </DefaultIconButton>
                      Löschen
                    </MenuItem>
                  </Menu>
                  <div
                    style={{
                      fontSize: 14,
                      maxWidth: '50%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {survey.title || 'Titel'}
                  </div>
                  <div style={{ marginLeft: 'auto' }}>
                    <DefaultIconButton
                      style={{ marginRight: 10 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenMembersDialog(e, survey, 'Survey');
                      }}
                    >
                      <Send style={{ color: theme.palette.primary.main }} />
                    </DefaultIconButton>
                    <DefaultIconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push('/surveyresults', { survey });
                      }}
                    >
                      <AssessmentOutlined
                        style={{ color: theme.palette.primary.main }}
                      />
                    </DefaultIconButton>
                  </div>
                </Card>
              ))
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant={'body1'}
                  color={'primary'}
                  style={{ fontSize: 12, marginLeft: 15, marginTop: 5 }}
                >
                  Keine von dir gestellten Fragen, erstelle eine Vorlage und
                  aktiviere sie:
                </Typography>
                <DefaultIconButton
                  border={true}
                  style={{ marginLeft: 15, marginRight: 10 }}
                  onClick={addSurvey}
                >
                  <Add
                    fontSize={'small'}
                    style={{ color: theme.palette.primary.main }}
                  />
                </DefaultIconButton>
              </div>
            )}
            <Typography
              style={{ marginLeft: 15, marginTop: 25, fontWeight: 'bold' }}
              variant={'body2'}
            >
              Fragen an dich
            </Typography>
            {activeOpenSurveys.length > 0 ? (
              activeOpenSurveys.map((survey, index) => (
                <Card
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: 10,
                    margin: 5,
                  }}
                  key={index}
                >
                  <DefaultIconButton
                    style={{ padding: 0, marginLeft: -5, marginRight: 5 }}
                    onClick={(event) => removeActiveOpenSurvey(event, index)}
                  >
                    <Delete
                      fontSize={'small'}
                      style={{ color: theme.palette.primary.main }}
                    />
                  </DefaultIconButton>
                  <div
                    style={{
                      fontSize: 14,
                      maxWidth: '50%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {survey.title || 'Titel'}
                  </div>
                  <div style={{ marginLeft: 'auto' }}>
                    <IconButton
                      onClick={() => history.push('/answersurvey', { survey })}
                    >
                      <ArrowForwardIos
                        style={{ color: theme.palette.primary.main }}
                      />
                    </IconButton>
                  </div>
                </Card>
              ))
            ) : (
              <Typography
                variant={'body2'}
                color={'primary'}
                style={{ fontSize: 12, marginLeft: 15, marginTop: 5 }}
              >
                Es gibt im Moment keine Fragen an dich
              </Typography>
            )}
          </div>
        )}
        {tab === 1 && (
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <IconButton
              color={'primary'}
              variant={'outlined'}
              style={{ marginLeft: 'auto' }}
              onClick={addSurvey}
            >
              <AddCircleOutlined
                fontSize={'large'}
                style={{ color: theme.palette.primary.main }}
              />
            </IconButton>
            {surveyTemplates.map((template, index) => (
              <Card
                key={index}
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  padding: 10,
                  marginBottom: 5,
                  marginTop: 5,
                }}
                onClick={() =>
                  history.push('/editsurvey', { survey: template })
                }
              >
                <DefaultIconButton
                  style={{ marginLeft: -5, marginRight: 10 }}
                  onClick={(e) => handleTemplatesMenu(e, template)}
                >
                  <MoreVert color={'primary'} />
                </DefaultIconButton>
                <Menu
                  open={anchorElTemplates === template}
                  /*anchorEl={console.log("anchorEl", anchorEl) || anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}*/
                  onClose={(e) => {
                    e.stopPropagation();
                    setAnchorElTemplates(null);
                  }}
                >
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      removeSurveyTemplate(event, index);
                      setAnchorElTemplates(null);
                    }}
                  >
                    <DefaultIconButton
                      style={{ marginRight: 10 }}
                      onClick={(event) => {
                        event.stopPropagation();
                        removeSurveyTemplate(event, index);
                        setAnchorElTemplates(null);
                      }}
                    >
                      <Delete
                        fontSize={'small'}
                        style={{ color: theme.palette.primary.main }}
                      />
                    </DefaultIconButton>
                    Löschen
                  </MenuItem>
                  <MenuItem
                    disabled={
                      !template ||
                      template.author.userId !== userData.userInfo.userId
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenMembersDialog(e, template, 'SurveyTemplate');
                      setAnchorElTemplates(null);
                    }}
                  >
                    <DefaultIconButton
                      style={{ marginRight: 10 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenMembersDialog(e, template, 'SurveyTemplate');
                        setAnchorElTemplates(null);
                      }}
                    >
                      <Share
                        fontSize={'small'}
                        style={{ color: theme.palette.primary.main }}
                      />
                    </DefaultIconButton>
                    Teilen
                  </MenuItem>
                  <MenuItem
                    disabled={
                      !template ||
                      template.author.userId !== userData.userInfo.userId
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push('/editsurvey', { survey: template });
                      setAnchorElTemplates(null);
                    }}
                  >
                    <DefaultIconButton
                      style={{ marginRight: 10 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push('/editsurvey', { survey: template });
                        setAnchorElTemplates(null);
                      }}
                    >
                      <Edit
                        fontSize={'small'}
                        style={{ color: theme.palette.primary.main }}
                      />
                    </DefaultIconButton>
                    Bearbeiten
                  </MenuItem>
                </Menu>
                <div
                  style={{
                    fontSize: 14,
                    maxWidth: '50%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {template.title || 'Titel'}
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <DefaultButton
                    disableElevation
                    startIcon={
                      <PlaylistAddCheck
                        style={{ color: theme.palette.primary.main }}
                      />
                    }
                    customColor={theme.palette.secondary}
                    textColor={theme.palette.primary.main}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleActivateSurvey(e, template);
                    }}
                  >
                    Aktivieren
                  </DefaultButton>
                </div>
              </Card>
            ))}
          </div>
        )}
      </div>
      <Dialog
        fullWidth
        onClose={() => setOpenMembersDialog(false)}
        open={openMembersDialog}
      >
        <SurveyMembers
          setOpen={setOpenMembersDialog}
          handleUpdateMembers={handleUpdateMembers}
          userData={userData}
          survey={openMembersDialog && openMembersDialog.survey}
          type={openMembersDialog && openMembersDialog.type}
        />
      </Dialog>
      <Dialog
        fullWidth
        onClose={() => setOpenRenameDialog(false)}
        open={openRenameDialog}
      >
        <DialogRenameActiveSurvey
          setOpen={setOpenRenameDialog}
          handleRenameActiveSurvey={handleRenameActiveSurvey}
          survey={openRenameDialog}
        />
      </Dialog>
    </div>
  );
};
