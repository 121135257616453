export const generateCode = (length = 5) => {
  var coupon = '';
  var possible =
    'abcdefghijklmnopqrstuvwxyzAVBCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const codeLength = length;
  for (var i = 0; i < codeLength; i++) {
    coupon += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return coupon;
};
