export const dummyDataBenefits = [
  {
    area: 'Freiheit',
    facets: [
      {
        name: 'Abwechslung',
        value: 0,
      },
      {
        name: 'Unabhängigkeit',
        value: 0,
      },
      {
        name: 'Kreativität',
        value: 0,
      },
      {
        name: 'Spaß/Genuss',
        value: 0,
      },
    ],
  },
  {
    area: 'Sicherheit',
    facets: [
      {
        name: 'Finanzen',
        value: 0,
      },
      {
        name: 'Geborgenheit',
        value: 0,
      },
      {
        name: 'Gesundheit',
        value: 0,
      },
      {
        name: 'Orientierung',
        value: 0,
      },
    ],
  },
  {
    area: 'Fürsorge',
    facets: [
      {
        name: 'Zusammenhalt',
        value: 0,
      },
      {
        name: 'Hilfsbereitschaft',
        value: 0,
      },
      {
        name: 'Gleichheit',
        value: 0,
      },
      {
        name: 'Selbstlosigkeit',
        value: 0,
      },
    ],
  },
  {
    area: 'Stärke',
    facets: [
      {
        name: 'Selbstwirksamkeit',
        value: 0,
      },
      {
        name: 'Durchsetzungskraft',
        value: 0,
      },
      {
        name: 'Energie',
        value: 0,
      },
      {
        name: 'Stolz',
        value: 0,
      },
    ],
  },
];
