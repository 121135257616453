import React, { useContext, useEffect, useState } from 'react';
import { Paper, styled } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { UserDataContext } from 'state/contexts/UserDataContext';

export const StepOwnerAutoComplete = ({ owner, handleOwnerChange, goal }) => {
  const [goalMembers, setGoalMembers] = useState([]);
  useEffect(() => {
    if (goal && goal.members) {
      setGoalMembers(Object.values(goal.members));
    }
  }, [goal]);

  return (
    <Container>
      <Autocomplete
        id='tags-standard'
        options={goalMembers || []}
        getOptionLabel={(option) => option.userName}
        getOptionSelected={(option, value) => option.userId === value.userId}
        defaultValue={owner}
        onChange={(e, v) => v && handleOwnerChange(v)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={'outlined'}
            label='Name'
            placeholder='Verantwortlich'
          />
        )}
      />
    </Container>
  );
};

const Container = styled('div')({
  width: '100%',
  padding: 10,
});

const Contact = styled(Paper)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: 60,
  marginBottom: 10,
  backgroundColor: 'white',
});
