import ReactQuill from 'react-quill';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { saveAs } from 'file-saver';
import { dateWithTimeOptions } from '../../utils/constants';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import { Close, FileCopy, Save } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import { Button, styled, Typography } from '@material-ui/core';
var htmlToRtf = new HtmlToRtfBrowser();

const getDuration = (start, end) => {
  var diff = (end.getTime() - start.getTime()) / 1000;
  diff /= 60 * 60;
  return Math.abs(Math.round(diff * 10) / 10);
};

export const ClientDocExport = ({
  documentation,
  system,
  setOpen,
  userGoals,
}) => {
  const [reactQuillRef, setReactQuillRef] = useState();
  const [quillText, setQuillText] = useState('');

  /*useEffect(() => {
    if (quillText !== '') {
      const convertToWord = async () => {
        const quillDelta = reactQuillRef.current.getEditor().getContents();
        const quillToWordConfig = {
          exportAs: 'blob',
        };
        /!*const doc = await quillToWord.generateWord(
          quillDelta,
          quillToWordConfig
        );
        console.log("quilltoword doc", doc)*!/
        /!*saveAs(doc, 'iuvivo_docu.docx');
        finishExport();
        console.log('quill worddoc', doc);
        console.log('quill stuff', reactQuillRef.current.state.value, quillDelta)
        const quillHTML = reactQuillRef.current.state.value;
        console.log('quill stuff', quillHTML)
        const html = htmlToDocx.asBlob(quillHTML)
        saveAs(html, 'html-test.docx')*!/

        const rtf = htmlToRtf.convertHtmlToRtf(
          reactQuillRef.current.state.value
        );
        console.log('rtf', rtf);
        const blob = new Blob([rtf], { type: 'application/rtf;charset=utf-8' });
        saveAs(blob, 'rtf-test.rtf');
        /!*const link=window.URL.createObjectURL(blob);
        window.location=link;*!/
        //htmlToRtf.saveRtfInFile('htmltoreff-test.rtf', reactQuillRef.current.state.value)
      };
      convertToWord();
    }
  }, [quillText]);*/

  useEffect(() => {
    if (reactQuillRef && documentation && !quillText) {
      const editor = reactQuillRef.getEditor();
      const editorContents = editor.getContents();
      editorContents.push({ insert: system.name });
      editorContents.push({ insert: '\n', attributes: { header: 1 } });
      editorContents.push({
        insert: '------------------------------------------',
        attributes: { bold: true },
      });
      editorContents.push({ insert: '\n' });
      documentation.forEach((docu) => {
        editorContents.push({ insert: '\n' });
        editorContents.push({
          insert: '-------------------------------------',
        });
        editorContents.push({ insert: '\n', attributes: { header: 0 } });
        editorContents.push({
          insert:
            'von: ' +
            new Date(docu.startTime).toLocaleDateString(
              'de-DE',
              dateWithTimeOptions
            ),
          attributes: { bold: true },
        });
        editorContents.push({ insert: '\n' });
        editorContents.push({
          insert:
            'bis: ' +
            new Date(docu.endTime).toLocaleDateString(
              'de-DE',
              dateWithTimeOptions
            ),
          attributes: { bold: true },
        });
        editorContents.push({ insert: '\n' });
        editorContents.push({
          insert: 'Dauer: ' + getDuration(docu.startTime, docu.endTime),
          attributes: { bold: true },
        });
        editorContents.push({ insert: '\n' });
        editorContents.push({ insert: '\n' });

        docu.doc.forEach((doc) => {
          if (doc.text) {
            doc.text.ops.forEach((op) => editorContents.push(op));
          } else if (doc.goal && doc.goal.id && userGoals) {
            const goal = userGoals[doc.goal.id];

            if (goal) {
              const memberString =
                (goal.members &&
                  `${Object.values(goal.members).map(
                    (member) => ` ${member.userName}`
                  )}`) ||
                'Keine Mitglieder';
              editorContents.push({ insert: '\n' });
              editorContents.push({
                insert: 'Ziel: ' + goal.title,
                attributes: { bold: true, color: '#ee8a0f' },
              });
              editorContents.push({ insert: '\n' });
              editorContents.push({
                insert: 'Teilnehmer: ' + memberString,
              });
              editorContents.push({ insert: '\n' });
            }
          }
        });
      });
      setQuillText(editorContents);
    }
  }, [userGoals, system, documentation, reactQuillRef]);

  const exportToRTF = () => {
    const rtf = htmlToRtf.convertHtmlToRtf(reactQuillRef.state.value);
    const blob = new Blob([rtf], { type: 'application/rtf;charset=utf-8' });
    saveAs(
      blob,
      'Dokumentation_' +
        system.name +
        '_' +
        new Date(
          documentation[documentation.length - 1].endTime
        ).toLocaleDateString('de-DE', dateWithTimeOptions) +
        '.rtf'
    );
  };

  const setRef = useCallback((ref) => {
    setReactQuillRef(ref);
  }, []);

  return (
    <Dialog fullWidth onClose={() => setOpen(false)} open={true}>
      <Container>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: 10,
          }}
        >
          <Typography variant={'subtitle1'}>Dokumentation</Typography>
          <Close color={'primary'} onClick={() => setOpen(false)} />
        </div>
        <div style={{ alignSelf: 'flex-start', fontSize: 12 }}>
          Kopiere die Dokumentation mit "Strg+A" und "Strg-C" oder speichere sie
          als Datei.
        </div>
        <Button
          disabled={!quillText}
          style={{ marginTop: 10, marginBottom: 10 }}
          color={'primary'}
          variant={'outlined'}
          startIcon={<Save />}
          onClick={exportToRTF}
        >
          Speichern
        </Button>
        <ReactQuill
          style={{ width: '100%' }}
          modules={{ toolbar: null }}
          ref={setRef}
          value={quillText}
        >
          <div style={{ width: '100%' }} />
        </ReactQuill>
      </Container>
    </Dialog>
  );
};

const Container = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  padding: 10,
});
