import React, { useContext } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FolderOutlined, SupervisedUserCircle } from '@material-ui/icons';
import { UserDataContext } from '../../state/contexts/UserDataContext';

export const Manage = () => {
  let history = useHistory();
  const { userData } = useContext(UserDataContext);

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      {userData && userData.userInfo.assignment.type === 'organization' && (
        <ListItem
          button
          onClick={() => {
            history.push('/clients');
          }}
        >
          <ListItemIcon>
            <SupervisedUserCircle color={'primary'} />
          </ListItemIcon>
          <ListItemText primary={'Klient:innenverwaltung'} />
        </ListItem>
      )}
      <ListItem
        button
        onClick={() => {
          history.push('/files');
        }}
      >
        <ListItemIcon>
          <FolderOutlined color={'primary'} />
        </ListItemIcon>
        <ListItemText primary={'Dateimanager'} />
      </ListItem>
    </div>
  );
};
