import { Card, IconButton, Typography, useTheme } from '@material-ui/core';
import uuid from 'react-uuid';
import {
  ArrowBack,
  ArrowForwardIos,
  ArrowForwardIosSharp,
  Assessment,
  Delete,
  SupervisedUserCircle,
  TextFields,
} from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import userData from '../../services/userData';
import {
  fetchGetActiveSurveys,
  fetchRemoveUserFromSurvey,
} from '../../services/methods';
import { AuthContext } from '../../state/contexts/AuthContext';
import { SurveyAnswers } from './SurveyAnswers';
import { useHistory } from 'react-router-dom';

export const SurveysClient = () => {
  const { user } = useContext(AuthContext);
  let history = useHistory();
  const theme = useTheme();
  const [openSurveys, setOpenSurveys] = useState([]);

  useEffect(() => {
    const getActiveSurveys = async () => {
      const activeSurveys = await fetchGetActiveSurveys(user.sessionToken);
      setOpenSurveys(activeSurveys);
    };
    getActiveSurveys();
  }, [user]);

  const removeOpenSurvey = async (event, index) => {
    await fetchRemoveUserFromSurvey(
      openSurveys[index],
      user.userId,
      user.sessionToken,
      'Survey'
    );
    const currentSurveys = [...openSurveys];
    currentSurveys.splice(index, 1);
    setOpenSurveys(currentSurveys);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        <IconButton onClick={() => history.goBack()}>
          <ArrowBack />
        </IconButton>
        <Typography variant={'subtitle1'}>Umfragen</Typography>
      </div>
      {openSurveys.map((survey, index) => (
        <div key={survey.id}>
          <Card
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              padding: 10,
              marginBottom: 5,
              marginTop: 5,
            }}
            key={uuid()}
          >
            <IconButton
              style={{ padding: 0, marginLeft: -5, marginRight: 5 }}
              onClick={(event) => removeOpenSurvey(event, index)}
            >
              <Delete
                fontSize={'small'}
                style={{ color: theme.palette.primary.main }}
              />
            </IconButton>
            <div
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                color: theme.palette.primary.dark,
              }}
            >
              {survey.title || 'Titel'}
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <IconButton
                onClick={() => history.push('/answersurvey', { survey })}
              >
                <ArrowForwardIos
                  style={{ color: theme.palette.primary.main }}
                />
              </IconButton>
            </div>
          </Card>
        </div>
      ))}
      {openSurveys.length === 0 && (
        <Typography variant={'body2'} color={'primary'} style={{ margin: 15 }}>
          Aktuell liegen keine Fragen für dich vor
        </Typography>
      )}
    </div>
  );
};
