import { File } from '../Files/File';
import React, { useContext, useEffect, useState } from 'react';
import FilesContextProvider, {
  FilesContext,
} from '../../state/contexts/FilesContext';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { AuthContext } from '../../state/contexts/AuthContext';
import { IconButton, styled } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';

export const ClientSharedFiles = () => {
  return (
    <FilesContextProvider>
      <ClientFilesComponent />
    </FilesContextProvider>
  );
};

const ClientFilesComponent = () => {
  let history = useHistory();
  const { getFiles } = useContext(FilesContext);
  const { userData } = useContext(UserDataContext);
  const { user } = useContext(AuthContext);

  const [files, setFiles] = useState([]);
  const [openMembers, setOpenMembers] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [system, setSystem] = useState();

  useEffect(() => {
    if (userData && history.location && history.location.state) {
      setSystem(history.location.state.system);
    }
  }, [userData, history.location]);

  useEffect(() => {
    if (system) {
      reloadFiles();
    }
  }, [system]);

  const reloadFiles = async () => {
    const files = await getFiles(userData.userInfo, user.sessionToken);
    const clientFiles = [];
    const systemMembers =
      (system.members && system.members.map((member) => member.userId)) || [];
    files.forEach((file) => {
      if (
        file.members &&
        file.members.some((member) => systemMembers.includes(member.userId))
      ) {
        clientFiles.push(file);
      }
    });
    setFiles(clientFiles);
  };

  const handleShare = (file) => {
    setOpenMembers(true);
    setSelectedFile(selectedFile);
  };

  const handleMenu = (event, file) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedFile(file);
  };

  return (
    <FileList>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={() => history.goBack()}>
          <ArrowBack />
        </IconButton>
        <div style={{ fontSize: 16, fontWeight: 'bold' }}>
          {system && system.name}
        </div>
      </div>
      {files.length > 0 ? (
        files.map((file) => (
          <File
            onClick={() =>
              setSelectedFile(selectedFile !== file ? file : false)
            }
            file={file}
            selectedFile={selectedFile}
            userData={userData}
            onClickShare={(event) => handleShare(event, file)}
            ref={anchorEl}
            onClickMenu={(event) => handleMenu(event, file)}
          />
        ))
      ) : (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: 100,
          }}
        >
          <div style={{ width: 200, textAlign: 'center' }}>
            Es wurden noch keine Dateien geteilt.
            <br /> Gib welche im Dateimanager frei!
          </div>
        </div>
      )}
    </FileList>
  );
};

const FileList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});
