import { events } from 'utils/constants';

export const subscribeToQuery = async (query, handleEvent, sessionToken) => {
  const subscription = await query.subscribe(sessionToken);

  subscription.on('create', function (object) {
    console.log('subscription: object created');
    handleEvent({
      object,
      event: events.ADD,
    });
  });

  subscription.on('update', function (object) {
    console.log('subscription: object updated');
    handleEvent({
      object,
      event: events.UPDATE,
    });
  });

  subscription.on('enter', function (object) {
    console.log('object entered');
    handleEvent({
      object,
      event: events.ADD,
    });
  });

  subscription.on('leave', function (object) {
    console.log('object left');
    handleEvent({
      object,
      event: events.REMOVE,
    });
  });

  subscription.on('delete', function (object) {
    console.log('object deleted');
    handleEvent({
      object,
      event: events.REMOVE,
    });
  });

  return subscription;
};

export default {
  subscribeToQuery,
};
