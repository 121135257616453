import React, { useContext } from 'react';
import { makeStyles, Paper, styled } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { UserDataContext } from 'state/contexts/UserDataContext';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 3,
    border: 0,
  },
  tag: {
    paddingLeft: 3,
    height: 24,
    fontSize: 10,
  },
  inputRoot: {
    padding: 0,
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"]': {
      // Default left padding is 6px
      padding: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

export const ClientDocGoalMembers = ({ members, handleMembersChange }) => {
  const {
    userData: { contacts },
  } = useContext(UserDataContext);

  const classes = useStyles();

  return (
    <Container>
      <Autocomplete
        classes={{
          root: classes.root, // class name, e.g. `classes-nesting-root-x`
          label: classes.label, // class name, e.g. `classes-nesting-label-x`
          tag: classes.tag,
          inputRoot: classes.inputRoot,
        }}
        multiple
        id='tags-standard'
        options={contacts || []}
        getOptionLabel={(option) => option.userName}
        getOptionSelected={(option, value) => option.userId === value.userId}
        defaultValue={members}
        value={members}
        //inputValue={goal.members ? goal.members : undefined}
        onChange={(e, v) => handleMembersChange(v)}
        renderInput={(params) => <TextField {...params} variant={'outlined'} />}
      />
    </Container>
  );
};

const Container = styled('div')({
  width: '100%',
});
