import { Slider, withStyles } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import React from 'react';

const StyledSlider = withStyles({
  root: {
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

export const ValueSlider = ({
  sliderType,
  index,
  itemValue,
  onChangeCommitted,
}) => {
  const [value, setValue] = useState(0);

  useEffect(() => setValue(itemValue), [itemValue]);

  return (
    <StyledSlider
      defaultValue={5}
      value={value}
      style={{ color: sliderType === 'desire' ? '#ffad65' : '#52af77' }}
      valueLabelDisplay='auto'
      min={1}
      max={10}
      onChangeCommitted={(_, value) => {
        onChangeCommitted(index, sliderType, value);
      }}
      onChange={(_, value) => setValue(value)}
    />
  );
};
