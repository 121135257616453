import React, { useContext, useEffect, useState } from 'react';
import { AddCircle } from '@material-ui/icons';
import { IconButton, styled, useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { CustomAspectEdit } from '../elements/CustomAspectEdit';
import { Divider } from '../elements/Divider';
import { getIcons } from '../../utils/getIcons';

import { AuthContext } from 'state/contexts/AuthContext';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import Button from '@material-ui/core/Button';

export const CustomAspects = ({ goal, diaryDay, handleCustomAspectChange }) => {
  const [showDialog, setShowDialog] = useState();
  const [images, setImages] = useState();
  const [active, setActive] = useState([]);
  const [customAspects, setCustomAspects] = useState([]);

  const { user } = useContext(AuthContext);
  const { userData } = useContext(UserDataContext);

  useEffect(() => {
    if (userData.aspects) {
      setCustomAspects(userData.aspects);
    }
    if (
      user &&
      goal &&
      goal.customBenefits &&
      goal.customBenefits[user.userId]
    ) {
      setActive(goal.customBenefits[user.userId]);
    }
  }, [userData.aspects, user, goal]);

  useEffect(() => {
    if (diaryDay && diaryDay.activities) {
      setActive(diaryDay.activities);
    }
  }, [diaryDay]);

  useEffect(() => {
    const images = getIcons(customAspects);
    setImages(images);
  }, [customAspects]);

  const handleChangeMultipleActive = (item) => {
    if (active.filter((active) => active.title === item.title).length > 0) {
      const remaining = active.filter(
        (element) => element.title !== item.title
      );
      handleCustomAspectChange(remaining);
      setActive(remaining);
    } else {
      const actives = [...active];
      actives.push(item);
      handleCustomAspectChange(actives);
      setActive((active) => [...active, item]);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        marginTop: 10,
      }}
    >
      <Button
        variant={'outlined'}
        color={'primary'}
        style={{ textTransform: 'none' }}
        onClick={() => setShowDialog(true)}
      >
        {' '}
        <Typography style={{ marginRight: 10 }} variant={'subtitle2'}>
          {goal ? 'Vorteile' : 'Aktivität'} hinzufügen
        </Typography>
        <AddCircle color={'primary'} fontSize='large' />
      </Button>
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
        }}
      >
        {customAspects &&
          customAspects.map((benefit, index) => {
            const isActive =
              active.filter((item) => item.title === benefit.title).length > 0;
            return (
              <div
                onClick={() => handleChangeMultipleActive(benefit)}
                key={benefit.title + index}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  maxWidth: 70,
                  margin: 10,
                }}
              >
                <StyledIcon
                  key={benefit.title}
                  active={isActive}
                  icon={images[benefit.image]}
                />
                <span style={{ fontSize: 12, textAlign: 'center' }}>
                  {benefit.title}
                </span>
              </div>
            );
          })}
        <Divider />
      </div>
      <i style={{ fontSize: 12, alignSelf: 'center' }}>
        Icon klicken zum Aktivieren
      </i>
      {showDialog && <CustomAspectEdit setShowDialog={setShowDialog} />}
    </div>
  );
};

const StyledIcon = styled('div')(({ active, icon }) => ({
  width: 50,
  height: 50,
  //backgroundColor: active ? 'green' : undefined,
  borderRadius: '50%',
  backgroundImage: 'url(' + icon + ')',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  filter: active ? undefined : 'grayscale(100%)',
  opacity: active ? 1 : 0.4,
}));
