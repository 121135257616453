import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import initialData from '../DroppableStuff/initialData';
import DraggableItem from '../DroppableStuff/DraggableItem';
import DroppableArea from '../DroppableStuff/DroppableArea';
import { Icon, IconButton, styled, Typography } from '@material-ui/core';
import { Accordeon } from './Accordeon';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import StepList from './StepList';
import { GoalDetails } from './GoalDetails';
import { Add, AddCircleOutline, Star } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import StorageIcon from '@material-ui/icons/Storage';

import { GoalsContext } from 'state/contexts/GoalsContext';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { useTheme } from '@material-ui/core/styles';
import StepsIcon from '../../res/StepsIcon';
import { fetchSendNotification } from '../../services/notifications';
import { AuthContext } from '../../state/contexts/AuthContext';
import EmptyGoals from './EmptyGoals';
import { ClientsContext } from '../../state/contexts/ClientsContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getClientGoals, getPrivateGoals } from '../../utils/selectGoals';

const getWinTendency = (goal) => {
  let progress = 0;
  const { steps } = goal;

  if (steps) {
    Object.values(steps).forEach((step) => {
      if (step.done === 'done') {
        progress += 1 / Object.keys(steps).length;
      } else {
        if (!isNaN(new Date(step.deadline)) && step.deadline - Date.now() < 0) {
          progress += (step.deadline - Date.now()) / 86400000 / 100;
        }
      }
    });
    progress = Math.round(progress * 100);
  }
  return progress;
};

const sortedGoals = (goalProps) =>
  Object.keys(goalProps).sort((a, b) => {
    return goalProps[a].position.localeCompare(goalProps[b].position);
  });

export default ({ system }) => {
  let history = useHistory();
  const [data, setData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedGoals, setSelectedGoals] = useState();

  const { goals } = useContext(GoalsContext);
  const { userData, setArchiveAndPrepareGoalPoints } = useContext(
    UserDataContext
  );
  const { user, isOnline } = useContext(AuthContext);
  const { clientSystems } = useContext(ClientsContext);
  const [localGoals, setLocalGoals] = useState();

  useEffect(() => {
    if (goals) {
      setLocalGoals(goals);
    }
  }, [goals]);

  useEffect(() => {
    if (localGoals && userData) {
      let chosenGoals = localGoals;
      //show only private goals if coming from counselor view for clients
      if (system && system.private && clientSystems) {
        chosenGoals = getPrivateGoals(localGoals, clientSystems);
      }
      //show only selectedGoals of client system if coming from counselor view for clients
      if (system && system.systemId) {
        chosenGoals = getClientGoals(localGoals, system);
      }
      setSelectedGoals(chosenGoals);
    }
  }, [userData, localGoals, system, clientSystems]);

  useEffect(() => {
    setIsLoading(true);
    if (selectedGoals) {
      initialData.columns['column-1'].itemIds = [];
      initialData.items = {};

      const newData = {
        ...initialData,
      };

      Object.values(selectedGoals).forEach((goal) => {
        const { id } = goal;
        newData.columns['column-1'].itemIds.push(id);
        newData.items[id] = {
          id: goal,
          content: goalAccordeon(id, goal),
        };
      });

      setData(newData);
      setIsLoading(false);
    }
  }, [selectedGoals]);

  // const setGoalOrder = (data) => {
  //   const goalOrder = data.columns['column-1'].itemIds;
  //   const goalsWithPositions = goalOrder.reduce(
  //     (a, b) => ((a[b] = { position: goalOrder.indexOf(b).toString() }), a),
  //     {}
  //   );
  //   setGoalProps(goalsWithPositions);
  //   editGoalsPositions(
  //     context.userInfo.userId,
  //     goalsWithPositions,
  //     () => 'new order set'
  //   );
  // };

  const onDragEnd = (result) => {
    // const { destination, source } = result;
    // if (!destination) {
    //   return;
    // }
    // if (destination.droppableId === source.droppableId) {
    //   const dataClone = Object.assign({}, data);
    //   const items = dataClone.columns[source.droppableId].itemIds; // JSON.parse(JSON.stringify(data));
    //   const [removed] = items.splice(source.index, 1);
    //   items.splice(destination.index, 0, removed);
    //   setGoalOrder(dataClone);
    //   setData(dataClone);
    // }
  };

  const handleArchive = (id) => {
    if (isOnline()) {
      if (selectedGoals[id].goalOwner.userId === userData.userInfo.userId) {
        const membersToNotify = Object.values(selectedGoals[id].members)
          .filter((member) => member.userId !== userData.userInfo.userId)
          .map((member) => member.userId);
        if (membersToNotify.length > 0) {
          fetchSendNotification(
            membersToNotify,
            {
              messageText: selectedGoals[id].title,
              messageSender: userData.userInfo.userName,
            },
            'goalComplete',
            user.sessionToken
          );
        }
      }
      setArchiveAndPrepareGoalPoints(
        userData.userInfo,
        selectedGoals[id],
        user.sessionToken
      );
    }
  };

  const goalAccordeon = (id, goal) => {
    if (goal) {
      const winTendency = getWinTendency(goal);
      return (
        <Accordeon
          key={id}
          expanded={false}
          id={id}
          data={goal}
          winTendency={winTendency}
          system={system}
        >
          {winTendency === 100 ? (
            <ExpansionDetailsViewport>
              <div
                style={{
                  display: 'flex',
                  padding: 10,
                  justifyContent: 'center',
                }}
              >
                <Button
                  color={'primary'}
                  variant={'outlined'}
                  onClick={() => handleArchive(id)}
                >
                  <Star style={{ marginRight: 10, color: 'orange' }} />
                  Erfolg sichern
                </Button>
              </div>
            </ExpansionDetailsViewport>
          ) : (
            <ExpansionDetailsViewport>
              <GoalDetails goal={goal} id={id} progress={winTendency} />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: 10,
                }}
              >
                <StepsIcon size={25} />
                <Typography
                  variant={'body2'}
                  style={{ fontWeight: 'bold', paddingLeft: 5 }}
                >
                  Schritte
                </Typography>
              </div>

              <StepsViewport>
                <StepList goal={goal} />
              </StepsViewport>

              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <IconButton onClick={() => history.push('/addstep', { id })}>
                  <AddCircleOutline
                    style={{ color: 'rgba(154,206,89,0.49)' }}
                    fontSize={'large'}
                  />{' '}
                </IconButton>
              </div>
            </ExpansionDetailsViewport>
          )}
        </Accordeon>
      );
    }
  };

  return !isLoading ? (
    <DragDropContext style={{ width: '100%' }} onDragEnd={onDragEnd}>
      {system && data && Object.keys(data.items).length === 0 ? (
        <ResultViewport>
          <EmptyGoals system={system} />
        </ResultViewport>
      ) : (
        <ResultViewport>
          <DroppableArea
            style={{ width: '100%' }}
            key={Object.keys(data.columns)[0]}
            columnId={Object.keys(data.columns)[0]}
          >
            {data.columns[Object.keys(data.columns)[0]].itemIds.map(
              (itemId, index) => {
                return (
                  <DraggableItem
                    key={itemId}
                    itemId={itemId}
                    index={index}
                    data={data}
                  />
                );
              }
            )}
          </DroppableArea>
          <AddGoalButton
            onClick={() => history.push('/addgoal', { system: system })}
          >
            <Add
              style={{ color: 'white', width: 30, height: 30 }}
              fontSize={'large'}
            />{' '}
          </AddGoalButton>
        </ResultViewport>
      )}
    </DragDropContext>
  ) : (
    <ProgressContainer>
      <CircularProgress color='primary' />
    </ProgressContainer>
  );
};

const ProgressContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '50vh',
});

const AddGoalButton = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: '38px',
  width: 46,
  height: 46,
  zIndex: 200,
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StepsViewport = styled('div')({
  display: 'flex',
  width: '100%',
});

const ExpansionDetailsViewport = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: theme.palette.primary.verylight,
}));

const ResultViewport = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 10,
});
