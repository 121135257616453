import React, { useContext, useEffect, useState } from 'react';
import {
  IconButton,
  Paper,
  styled,
  TextField,
  useTheme,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { useHistory } from 'react-router-dom';
import { Email, Close, Check } from '@material-ui/icons';
import { AuthContext } from '../../state/contexts/AuthContext';

export default () => {
  const { userData, searchUser, setContactRequest } = useContext(
    UserDataContext
  );
  const { user, isOnline } = useContext(AuthContext);
  const [searchMember, setSearchMember] = useState('');
  const [foundMembers, setFoundMembers] = useState([]);
  const [loading, setLoading] = useState();
  const [contacts, setContacts] = useState([]);
  const [requests, setRequests] = useState({});
  const history = useHistory();
  const theme = useTheme();

  useEffect(() => {
    if (userData) {
      if (userData.contacts) {
        setContacts(userData.contacts);
      }
      if (userData.contactRequests) {
        setRequests(userData.contactRequests);
      }
    }
  }, [userData]);

  const handleNameChange = (event) => {
    setSearchMember(event.target.value);
  };

  const handleRequest = (member) => {
    if (isOnline()) {
      setContactRequest(
        userData.userInfo,
        member,
        'pending',
        user.sessionToken
      );
    }
  };

  const handleSearch = async () => {
    if (isOnline()) {
      if (
        searchMember &&
        searchMember !== userData.userInfo.userName &&
        contacts.filter((contact) => contact.userName === searchMember)
          .length === 0
      ) {
        setLoading(true);
        setFoundMembers([]);
        let result = await searchUser(searchMember, user.sessionToken);
        setLoading(false);
        if (result.length === 0) {
          result = null;
        }
        setFoundMembers(result);
      }
    }
  };

  const handleReaction = (request, status) => {
    if (isOnline()) {
      setContactRequest(request, userData.userInfo, status, user.sessionToken);
    }
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        padding: 10,
        backgroundColor: 'white',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        <StyledTextField
          placeholder='Name'
          value={searchMember}
          margin='normal'
          variant='outlined'
          label={'Suche eine Person'}
          onChange={handleNameChange}
        />
        <IconButton onClick={handleSearch}>
          <SearchIcon />
        </IconButton>
      </div>
      <MemberContainer>
        {loading && <CircularProgress color='primary' />}
        {foundMembers ? (
          foundMembers.map((member, index) => (
            <Member key={index}>
              {member.userName}
              <Button
                variant='contained'
                color='primary'
                onClick={() => handleRequest(member)}
              >
                Kontaktieren
              </Button>
            </Member>
          ))
        ) : (
          <div>Benutzer nicht gefunden</div>
        )}
      </MemberContainer>
      <ContactsContainer>
        {Object.keys(requests).filter(
          (key) => requests[key].from.userId === userData.userInfo.userId
        ).length > 0 && <Title>Kontaktanfragen von mir</Title>}
        {Object.keys(requests).map((key) => {
          if (requests[key].from.userId === userData.userInfo.userId) {
            return (
              <RequestTo status={requests[key].status}>
                {requests[key].to.userName}{' '}
                {requests[key].status === 'decline'
                  ? 'hat abgelehnt'
                  : '(Warte auf Antwort...)'}
              </RequestTo>
            );
          }
        })}
        {Object.keys(requests).filter(
          (key) => requests[key].to.userId === userData.userInfo.userId
        ).length > 0 && <Title>Kontaktanfragen an mich</Title>}
        {Object.keys(requests).map((key) => {
          if (requests[key].to.userId === userData.userInfo.userId) {
            return (
              <RequestFrom
                style={{
                  backgroundColor:
                    requests[key].from.status === 'accept'
                      ? 'green'
                      : undefined,
                }}
              >
                {requests[key].from.userName}
                <div>
                  <StyledIconButton
                    style={{ marginRight: 40, backgroundColor: 'white' }}
                    onClick={() =>
                      handleReaction(requests[key].from, 'decline')
                    }
                  >
                    <Close fontSize={'small'} style={{ color: 'red' }} />{' '}
                  </StyledIconButton>
                  <StyledIconButton
                    style={{ backgroundColor: 'white' }}
                    onClick={() => handleReaction(requests[key].from, 'accept')}
                  >
                    <Check fontSize={'small'} style={{ color: 'green' }} />{' '}
                  </StyledIconButton>
                </div>
              </RequestFrom>
            );
          }
        })}
      </ContactsContainer>
      <ContactsContainer>
        {contacts.length > 0 && <Title>Meine Kontakte</Title>}
        {contacts.map((contact) => (
          <Contact
            style={{ backgroundColor: theme.palette.secondary.main }}
            onClick={() =>
              history.push('/chat', { contact: { chatData: contact } })
            }
          >
            {contact.userName}
            <IconButton
              variant={'contained'}
              onClick={() =>
                history.push('/chat', { contact: { chatData: contact } })
              }
            >
              <Email color={'primary'} />
            </IconButton>
          </Contact>
        ))}
      </ContactsContainer>
    </div>
  );
};

const StyledTextField = styled(TextField)({
  width: '100%',
});

const ContactsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
});

const MemberContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const Contact = styled(Paper)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: 10,
  height: 60,
  marginBottom: 10,
});

const RequestTo = styled(Paper)(({ status }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: 50,
  marginBottom: 10,
  padding: 10,
  backgroundColor: status === 'decline' ? '#e7e7e7' : '#ffcc9d',
}));

const RequestFrom = styled(Paper)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: 70,
  marginBottom: 10,
  padding: 10,
  backgroundColor: '#d9ffbe',
});

const Member = styled(Paper)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  width: '100%',
  height: 60,
  marginBottom: 10,
});

const Title = styled('div')({
  marginBottom: 10,
  fontWeight: 500,
});

const StyledIconButton = styled(IconButton)({
  boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.55)',
});
