export const calculatePersonalResults = (values) => {
  const openBelief =
    (values[0].belief +
      values[10].belief +
      values[12].belief +
      values[6].belief) /
    4;
  const openDesire =
    (values[0].desire +
      values[10].desire +
      values[12].desire +
      values[6].desire) /
    4;

  const selfBelief =
    (values[14].belief +
      values[8].belief +
      values[3].belief +
      values[17].belief) /
    4;
  const selfDesire =
    (values[14].desire +
      values[8].desire +
      values[3].desire +
      values[17].desire) /
    4;

  const conservationBelief =
    (values[18].belief +
      values[11].belief +
      values[4].belief +
      values[7].belief +
      values[16].belief +
      values[5].belief) /
    6;
  const conservationDesire =
    (values[18].desire +
      values[11].desire +
      values[4].desire +
      values[7].desire +
      values[16].desire +
      values[5].desire) /
    6;

  const otherBelief =
    (values[1].belief +
      values[13].belief +
      values[15].belief +
      values[9].belief +
      values[2].belief) /
    5;
  const otherDesire =
    (values[1].desire +
      values[13].desire +
      values[15].desire +
      values[9].desire +
      values[2].desire) /
    5;

  const openBeliefRelative = (openBelief / 10) * openDesire;
  const selfBeliefRelative = (selfBelief / 10) * selfDesire;
  const otherBeliefRelative = (otherBelief / 10) * otherDesire;
  const conservationBeliefRelative =
    (conservationBelief / 10) * conservationDesire;

  return {
    beliefs: [
      openBeliefRelative,
      selfBeliefRelative,
      conservationBeliefRelative,
      otherBeliefRelative,
    ],
    desires: [openDesire, selfDesire, conservationDesire, otherDesire],
  };
};

export const calculatePersonalResultsPerDimension = (values) => {
  const openSelfDirectionDesire = (values[0].desire + values[10].desire) / 2;
  const openSelfDirectionBelief = (values[0].belief + values[10].belief) / 2;
  const openStimulationDesire = values[12].desire;
  const openStimulationBelief = values[12].belief;

  const openHedonismDesire = values[6].desire;
  const openHedonismBelief = values[6].belief;

  const selfAchievementDesire = values[14].desire;
  const selfAchievementBelief = values[14].belief;
  const selfPowerDesire =
    (values[3].desire + values[8].desire + values[17].desire) / 3;
  const selfPowerBelief =
    (values[3].belief + values[8].belief + values[17].belief) / 3;

  const conservationSecurityDesire = (values[7].desire + values[16].desire) / 2;
  const conservationSecurityBelief = (values[7].belief + values[16].belief) / 2;
  const conservationTraditionDesire =
    (values[4].desire +
      values[5].desire +
      values[11].desire +
      values[17].desire +
      values[18].desire) /
    5;
  const conservationTraditionBelief =
    (values[4].belief +
      values[5].belief +
      values[11].belief +
      values[17].belief +
      values[18].belief) /
    5;

  const otherBenevolenceDesire = (values[1].desire + values[13].desire) / 2;
  const otherBenevolenceBelief = (values[1].belief + values[13].belief) / 2;
  const otherUniversalismDesire =
    (values[2].desire + values[9].desire + values[15].desire) / 3;
  const otherUniversalismBelief =
    (values[2].belief + values[9].belief + values[15].belief) / 3;

  const openSelfDirectionBeliefRelative =
    (openSelfDirectionBelief / 10) * openSelfDirectionDesire;
  const openStimulationBeliefRelative =
    (openStimulationBelief / 10) * openStimulationDesire;
  const openHedonismBeliefRelative =
    (openHedonismBelief / 10) * openHedonismDesire;
  const selfAchievementBeliefRelative =
    (selfAchievementBelief / 10) * selfAchievementDesire;
  const selfPowerBeliefRelative = (selfPowerBelief / 10) * selfPowerDesire;
  const conservationSecurityBeliefRelative =
    (conservationSecurityBelief / 10) * conservationSecurityDesire;
  const conservationTraditionBeliefRelative =
    (conservationTraditionBelief / 10) * conservationTraditionDesire;
  const otherBenevolenceBeliefRelative =
    (otherBenevolenceBelief / 10) * otherBenevolenceDesire;
  const otherUniversalismBeliefRelative =
    (otherUniversalismBelief / 10) * otherUniversalismDesire;

  return {
    beliefs: [
      openSelfDirectionBeliefRelative,
      openStimulationBeliefRelative,
      openHedonismBeliefRelative,
      selfAchievementBeliefRelative,
      selfPowerBeliefRelative,
      conservationSecurityBeliefRelative,
      conservationTraditionBeliefRelative,
      otherBenevolenceBeliefRelative,
      otherUniversalismBeliefRelative,
    ],
    desires: [
      openSelfDirectionDesire,
      openStimulationDesire,
      openHedonismDesire,
      selfAchievementDesire,
      selfPowerDesire,
      conservationSecurityDesire,
      conservationTraditionDesire,
      otherBenevolenceDesire,
      otherUniversalismDesire,
    ],
  };
};
