import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  styled,
  TextField,
  Typography,
} from '@material-ui/core';
import CustomSnackbar from '../elements/CustomSnackbar';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import localeDe from 'date-fns/locale/de';
import StepsIcon from '../../res/StepsIcon';
import ButtonIconWrapper from '../elements/ButtonIconWrapper';
import { Save, Delete, Edit } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { GoalsContext } from '../../state/contexts/GoalsContext';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { fetchRemoveStep } from '../../services/goal';
import { useTheme } from '@material-ui/core/styles';
import { Divider } from '../elements/Divider';
import { fetchSendNotification } from '../../services/notifications';
import { AuthContext } from '../../state/contexts/AuthContext';
import Dialog from '@material-ui/core/Dialog';
import { StepOwnerAutoComplete } from './StepOwnerAutoComplete';
import { ButtonEditCircle } from '../elements/ButtonEditCircle';
import { BackNavigation } from '../elements/BackNavigation';

export const EditStep = () => {
  let history = useHistory();
  const { userData } = useContext(UserDataContext);
  const { createStep, goals } = useContext(GoalsContext);
  const { user, isOnline } = useContext(AuthContext);
  const [step, setStep] = useState();
  const [successfullySaved, setSuccessfullySaved] = useState();
  const [count, setCount] = useState(1);
  const [remindDays, setRemindDays] = useState();
  const [showOwnerDialog, setShowOwnerDialog] = useState();

  useEffect(() => {
    if (history.location.state.data) {
      setStep(history.location.state.data);
    } else if (userData.userInfo) {
      setStep((prev) => ({
        ...prev,
        owner: {
          userId: userData.userInfo.userId,
          userName: userData.userInfo.userName,
        },
        done: 'not_done',
        deadline: new Date(),
      }));
    }
  }, [userData, history]);

  useEffect(() => {
    if (step && step.count) {
      setCount(step.count.length);
    }
  }, [step]);

  useEffect(() => {
    if (step && step.remindDays) {
      setRemindDays(step.remindDays);
    }
  }, [step]);

  const handleTitleChange = (event) => {
    const title = event.target.value;
    setStep((prev) => ({ ...prev, title: title }));
  };

  const handleDateChange = (event) => {
    const deadline = event;
    if (deadline) {
      setStep((prev) => ({ ...prev, deadline: deadline.getTime() }));
    }
  };

  const handleCountChange = (event) => {
    let count = event.target.value;
    if (count > 14) {
      count = 14;
    }
    let countArray = [];
    for (let x = 0; x < count; x++) {
      countArray.push(false);
    }
    countArray = countArray.length > 1 ? countArray : undefined;
    setStep((prev) => ({ ...prev, count: countArray }));
    setCount(count);
  };

  const handleRemindChange = (event) => {
    let remindDays = event.target.value > 0 ? event.target.value : undefined;
    setStep((prev) => ({ ...prev, remindDays: remindDays }));
    setRemindDays(remindDays);
  };

  const firestoreResponse = (response) => {
    setSuccessfullySaved(true);
    history.goBack();
  };

  const handleSave = async () => {
    if (isOnline()) {
      await createStep(history.location.state.id, step, user.sessionToken);
      if (!history.location.state.data) {
        // is new step
        const goal = goals[history.location.state.id];
        const membersToNotify = Object.values(goal.members)
          .filter((member) => member.userId !== userData.userInfo.userId)
          .map((member) => member.userId);
        if (membersToNotify.length > 0) {
          fetchSendNotification(
            membersToNotify,
            {
              messageText: step.title,
              messageSender: userData.userInfo.userName,
            },
            'newStep',
            user.sessionToken
          );
        }
      }
      firestoreResponse();
    }
  };

  const handleDelete = async () => {
    if (isOnline()) {
      await fetchRemoveStep(
        history.location.state.id,
        step.id,
        user.sessionToken
      );
      history.goBack();
    }
  };

  const handleOwnerChange = (owner) => {
    setStep((prev) => ({
      ...prev,
      owner: { userId: owner.userId, userName: owner.userName },
    }));
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeDe}>
      <div
        style={{
          width: '100%',
          height: '100%',
          padding: 5,
          maxWidth: 800,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <BackNavigation title={'Schritt'} />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 10,
            backgroundColor: useTheme().palette.primary.verylight,
          }}
        >
          <StepsIcon size={40} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography style={{ fontWeight: 'bold' }} variant='body1'>
              {step ? step.title : null}{' '}
            </Typography>
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={() => setShowOwnerDialog(true)}
            >
              <Typography
                variant='body2'
                style={{ marginRight: 5, fontWeight: 'bold' }}
              >
                <span style={{ fontSize: 12, fontWeight: 'normal' }}>
                  verantwortlich:
                </span>{' '}
                {step ? step.owner.userName : null}{' '}
              </Typography>
              <ButtonEditCircle size={18} />
            </div>
          </div>
          <IconButton
            style={{ marginLeft: 'auto', padding: 5 }}
            onClick={handleDelete}
          >
            <Delete color={'primary'} style={{ width: 30, height: 30 }} />
          </IconButton>
        </div>
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              maxWidth: 400,
            }}
          >
            <div
              style={{
                borderRadius: 5,
                border: `1px solid ${useTheme().palette.primary.light}`,
                padding: 10,
                textAlign: 'center',
                backgroundColor: useTheme().palette.primary.midlight,
              }}
            >
              <Typography variant='subtitle2'>
                Schritte sind der Weg zum Ziel. Bestimme klar und konkret, was
                genau du tun möchtest!
              </Typography>
            </div>
            <StyledTextField
              multiline
              style={{ maxWidth: 400 }}
              placeholder='Schritt eingeben'
              value={step ? step.title : ''}
              margin='normal'
              variant='outlined'
              label={'Was möchtest Du tun?'}
              onChange={handleTitleChange}
            />
            <StyledTextField
              style={{ maxWidth: 70, alignSelf: 'flex-start' }}
              placeholder='Anzahl eingeben'
              value={count ? count : null}
              margin='normal'
              variant='outlined'
              label={'Wie oft? (optional)'}
              type='number'
              onChange={handleCountChange}
            />
            <Divider
              style={{ marginTop: 20, marginBottom: 20 }}
              width={'100%'}
              marginLR={10}
              color={useTheme().palette.primary.light}
            />
            <div
              style={{
                borderRadius: 5,
                border: `1px solid ${useTheme().palette.primary.light}`,
                padding: 10,
                textAlign: 'center',
                backgroundColor: useTheme().palette.primary.midlight,
              }}
            >
              <Typography variant='subtitle2'>
                Besonders wichtig ist ein konkreter Termin - bis wann willst du
                deinen Schritt erledigt haben?
              </Typography>
            </div>
            <div style={{ marginTop: 20, alignSelf: 'flex-start' }}>
              <DatePicker
                style={{ maxWidth: 400 }}
                format='dd. MMMMMMMMM yyyy'
                value={step ? step.deadline : new Date().getTime()}
                onChange={handleDateChange}
              />
            </div>
            <FormControl
              style={{
                marginTop: 10,
                minWidth: 150,
                maxWidth: 300,
                alignSelf: 'flex-start',
              }}
            >
              <InputLabel>Erinnern</InputLabel>
              <Select
                value={remindDays ? remindDays : ''}
                onChange={handleRemindChange}
                defaultValue={0}
              >
                <MenuItem value={0}>Nicht erinnern</MenuItem>
                <MenuItem value={1}>1 Tag vorher</MenuItem>
                <MenuItem value={2}>2 Tage vorher</MenuItem>
                <MenuItem value={3}>3 Tage vorher</MenuItem>
                <MenuItem value={4}>4 Tage vorher</MenuItem>
                <MenuItem value={5}>5 Tage vorher</MenuItem>
                <MenuItem value={6}>6 Tage vorher</MenuItem>
                <MenuItem value={7}>7 Tage vorher</MenuItem>
                <MenuItem value={8}>8 Tage vorher</MenuItem>
                <MenuItem value={9}>9 Tage vorher</MenuItem>
                <MenuItem value={10}>10 Tage vorher</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div
            style={{
              position: 'fixed',
              display: 'flex',
              flexDirection: 'column',
              bottom: 56,
              right: 10,
            }}
          >
            <IconButton style={{ padding: 0 }} onClick={handleSave}>
              <ButtonIconWrapper>
                <Save style={{ color: 'white', width: 40, height: 40 }} />
              </ButtonIconWrapper>
            </IconButton>
            Speichern
          </div>
          {successfullySaved && (
            <CustomSnackbar setSuccessfullySaved={setSuccessfullySaved} />
          )}
        </div>
      </div>
      <Dialog
        fullWidth
        onClose={() => setShowOwnerDialog(false)}
        open={showOwnerDialog}
      >
        <StepOwnerAutoComplete
          owner={step && step.owner}
          handleOwnerChange={handleOwnerChange}
          goal={
            history &&
            history.location.state &&
            goals &&
            goals[history.location.state.id]
          }
        />
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={() => setShowOwnerDialog(false)}
        >
          Ok
        </Button>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
};

const StyledTextField = styled(TextField)({
  width: '100%',
});
