import Parse from '../utils/initParse';
import { mapApiToCalendarEventModel } from '../models/CalendarEventModel';

const CalendarEvent = Parse.Object.extend('CalendarEvent');

const acl = (event) => {
  const acl = new Parse.ACL();
  acl.setReadAccess(event.author.userId, true);
  acl.setWriteAccess(event.author.userId, true);

  event.members &&
    Object.values(event.members).forEach((member) => {
      acl.setReadAccess(member.userId, true);
      acl.setWriteAccess(member.userId, true);
    });
  return acl;
};

export const fetchSetCalendarEvent = async (event, userInfo, sessionToken) => {
  let calendarEvent;
  if (event && event.id) {
    const eventQuery = new Parse.Query(CalendarEvent);
    eventQuery.equalTo('objectId', event.id);
    calendarEvent = await eventQuery.first({ sessionToken });
  } else {
    calendarEvent = new CalendarEvent();
    calendarEvent.set('author', {
      userId: userInfo.userId,
      userName: userInfo.userName,
    });
  }
  calendarEvent.set('title', event.title);
  calendarEvent.set('color', event.color);
  calendarEvent.set('description', event.description);
  calendarEvent.set('start', event.start);
  calendarEvent.set('end', event.end);
  calendarEvent.set('recurrenceEnd', event.recurrenceEnd);
  calendarEvent.set('recurrenceFreq', event.recurrenceFreq);
  calendarEvent.set('exceptions', event.exceptions);
  calendarEvent.set('exceptionId', event.exceptionId);
  calendarEvent.set('originalEventId', event.originalEventId);
  calendarEvent.set('originalStart', event.originalStart);
  calendarEvent.set('originalEnd', event.originalEnd);
  calendarEvent.set('members', event.members);
  calendarEvent.set('reminders', event.reminders);

  calendarEvent.setACL(acl(event));
  return await calendarEvent.save({}, { sessionToken });
};

export const fetchGetCalendarEvents = async (dateRange, sessionToken) => {
  //get all events for last, current and next month (to show overlapping events of surrounding months in month view)
  const tmpStartDate = new Date(dateRange.year, dateRange.month);
  const startDate = new Date(
    tmpStartDate.setMonth(tmpStartDate.getMonth() - 1)
  );
  const tmpEndDate = new Date(dateRange.year, dateRange.month);
  const endDate = new Date(tmpEndDate.setMonth(tmpEndDate.getMonth() + 2)); //end of next month

  // 1. starting current or last month
  const eventStartQuery = new Parse.Query(CalendarEvent);
  eventStartQuery
    .greaterThanOrEqualTo('start', startDate)
    .lessThanOrEqualTo('start', endDate);

  // 2. started earlier but not yet ending in current month
  const eventEndQuery = new Parse.Query(CalendarEvent);
  eventEndQuery
    .greaterThanOrEqualTo('end', startDate)
    .lessThanOrEqualTo('start', endDate);

  //TODO determine involved months to check if event occurs in current month
  //3. started earlier but recurrenceEnd is after current month
  const eventRecurrenceEndQuery = new Parse.Query(CalendarEvent);
  eventRecurrenceEndQuery.greaterThanOrEqualTo('recurrenceEnd', startDate);

  const compoundQuery = new Parse.Query.or(
    eventStartQuery,
    eventEndQuery,
    eventRecurrenceEndQuery
  );
  const events = (await compoundQuery.find({ sessionToken })) || [];

  return events.map((survey) => mapApiToCalendarEventModel(survey));
};

export const fetchRemoveCalendarEvent = async (
  userId,
  eventId,
  sessionToken
) => {
  const calendarEventQuery = new Parse.Query(CalendarEvent);
  calendarEventQuery.equalTo('objectId', eventId);
  let event = await calendarEventQuery.first({ sessionToken });
  return await event.destroy({ sessionToken });
};
