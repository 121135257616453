import React, { useContext } from 'react';
import { Button, Dialog, MobileStepper, useTheme } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import IntroStep1 from '../../res/intro/1.svg';
import IntroStep2 from '../../res/intro/2.svg';
import IntroStep3 from '../../res/intro/3.svg';
import IntroStep4 from '../../res/intro/4.svg';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { AuthContext } from '../../state/contexts/AuthContext';

export const Intro = ({ setShowIntro = () => {} }) => {
  const tutorialSteps = [
    {
      title: 'Willkommen bei iuvivo!',
      label:
        'Hier hast du nicht nur deinen Beratungsprozess und deine Ziele, sondern auch deine Erfolge immer im Blick – und die Motivation für kommende Fortschritte immer auf deinem Gerät!',
      imgPath: IntroStep1,
      bgColor: 'rgb(253 162 8)',
    },
    {
      title: 'Du bleibst im Austausch',
      label:
        'Du kannst leicht Kontakt zu anderen iuvivo-Teilnehmer:innen herstellen - einfach auf das Messenger-Symbol klicken und über das kleine Plus den Namen eingeben. Berater:innen können neue Klient:innen über das Menü einladen.',
      imgPath: IntroStep2,
      bgColor: 'rgb(249 136 26)',
    },
    {
      title: 'Schritt für Schritt zum Ziel',
      label:
        'Über das Plus in der Mitte des Bildschirms kannst du Ziele und dazu gehörige Schritte definieren. Die Schritte sind Meilensteine, mit denen du dich deinem jeweiligen Ziel näherst. Aktuelle Ziele findet du auf dem Startscreen, abgeschlossene Ziele unter „Erfolge“',
      imgPath: IntroStep3,
      bgColor: 'rgb(248 110 31)',
    },
    {
      title: 'Gemeinsam zu euren Zielen',
      label:
        'Übrigens: iuvivo ermöglicht es, Ziele gemeinsam mit anderen Teilnehmer:innen zu erreichen! Füge die jeweilige Person bei der Ziel-Erstellung der „Crew“ hinzu und schon könnt ihr loslegen!',
      imgPath: IntroStep4,
      bgColor: 'rgb(245 83 36)',
    },
  ];

  const { userData, setUserSettings } = useContext(UserDataContext);
  const { user } = useContext(AuthContext);
  const theme = useTheme();

  const [activeStep, setActiveStep] = React.useState(0);
  const [open, setOpen] = React.useState(true);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const closeDialog = () => {
    setShowIntro(false);
    setOpen(false);
    if (!userData.settings) {
      userData.settings = {};
    }
    userData.settings['introFinished'] = true;
    setUserSettings(user.userId, userData.settings, user.sessionToken);
  };

  return (
    <Dialog open={open} fullWidth>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
      >
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {tutorialSteps.map((step, index) => (
            <div
              key={step.label}
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: 10,
                paddingBottom: 20,
                textAlign: 'center',
                backgroundColor: step.bgColor,
              }}
            >
              {Math.abs(activeStep - index) <= 2 ? (
                <div
                  style={{
                    backgroundImage: `url(${step.imgPath})`,
                    width: '100%',
                    height: 300,
                    flexShrink: 0,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              ) : null}
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: 16,
                  color: 'white',
                  marginBottom: 5,
                }}
              >
                {step.title}
              </div>
              <div style={{ color: 'white' }}>{step.label}</div>
            </div>
          ))}
        </SwipeableViews>
        <MobileStepper
          variant='dots'
          steps={4}
          position='static'
          activeStep={activeStep}
          //className={classes.root}
          nextButton={
            activeStep === 3 ? (
              <Button size='small' onClick={() => closeDialog()}>
                Fertig
              </Button>
            ) : (
              <Button size='small' onClick={handleNext}>
                Next
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            )
          }
          backButton={
            <Button
              size='small'
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </div>
    </Dialog>
  );
};
