import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, styled, TextField, Typography } from '@material-ui/core';
import CustomSnackbar from '../../elements/CustomSnackbar';
import { GoalMembers } from '../GoalMembers/GoalMembers';
import SwipeableViews from 'react-swipeable-views';
import {
  ArrowBack,
  ArrowForward,
  Save,
  Explore,
  AccountCircle,
  FilterVintage,
  SupervisedUserCircle,
  Stars,
  ExploreOutlined,
  Delete,
  FindInPage,
  DeleteOutlined,
} from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Good from '../../../res/milkmocha/tenortestgif.gif';
import TitlePic from '../../../res/milkmocha/milkmocha_benefit.gif';
import { Benefits } from '../Benefits';
import ButtonIconWrapper from '../../elements/ButtonIconWrapper';

import { AuthContext } from 'state/contexts/AuthContext';
import { GoalsContext } from 'state/contexts/GoalsContext';
import { fetchRemoveGoal } from '../../../services/goal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import { useTheme } from '@material-ui/core/styles';
import { Divider } from '../../elements/Divider';
import { GoalIcon } from '../GoalIcon';
import { GoalSupporters } from '../GoalSupporters/GoalSupporters';
import { UserDataContext } from '../../../state/contexts/UserDataContext';
import { mapApiToGoalModel } from '../../../models/GoalModel';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { DialogGoalTemplates } from '../DialogGoalTemplates';
import { BackNavigation } from '../../elements/BackNavigation';
import { DefaultButton } from '../../elements/DefaultButton';
import { DialogMembersWarning } from '../DialogMembersWarning';

export default () => {
  const [goal, setGoal] = useState();
  const [goalCreated, setGoalCreated] = useState();
  const [members, setMembers] = useState([]);
  const [supporters, setSupporters] = useState([]);
  let history = useHistory();
  const [successfullySaved, setSuccessfullySaved] = useState();
  const [showGoalTemplates, setShowGoalTemplates] = useState();

  const { user, isOnline } = useContext(AuthContext);
  const { goals, createGoal, updateGoal } = useContext(GoalsContext);
  const { userData } = useContext(UserDataContext);

  const [tab, setTab] = useState(0);
  const theme = useTheme();

  const [system, setSystem] = useState();
  const [showMembersWarningDialog, setShowMembersWarningDialog] = useState();
  const [ignoreMembersWarning, setIgnoreMembersWarning] = useState();

  const showGIFs = userData && userData.settings && userData.settings.cutify;

  const classes = useStyles();

  useEffect(() => {
    if (!goal && (!history.location.state || !history.location.state.id)) {
      //as via history.push('/addGoal')
      setGoal({});
      history.location.state &&
        history.location.state.system &&
        setSystem(history.location.state.system);
    } else if (!goal && goals) {
      const goalToEdit = Object.values(goals).find(
        (goal) => goal.id === history.location.state.id
      );
      setGoal(goalToEdit);
      setSystem(history.location.state.system);

      const members = Object.values(goalToEdit.members).filter(
        (member) => member.userId !== user.userId
      );
      setMembers(members);
      if (goalToEdit.supporters) {
        const supporters = goalToEdit.supporters.filter(
          (supporter) => supporter.memberId === user.userId
        );
        setSupporters(supporters);
      }
    }
  }, [goal, goals, history.location.state, user.userId]);

  const callbackOnResponse = (history, setSuccessfullySaved) => {
    setSuccessfullySaved(true);
    if (tab === 2) {
      history.push('/');
    }
  };

  const handleTitleChange = (event) => {
    const title = event.target.value;
    setGoal((prev) => ({ ...prev, title: title }));
  };

  const handleBenefitChange = (benefits) => {
    setGoal((prev) => ({ ...prev, benefits }));
  };

  const handleCustomAspectChange = (customBenefits) => {
    setGoal((prev) => ({
      ...prev,
      customBenefits: {
        ...prev.customBenefits,
        [user.userId]: customBenefits,
      },
    }));
  };

  const handleMembersChange = (members) => {
    setMembers(members);
  };

  const handleMembersWarning = (ignoreWarning) => {
    ignoreWarning && setIgnoreMembersWarning(true);
    setShowMembersWarningDialog(false);
  };

  const handleSave = async () => {
    //check if goal members involve system members otherwise set a warning
    const systemMembers =
      (system &&
        system.members &&
        system.members.map((member) => member.userId)) ||
      [];
    if (
      tab === 2 &&
      system &&
      !system.private &&
      !ignoreMembersWarning &&
      !members.some((member) => systemMembers.includes(member.userId))
    ) {
      setShowMembersWarningDialog(system);
      return;
    }

    if (isOnline()) {
      if (
        (!history.location.state || !history.location.state.id) &&
        !goalCreated
      ) {
        createGoal(user, goal, members, supporters, user.sessionToken).then(
          (goal) => {
            setGoalCreated(true);
            setGoal(mapApiToGoalModel(goal));
            callbackOnResponse(history, setSuccessfullySaved);
          }
        );
      } else {
        updateGoal(user, goal, members, supporters, user.sessionToken).then(
          (_response) => {
            console.log('successfully edited goal');
            callbackOnResponse(history, setSuccessfullySaved);
          }
        );
      }
      if (tab < 2) {
        setTab(tab + 1);
      }
    }
  };

  const handleDelete = async () => {
    if (isOnline()) {
      await fetchRemoveGoal(user.userId, goal, user.sessionToken);
      history.goBack();
    }
  };

  const handleImage = async (image) => {
    setGoal((prev) => ({ ...prev, image: image }));
  };

  const handleSetSupporters = (supporters) => {
    const originalSupporters = goal.supporters ? [...goal.supporters] : [];
    const newSupporters = originalSupporters.filter(
      (supporter) => supporter.memberId !== user.userId
    );
    supporters.forEach((supporter) => {
      newSupporters.push({
        memberId: user.userId,
        userName: supporter.userName,
        userId: supporter.userId,
      });
    });
    setSupporters(newSupporters);
  };

  const handleGoalTemplate = (goalTemplate) => {
    setShowGoalTemplates(false);
    if (!goal.goalOwner || goal.goalOwner.userId === userData.userInfo.userId) {
      setGoal((prev) => ({
        ...prev,
        title: goalTemplate.title,
        steps: goalTemplate.steps,
        image: goalTemplate.image,
      }));
    } else {
      setSuccessfullySaved({
        type: 'warning',
        text: 'Vorlagen können nur für eigene Ziele verwendet werden.',
      });
    }
  };

  return (
    <StyledGoalContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <BackNavigation title={'Ziel'} />
        <IconButton
          style={{ marginLeft: 'auto', padding: 5 }}
          onClick={handleDelete}
        >
          <DeleteOutlined color={'primary'} style={{ width: 24, height: 24 }} />
        </IconButton>
      </div>
      <AppBar
        position='static'
        style={{ background: theme.palette.primary.baseGradient }}
        elevation={1}
      >
        <Tabs
          value={tab}
          onChange={(event, value) => setTab(value)}
          TabIndicatorProps={{
            style: {
              display: 'none',
              /*backgroundColor: theme.palette.primary.indicator,
              height: 3,*/
            },
          }}
          variant='fullWidth'
        >
          <Tab
            style={{
              color: tab === 0 && 'white',
              backgroundColor:
                tab === 0 && theme.palette.primary.indicatorFilter,
            }}
            label='1. Ziel'
            /*icon={
              <Explore style={{ color: 'orange', width: 20, height: 20 }} />
            }*/
          />
          <Tab
            style={{
              color: tab === 1 && 'white',
              backgroundColor:
                tab === 1 && theme.palette.primary.indicatorFilter,
            }}
            label='2. Vorteile'
            // icon={<Stars style={{ color: 'orange', width: 20, height: 20 }} />}
          />
          <Tab
            style={{
              color: tab === 2 && 'white',
              backgroundColor:
                tab === 2 && theme.palette.primary.indicatorFilter,
            }}
            label='3. Crew'
            /*icon={
              <SupervisedUserCircle
                style={{ color: 'orange', width: 20, height: 20 }}
              />
            }*/
          />
        </Tabs>
      </AppBar>
      {tab === 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}
          >
            {showGIFs && (
              <div
                style={{
                  width: 100,
                  height: 100,
                  flexShrink: 0,
                  backgroundImage: `url(${TitlePic})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            )}
            <div
              style={{
                borderRadius: 5,
                border: `1px solid ${theme.palette.primary.light}`,
                padding: 10,
                margin: 10,
                marginBottom: 20,
                textAlign: 'center',
                backgroundColor: useTheme().palette.primary.midlight,
              }}
            >
              <div style={{ lineHeight: 1.2 }}>
                Ein klar formuliertes Ziel ist schon die halbe Miete. Was willst
                du erreichen?
              </div>
            </div>
          </div>
          <DefaultButton
            variant={'outlined'}
            color={'primary'}
            endIcon={<FindInPage />}
            onClick={() => setShowGoalTemplates(true)}
          >
            Vorlagen
          </DefaultButton>
          <div style={{ padding: 10, width: '100%', maxWidth: 500 }}>
            <TextField
              classes={classes}
              multiline
              placeholder='Ziel eingeben'
              value={(goal && goal.title) || ''}
              margin='normal'
              variant='outlined'
              label='Ziel'
              onChange={handleTitleChange}
            />
          </div>
          {goal && <GoalIcon goal={goal} setGoalImage={handleImage} />}
        </div>
      )}
      {tab === 1 && goal ? (
        <Benefits
          goal={goal}
          handleBenefitChange={handleBenefitChange}
          handleCustomAspectChange={handleCustomAspectChange}
        />
      ) : (
        <div />
      )}
      {tab === 2 && goal ? (
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 20,
              }}
            >
              {showGIFs && (
                <div
                  style={{
                    width: 100,
                    height: 100,
                    marginLeft: 5,
                    flexShrink: 0,
                    backgroundImage: `url(${Good})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              )}
              <div
                style={{
                  borderRadius: 5,
                  border: `1px solid ${theme.palette.primary.light}`,
                  padding: 10,
                  margin: 10,
                  textAlign: 'center',
                  backgroundColor: theme.palette.primary.midlight,
                }}
              >
                <div style={{ lineHeight: 1.2 }}>
                  Gemeinsame Ziele mit anderen sind wertvoller, machen mehr Spaß
                  und du erreichst sie viel eher. Wer gehört zu deiner Crew?
                </div>
              </div>
            </div>
          </div>
          <GoalMembers
            members={members}
            handleMembersChange={handleMembersChange}
          />
          <Divider
            style={{ marginBottom: 20, marginTop: 40 }}
            width={'100%'}
            color={theme.palette.primary.light}
          />
          <div
            style={{
              borderRadius: 5,
              border: `1px solid ${theme.palette.primary.light}`,
              padding: 10,
              margin: 10,
              marginTop: 20,
              textAlign: 'center',
              backgroundColor: theme.palette.primary.midlight,
            }}
          >
            <div style={{ lineHeight: 1.2 }}>
              ...oder bestimme "Mitwisser", die dir die Daumen drücken können.
            </div>
          </div>
          <GoalSupporters
            supporters={supporters}
            setSupporters={handleSetSupporters}
            goal={goal}
          />
        </div>
      ) : (
        <div />
      )}
      <div
        style={{
          position: 'fixed',
          right: 0,
          bottom: 56,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={handleSave}>
            <ButtonIconWrapper>
              {tab !== 2 ? (
                <ArrowForward
                  style={{ color: 'white', width: 40, height: 40 }}
                />
              ) : (
                <Save style={{ color: 'white', width: 40, height: 40 }} />
              )}
            </ButtonIconWrapper>
          </IconButton>
          <div
            style={{
              marginTop: -12,
              fontSize: 12,
              color: theme.palette.primary.main,
            }}
          >
            {tab !== 2 ? 'Weiter' : 'Fertig'}
          </div>
        </div>
      </div>
      <Dialog
        fullScreen
        onClose={() => setShowGoalTemplates(false)}
        open={showGoalTemplates}
      >
        <DialogGoalTemplates
          setOpen={setShowGoalTemplates}
          handleGoalTemplate={handleGoalTemplate}
        />
      </Dialog>
      <Dialog
        fullWidth
        onClose={() => setShowMembersWarningDialog(false)}
        open={showMembersWarningDialog}
      >
        <DialogMembersWarning
          setOpen={setShowMembersWarningDialog}
          handleMembersWarning={handleMembersWarning}
          system={system}
        />
      </Dialog>
      {successfullySaved && (
        <CustomSnackbar
          setSuccessfullySaved={setSuccessfullySaved}
          type={successfullySaved.type}
          text={successfullySaved.text}
        />
      )}
    </StyledGoalContainer>
  );
};

const StyledTextField = styled(TextField)({
  //width: '100%',
});

const StyledGoalContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: 800,
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 500,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
}));
