import Parse from '../utils/initParse';

const Keyv = Parse.Object.extend('keyv');

const props = {
  ACL: 'ACL',
  TITLE: 'title',
  OWNER: 'owner',
  MEMBERS: 'members',
  SCENE: 'scene',
  UPDATED: 'updatedAt',
  LIVEROOM_OWNER: 'liveRoomOwner',
  ROOMDATA: 'roomData',
};
const propsArray = Object.values(props);

export function mapApiToWhiteboardSceneModel(apiObject) {
  return propsArray.reduce(
    (model, mapEl) => {
      model[mapEl] = apiObject.get(mapEl);
      return model;
    },
    {
      id: apiObject.id,
    }
  );
}

export function mapCalendarEventModelToApi(modelObject) {
  const calendarEvent = new Keyv();

  propsArray.forEach((prop) => {
    calendarEvent.set(prop, modelObject[prop]);
  });
  calendarEvent.id = modelObject.id;

  return calendarEvent;
}
