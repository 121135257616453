import {
  SwipeableList,
  SwipeableListItem,
} from '@sandstreamdev/react-swipeable-list';
import {
  BeachAccess,
  Build,
  Delete,
  Eco,
  Edit,
  ExpandLess,
  ExpandMore,
  Security,
  AccountCircle,
} from '@material-ui/icons';
import Pro from '../../res/milkmocha/milkmocha_happy.gif';
import Contra from '../../res/milkmocha/milkmocha_devil.gif';
import React, { useContext, useEffect, useState } from 'react';
import { Collapse, styled } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Rating from '@material-ui/lab/Rating';
import Button from '@material-ui/core/Button';
import { WinTendency } from './WinTendency';
import { getIcons } from '../../utils/getIcons';
import IconButton from '@material-ui/core/IconButton';
import ButtonIconWrapper from '../elements/ButtonIconWrapper';

import { AuthContext } from 'state/contexts/AuthContext';
import { fetchRemoveGoal } from '../../services/goal';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import userData from '../../services/userData';
import { Divider } from '../elements/Divider';
import { useTheme } from '@material-ui/core/styles';

export const GoalDetails = ({ goal, id, progress, editable = true }) => {
  let history = useHistory();

  const { userData } = useContext(UserDataContext);

  const [showGoalDetails, setShowGoalDetails] = useState();
  const [winTendency, setWinTendency] = useState();
  const [customBenefits, setCustomBenefits] = useState([]);
  const [images, setImages] = useState([]);

  const {
    userInfo: { userId, userName },
  } = userData;

  useEffect(() => {
    setWinTendency(progress);
  }, [progress]);

  useEffect(() => {
    setImages(getIcons(customBenefits));
  }, [customBenefits]);

  useEffect(() => {
    if (
      userData &&
      userData.userInfo &&
      goal.customBenefits &&
      goal.customBenefits[userData.userInfo.userId]
    ) {
      setCustomBenefits(goal.customBenefits[userData.userInfo.userId]);
    }
  }, [userData, goal]);

  const icons = [
    {
      small: <BeachAccess fontSize='small' />,
      normal: <BeachAccess fontSize='large' />,
    },
    {
      small: <Security fontSize='small' />,
      normal: <Security fontSize='large' />,
    },
    { small: <Eco fontSize='small' />, normal: <Eco fontSize='large' /> },
    { small: <Build fontSize='small' />, normal: <Build fontSize='large' /> },
  ];

  const getIcon = (index, size = null) => {
    // const icon = icons[index]
    return icons[index][size];
  };

  const getBenefits = (goal, area) => {
    let benefits = 0;
    const goalBenefits = goal.benefits;
    if (goalBenefits) {
      // TODO: watchout: possible division by zero!
      benefits =
        goalBenefits[area].facets.reduce((a, b) => ({
          value: a.value + b.value,
        })).value / goalBenefits[area].facets.length;
    }
    return benefits;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {/*{progress !== 100 && <WinTendency winTendency={winTendency} />}*/}

      <Collapse
        in={showGoalDetails}
        timeout='auto'
        unmountOnExit
        style={{ width: '100%' }}
      >
        <StyledSummaryDetails
          onClick={() => setShowGoalDetails(!showGoalDetails)}
        >
          {/*<WinTendency winTendency={winTendency} />*/}
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                marginTop: -20,
              }}
            >
              <CharacterWrapper>
                <div
                  style={{
                    width: 50,
                    height: 50,
                    backgroundImage: `url(${Contra})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
                {/*<AddCircleOutline style={{zIndex: 1000}}
                                              onClick={() => history.push('/addgoal')}
                                              fontSize={'large'} color={'primary'}/>*/}
              </CharacterWrapper>

              <CircleWrapper>
                {goal.benefits &&
                  goal.benefits.map((benefit, index) => {
                    return (
                      <div key={benefit.area} style={{ textAlign: 'center' }}>
                        <Circle key={benefit.area}>
                          {getIcon(index, 'small')}
                        </Circle>
                        <div
                          key={benefit.area}
                          style={{
                            fontSize: 10,
                            fontWeight: 'bold',
                            color: 'orange',
                          }}
                        >
                          {' '}
                          {Math.round(getBenefits(goal, index) * 5)}
                        </div>
                      </div>
                    );
                  })}
                {/*<StyledRating
                                    readOnly
                                    value={getBenefits(id, index)}
                                    precision={0.5}
                                    icon={getIcon(index, 'small')}
                                />*/}
                {/*<Circle>{getBenefits(id, 0)}</Circle>
                <Circle>{getBenefits(id, 1)}</Circle>
                <Circle>{getBenefits(id,2)}</Circle>
                <Circle>{getBenefits(id,3)}</Circle>*/}
              </CircleWrapper>
              <CharacterWrapper>
                <div
                  style={{
                    width: 50,
                    height: 50,
                    backgroundImage: `url(${Pro})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              </CharacterWrapper>
            </div>
          </div>
          {/*<GoalMenu props={goals[id]} />*/}
          {winTendency < -20 ? (
            <span
              style={{
                paddingLeft: 5,
                marginRight: 'auto',
                color: 'orangered',
                fontSize: 12,
              }}
            >
              Harhar, das wird knapp...
            </span>
          ) : winTendency > 69 ? (
            <span
              style={{
                paddingRight: 5,
                marginLeft: 'auto',
                color: 'green',
                fontSize: 12,
              }}
            >
              Jaa, {winTendency < 100 ? 'fast' : ''} geschafft!
            </span>
          ) : null}
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexWrap: 'wrap',
              marginTop: 10,
            }}
          >
            {customBenefits.map((benefit, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 5,
                }}
              >
                <StyledIcon key={benefit.title} icon={images[benefit.image]} />
                <span style={{ fontSize: 12, textAlign: 'center' }}>
                  {benefit.title}
                </span>
              </div>
            ))}
          </div>
        </StyledSummaryDetails>
      </Collapse>

      <Button onClick={() => setShowGoalDetails(!showGoalDetails)}>
        {showGoalDetails ? (
          <ExpandLess style={{ color: 'orange' }} />
        ) : (
          <ExpandMore style={{ color: 'orange' }} />
        )}
      </Button>
    </div>
  );
};

const StyledSummaryDetails = styled('div')({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  marginBottom: 5,
  backgroundColor: 'white',
  touchAction: 'manipulation',
  flexDirection: 'column',
});

const Circle = styled('div')(({ theme, img }) => ({
  padding: 5,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: theme.palette.primary.main,
  borderRadius: '50%',
  //backgroundColor: theme.palette.secondary.light,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  color: 'orange', //theme.palette.primary.main,
}));

const CircleWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
  width: '70%',
  marginTop: 25,
  marginBottom: 0,
});

const CharacterWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: 50,
});

const StyledRating = styled(Rating)`
&& {
{},
.MuiRating-root {
color: '#ff3d47',
},
  }
`;

const StyledIcon = styled('div')(({ icon }) => ({
  width: 30,
  height: 30,
  borderRadius: '50%',
  backgroundImage: 'url(' + icon + ')',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
}));

const MemberIcon = styled('div')(({ icon }) => ({
  width: 10,
  height: 10,
  borderRadius: '50%',
  backgroundImage: 'url(' + icon + ')',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
}));
