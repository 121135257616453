import React, { useContext, useEffect, useState } from 'react';
import { ScaleSlider } from './elements/ScaleSlider';
import {
  Button,
  Card,
  IconButton,
  styled,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import {
  fetchSetSurveyAnswers,
  fetchSetSurveyTemplate,
} from '../../services/methods';
import { AuthContext } from '../../state/contexts/AuthContext';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { useHistory } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';

const answerElement = (type, index, value, handleAnswer, theme) => {
  if (type === 'binary') {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-evenly',
        }}
      >
        <Button
          variant={'outlined'}
          style={{
            width: 100,
            margin: 5,
            color: value === 'yes' && 'white',
            backgroundColor: value === 'yes' && theme.palette.primary.main,
          }}
          color={'primary'}
          onClick={() => handleAnswer(index, 'yes')}
        >
          Ja
        </Button>
        <Button
          variant={'outlined'}
          style={{
            width: 100,
            margin: 5,
            color: value === 'no' && 'white',
            backgroundColor: value === 'no' && theme.palette.primary.main,
          }}
          color={'primary'}
          onClick={() => handleAnswer(index, 'no')}
        >
          Nein
        </Button>
      </div>
    );
  } else if (type === 'text') {
    return (
      <TextField
        style={{ width: '100%' }}
        variant={'outlined'}
        label={'Antwort'}
        value={value || ''}
        onChange={(ev) => handleAnswer(index, ev.target.value)}
      />
    );
  } else if (type === 'scale') {
    return (
      <ScaleSlider
        index={index}
        itemValue={value}
        onChangeCommitted={handleAnswer}
      />
    );
  }
};

export const SurveyAnswers = () => {
  const { user } = useContext(AuthContext);
  const { userData } = useContext(UserDataContext);
  let history = useHistory();
  const theme = useTheme();
  const [answers, setAnswers] = useState([]);
  const [survey, setSurvey] = useState();
  const [complete, setComplete] = useState();

  useEffect(() => {
    if (history && history.location.state) {
      setSurvey(history.location.state.survey);

      const answersArray = [];
      history.location.state.survey.elements.forEach((_, index) =>
        answersArray.push({})
      );
      setAnswers(answersArray);
    }
  }, [survey, history]);

  useEffect(() => {
    let completeCheck = true;
    answers.forEach((answer) => {
      if (!answer.answer) {
        completeCheck = false;
      }
    });
    setComplete(completeCheck);
  }, [survey, answers]);

  const handleSaveSurvey = async () => {
    const answersObject = {};
    answersObject.user = {
      userName: userData.userInfo.userName,
      userId: userData.userInfo.userId,
    };
    answersObject.answers = answers;
    const success = await fetchSetSurveyAnswers(
      survey.id,
      answersObject,
      userData.userInfo,
      user.sessionToken
    );
    if (success) {
      history.goBack();
    }
  };

  const handleAnswer = (index, answer) => {
    const tmpAnswers = [...answers];
    tmpAnswers[index].answer = answer;
    setAnswers(tmpAnswers);
  };

  return (
    <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        <IconButton onClick={() => history.goBack()}>
          <ArrowBack />
        </IconButton>
        <Typography variant={'subtitle1'}>{survey && survey.title}</Typography>
      </div>
      {survey &&
        survey.elements &&
        survey.elements.map((element, index) => {
          return (
            <Card style={{ margin: 5, marginBottom: 10 }}>
              <div
                style={{
                  width: '100%',
                  padding: 10,
                  backgroundColor: theme.palette.secondary.light,
                }}
              >
                <Typography variant={'body2'} style={{ fontWeight: 'bold' }}>
                  {element.question}
                </Typography>
              </div>
              <div style={{ padding: 10 }}>
                {answerElement(
                  element.type,
                  index,
                  answers[index] && answers[index].answer,
                  handleAnswer,
                  theme
                )}
              </div>
            </Card>
          );
        })}
      <Button
        disabled={!complete}
        variant={'outlined'}
        color={'primary'}
        onClick={handleSaveSurvey}
      >
        Abschicken
      </Button>
    </div>
  );
};
