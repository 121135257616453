import { Values } from 'services/userData';

const props = {
  ACL: 'ACL',
  USER: 'user',
  VALUES: 'values',
  MEMBERS: 'members',
  ISCOMPLETE: 'isComplete',
  UPDATED: 'updatedAt',
};
const propsArray = Object.values(props);

export function mapApiToValuesModel(apiObject) {
  return propsArray.reduce(
    (model, mapEl) => {
      model[mapEl] = apiObject.get(mapEl);
      return model;
    },
    {
      id: apiObject.id,
    }
  );
}

export function mapValuesModelToApi(modelObject) {
  const values = new Values();

  propsArray.forEach((prop) => {
    values.set(prop, modelObject[prop]);
  });
  values.id = modelObject.id;

  return values;
}
