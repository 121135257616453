import React, { useContext, useState } from 'react';
import {
  Checkbox,
  Button,
  styled,
  Typography,
  TextField,
} from '@material-ui/core';
import { Close, MoreVert } from '@material-ui/icons';
import { updateFile } from '../../services/files';
import { fetchSendNotification } from '../../services/notifications';
import { AuthContext } from '../../state/contexts/AuthContext';

export const DialogRequestPasswordReset = ({
  setOpen,
  requestPasswordReset,
}) => {
  const [email, setEmail] = useState('');

  const validEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email)) {
      //handleFirebaseError('non-valid username');
      return;
    }
    //setFirebaseError(false)
    return re.test(email);
  };

  const isEmail = () => {
    return validEmail(email);
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        padding: 20,
      }}
    >
      <div style={{ display: 'flex', width: '100%', marginBottom: 20 }}>
        <Typography variant={'h6'}>Passwort zurücksetzen</Typography>
        <Close
          color={'primary'}
          style={{ marginLeft: 'auto' }}
          onClick={() => setOpen(false)}
        />
      </div>

      <Typography variant={'body2'} style={{ marginBottom: 10 }}>
        Gib deine Email-Adresse ein und wir schicken dir einen Link zum
        Zurücksetzen deines Passwortes.
      </Typography>
      <TextField
        variant={'filled'}
        value={email}
        onChange={(ev) => setEmail(ev.target.value)}
      />
      <Button
        disabled={!isEmail()}
        onClick={() => {
          requestPasswordReset(email);
          setOpen(false);
        }}
        variant={'contained'}
        color={'primary'}
        style={{ marginTop: 15 }}
      >
        Abschicken
      </Button>
    </div>
  );
};
