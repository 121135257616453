import React, { useState } from 'react';
import { Checkbox, Button, styled, Typography } from '@material-ui/core';
import { Close, MoreVert } from '@material-ui/icons';
import { updateFile } from '../../services/files';
import { useTheme } from '@material-ui/core/styles';

export const StepConfirmDialog = ({
  setOpen,
  handleConfirmation,
  goal,
  step,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        padding: 20,
      }}
    >
      <div style={{ display: 'flex', width: '100%', marginBottom: 20 }}>
        <Typography variant={'h6'}>Schritt erledigt</Typography>
        <Close
          color={'primary'}
          style={{ marginLeft: 'auto' }}
          onClick={() => setOpen(false)}
        />
      </div>
      <Typography variant={'subtitle1'}>
        <b>{step.title}</b>
      </Typography>
      <Typography variant={'subtitle2'}>
        Super, du hast es geschafft! Willst du den Schritt abhaken und{' '}
        {goal.goalOwner.userName} benachrichtigen?
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 20,
        }}
      >
        <Button
          onClick={() => setOpen(false)}
          variant={'contained'}
          color={'primary'}
        >
          Abbrechen
        </Button>
        <Button
          onClick={handleConfirmation}
          variant={'contained'}
          style={{ backgroundColor: useTheme().palette.button.done }}
        >
          Ja, klar!
        </Button>
      </div>
    </div>
  );
};
