import React, { useContext } from 'react';
import { MethodCard } from './MethodCard';
import { GoodQuestion } from './GoodQuestion';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { userHasPermission } from '../../utils/handleRolePermissions';
import previewDiary from '../../res/preview_diary.jpg';
import previewValues from '../../res/preview_values.jpg';
import previewSurveys from '../../res/preview_surveys.jpg';
import imgSurveys from '../../res/survey_illustration.png';
import imgValues from '../../res/values_illustration.png';
import imgDiary from '../../res/diary_illustration.png';
import imgWhiteboard from '../../res/whiteboard_illustration.png';
import { permissions } from '../../utils/constants';

export const Methods = () => {
  const { userData } = useContext(UserDataContext);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <GoodQuestion />
      <div
        style={{
          display: 'flex',
          width: '100%',
          maxWidth: 600,
          opacity: 0.7,
          flexDirection: 'column',
        }}
      >
        <MethodCard
          title={'Emotionstagebuch'}
          routeURL={'/diary'}
          description={
            'Verfolge, wie es dir geht - und finde heraus, was dir gut tut und was nicht'
          }
          img={imgDiary}
          previewImg={
            !userHasPermission(userData, permissions.USE_METHOD_DIARY) &&
            previewDiary
          }
        />
        <MethodCard
          title={'Lebensanalyse'}
          routeURL={'/values'}
          description={
            'Erforsche, was dir im Leben wichtig ist - und wovon du mehr brauchst'
          }
          img={imgValues}
          previewImg={
            !userHasPermission(userData, permissions.USE_METHOD_VALUES) &&
            previewValues
          }
        />
        <MethodCard
          title={'Fragebögen'}
          routeURL={'/surveys'}
          description={
            userData &&
            userData.userInfo.assignment &&
            userData.userInfo.assignment.type === 'organization'
              ? 'Erstelle Fragebögen (Evaluation, Methoden, o.Ä.) für Klient:innen'
              : 'Beantworte hilfreiche Fragen deine:r Berater:in'
          }
          img={imgSurveys}
          previewImg={
            !userHasPermission(userData, permissions.USE_METHOD_SURVEYS) &&
            previewSurveys
          }
        />
        <MethodCard
          title={'Whiteboard'}
          routeURL={'/whiteboard'}
          description={'Zeichne dein Inneres und arbeite live zusammen'}
          img={imgWhiteboard}
          previewImg={
            !userHasPermission(userData, permissions.USE_METHOD_WHITEBOARD) &&
            imgWhiteboard
          }
        />
      </div>
    </div>
  );
};
