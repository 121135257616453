import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  BottomNavigation,
  BottomNavigationAction,
  styled,
  useTheme,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Explore as GoalIcon,
  Email,
  Folder,
  BusinessCenter,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    height: 56,
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    '& .Mui-selected': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.indicator,
      border: '1px solid orange',
      borderRadius: 5,
    },
    '& .MuiBottomNavigationAction-label.Mui-selected': {
      border: 'none',
      backgroundColor: 'transparent',
    },
  },
  selected: {},
}));

export default ({ setFrame }) => {
  const classes = useStyles();
  let history = useHistory();
  const [value, setValue] = useState('/');
  const pathName = useLocation().pathname;

  useEffect(() => {
    if (pathName) {
      if (pathName === '/messenger' && value !== '/messenger') {
        setValue('/messenger');
      } else if (pathName === '/' && value !== '/') {
        setValue('/');
      }
    }
  }, [value, pathName]);

  const handleChange = (event, newValue) => {
    setFrame(newValue === '/messenger');
    setValue(newValue);
    history.replace(`${newValue}`);
  };

  return (
    <BottomNavigation
      style={{ zIndex: 100 }}
      showLabels
      value={value}
      onChange={handleChange}
      className={classes.root}
    >
      <StyledBottomNavigationAction
        label='Ziele'
        value='/'
        icon={<GoalIcon />}
      />

      {/*<BottomNavigationAction
        label='Innenleben'
        value='/favorite'
        icon={<FavoriteIcon />}
      />*/}
      <StyledBottomNavigationAction
        label='Messenger'
        value='/messenger'
        icon={<Email />}
      />
      <StyledBottomNavigationAction
        label='Methoden'
        value='/methods'
        icon={<BusinessCenter />}
      />
      <StyledBottomNavigationAction
        label='Dateien'
        value='/files'
        icon={<Folder />}
      />
    </BottomNavigation>
  );
};

const StyledBottomNavigationAction = styled(BottomNavigationAction)(
  ({ theme }) => ({
    color: theme.palette.primary.main,
  })
);
