import { Steps } from './Steps';
import React, { useContext } from 'react';
import { GoalsContext } from '../../state/contexts/GoalsContext';
import { UserDataContext } from '../../state/contexts/UserDataContext';

export default ({ goal, editable = true, type }) => {
  const { goals } = useContext(GoalsContext);
  const { userData } = useContext(UserDataContext);

  let currentGoal = editable ? goals[goal.id] : userData.goalArchive[goal.id];
  if (type === 'supportedGoals') {
    currentGoal = goal;
  }

  const sortSteps = (steps) => {
    const sortedGoals = Object.keys(steps).sort((a, b) => {
      return (
        steps[a].deadline &&
        steps[b].deadline &&
        steps[a].deadline.toString().localeCompare(steps[b].deadline.toString())
      );
    });
    return sortedGoals;
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {currentGoal && currentGoal.steps ? (
        sortSteps(currentGoal.steps).map((key) => {
          return (
            <Steps
              key={currentGoal.steps[key].id}
              goal={currentGoal}
              data={currentGoal.steps[key]}
              editable={editable}
            />
          );
        })
      ) : (
        <div>Füge Schritte hinzu</div>
      )}
    </div>
  );
};
