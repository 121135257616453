import {
  DateTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  Checkbox,
  Menu,
  MenuItem,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { DefaultIconButton } from '../elements/DefaultIconButton';
import { Close, DeleteOutline, ExpandMore } from '@material-ui/icons';
import { HexColorPicker } from 'react-colorful';
import { DefaultButton } from '../elements/DefaultButton';
import { CalendarMembers } from './CalendarMembers';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../state/contexts/AuthContext';
import { calendarRecurrenceFreq } from '../../utils/constants';
import deLocale from 'date-fns/locale/de';

const flutterToken = window.localStorage.getItem('flutterToken');
const recurrenceTypes = {
  daily: 'Täglich',
  weekly: 'Wöchentlich',
  biweekly: 'Zweiwöchentlich',
  monthly: 'Monatlich',
  yearly: 'Jährlich',
};

export const CalendarEvent = (props) => {
  const theme = useTheme();
  const [openColorPicker, setOpenColorPicker] = useState();
  const [event, setEvent] = useState(props.event && props.event);
  const { user } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState();
  const [guestReminderChange, setGuestReminderChange] = useState();

  const eventAuthor = user && event.author.userId === user.userId;

  const handleTitleChange = (title) => {
    setEvent((prev) => ({ ...prev, title: title }));
  };

  const handleDescChange = (description) => {
    setEvent((prev) => ({ ...prev, description: description }));
  };

  const handleColorChange = (color) => {
    setEvent((prev) => ({ ...prev, color: color }));
  };

  const handleStartChange = (start) => {
    //if new start date is greater than end date then move end date according to the duration of the event
    if (event.end < start) {
      const duration = new Date(event.end) - new Date(event.start);
      const endDate = new Date(start.getTime() + duration);

      setEvent((prev) => ({ ...prev, end: endDate }));
    }

    setEvent((prev) => ({ ...prev, start: start }));
  };

  const handleEndChange = (end) => {
    //if new end date is less than start date then move start date according to the duration of the event
    if (event.start > end) {
      const duration = new Date(event.end) - new Date(event.start);
      const startDate = new Date(end.getTime() - duration);
      setEvent((prev) => ({ ...prev, start: startDate }));
    }

    setEvent((prev) => ({ ...prev, end: end }));
  };

  const handleMembersChange = (members) => {
    setEvent((prev) => ({ ...prev, members: members }));
  };

  const handleRecurrenceFreq = (recurrenceFreq) => {
    setEvent((prev) => ({ ...prev, recurrenceFreq: recurrenceFreq }));
    setEvent((prev) => ({
      ...prev,
      recurrenceEnd: new Date(
        new Date(event.end).setMonth(new Date(event.end).getMonth() + 1)
      ),
    }));
    if (!recurrenceFreq) {
      setEvent((prev) => ({ ...prev, recurrenceFreq: undefined }));
      setEvent((prev) => ({ ...prev, recurrenceEnd: undefined }));
    }
    setAnchorEl(null);
  };

  const handleRecurrenceEnd = (recurrenceEnd) => {
    if (recurrenceEnd > event.end) {
      setEvent((prev) => ({ ...prev, recurrenceEnd: recurrenceEnd }));
    }
  };

  const handleRemindChange = (event) => {
    const tmpReminders = event.reminders ? [...event.reminders] : [];
    tmpReminders.filter((member) => member.userId !== user.userId);
    event.target.checked &&
      tmpReminders.push({ userId: user.userId, remindDays: 1 });

    setEvent((prev) => ({ ...prev, reminders: tmpReminders }));
    if (!eventAuthor) {
      setGuestReminderChange(true);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginBottom: 10,
            padding: 10,
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.secondary.main,
          }}
        >
          <DefaultIconButton>
            <DeleteOutline
              color={'primary'}
              onClick={() => {
                props.deleteEvent(event);
              }}
            />
          </DefaultIconButton>
          <Typography variant={'body1'}>Termin</Typography>
          <DefaultIconButton>
            <Close color={'primary'} onClick={props.onClose} />
          </DefaultIconButton>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: 10,
          }}
        >
          <div style={{ fontSize: 12, marginBottom: 5 }}>
            Verwaltet von: {event.author.userName}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              marginBottom: 10,
            }}
          >
            <div
              style={{
                marginRight: 10,
                width: 30,
                height: 30,
                border: '1px solid grey',
                borderRadius: 2,
                backgroundColor: event && event.color,
              }}
              onClick={() => setOpenColorPicker(true)}
            />
            <TextField
              disabled={!eventAuthor}
              style={{ width: '100%' }}
              inputProps={{
                style: {
                  padding: 10,
                  fontWeight: 500,
                },
              }}
              variant={'outlined'}
              placeholder={'Titel'}
              value={event && event.title}
              onChange={(e) => handleTitleChange(e.target.value)}
            />
          </div>

          {eventAuthor && openColorPicker && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: 10,
              }}
            >
              <HexColorPicker
                style={{ marginTop: 10, marginBottom: 10, width: '100%' }}
                color={event && event.color}
                onChange={handleColorChange}
              />
              <DefaultButton
                style={{ marginLeft: 'auto' }}
                onClick={() => setOpenColorPicker(false)}
              >
                Fertig
              </DefaultButton>
            </div>
          )}
          <TextField
            disabled={!eventAuthor}
            multiline
            InputProps={{
              style: {
                padding: 10,
              },
            }}
            variant={'outlined'}
            placeholder={'Beschreibung'}
            value={event && event.description}
            onChange={(e) => handleDescChange(e.target.value)}
          />
          <div
            style={{
              backgroundColor: theme.palette.secondary.main,
              borderRadius: 4,
              padding: 5,
              paddingTop: 10,
              marginTop: 10,
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  border: '1px solid orange',
                  borderRadius: 4,
                }}
              >
                <div style={{ fontSize: 12 }}>Start</div>
                <StyledDatePicker
                  disabled={!eventAuthor}
                  format='dd.MM.yy  HH:mm'
                  ampmInClock={false}
                  ampm={false}
                  value={event && event.start}
                  onChange={(v) => handleStartChange(v)}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  border: '1px solid orange',
                  borderRadius: 4,
                }}
              >
                <div style={{ fontSize: 12 }}>Ende</div>
                <StyledDatePicker
                  disabled={!eventAuthor}
                  format='dd.MM.yy  HH:mm'
                  ampmInClock={false}
                  ampm={false}
                  value={event && event.end}
                  onChange={(v) => handleEndChange(v)}
                />
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <DefaultButton
                disabled={!eventAuthor}
                startIcon={
                  <ExpandMore style={{ marginLeft: -20 }} color={'primary'} />
                }
                style={{
                  width: '100%',
                  boxShadow: 'none',
                  border: `1px solid ${useTheme().palette.primary.main}`,
                }}
                customColor={{ main: 'white', dark: 'white' }}
                textColor={useTheme().palette.primary.main}
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                {(event &&
                  event.recurrenceFreq &&
                  recurrenceTypes[event.recurrenceFreq]) ||
                  'Einmalig'}
              </DefaultButton>
              {event && event.recurrenceFreq && (
                <div
                  style={{
                    marginLeft: 5,
                    borderRadius: 4,
                    backgroundColor: theme.palette.secondary.main,
                  }}
                >
                  <div
                    style={{
                      fontSize: 10,
                      paddingTop: 5,
                      fontWeight: 500,
                      textAlign: 'center',
                    }}
                  >
                    bis
                  </div>
                  <KeyboardDatePicker
                    disabled={!eventAuthor}
                    style={{ marginLeft: 5 }}
                    onChange={(v) => handleRecurrenceEnd(v)}
                    value={event && event.recurrenceEnd}
                    format='dd.MM.yy'
                    disableUnderline
                    inputProps={{
                      style: {
                        fontSize: 10,
                        textAlign: 'right',
                      },
                    }}
                    InputProps={{ disableUnderline: true, readOnly: true }}
                    placeholder={'bis'}
                  />
                </div>
              )}
            </div>
            <Menu
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              onClose={() => {
                setAnchorEl(null);
              }}
              PaperProps={{
                style: {
                  width: '90%',
                  maxWidth: 500,
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleRecurrenceFreq();
                }}
              >
                Einmalig
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleRecurrenceFreq(calendarRecurrenceFreq.DAILY);
                }}
              >
                Täglich
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleRecurrenceFreq(calendarRecurrenceFreq.WEEKLY);
                }}
              >
                Wöchentlich
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleRecurrenceFreq(calendarRecurrenceFreq.BIWEEKLY);
                }}
              >
                Zweiwöchentlich
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleRecurrenceFreq(calendarRecurrenceFreq.MONTHLY);
                }}
              >
                Jeden {event.start.getDate()}. im Monat
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleRecurrenceFreq(calendarRecurrenceFreq.YEARLY);
                }}
              >
                Jährlich
              </MenuItem>
            </Menu>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                color={'primary'}
                checked={
                  event.reminders &&
                  event.reminders.find(
                    (member) => member.userId === user.userId
                  )
                }
                onChange={handleRemindChange}
              />
              <div style={{ fontSize: 12, color: theme.palette.primary.main }}>
                Einen Tag vorher erinnern
              </div>
            </div>
          </div>
          <div
            style={{
              padding: 10,
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            <div style={{ fontSize: 12 }}>Teilnehmer:innen</div>
            <CalendarMembers
              eventAuthor={eventAuthor}
              members={event && event.members}
              setMembers={handleMembersChange}
            />
          </div>
          {!eventAuthor && (
            <div style={{ fontSize: 12, marginBottom: 5, textAlign: 'center' }}>
              Verwaltet von: {event.author.userName} (Erinnerung änderbar))
            </div>
          )}
          <DefaultButton
            disabled={!event.title || (!eventAuthor && !guestReminderChange)}
            onClick={() => props.saveEvent(event)}
          >
            Fertig
          </DefaultButton>
        </div>
      </div>
    </MuiPickersUtilsProvider>
  );
};

const StyledDatePicker = styled(DateTimePicker)(({ theme }) => ({
  '& .MuiInputBase-input': {
    fontSize: flutterToken ? 16 : 12,
    textAlign: 'center',
  },
  '& .MuiInput-underline:before': {
    borderBottom: 0,
  },
}));
