import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  fetchAddCustomAspect,
  fetchAllValues,
  fetchCurrentGoodQuestion,
  fetchDeleteArchivedGoalFromUser,
  fetchDeleteCustomAspect,
  fetchGetUserData,
  fetchSearchUser,
  fetchSetArchiveAndPreparePoints,
  fetchSetCustomAspects,
  fetchSetDiary,
  fetchSetFCMToken,
  fetchSetFileManager,
  fetchSetUserSettings,
  fetchSetUserValues,
  fetchSubscribeUserData,
  fetchUnsubscribeUserData,
  fetchUserDiary,
  fetchUserValues,
  setContactRequest,
  setDBGoalPoints,
} from 'services/userData';

import { events, IFRAMEORIGINANDSOURCE } from 'utils/constants';

import { mapApiToUserDataModel } from 'models/UserDataModel';
import { mapApiToValuesModel } from '../../models/ValuesModel';
import { AuthContext } from './AuthContext';
import { GoalsContext } from './GoalsContext';
import { ClientsContext } from './ClientsContext';
import { fetchLogout } from '../../services/authentication';
import { logoutMatrix } from '../../utils/handleMatrix';

export const UserDataContext = createContext({});

const UserDataContextProvider = (props) => {
  const { children } = props;
  const { user } = useContext(AuthContext);
  const { goals, getGoals, unsubscribeGoals } = useContext(GoalsContext);
  const { getClients, unsubscribeClientData } = useContext(ClientsContext);
  const [userData, setUserData] = useState();
  const [currentFCMToken, setCurrentFCMToken] = useState();
  const [foregroundMessage, setForegroundMessage] = useState();
  const [currentGoodQuestion, setCurrentGoodQuestion] = useState();
  const subscription = useRef();
  const [isLoggingOut, setIsLoggingOut] = useState();
  const [matrixInvites, setMatrixInvites] = useState();
  const [joinMatrixRoom, setJoinMatrixRoom] = useState();

  useEffect(() => {
    if (user && userData) {
      if (userData.goals) {
        getGoals(userData.goals, user.sessionToken);
      }
      if (userData.settings && !userData.settings.unsubscribeGoodQuestion) {
        const fetchGoodQuestion = async () => {
          const goodQuestion = await getGoodQuestion(user.sessionToken);
          if (goodQuestion) {
            setCurrentGoodQuestion(goodQuestion.question);
          }
        };
        fetchGoodQuestion();
      } else {
        setCurrentGoodQuestion(undefined);
      }
    }
  }, [user, userData]);

  const handleAsync = async (eventObject) => {
    const { object, event } = eventObject;

    if (event === events.UPDATE) {
      const updatedUserData = mapApiToUserDataModel(object);
      setUserData(updatedUserData);
      console.log('successfully updated userData');
    } else {
      console.log('unknown event');
    }
  };

  const getUserData = (userId, sessionToken) => {
    return fetchGetUserData(userId, sessionToken).then(async (data) => {
      const userData = mapApiToUserDataModel(data);
      //TODO if we get clients and subscribe to them first we'll get 2 LiveQuery clients - but in this order it works. Why?
      await subscribeUserData(userId, sessionToken);
      console.log('start susbcribing clients');
      await getClients(userData.userInfo, sessionToken);
      await setUserData(mapApiToUserDataModel(data));
    });
  };

  const subscribeUserData = async (userId, sessionToken) => {
    if (subscription.current) {
      await unsubscribeUserData();
    }
    subscription.current = await fetchSubscribeUserData(
      userId,
      handleAsync,
      sessionToken
    );
    console.log('successfully subscribed userData');
  };

  const unsubscribeUserData = async () => {
    if (subscription.current) {
      await fetchUnsubscribeUserData(subscription.current);
      subscription.current = undefined;
      console.log('successfully unsubscribed userData');
    }
  };

  const setDBDiary = async (diary, sessionToken) => {
    const userDiary = await fetchSetDiary(
      userData.userInfo.userId,
      diary,
      sessionToken
    );

    return userDiary;
  };

  const getDBDiary = async (sessionToken) => {
    const userDiary = await fetchUserDiary(user.userId, sessionToken);

    return userDiary;
  };

  const searchUser = async (userName, sessionToken) => {
    return await fetchSearchUser(userName, sessionToken);
  };

  const setArchiveAndPrepareGoalPoints = (user, goal, sessionToken) => {
    fetchSetArchiveAndPreparePoints(user, goal, sessionToken);
  };

  const setDBAspects = async (userInfo, items, sessionToken) => {
    const aspects = await fetchSetCustomAspects(userInfo, items, sessionToken);
  };

  const addDBAspect = async (userInfo, item, sessionToken) => {
    const aspects = await fetchAddCustomAspect(userInfo, item, sessionToken);
  };

  const deleteDBAspect = async (userInfo, item, sessionToken) => {
    const aspects = await fetchDeleteCustomAspect(userInfo, item, sessionToken);
  };

  const setServerFcmToken = async (userInfo, tokens, sessionToken) => {
    const result = await fetchSetFCMToken(userInfo, tokens, sessionToken);
    console.log('setfcmtoken result');
  };

  const deleteArchivedGoalFromUser = (userId, goalId, sessionToken) => {
    fetchDeleteArchivedGoalFromUser(userId, goalId, sessionToken);
  };

  const setUserSettings = (userId, settings, sessionToken) => {
    fetchSetUserSettings(userId, settings, sessionToken);
  };

  const getUserValues = async (userId, sessionToken) => {
    const userValuesObject = await fetchUserValues(userId, sessionToken);
    console.log('getUserValues');
    return userValuesObject ? mapApiToValuesModel(userValuesObject) : undefined;
  };

  const getAllValues = async (userIds, sessionToken) => {
    const allValues = await fetchAllValues(userIds, sessionToken);
    return (
      allValues.map((valuesObject) => mapApiToValuesModel(valuesObject)) || []
    );
  };

  const getGoodQuestion = async (sessionToken) => {
    return await fetchCurrentGoodQuestion(sessionToken);
  };

  const setUserValues = async (
    userInfo,
    valuesObject,
    sessionToken,
    isComplete
  ) => {
    return await fetchSetUserValues(
      userInfo,
      valuesObject,
      sessionToken,
      isComplete
    );
  };

  const setDBFileManager = (userId, fileManager, sessionToken) => {
    fetchSetFileManager(userId, fileManager, sessionToken);
  };

  const logout = async () => {
    await unsubscribeClientData();
    await unsubscribeGoals();
    await unsubscribeUserData();

    const response = await fetchLogout(currentFCMToken);
    if (response) {
      console.log('logout response');
      setCurrentFCMToken(undefined);
      setIsLoggingOut(true);
      logoutMatrix();
      return true;
    }
    return false;
  };

  return (
    <UserDataContext.Provider
      value={{
        userData,
        setUserData,
        getUserData,
        subscribeUserData,
        unsubscribeUserData,
        setDBDiary,
        searchUser,
        setContactRequest,
        setDBGoalPoints,
        setArchiveAndPrepareGoalPoints,
        setDBAspects,
        addDBAspect,
        deleteDBAspect,
        setServerFcmToken,
        deleteArchivedGoalFromUser,
        setUserSettings,
        getUserValues,
        getAllValues,
        setUserValues,
        setDBFileManager,
        getDBDiary,
        getGoodQuestion,
        currentFCMToken,
        setCurrentFCMToken,
        foregroundMessage,
        setForegroundMessage,
        currentGoodQuestion,
        logout,
        isLoggingOut,
        setIsLoggingOut,
        matrixInvites,
        setMatrixInvites,
        joinMatrixRoom,
        setJoinMatrixRoom,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export default UserDataContextProvider;
