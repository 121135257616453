import React, {useContext, useState} from 'react';
import {TextField, Typography} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {AuthContext} from '../../state/contexts/AuthContext';
import {DefaultButton} from '../elements/DefaultButton';
import {renameClientSystem} from '../../services/clients';

export const DialogClientSystemRename = ({setOpen, userData, system}) => {
    const {user, isOnline} = useContext(AuthContext);
    const [name, setName] = useState(system.name);

    const handleSave = async () => {
        if (isOnline() && name) {
            await renameClientSystem(system.systemId, name, user.sessionToken)
            setOpen(false);
        }
    };

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    padding: 10,
                    backgroundColor: '#ffb11e',
                }}
            >
                <Typography style={{color: 'white'}} variant={'h6'}>
                    Fall-ID
                </Typography>
                <Close
                    style={{marginLeft: 'auto', color: 'white'}}
                    onClick={() => setOpen(false)}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    flexDirection: 'column',
                    padding: 20,
                }}
            >
                <Typography variant={'body2'}>
                    Neuer Name des Falls/Systems
                </Typography>
                <TextField
                    color={'primary'}
                    placeholder='Name'
                    margin='normal'
                    variant='outlined'
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                />
                <div
                    style={{display: 'flex', justifyContent: 'center', marginTop: 10}}
                >
                    <DefaultButton style={{width: '100%'}} onClick={handleSave}>
                        Speichern
                    </DefaultButton>
                </div>
            </div>
        </div>
    );
};
