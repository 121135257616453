import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css';
import React, { useEffect, useState, useMemo, useContext } from 'react';

import {
  CircularProgress,
  IconButton,
  styled,
  useTheme,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ClientsContext } from '../../state/contexts/ClientsContext';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { AuthContext } from '../../state/contexts/AuthContext';
import { GoalsContext } from '../../state/contexts/GoalsContext';
import {
  AddCircleOutline,
  ArrowBack,
  Delete,
  GetApp,
  Save,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import { ClientDocGoal } from './ClientDocGoal';
import { generateCode } from '../../utils/generateCode';
import _isEqual from 'lodash.isequal';
import ButtonIconWrapper from '../elements/ButtonIconWrapper';
import { useHistory } from 'react-router-dom';
import { ClientCounselors } from './ClientCounselors';
import Button from '@material-ui/core/Button';
import { Divider } from '../elements/Divider';
import CustomizedSnackbars from '../elements/CustomSnackbar';
import { ClientDocExport } from './ClientDocExport';

//TODO delete client documentation when user account is deleted/deactivated
//TODO refresh after saving documentation / check save success

const flutterToken = window.localStorage.getItem('flutterToken');

export const ClientDocumentation = () => {
  let history = useHistory();
  const {
    saveClientSystemDocumentation,
    getClientOrganizationMembers,
    addClientSystemCounselor,
    clientSystems,
  } = useContext(ClientsContext);
  const { userData } = useContext(UserDataContext);
  const { user, isOnline } = useContext(AuthContext);
  const { goals: userGoals, getGoals } = useContext(GoalsContext);
  const theme = useTheme();
  const [data, setData] = useState();
  const [focusTile, setFocusTile] = useState();
  const [showAllAuthors, setShowAllAuthors] = useState();
  const [allEntries, setAllEntries] = useState([]);
  const [system, setSystem] = useState();
  const [openDialog, setOpenDialog] = useState();
  const [saved, setSaved] = useState(false);
  const [showExport, setShowExport] = useState(false);

  useEffect(() => {
    if (data) {
      const tmpData = { ...data };
      setAllEntries(sortAllEntries(tmpData));
    }
  }, [data]);

  useEffect(() => {
    if (userData) {
      const fetchGoals = async () => {
        await getGoals(userData.goals, user.sessionToken);
      };
      fetchGoals();
    }
  }, [userData, user.sessionToken]);

  useEffect(() => {
    if (
      history.location &&
      history.location.state &&
      history.location.state.system &&
      history.location.state.system &&
      clientSystems
    ) {
      const system = clientSystems.find(
        (system) => system.systemId === history.location.state.system.systemId
      );
      setData(system.documentation);
      setSystem(system);
    }
  }, [clientSystems, user, history.location]);

  const sortAllEntries = (allEntries) => {
    const mergedContent = [];
    allEntries &&
      Object.values(allEntries).forEach((author) =>
        author.content.forEach((entry) => mergedContent.push(entry))
      );
    mergedContent.sort((a, b) => {
      return new Date(b.startTime) - new Date(a.startTime);
    });
    return mergedContent;
  };

  /*const modules = useMemo(
          () => ({
              toolbar: [[{header: [1, 2, false]}], ['bold', 'italic', 'underline',], [{'list': 'ordered'}, {'list': 'bullet'}],]
          }), []
      )*/

  const editContainer = useMemo(
    () => (
      <div
        style={{ border: 'none', fontSize: flutterToken ? 16 : undefined }}
        className='my-editing-area'
      />
    ),
    []
  );

  const sortContentByDate = (tmpData) => {
    Object.keys(tmpData).forEach((key) => {
      const sorted = tmpData[key].content.sort((a, b) => {
        return new Date(b.startTime) - new Date(a.startTime);
      });
      tmpData[key] = { content: sorted };
    });
    return tmpData;
  };

  const addEntry = () => {
    let tmpData = { ...data };
    if (!data) {
      tmpData = { [user.userId]: { content: [] } };
    }
    if (!tmpData[user.userId]) {
      tmpData[user.userId] = { content: [] };
    }
    tmpData[user.userId].content.push({
      authorName: user.userName,
      authorId: user.userId,
      id: generateCode(10),
      startTime: new Date(),
      endTime: new Date(),
      doc: [{ text: { ops: [{ insert: ' ' }] } }],
    });
    setData(sortContentByDate(tmpData));
  };

  const deleteEntry = (key, id) => {
    const tmpData = { ...data };
    const index = tmpData[key].content.findIndex((entry) => entry.id === id);
    tmpData[key].content.splice(index, 1);
    setData(tmpData);
  };

  const addGoal = (key, id, tileIndex) => {
    const tmpData = { ...data };
    const index = tmpData[key].content.findIndex((entry) => entry.id === id);
    tmpData[key].content[index].doc.splice(tileIndex + 1, 0, { goal: {} });
    if (!tmpData[key].content[index].doc[tileIndex + 2]) {
      //add empty editor item if goal is last item
      tmpData[key].content[index].doc.splice(tileIndex + 2, 0, {
        text: { ops: [{ insert: '\n' }] },
      });
    }
    setData(tmpData);
  };

  const deleteGoal = ({ key, id, tileIndex }, goalId) => {
    const tmpData = { ...data };
    const index = tmpData[key].content.findIndex((entry) => entry.id === id);
    //check if item after goal is empty and delete in case it is
    if (
      tmpData[key].content[index].doc[tileIndex + 1] &&
      tmpData[key].content[index].doc[tileIndex + 1].text &&
      _isEqual(tmpData[key].content[index].doc[tileIndex + 1].text.ops, [
        { insert: '\n' },
      ])
    ) {
      tmpData[key].content[index].doc.splice(tileIndex + 1, 1);
    }
    tmpData[key].content[index].doc.splice(tileIndex, 1);
    setData(tmpData);
  };

  const handleClientGoalChange = ({ key, id, tileIndex }, goalId) => {
    const tmpData = { ...data };
    const index = tmpData[key].content.findIndex((entry) => entry.id === id);
    tmpData[key].content[index].doc[tileIndex].goal.id = goalId;
    setData(tmpData);
  };

  const handleChange = (key, id, tileIndex, content, delta, source, editor) => {
    const value = editor.getContents();
    const tmpData = { ...data };
    const index = tmpData[key].content.findIndex((entry) => entry.id === id);
    tmpData[key].content[index].doc[tileIndex].text = value;
    setData(tmpData);
  };

  const handleStartTime = (key, id, value) => {
    const tmpData = { ...data };
    const index = tmpData[key].content.findIndex((entry) => entry.id === id);
    tmpData[key].content[index].startTime = value;
    setData(sortContentByDate(tmpData));
  };

  const handleEndTime = (key, id, value) => {
    const tmpData = { ...data };
    const index = tmpData[key].content.findIndex((entry) => entry.id === id);
    tmpData[key].content[index].endTime = value;
    setData(tmpData);
  };

  const handleSave = async () => {
    if (system && isOnline) {
      const saveResult = await saveClientSystemDocumentation(
        user.userId,
        system.systemId,
        data[user.userId],
        user.sessionToken
      );
      if (saveResult) {
        setSaved(true);
      }
    }
  };

  const getDuration = (start, end) => {
    var diff = (end.getTime() - start.getTime()) / 1000;
    diff /= 60 * 60;
    return Math.abs(Math.round(diff * 10) / 10);
  };

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={() => history.goBack()}>
          <ArrowBack />
        </IconButton>
        <div style={{ fontSize: 16, fontWeight: 'bold' }}>
          {system && system.name}
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 10,
          width: '100%',
        }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <Button
                //disabled
                style={{ height: 30 }}
                color={'primary'}
                variant={'outlined'}
                onClick={() => {
                  setShowExport(true);
                }}
                startIcon={<GetApp fontSize={'large'} />}
              >
                Export
              </Button>
              {showExport && (
                <CircularProgress style={{ marginLeft: 5 }} size={20} />
              )}
            </div>
            <Button
              style={{ height: 30 }}
              color={'primary'}
              variant={'outlined'}
              onClick={() => setOpenDialog(true)}
              startIcon={<AddCircleOutline fontSize={'large'} />}
            >
              MA
            </Button>
          </div>
          <Divider style={{ backgroundColor: theme.palette.primary.light }} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                maxWidth: '50%',
                display: 'flex',
                justifyContent: 'center',
              }}
              onClick={() => setShowAllAuthors(!showAllAuthors)}
            >
              <IconButton
                size={'small'}
                style={{
                  borderRadius: '50%',
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderColor: theme.palette.primary.light,
                }}
                onClick={() => setShowAllAuthors(!showAllAuthors)}
              >
                {showAllAuthors ? (
                  <Visibility color={'primary'} />
                ) : (
                  <VisibilityOff color={'primary'} />
                )}
              </IconButton>
              <div style={{ marginLeft: 5, fontSize: 10, color: 'grey' }}>
                {system &&
                  system.counselors &&
                  showAllAuthors &&
                  `MA: ${
                    system.counselors.length === 1
                      ? 'Keine weiteren MA'
                      : system.counselors
                          .filter(
                            (counselor) => counselor.userId !== user.userId
                          )
                          .map((counselor) => ` ${counselor.userName}`)
                  }`}
              </div>
            </div>
            <Button
              style={{ height: 30 }}
              color={'primary'}
              variant={'outlined'}
              onClick={addEntry}
              startIcon={<AddCircleOutline fontSize={'large'} />}
            >
              Termin
            </Button>
          </div>
          <div style={{ marginTop: 20 }}>
            {allEntries.map((dateEntry) => (
              <div
                style={{
                  borderStyle: 'solid',
                  borderColor: theme.palette.primary.light,
                }}
              >
                {(dateEntry.authorId === user.userId || showAllAuthors) && (
                  <div>
                    {showAllAuthors && (
                      <div
                        style={{
                          textAlign: 'center',
                          fontWeight: 'bold',
                          color: 'grey',
                          backgroundColor: theme.palette.primary.light,
                        }}
                      >
                        {dateEntry.authorName}
                      </div>
                    )}
                    <div
                      style={{
                        backgroundColor: theme.palette.primary.light,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {dateEntry.authorId === user.userId && (
                        <IconButton
                          style={{ padding: 0 }}
                          onClick={() =>
                            deleteEntry(dateEntry.authorId, dateEntry.id)
                          }
                        >
                          <Delete color={'primary'} />
                        </IconButton>
                      )}
                      <div
                        style={{
                          marginLeft: 5,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: flutterToken ? 'column' : undefined,
                        }}
                      >
                        <div style={{ fontSize: 11, color: 'grey' }}>von:</div>
                        <StyledDatePicker
                          format='dd.MM.yy  (HH:mm)'
                          ampmInClock={false}
                          ampm={false}
                          disabled={dateEntry.authorId !== user.userId}
                          value={dateEntry.startTime}
                          onChange={(value) =>
                            handleStartTime(
                              dateEntry.authorId,
                              dateEntry.id,
                              value
                            )
                          }
                        />
                        <div style={{ fontSize: 11, color: 'grey' }}>bis:</div>
                        <StyledDatePicker
                          format='dd.MM.yy  (HH:mm)'
                          ampmInClock={false}
                          ampm={false}
                          disabled={dateEntry.authorId !== user.userId}
                          value={dateEntry.endTime}
                          onChange={(value) =>
                            handleEndTime(
                              dateEntry.authorId,
                              dateEntry.id,
                              value
                            )
                          }
                        />
                      </div>
                      <div
                        style={{
                          fontSize: 11,
                          fontWeight: 'bold',
                          textAlign: 'center',
                        }}
                      >
                        Dauer:{' '}
                        {getDuration(dateEntry.startTime, dateEntry.endTime)}
                      </div>
                    </div>
                    {dateEntry.doc.map((tile, tileIndex) =>
                      tile.text ? (
                        <div
                          style={{ position: 'relative' }}
                          key={dateEntry.id + tileIndex}
                          onClick={() => setFocusTile(tile)}
                        >
                          <ReactQuill
                            readOnly={dateEntry.authorId !== user.userId}
                            key={dateEntry.id + tileIndex}
                            modules={
                              dateEntry.authorId === user.userId &&
                              focusTile === tile
                                ? undefined
                                : { toolbar: null }
                            }
                            defaultValue={tile.text}
                            onChange={(content, delta, source, editor) =>
                              dateEntry.authorId === user.userId &&
                              handleChange(
                                dateEntry.authorId,
                                dateEntry.id,
                                tileIndex,
                                content,
                                delta,
                                source,
                                editor
                              )
                            }
                          >
                            {editContainer}
                          </ReactQuill>
                          {dateEntry.authorId === user.userId &&
                            tile === focusTile && (
                              <IconButton
                                style={{
                                  position: 'absolute',
                                  right: 0,
                                  bottom: 0,
                                }}
                                onClick={() =>
                                  addGoal(
                                    dateEntry.authorId,
                                    dateEntry.id,
                                    tileIndex
                                  )
                                }
                              >
                                <AddCircleOutline
                                  color={'primary'}
                                  fontSize={'small'}
                                />
                              </IconButton>
                            )}
                        </div>
                      ) : (
                        <ClientDocGoal
                          key={dateEntry.id + tileIndex}
                          userGoals={userGoals}
                          clientGoal={tile.goal}
                          details={{
                            key: dateEntry.authorId,
                            id: dateEntry.id,
                            tileIndex,
                          }}
                          setClientGoal={handleClientGoalChange}
                          deleteClientGoal={deleteGoal}
                        />
                      )
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </MuiPickersUtilsProvider>
        <IconButton
          style={{ padding: 0, position: 'fixed', bottom: 56, right: 10 }}
          onClick={handleSave}
        >
          <ButtonIconWrapper>
            <Save style={{ color: 'white', width: 40, height: 40 }} />
          </ButtonIconWrapper>
        </IconButton>
        {openDialog && (
          <ClientCounselors
            addClientSystemCounselor={addClientSystemCounselor}
            setOpen={setOpenDialog}
            organizationId={
              //TODO this is temporary: remove .code version once all user assignments have orgId
              (userData.userInfo.assignment &&
                userData.userInfo.assignment.orgId) ||
              (userData.userInfo.assignment &&
                userData.userInfo.assignment.code)
            }
            sessionToken={user.sessionToken}
            getClientOrganizationMembers={getClientOrganizationMembers}
            system={system}
          />
        )}
        {saved && <CustomizedSnackbars setSuccessfullySaved={setSaved} />}
        {showExport && (
          <ClientDocExport
            documentation={data && data[user.userId].content}
            system={system}
            userGoals={userGoals}
            setOpen={setShowExport}
          />
        )}
      </div>
    </div>
  );
};

const StyledDatePicker = styled(DateTimePicker)(({ theme }) => ({
  '& .MuiInputBase-input': {
    fontSize: flutterToken ? 16 : 12,
    textAlign: 'center',
  },
  '& .MuiInput-underline:before': {
    borderBottom: 0,
  },
}));
