import { KeyboardDatePicker } from '@material-ui/pickers';
import Typography from '@material-ui/core/Typography';
import { MoodBar } from './MoodBar';
import TextField from '@material-ui/core/TextField';
import { CustomAspects } from '../../Goals/CustomAspects';
import React, { useEffect, useState } from 'react';
import { Close } from '@material-ui/icons';
import { Button, useTheme } from '@material-ui/core';

const TextFieldComponent = (props) => {
  return <TextField {...props} disabled />;
};

export const EditDiaryDialog = (props) => {
  const theme = useTheme();
  const [selectedDay, setSelectedDay] = useState({});

  useEffect(() => {
    if (props.day) {
      setSelectedDay(props.day);
    }
  }, [props.dateChanged]);

  const handleMoodChange = (mood) => {
    setSelectedDay((prev) => ({ ...prev, mood: mood }));
  };

  const handleCustomAspectChange = (activities) => {
    setSelectedDay((prev) => ({ ...prev, activities: activities }));
  };

  const handleNoteChange = (event) => {
    const note = event.target.value;
    setSelectedDay((prev) => ({ ...prev, note: note }));
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          maxWidth: 800,
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            position: 'fixed',
            width: '100%',
            maxWidth: 800,
            zIndex: 1,
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              padding: 10,
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <Typography variant={'h6'}>Tag bearbeiten</Typography>
            <Close
              color={'primary'}
              style={{ marginLeft: 'auto' }}
              onClick={() => props.setEditMode(false)}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: 10,
            marginTop: 55,
          }}
        >
          <KeyboardDatePicker
            style={{
              maxWidth: 300,
              alignSelf: 'flex-start',
            }}
            label='Datum'
            format='dd. MMMMMMMMM yyyy'
            value={props.day && props.day.date ? props.day.date : new Date()}
            onChange={props.handleDateChange}
            TextFieldComponent={TextFieldComponent}
          />
          <Typography
            style={{
              marginBottom: 10,
              marginTop: 30,
              fontWeight: 'bold',
            }}
            variant={'subtitle2'}
          >
            Wie war der Tag?
          </Typography>
          <MoodBar
            selected={selectedDay.mood}
            handleMoodChange={handleMoodChange}
          />
          <div style={{ marginBottom: 30 }} />
          <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
            Was hast du gemacht?
          </Typography>
          <TextField
            multiline
            style={{ width: '100%' }}
            placeholder='Notiz eingeben'
            value={selectedDay.note ? selectedDay.note : ''}
            margin='normal'
            variant='outlined'
            label={'Notiz'}
            onChange={handleNoteChange}
          />
          <CustomAspects
            diaryDay={selectedDay}
            handleCustomAspectChange={handleCustomAspectChange}
          />

          <Button
            style={{ margin: 10 }}
            variant={'contained'}
            color={'primary'}
            onClick={() => props.setDayEdited(selectedDay)}
          >
            Speichern
          </Button>
        </div>
      </div>
    </div>
  );
};
