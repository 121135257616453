import React from 'react';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AddCircleOutline } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';

export default ({ system }) => {
  let history = useHistory();
  const theme = useTheme();

  return (
    <div
      style={{
        width: '100%',
        marginTop: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <span style={{ width: 200, textAlign: 'center' }}>
        {!system || system.private
          ? "Los geht's - erstelle dein erstes Ziel!"
          : 'Diese Klient:in hat noch kein Ziel mit dir - erstelle eins für euch!'}
      </span>
      <IconButton
        style={{ backgroundColor: theme.palette.primary.main, marginTop: 10 }}
        onClick={() => history.push('/addgoal', { system: system })}
      >
        <AddCircleOutline
          style={{ color: 'white', width: 40, height: 40 }}
          fontSize={'large'}
        />{' '}
      </IconButton>
    </div>
  );
};
