export const events = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  UPDATE: 'UPDATE',
  LEAVE: 'LEAVE',
};

export const MAX_FILESIZE = 10000000; // 10MB

export const IFRAMEORIGINANDSOURCE = process.env.REACT_APP_ELEMENT_URL; //'http://127.0.0.1:8080' //

export const dateOptions = {
  weekday: 'short',
  year: '2-digit',
  month: 'numeric',
  day: 'numeric',
};

export const dateWithTimeOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export default {
  events,
  dateOptions,
  dateWithTimeOptions,
};

export const permissions = {
  COUNSELOR: 'counselor',
  USE_METHOD_DIARY: 'useMethodDiary',
  USE_METHOD_VALUES: 'useMethodValues',
  USE_METHOD_SURVEYS: 'useMethodSurveys',
  USE_METHOD_WHITEBOARD: 'useMethodWhiteboard',
  MESSENGER: '/messenger',
  FILES: '/files',
  DOC: '/documentation',
};

export const calendarRecurrenceFreq = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  BIWEEKLY: 'biweekly',
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
};

export const calendarRecurrenceEdit = {
  ALL: 'all',
  SINGLE: 'single',
  FORWARD: 'forward',
};
