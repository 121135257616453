import userData from '../../services/userData';
import { AccountCircle, Delete, Edit } from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { Menu, MenuItem, styled } from '@material-ui/core';
import CrownIcon from '../../res/CrownIcon';

export const GoalOverviewMembers = ({ goal }) => {
  const { userData } = useContext(UserDataContext);
  const [images, setImages] = useState([]);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        minHeight: 45,
      }}
    >
      {goal && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            marginRight: 5,
          }}
        >
          {/*{member.image ? (
            <Circle >
              <MemberIcon
                  icon={images[member.image]}
              />
            </Circle>
        ) : (
            <AccountCircle color={'primary'} fontSize={'small'} />
        )}*/}
          <CrownIcon />

          <span
            style={{
              fontSize: 10,
              textAlign: 'center',
              color: '#8b8b8b',
              maxWidth: '8ch',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {goal.goalOwner && goal.goalOwner.userName}
          </span>
        </div>
      )}
      {goal &&
        goal.members &&
        Object.values(goal.members).map((member) => {
          if (
            member.userName !== userData.userInfo.userName &&
            goal.goalOwner &&
            member.userName !== goal.goalOwner.userName
          ) {
            return (
              <div
                key={member.userId}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  marginRight: 5,
                }}
              >
                {member.image ? (
                  <Circle key={member.userId}>
                    <MemberIcon
                      key={member.userId}
                      icon={images[member.image]}
                    />
                  </Circle>
                ) : (
                  <AccountCircle
                    style={{ color: '#ddeecb' }}
                    fontSize={'small'}
                  />
                )}

                <span
                  style={{
                    fontSize: 10,
                    textAlign: 'center',
                    marginLeft: 5,
                    color: '#acacac',
                    maxWidth: '7ch',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {member.userName}
                </span>
              </div>
            );
          }
        })}
    </div>
  );
};

const Circle = styled('div')(({ theme, img }) => ({
  padding: 5,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: theme.palette.primary.main,
  borderRadius: '50%',
  //backgroundColor: theme.palette.secondary.light,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  color: 'orange', //theme.palette.primary.main,
}));

const MemberIcon = styled('div')(({ icon }) => ({
  width: 10,
  height: 10,
  borderRadius: '50%',
  backgroundImage: 'url(' + icon + ')',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
}));
