import { useContext, useEffect, useState } from 'react';
import { firebase } from '../firebase';
import { messaging as firebaseMessaging } from 'firebase';
import { UserDataContext } from '../state/contexts/UserDataContext';
import { AuthContext } from '../state/contexts/AuthContext';
import { isSafari } from '../utils/detectBrowser';
import { saveLog } from '../utils/saveLog';

export const FCMNotifications = () => {
  const {
    userData,
    setServerFcmToken,
    setCurrentFCMToken,
    currentFCMToken,
    setForegroundMessage,
    isLoggingOut,
  } = useContext(UserDataContext);
  const { user } = useContext(AuthContext);
  const [context, setContext] = useState();
  const [sessionToken, setSessionToken] = useState();

  const setFlutterToken = () => {
    //FIXME workaround for deleted iframe cache in Safari/WebviewKit: save password locally
    //possible workaround here? https://gist.github.com/iansltx/18caf551baaa60b79206
    const flutterToken = window.localStorage.getItem('flutterToken');
    if (flutterToken && userData.fcmTokenList) {
      const tokenList = [];
      userData.fcmTokenList.forEach((token) => {
        if (token.timestamp) {
          tokenList.push(token.token);
        } else {
          tokenList.push(token);
        }
      });
      saveLog({ flutter0: 'run' });
      if (!tokenList.includes(flutterToken)) {
        saveLog({ flutter1: 'run' });
        setCurrentFCMToken(flutterToken);
        sendTokenToServer(flutterToken);
      }
    }
  };

  useEffect(() => {
    if (context) {
      setFlutterToken();
    }
  }, [context]);

  useEffect(() => {
    if (userData && user) {
      setContext(userData);
      setSessionToken(user.sessionToken);
    }
  }, [user, userData]);

  let messaging;

  //TODO: isSupported is not a function?! - used to work before
  if (firebaseMessaging.isSupported()) {
    messaging = firebase.messaging();
  }

  //messaging.usePublicVapidKey("BHHmEnHlfwnYDpY9nFSwEFXEGzp4R8e7YxgJuZEOUUVU54dKrONEhiv976HFFzOir-LbmR8IyuXyItTrJ2x8Ohg");

  const [vapid, setVapid] = useState();
  useEffect(() => {
    if (messaging) {
      if (!vapid) {
        messaging.usePublicVapidKey(
          'BHHmEnHlfwnYDpY9nFSwEFXEGzp4R8e7YxgJuZEOUUVU54dKrONEhiv976HFFzOir-LbmR8IyuXyItTrJ2x8Ohg'
        );
        setVapid(true);
      }
    }
  }, [messaging, vapid]);

  const sendTokenToServer = (currentToken) => {
    if (context.userInfo && context.userInfo.userId) {
      if (!context.fcmTokenList) {
        setServerFcmToken(
          context.userInfo,
          [{ token: currentToken, timestamp: Date.now() }],
          sessionToken
        );
      } else {
        //temporarily model token list until all users provide token object {token: xxxx, timestamp: xxxx}
        const tokenObjectList = [];
        context.fcmTokenList.forEach((token) => {
          if (token.timestamp) {
            tokenObjectList.push(token);
          } else {
            tokenObjectList.push({ token: token, timestamp: Date.now() });
          }
        });
        //TODO set proper time frame
        // filter out previous version of current token, add current one with actual date, and filter out outdated tokens
        const currentlyUsedTokens = tokenObjectList
          .filter((tokenObj) => tokenObj.token !== currentToken)
          .filter((tokenObj) => Date.now() - tokenObj.timestamp < 2419200000); // 4 weeks

        currentlyUsedTokens.push({
          token: currentToken,
          timestamp: Date.now(),
        });
        saveLog({ flutter3: 'run' });
        setServerFcmToken(context.userInfo, currentlyUsedTokens, sessionToken);
      }
    }
  };

  const getToken = () => {
    return messaging
      .getToken()
      .then((currentToken) => {
        if (currentToken) {
          setCurrentFCMToken(currentToken);
          sendTokenToServer(currentToken);
          return currentToken;
          //updateUIForPushEnabled(currentToken);
        } else {
          // Show permission request.
          console.log(
            'No Instance ID token available. Request permission to generate one.'
          );
          // Show permission UI.
          //updateUIForPushPermissionRequired();
          //setTokenSentToServer(false);
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        //showToken('Error retrieving Instance ID token. ', err);
        //setTokenSentToServer(false);
      });
  };

  if (messaging) {
    // Callback fired if Instance ID token is updated.
    messaging.onTokenRefresh(() => {
      messaging
        .getToken()
        .then((refreshedToken) => {
          // Indicate that the new Instance ID token has not yet been sent to the
          // app server.
          //setTokenSentToServer(false);
          // Send Instance ID token to app server.
          sendTokenToServer(refreshedToken);
          // ...
        })
        .catch((err) => {
          console.log('Unable to retrieve refreshed token ', err);
          //showToken('Unable to retrieve refreshed token ', err);
        });
    });

    // Handle incoming messages. Called when:
    // - a message is received while the app has focus
    // - the user clicks on an app notification created by a service worker
    //   `messaging.setBackgroundMessageHandler` handler.
    /*messaging.onMessage((payload) => {
          console.log('Message received. ', payload);
          setMessage(message.data['firebase-messaging-msg-data'].data.message);
          // ...
        });*/
  }

  useEffect(() => {
    if (messaging) {
      navigator.serviceWorker.addEventListener('message', (message) => {
        if (
          message &&
          message.data['firebase-messaging-msg-data'] &&
          message.data['firebase-messaging-msg-data'].data &&
          message.data['firebase-messaging-msg-type'] !== 'notification-clicked'
        ) {
          const content = message.data['firebase-messaging-msg-data'].data;

          setForegroundMessage({
            title: content.title,
            body: content.body,
            type: content.type,
            tag: content.tag,
            badge: 'static/logo_title.png',
            icon: 'static/logo_title.png',
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (
      sessionToken &&
      messaging &&
      !currentFCMToken &&
      context &&
      !isLoggingOut
    ) {
      const waitForToken = async () => {
        await getToken();
      };
      waitForToken();
    }
  }, [sessionToken, context, currentFCMToken, isLoggingOut]);

  /*useEffect(() => {
      if (
        messaging &&
        context &&
        context.userInfo.userId &&
        currentToken &&
        context.fcmToken !== currentToken
      )
        if (!delToken) {
          setDelToken(true);
          const refreshToken = async () => {
            await messaging.deleteToken(context.fcmToken);
            console.log('fcm deletedtoken', context.fcmToken);
            getToken();
          };

          refreshToken();
        }
    }, [delToken, context, messaging, currentToken]);*/

  return null;
};
