import { FormControl, InputLabel, makeStyles, Select } from '@material-ui/core';
import React from 'react';

const flutterToken = window.localStorage.getItem('flutterToken');

const useStyles = makeStyles({
  root: {
    fontSize: flutterToken ? 16 : 12,
  },
});

export const ClientGoalSelect = (props) => {
  const classes = useStyles();

  return (
    <FormControl
      classes={{ root: classes.root }}
      style={{
        minWidth: 100,
        maxWidth: 300,
      }}
    >
      <div style={{ fontSize: flutterToken ? 16 : 12, marginBottom: -6 }}>
        <b>...oder Ziel wählen</b>
      </div>
      <Select
        label={'sdsdfsdf'}
        disableUnderline
        classes={{ root: classes.root }}
        value={props.selectedGoal ? props.selectedGoal : ''}
        onChange={props.onChange}
      >
        {props.userGoals &&
          Object.values(props.userGoals).map(props.callbackfn)}
      </Select>
    </FormControl>
  );
};
