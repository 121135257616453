import React, { useEffect, useState, createContext } from 'react';

import {
  fetchRegister,
  fetchLogin,
  fetchLogout,
  fetchCurrentUser,
  fetchDeleteUser,
  fetchRequestPasswordReset,
} from 'services/authentication';
import { fetchCheckCode } from '../../services/authentication';
import { checkNetworkState } from '../../utils/networkState';
import { IFRAMEORIGINANDSOURCE } from '../../utils/constants';

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const { children } = props;
  const [user, setUser] = useState();
  const [loadingUser, setLoadingUser] = useState(true);
  const [networkState, setNetworkState] = useState();
  const [isMonitoringCurrentUser, setIsMonitoringCurrentUser] = useState();

  const isOnline = (type) => {
    const state = checkNetworkState();
    if (type === 'stateChange' || (!type && !state.online)) {
      state.stateChange = true;
      setNetworkState(state);
    }
    if (!type) {
      return state.online;
    }
  };

  const checkCode = async (code) => {
    return await fetchCheckCode(code);
  };

  const login = (data) => {
    return fetchLogin(data).then(
      async (user) => {
        monitorCurrentUser();
        setUser({
          userId: user.objectId,
          userName: user.username,
          sessionToken: user.sessionToken,
          password: data.password,
        });
      },
      ({ message }) => {
        return message;
      }
    );
  };

  const register = (data) => {
    return fetchRegister(data).then(
      async (user) => {
        monitorCurrentUser();
        setUser({
          userId: user.objectId,
          userName: user.username,
          sessionToken: user.sessionToken,
          password: data.password,
        });
      },
      ({ message }) => {
        setUser(null);

        return message;
      }
    );
  };

  const deleteUser = (userId, password) => {
    return fetchDeleteUser(userId, password).then(
      (response) => {
        response.ok = true;
        return response;
      },
      ({ message }) => {
        return message;
      }
    );
  };

  const requestPasswordReset = (email) => {
    return fetchRequestPasswordReset(email).then(
      (response) => {
        response.ok = true;
        return response;
      },
      ({ message }) => {
        return message;
      }
    );
  };

  const monitorCurrentUser = () => {
    if (!isMonitoringCurrentUser) {
      setIsMonitoringCurrentUser(true);
      setTimeout(function () {
        fetchCurrentUser().then(async (user) => {
          if (user && user.objectId) {
            monitorCurrentUser();
          } else {
            setIsMonitoringCurrentUser(false);
            setUser(null);
          }
        });
      }, 60000);
    }
  };

  useEffect(() => {
    fetchCurrentUser().then(async (user) => {
      setLoadingUser(false);
      if (user && user.objectId) {
        monitorCurrentUser();
        setUser({
          userId: user.objectId,
          userName: user.username,
          sessionToken: user.sessionToken,
        });
      } else {
        setUser(null);
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        loadingUser,
        register,
        login,
        checkCode,
        networkState,
        isOnline,
        deleteUser,
        requestPasswordReset,
        monitorCurrentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
