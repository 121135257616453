import InnerHappiness from '../res/milkmocha/milkmocha_benefit.gif';
import Pro from '../res/milkmocha/milkmocha_happy.gif';
import Rope from '../res/rope.jpg';
import Medal from '../res/medal.svg';
import InnerSwinehound from '../res/milkmocha/milkmocha_lazy.gif';
import Contra from '../res/milkmocha/test.png';
import Typography from '@material-ui/core/Typography';
import Gold from '../res/coin_star_gold_stop.gif';
import Bronze from '../res/coin_star_bronze_stop.gif';
import React, { useEffect, useState } from 'react';
import { FavoriteDialog } from './FavoriteDialog';
import StarAnimation from '../components/elements/StarAnimation';
import Glass from '../res/glass.png';

export const Rooms = ({ collect }) => {
  const [room, setRoom] = useState(InnerHappiness);
  const [showDialog, setShowDialog] = useState();
  const [bonusStars, setBonusStars] = useState([1, 1, 1]);

  const medalWidth = 40;
  const ropeWidth = window.innerWidth > 600 ? 600 : window.innerWidth - 200;
  const ropePosition =
    (collect * ropeWidth) / 100 - medalWidth / 2 + ropeWidth / 2;

  useEffect(() => {
    const oldBonusStars = [];
    for (let x = 0; x < Math.round(collect / 5); x++) {
      oldBonusStars.push(1);
    }
    setBonusStars(oldBonusStars);
  }, [collect]);

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <div
          onClick={() => setRoom(InnerHappiness)}
          style={{
            width: 100,
            height: 100,
            marginRight: -20,
            backgroundImage: `url(${Pro})`,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }}
        />
        <div
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            width: ropeWidth,
            height: 100,
            backgroundImage: `url(${Rope})`,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'repeat',
          }}
        >
          <div
            style={{
              position: 'absolute',
              right: ropePosition,
            }}
          >
            <div
              style={{
                width: medalWidth,
                height: 40,
                backgroundImage: `url(${Medal})`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
              }}
            />
            <div style={{ marginTop: -5, textAlign: 'center' }}>
              {collect * 5}
            </div>
          </div>
        </div>
        <div
          onClick={() => setRoom(InnerSwinehound)}
          style={{
            width: 100,
            height: 100,
            marginLeft: -20,
            backgroundImage: `url(${Contra})`,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }}
        />
      </div>
      <Typography variant={'subtitle2'}>
        {room === InnerHappiness
          ? 'Palast des Inneren Glücks'
          : 'Höhle des Inneren Schweinehundes'}
      </Typography>

      <div
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          borderRadius: '5%',
          backgroundColor:
            room === InnerHappiness
              ? 'rgb(208,243,208)'
              : room === InnerSwinehound
              ? 'rgb(255,211,211)'
              : null,
        }}
      >
        <div
          style={{
            position: 'absolute',
            backgroundColor:
              room === InnerHappiness
                ? 'rgb(208,243,208)'
                : room === InnerSwinehound
                ? 'rgb(255,211,211)'
                : null,
            left: room === InnerHappiness ? 30 : undefined,
            right: room === InnerSwinehound ? 30 : undefined,
            top: -15,
            width: 40,
            height: 40,
            transform: 'rotate(45deg)',
          }}
        ></div>
        <div
          style={{
            opacity: '80%',
            display: 'flex',
            height: '100%',
            width: '100%',
            backgroundImage: `url(${room})`,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            marginBottom: 15,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '30%',
              width: '30%',
              backgroundColor:
                room === InnerHappiness
                  ? 'green'
                  : room === InnerSwinehound
                  ? 'red'
                  : null,
            }}
          ></div>
        </div>
        <div
          style={{
            //borderStyle: 'solid',
            borderRadius: '8%',
            borderWidth: 2,
            position: 'relative',
            display: 'flex',
            height: '100%',
            width: '60%',
            flexWrap: 'wrap',
            // backgroundColor: 'rgb(253,198,136)',
            /*backgroundImage: `url(${Board})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',*/
          }}
        >
          <div
            onClick={() => {
              setShowDialog(true);
            }}
            style={{
              display: 'flex',
              width: '100%',
              height: '0%',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
            }}
          >
            {bonusStars.map((_, index) => (
              <div style={{ textAlign: 'center' }}>
                <div
                  key={index}
                  style={{
                    justifySelf: 'center',
                    display: 'flex',
                    height: 50,
                    width: 50,
                    marginTop: '8%',
                    marginBottom: '8%',
                    backgroundImage: `url(${
                      room === InnerHappiness
                        ? Gold + '?a=' + index
                        : Bronze + '?a=' + index
                    })`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  {/*<StarAnimation />*/}
                </div>
                Bonus
              </div>
            ))}
          </div>
        </div>
        {/*<div style={{ display: 'flex', marginTop: 'auto', marginBottom: 20 }}>
                {bonusStars.map(() => (
                    <div
                        style={{
                            justifySelf: 'center',
                            display: 'flex',
                            height: 50,
                            width: 50,
                            backgroundImage: `url(${Glass})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                        }}
                    >
                        <StarAnimation />
                    </div>
                ))}
            </div>*/}
      </div>
      {showDialog && <FavoriteDialog setShowDialog={setShowDialog} />}
    </div>
  );
};
