export const checkNetworkState = () => {
  const condition = navigator.onLine ? 'online' : 'offline';
  let networkState = {
    type: condition === 'online' ? 'success' : 'error',
    text:
      condition === 'online'
        ? 'Du bist wieder online'
        : 'Keine Internetverbindung',
    online: condition === 'online',
  };
  return networkState;
};
