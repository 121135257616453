const initialData = {
  columns: {
    'column-1': {
      id: 'column-1',
      itemIds: [],
    },
    'column-2': {
      id: 'column-2',
      itemIds: [],
    },
  },
  items: {},
};
export default initialData;
