import { styled } from '@material-ui/core';
import React from 'react';

export const CircleWrapper = ({
  color,
  backgroundColor,
  children,
  onClick,
  selected,
  size = 60,
}) => {
  return (
    <Circle
      onClick={onClick}
      color={color}
      backgroundcolor={backgroundColor}
      selected={selected}
      size={size}
    >
      {children}
    </Circle>
  );
};

const Circle = styled('div')(
  ({ theme, color, backgroundcolor = 'white', selected, size }) => ({
    width: size,
    height: size,
    flexShrink: 0,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: color,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: 'white',
    boxShadow: selected ? undefined : '2px 2px 4px 1px #888888',
    backgroundColor: backgroundcolor,
  })
);
