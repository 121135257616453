import React, { createContext } from 'react';

import { fetchFiles } from '../../services/files';
import { mapApiToFileModel } from '../../models/FileModel';

export const FilesContext = createContext({});

const FilesContextProvider = (props) => {
  const { children } = props;

  const getFiles = async (userInfo, sessionToken) => {
    const files = await fetchFiles(userInfo, sessionToken);
    return files.map((file) => mapApiToFileModel(file));
  };

  return (
    <FilesContext.Provider
      value={{
        getFiles,
      }}
    >
      {children}
    </FilesContext.Provider>
  );
};

export default FilesContextProvider;
