import styled, { keyframes } from 'styled-components';
import React, { useEffect, useState } from 'react';

export const GainParticles = ({
  children,
  value,
  icon,
  dimension,
  collect = () => {},
}) => {
  const [particles, setParticles] = useState([]);
  const [val, setVal] = useState();
  const [count, setCount] = useState();

  useEffect(() => {
    setVal(value * 50);
  }, [value]);

  const createParticle = () => {
    let oldParticles = [...particles];
    //TODO: aim: find a way to remove old icons from DOM after finishing animation, this one was just a test of sth.
    /*if(particles.length > 10){
                oldParticles = [...particles.slice(particles.length - 5, particles.length - 1)]
            }*/
    oldParticles.push(<Icon />);
    setVal(val - 10);
    collect(dimension, 1, false);
    setParticles(oldParticles);

    if (count && val - 10 <= 0) {
      collect(dimension, 0, true);
    }
  };

  useEffect(() => {
    if (count && val > 0) {
      createParticle();
      setCount(false);
    }
  }, [val, count, setCount]);

  const movementX = (Math.random() - 0.5) * 200 + 20;
  const rotation = (Math.random() - 0.5) * 200;

  const animation = keyframes`
     0% { 
     bottom: 0px; 
     opacity: 0;
     margin-left: 20px; 
     }
     10% { 
     opacity: 1
     }
     90% { 
     opacity: 1
     }
     100% { 
     bottom: 600px; 
     margin-left: ${movementX}px; 
     transform: rotate(${rotation * 2}deg);
     opacity: 0;
     display: none;
     }
 `;
  const Icon = styled(icon.type)`
    position: absolute;
    color: orange;
    opacity: 0;
    width: 30px;
    height: 30px;
    animation-name: ${animation};
    animation-duration: 2s;
    pointer-events: none;
  `;
  //set counter to more for better user experience -> drawback: performance problems
  return (
    <div
      style={{
        position: 'relative',
        touchAction: 'manipulation',
        textAlign: 'center',
      }}
      onClick={() => setCount(true)}
    >
      {children}
      {particles &&
        particles.map((particle) => {
          return particle;
        })}
      {val * 5}
    </div>
  );
};
