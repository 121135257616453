import Parse from '../utils/initParse';

const Survey = Parse.Object.extend('Survey');

const props = {
  ACL: 'ACL',
  TITLE: 'title',
  KEY: 'key',
  AUTHOR: 'author',
  ELEMENTS: 'elements',
  MEMBERS: 'members',
  ANSWERS: 'answers',
  ISCOMPLETE: 'isComplete',
  UPDATED: 'updatedAt',
};
const propsArray = Object.values(props);

export function mapApiToSurveyModel(apiObject) {
  return propsArray.reduce(
    (model, mapEl) => {
      model[mapEl] = apiObject.get(mapEl);
      return model;
    },
    {
      id: apiObject.id,
    }
  );
}

export function mapSurveyModelToApi(modelObject) {
  const survey = new Survey();

  propsArray.forEach((prop) => {
    survey.set(prop, modelObject[prop]);
  });
  survey.id = modelObject.id;

  return survey;
}
