import Parse from 'utils/initParse';
import isEmpty from 'lodash.isempty';

import { subscribeToQuery } from 'services/subscription';
import { IuvivoUser } from './userData';
import { generateCode } from '../utils/generateCode';
import { mapApiToGoalTemplatesModel } from '../models/GoalTemplatesModel';

export const Goal = Parse.Object.extend('Goal');
const GoalArchive = Parse.Object.extend('GoalArchive');
const Step = Parse.Object.extend('Step');
const CustomAspects = Parse.Object.extend('CustomAspects');
const query = new Parse.Query('Goal');

const goalAcl = (goal) => {
  const acl = new Parse.ACL();
  Object.values(goal.members).forEach((member) => {
    acl.setReadAccess(member.userId, true);
    acl.setWriteAccess(member.userId, true);
  });
  goal.supporters &&
    goal.supporters.forEach((supporter) => {
      acl.setReadAccess(supporter.userId, true);
    });
  return acl;
};

const goalTemplateAcl = (userInfo, template) => {
  const acl = new Parse.ACL();
  acl.setReadAccess(userInfo.userId, true);
  acl.setWriteAccess(userInfo.userId, true);

  template.members &&
    Object.values(template.members).forEach((member) => {
      acl.setReadAccess(member.userId, true);
    });
  return acl;
};

export const fetchGetGoals = async (userGoals, sessionToken) => {
  const goalIds = Object.keys(userGoals);
  // only get goals, of which current user is member
  query.containedIn('objectId', goalIds);

  //TODO remove goals in a userGoals list that don't exist (anymore) in Goals

  return query.find({ sessionToken });
};

export const fetchCreateGoal = async (goal, sessionToken) => {
  goal.setACL(goalAcl(goal.attributes));
  return goal.save({}, { sessionToken });
};

export const fetchUpdateGoal = async (goal, sessionToken) => {
  query.equalTo('objectId', goal.id);
  return query.first({ sessionToken }).then((goalToUpdate) => {
    goalToUpdate.setACL(goalAcl(goal));
    return goalToUpdate.save(goal, { sessionToken });
  });
};

export const fetchRemoveGoal = async (userId, goal, sessionToken) => {
  const userQuery = new Parse.Query(IuvivoUser);
  userQuery.equalTo('userInfo.userId', userId);
  const user = await userQuery.first({ sessionToken });
  const goals = user.get('goals');
  delete goals[goal.id];
  user.set('goals', goals);
  user.save({}, { sessionToken });

  const goalQuery = new Parse.Query(Goal);
  goalQuery.equalTo('objectId', goal.id);
  const result = await goalQuery.first({ sessionToken });
  const members = result.get('members');
  delete members[userId];
  const supporters = result.get('supporters') || [];
  supporters.filter((supporter) => supporter.memberId !== userId);
  if (isEmpty(members)) {
    return result.destroy({ sessionToken });
  } else {
    result.setACL(goalAcl({ members: members, supporters: supporters }));
    return result.save(
      { members: members, supporters: supporters },
      { sessionToken }
    );
  }
};

export const fetchSubscribeGoals = (userGoals, handleEvent, sessionToken) => {
  const goalIds = Object.keys(userGoals);
  const subscribeQuery = new Parse.Query('Goal');
  subscribeQuery.containedIn('objectId', goalIds);

  return subscribeToQuery(subscribeQuery, handleEvent, sessionToken).then(
    (subscription) => {
      console.info('successfully subscribed goals');
      return subscription;
    }
  );
};

export const fetchUnsubscribeGoals = (subscription) => {
  return subscription.unsubscribe().then(() => {
    console.info('successfully unsubscribed goals');
    return;
  });
};

export const fetchCreateStep = async (goalId, stepData, sessionToken) => {
  const goalQuery = new Parse.Query(Goal);
  goalQuery.equalTo('objectId', goalId);
  const results = await goalQuery.first({ sessionToken });

  if (!results.attributes.steps) {
    await results.set('steps', {});
    await results.save({}, { sessionToken });
  }
  stepData.id = stepData.id ? stepData.id : generateCode(10);
  results.set(`steps.${stepData.id}`, stepData);
  return results.save({}, { sessionToken });
};

export const fetchRemoveStep = async (goalId, stepId, sessionToken) => {
  const goalQuery = new Parse.Query(Goal);
  goalQuery.equalTo('objectId', goalId);
  const results = await goalQuery.first({ sessionToken });
  //await results.unset(`steps.${stepId}`);
  //TODO: only a workaround for unset bug
  const oldSteps = results.get('steps');
  const steps = { ...oldSteps };
  delete steps[stepId];
  results.set('steps', steps);
  //await results.unset(`steps.${stepId}`);
  /*console.log("results", results)*/
  return results.save({}, { sessionToken });
};

export default {
  fetchGetGoals,
  fetchCreateGoal,
  fetchUpdateGoal,
  fetchSubscribeGoals,
  fetchUnsubscribeGoals,
};

export const fetchSetGoalTemplate = async (
  template,
  userInfo,
  sessionToken
) => {
  const GoalTemplate = Parse.Object.extend('GoalTemplate');
  let goalTemplate;
  if (template && template.id) {
    const templateQuery = new Parse.Query(GoalTemplate);
    templateQuery.equalTo('objectId', template.id);
    goalTemplate = await templateQuery.first({ sessionToken });
  } else {
    goalTemplate = new GoalTemplate();
    goalTemplate.set('goalOwner', {
      userId: userInfo.userId,
      userName: userInfo.userName,
    });
  }
  goalTemplate.set('title', template.title);
  goalTemplate.set('steps', template.steps);
  goalTemplate.set('image', template.image);
  if (!template.members) {
    template.members = [];
  }
  goalTemplate.set('members', template.members);
  goalTemplate.setACL(goalTemplateAcl(userInfo, template));
  const savedTemplate = await goalTemplate.save({}, { sessionToken });
  return mapApiToGoalTemplatesModel(savedTemplate);
};

export const fetchUpdateGoalTemplateMembers = async (
  members,
  templateObject,
  userInfo,
  sessionToken
) => {
  const query = new Parse.Query('GoalTemplate');
  query.equalTo('objectId', templateObject.id);
  const template = await query.first({ sessionToken });
  /*const prevMembersInfo = template.get('members') || [];
  const prevMembers = prevMembersInfo.map((member) => member.userId);
  const newMembers = members.filter((member) => !prevMembers.includes(member));
  if (newMembers.length > 0) {
    sendSurveyNotification(
        newMembers,
        survey.title,
        userInfo.userName,
        'surveyNewMember',
        sessionToken
    );
  }*/
  template.set('members', members);
  template.setACL(goalTemplateAcl(userInfo, { members }));
  return await template.save({}, { sessionToken });
};

export const fetchGetGoalTemplates = async (sessionToken) => {
  const query = new Parse.Query('GoalTemplate');
  const templates = (await query.find({ sessionToken })) || [];
  return templates.map((template) => mapApiToGoalTemplatesModel(template));
};

export const fetchRemoveGoalTemplate = async (templateId, sessionToken) => {
  const query = new Parse.Query('GoalTemplate');
  const template = await query
    .equalTo('objectId', templateId)
    .first({ sessionToken });
  return await template.destroy({ sessionToken });
};
