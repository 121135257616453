import * as firebase from 'firebase';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { AuthContext } from 'state/contexts/AuthContext';
import { DialogPrivacyPolicy } from '../Drawer/DialogPrivacyPolicy';
import { authErrorType } from '../../utils/authErrorType';

import logo from '../../res/logo_title.png';
import Dialog from '@material-ui/core/Dialog';
import { DialogRequestPasswordReset } from './DialogRequestPasswordReset';
import { Checkbox } from '@material-ui/core';
import { Link } from '../elements/Link';
import { DefaultButton } from '../elements/DefaultButton';
import { Divider } from '../elements/Divider';
import { Cached, Edit, EditTwoTone } from '@material-ui/icons';

export default () => {
  const theme = useTheme();
  const [userName, setUsername] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [signupDisabled, setSignupDisabled] = useState(true);
  const [signinDisabled, setSigninDisabled] = useState(true);
  const [validCode, setValidCode] = useState();
  const [codeToCheck, setCodeToCheck] = useState();
  const [registerMode, setRegisterMode] = useState();
  const [policyAccepted, setPolicyAccepted] = useState();
  const [loadingAuth, setLoadingAuth] = useState();
  const [openDialog, setOpenDialog] = useState();
  const [stayLoggedIn, setStayLoggedIn] = useState(false);
  const [clientLinkCode, setClientLinkCode] = useState();
  const [openDialogPrivacyPolicy, setOpenDialogPrivacyPolicy] = useState();

  const {
    register,
    login,
    checkCode,
    isOnline,
    requestPasswordReset,
  } = useContext(AuthContext);

  const history = useHistory();

  useEffect(() => {
    if (registerMode) {
      if (userName && password && policyAccepted) {
        setSignupDisabled(false);
      } else {
        setSignupDisabled(true);
      }
    } else {
      if (userName && password) {
        setSigninDisabled(false);
      } else {
        setSigninDisabled(true);
      }
    }
  }, [userName, password, policyAccepted, registerMode]);

  useEffect(() => {
    if (clientLinkCode) {
      handleCode();
    }
  }, [clientLinkCode]);

  useEffect(() => {
    handleIdFromURL();
  }, []);

  /*const validEmail = (email) => {
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!re.test(email)) {
              //handleFirebaseError('non-valid username');
              return
          }
          //setFirebaseError(false)
          return re.test(email);
      };*/

  const handleIdFromURL = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let code = urlParams.get('code');
    if (code) {
      setClientLinkCode(code);
      setRegisterMode(true);
    }
  };

  const handleRegister = async () => {
    if (isOnline()) {
      if (userName && password) {
        if (userName.includes('.') || userName.includes(' ')) {
          setErrorMessage(
            authErrorType('User ID can only contain characters a-z, 0-9')
          );
          return;
        }
        setErrorMessage(false);
        setLoadingAuth(true);
        const errorResponse = await register({
          username: userName,
          password,
          assignment: validCode,
          email,
          stayLoggedIn,
        });
        setLoadingAuth(false);
        if (errorResponse) {
          setErrorMessage(authErrorType(errorResponse));
        } else {
          history.push('/');
        }
      }
    }
  };

  const handleLogin = async () => {
    if (isOnline()) {
      if (userName && password) {
        setErrorMessage(false);
        setLoadingAuth(true);
        const errorResponse = await login({
          username: userName,
          password,
          stayLoggedIn,
        });
        setLoadingAuth(false);
        if (errorResponse) {
          setErrorMessage(authErrorType(errorResponse));
        } else {
          history.push('/');
        }
      }
    }
  };

  const handleCode = async (testCode) => {
    if (isOnline()) {
      if (codeToCheck || clientLinkCode || testCode) {
        const code =
          codeToCheck ||
          (clientLinkCode && clientLinkCode) ||
          (testCode && testCode);
        setLoadingAuth(true);
        setErrorMessage(false);
        const result = await checkCode(code.split(' ').join('')); //remove spaces
        setLoadingAuth(false);
        if (result.error) {
          setErrorMessage(authErrorType(result.error));
          setValidCode(false);
        } else {
          setPolicyAccepted(false);
          setValidCode(result);
        }
      }
    }
  };

  return (
    <Container>
      {!loadingAuth && (
        <SignupWrapper>
          {registerMode && !validCode && (
            <SignupWrapper>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    width: 80,
                    height: 80,
                    backgroundImage: 'url(' + logo + ')',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
                Willkommen bei iuvivo!
              </div>
              <StyledTextField
                color={'primary'}
                placeholder='Gib deinen Code ein'
                margin='normal'
                variant='outlined'
                onChange={(event) => setCodeToCheck(event.target.value)}
              />
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              <DefaultButton
                variant='contained'
                customColor={theme.palette.primary}
                onClick={() => handleCode()}
                style={{ minWidth: 280, marginTop: 10, marginBottom: 10 }}
                startIcon={<Cached />}
              >
                Prüfen
              </DefaultButton>
              <Typography
                variant={'body1'}
                color={'primary'}
                style={{ fontSize: 12, width: 280 }}
              >
                Noch keinen Code? Dann schreibe uns an:{' '}
                <Link
                  color={theme.palette.primary.main}
                  textDecoration={'underline'}
                  href='mailto:info@iuvivo.de'
                  target='_blank'
                >
                  info@iuvivo.de
                </Link>
              </Typography>
              <Typography
                variant={'body1'}
                color={'primary'}
                style={{ marginTop: 50, fontSize: 12 }}
              >
                Oder probiere den kostenlosen eingeschränkten Testzugang
              </Typography>
              <StyledButton
                variant='outlined'
                onClick={() => handleCode('foVRsJBzmCIN')}
                color={'primary'}
              >
                Testzugang
              </StyledButton>
            </SignupWrapper>
          )}
          {registerMode && validCode && (
            <SignupWrapper>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    width: 80,
                    height: 80,
                    backgroundImage: 'url(' + logo + ')',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
                Willkommen bei iuvivo!
              </div>
              <StyledTextField
                placeholder='Benutzername'
                margin='normal'
                variant='outlined'
                onChange={(event) => {
                  setErrorMessage(false);
                  setUsername(event.target.value);
                }}
              />
              <StyledTextField
                type='password'
                placeholder='Passwort'
                margin='normal'
                variant='outlined'
                onChange={(event) => setPassword(event.target.value)}
              />
              <div style={{ color: theme.palette.primary.main, fontSize: 12 }}>
                <Checkbox
                  checked={stayLoggedIn}
                  onClick={(ev) => setStayLoggedIn(ev.target.checked)}
                  color={'primary'}
                />
                Angemeldet bleiben
              </div>
              <div
                style={{
                  fontSize: 11,
                  textAlign: 'center',
                  maxWidth: 280,
                  padding: 5,
                  margin: 10,
                  border: '1px dotted red',
                }}
              >
                ACHTUNG: Um dich zu schützen, erfragen wir keine Email-Adresse
                von dir. Wenn du allerdings gelegentlich dein Passwort vergisst,
                kannst du sie optional angeben, um ggf. einen Link zum
                Passwort-Zurücksetzen zu erhalten.{' '}
                <StyledTextField
                  style={{ width: '100%' }}
                  placeholder='E-Mail'
                  margin='normal'
                  variant='outlined'
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
              </div>
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              {registerMode && (
                <div
                  style={{
                    display: 'flex',
                    maxWidth: 280,
                    color: theme.palette.primary.main,
                  }}
                >
                  <Checkbox
                    checked={policyAccepted}
                    onClick={(ev) => setPolicyAccepted(ev.target.checked)}
                    color={'primary'}
                  />
                  <PrivacyPolicyCheckboxText>
                    Ich habe die{' '}
                    <span
                      style={{ cursor: 'pointer', textDecoration: 'underline' }}
                      onClick={() => setOpenDialogPrivacyPolicy(true)}
                    >
                      Datenschutzerklärung und Nutzungsbedingungen
                    </span>{' '}
                    gelesen und bin einverstanden.
                  </PrivacyPolicyCheckboxText>
                </div>
              )}
              <div>
                <StyledButton
                  disabled={signupDisabled}
                  variant='contained'
                  color={'primary'}
                  onClick={handleRegister}
                >
                  Registrieren
                </StyledButton>
              </div>
            </SignupWrapper>
          )}
          {!registerMode && (
            <SignupWrapper>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    width: 80,
                    height: 80,
                    backgroundImage: 'url(' + logo + ')',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
                Willkommen bei iuvivo!
              </div>
              <StyledTextField
                placeholder='Benutzername'
                margin='normal'
                variant='outlined'
                onChange={(event) => {
                  setErrorMessage(false);
                  setUsername(event.target.value);
                }}
              />
              <StyledTextField
                type='password'
                placeholder='Passwort'
                margin='normal'
                variant='outlined'
                onChange={(event) => setPassword(event.target.value)}
              />
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              <div
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  textAlign: 'end',
                  fontSize: 12,
                  width: 280,
                }}
                onClick={() => setOpenDialog(true)}
              >
                Passwort vergessen?
              </div>
              <div style={{ marginTop: 30, color: theme.palette.primary.main }}>
                <Checkbox
                  checked={stayLoggedIn}
                  onClick={(ev) => setStayLoggedIn(ev.target.checked)}
                  color={'primary'}
                />
                Angemeldet bleiben
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <DefaultButton
                  startIcon={<Edit />}
                  style={{ minWidth: 280 }}
                  customColor={theme.palette.primary}
                  disabled={signinDisabled}
                  variant='contained'
                  color={'primary'}
                  onClick={handleLogin}
                >
                  Anmelden
                </DefaultButton>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Divider
                    marginTop={50}
                    marginBottom={50}
                    color={theme.palette.text.secondary}
                  />
                  <div
                    style={{
                      fontWeight: 'bold',
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  >
                    ODER
                  </div>
                  <Divider
                    marginTop={50}
                    marginBottom={50}
                    color={theme.palette.text.secondary}
                  />
                </div>
                <DefaultButton
                  startIcon={<EditTwoTone />}
                  customColor={theme.palette.blueButton}
                  style={{ minWidth: 280 }}
                  onClick={() => {
                    setRegisterMode(true);
                    setErrorMessage(false);
                  }}
                >
                  Registrieren
                </DefaultButton>
              </div>
            </SignupWrapper>
          )}
        </SignupWrapper>
      )}
      {loadingAuth && (
        <LoadingContainer>
          <CircularProgress color={'primary'} />
          Prüfe Daten...
        </LoadingContainer>
      )}
      {openDialogPrivacyPolicy && (
        <Dialog
          fullScreen
          onClose={() => setOpenDialogPrivacyPolicy(false)}
          open={openDialogPrivacyPolicy}
        >
          <DialogPrivacyPolicy
            setOpen={setOpenDialogPrivacyPolicy}
            setPolicyAccepted={setPolicyAccepted}
          />
        </Dialog>
      )}
      <Dialog fullWidth onClose={() => setOpenDialog(false)} open={openDialog}>
        <DialogRequestPasswordReset
          setOpen={setOpenDialog}
          requestPasswordReset={requestPasswordReset}
        />
      </Dialog>
    </Container>
  );
};

const PrivacyPolicyCheckboxText = styled('div')({
  fontSize: 11,
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  hyphens: 'auto',
});

const LoadingContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: 150,
});

const Container = styled('div')({
  display: 'flex',
  width: '100%',
  padding: 1, //just to identify element in browser for debugging
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const SignupWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: 2, //just to identify element in browser for debugging
});

const StyledButton = styled(Button)(({ color }) => ({
  minWidth: 120,
}));

const StyledTextField = styled(TextField)({
  width: 280,
});

const ErrorMessage = styled('div')(({ theme }) => ({
  color: 'white',
  margin: '15px',
  padding: 15,
  textAlign: 'center',
  width: 280,
  backgroundColor: theme.palette.primary.verydark,
}));
