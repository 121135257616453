import React, { useEffect, useState } from 'react';
import { SurveyAnswers } from './SurveyAnswers';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { Card, IconButton, useTheme } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { ScaleSlider } from './elements/ScaleSlider';

export const SurveyResults = () => {
  let history = useHistory();
  const theme = useTheme();
  const [survey, setSurvey] = useState();
  const [answers, setAnswers] = useState([]);
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (history && history.location.state) {
      setSurvey(history.location.state.survey);

      const answers = [];
      survey &&
        survey.elements.forEach((_, index) => {
          const answerCollection =
            (survey.answers &&
              Object.values(survey.answers).map(
                (item) => item.answers[index]
              )) ||
            [];
          answers.push(answerCollection);
        });
      setAnswers(answers);
    }
  }, [survey, history]);

  useEffect(() => {
    const results = [];
    survey &&
      survey.elements.forEach((element, index) => {
        if (answers[index]) {
          if (element.type === 'scale') {
            let trueCount = 0;
            answers[index].forEach((answer) => {
              trueCount += answer.answer;
            });
            trueCount = trueCount / answers[index].length;
            results.push(
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                <ScaleSlider
                  disabled={true}
                  itemValue={trueCount.toFixed(0)}
                  variant={'body1'}
                />
                <Typography variant={'body1'}>
                  {'Durchschnittlicher Wert: ' + trueCount.toFixed(1)}
                </Typography>
              </div>
            );
          } else if (element.type === 'binary') {
            let trueCount = 0;
            answers[index].forEach((answer) => {
              answer.answer === 'yes' && trueCount++;
            });
            trueCount = ((trueCount / answers[index].length) * 100).toFixed(1);
            results.push(
              <div style={{ width: '100%' }}>
                <div
                  style={{
                    width: trueCount + '%',
                    backgroundColor: '#64c364',
                    color: 'white',
                    marginBottom: 5,
                    borderRadius: '0 25px 25px 0',
                    height: 40,
                  }}
                >
                  <div style={{ padding: 10, position: 'absolute' }}>
                    {trueCount + '%'} Ja
                  </div>
                </div>
                <div
                  style={{
                    width: 100 - trueCount + '%',
                    backgroundColor: '#d77575',
                    color: 'white',
                    borderRadius: '0 25px 25px 0',
                    height: 40,
                  }}
                >
                  <div style={{ padding: 10, position: 'absolute' }}>
                    {100 - trueCount + '%'} Nein
                  </div>
                </div>
              </div>
            );
          } else if (element.type === 'text') {
            let comments = [];
            answers[index].forEach((answer) => {
              comments.push(
                <div style={{ marginBottom: 10 }}>{answer.answer}</div>
              );
            });
            results.push(comments);
          }
        } else {
          results.push(<div>Bisher keine Angaben</div>);
        }
      });
    setResults(results);
  }, [survey, answers]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        <IconButton onClick={() => history.goBack()}>
          <ArrowBack />
        </IconButton>
        <Typography variant={'subtitle1'}>{survey && survey.title}</Typography>
      </div>
      {survey &&
        results.map((result, index) => (
          <Card style={{ margin: 5, marginBottom: 10 }}>
            <div
              style={{
                width: '100%',
                padding: 10,
                backgroundColor: theme.palette.secondary.light,
              }}
            >
              <Typography variant={'body2'} style={{ fontWeight: 'bold' }}>
                {survey.elements[index].question}
              </Typography>
            </div>
            <div style={{ padding: 10 }}>{result}</div>
          </Card>
        ))}
    </div>
  );
};
