import Parse from '../utils/initParse';

const CalendarEvent = Parse.Object.extend('CalendarEvent');

const props = {
  ACL: 'ACL',
  TITLE: 'title',
  DESCRIPTION: 'description',
  AUTHOR: 'author',
  START: 'start',
  END: 'end',
  COLOR: 'color',
  MEMBERS: 'members',
  RECURRENCEFREQ: 'recurrenceFreq',
  RECURRENCEEND: 'recurrenceEnd',
  EXCEPTIONS: 'exceptions',
  EXCEPTIONID: 'exceptionId',
  ORIGINALEVENTID: 'originalEventId',
  ORIGINALSTART: 'originalStart',
  ORIGINALEND: 'originalEnd',
  REMINDERS: 'reminders',
  UPDATED: 'updatedAt',
};
const propsArray = Object.values(props);

export function mapApiToCalendarEventModel(apiObject) {
  return propsArray.reduce(
    (model, mapEl) => {
      model[mapEl] = apiObject.get(mapEl);
      return model;
    },
    {
      id: apiObject.id,
    }
  );
}

export function mapCalendarEventModelToApi(modelObject) {
  const calendarEvent = new CalendarEvent();

  propsArray.forEach((prop) => {
    calendarEvent.set(prop, modelObject[prop]);
  });
  calendarEvent.id = modelObject.id;

  return calendarEvent;
}
