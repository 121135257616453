import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ArrowBack,
  Assignment,
  AttachFile,
  DateRange,
} from '@material-ui/icons';
import { Button, IconButton, styled, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Main } from '../Goals/Main';
import CircularProgress from '@material-ui/core/CircularProgress';
import { userHasPermission } from '../../utils/handleRolePermissions';
import { permissions } from '../../utils/constants';
import { DialogNoRolePermission } from '../elements/DialogNoRolePermission';
import Dialog from '@material-ui/core/Dialog';
import previewImg from '../../res/preview_doc.jpg';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { DefaultButton } from '../elements/DefaultButton';

export const ClientDetails = () => {
  let history = useHistory();
  const { userData } = useContext(UserDataContext);
  const theme = useTheme();
  const [system, setSystem] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [openPreviewDialog, setOpenPreviewDialog] = useState();

  useEffect(() => {
    if (
      history.location &&
      history.location.state &&
      history.location.state.system
    ) {
      setSystem(history.location.state.system);
      setIsLoading(false);
    }
  }, [history.location]);

  const handleClickDoc = () => {
    if (!userHasPermission(userData, permissions.DOC)) {
      setOpenPreviewDialog(true);
    } else {
      history.push('/documentation', { system: system });
    }
  };

  return !isLoading ? (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
        <IconButton
          style={{ marginRight: -6, marginLeft: -12, marginBottom: 0 }}
          onClick={() => history.goBack()}
        >
          <ArrowBack />
        </IconButton>
        <div
          style={{
            width: 180,
            overflow: 'hidden',
            wordBreak: 'break-all',
            marginRight: 5,
          }}
        >
          <Typography variant={'body2'} style={{ fontWeight: 'bold' }}>
            {system && system.name}
          </Typography>
        </div>
        {system && system.systemId && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
              marginLeft: 'auto',
              width: '100%',
            }}
          >
            <DefaultButton
              customColor={theme.palette.secondary}
              textColor={theme.palette.primary.main}
              style={{
                width: 100,
                marginRight: 5,
                marginTop: 5,
                borderColor: theme.palette.primary.main,
              }}
              onClick={() => history.push('/clientfiles', { system: system })}
              variant={'outlined'}
              startIcon={<AttachFile color={'primary'} fontSize={'large'} />}
            >
              <div style={{ fontSize: 12 }}>Dateien</div>
            </DefaultButton>
            <DefaultButton
              customColor={theme.palette.secondary}
              textColor={theme.palette.primary.main}
              style={{
                width: 100,
                marginRight: 5,
                marginTop: 5,
                borderColor: theme.palette.primary.main,
              }}
              onClick={() => handleClickDoc()}
              variant={'outlined'}
              startIcon={<Assignment color={'primary'} fontSize={'large'} />}
            >
              <div style={{ fontSize: 12 }}>Doku</div>
            </DefaultButton>
          </div>
        )}
      </div>
      <Main system={system} />
      <Dialog
        fullWidth
        onClose={() => setOpenPreviewDialog(false)}
        open={openPreviewDialog}
      >
        <DialogNoRolePermission
          setOpen={setOpenPreviewDialog}
          previewImg={previewImg}
        />
      </Dialog>
    </div>
  ) : (
    <ProgressContainer>
      <CircularProgress color='primary' />
    </ProgressContainer>
  );
};

const ProgressContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '50vh',
});

const StyledButtonDoc = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  fontSize: 12,
  textTransform: 'capitalize',
}));
