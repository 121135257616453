import React from 'react';

export default ({ fill = 'orange' }) => {
  return (
    <svg width='24px' height='24px' viewBox='0 0 32 37' fill={fill}>
      >
      <path d='M23 0.625H3.5C1.7125 0.625 0.25 2.0875 0.25 3.875V26.625H3.5V3.875H23V0.625ZM21.375 7.125L31.125 16.875V33.125C31.125 34.9125 29.6625 36.375 27.875 36.375H9.98375C8.19625 36.375 6.75 34.9125 6.75 33.125L6.76625 10.375C6.76625 8.5875 8.2125 7.125 10 7.125H21.375ZM19.75 18.5H28.6875L19.75 9.5625V18.5Z' />
    </svg>
  );
};
