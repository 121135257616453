import React, { useContext } from 'react';
import { Paper, styled } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { UserDataContext } from 'state/contexts/UserDataContext';

export const GoalTemplatesAutoComplete = ({
  templates,
  handleTemplateChange,
  selectedTemplate,
}) => {
  return (
    <Container>
      <Autocomplete
        id='tags-standard'
        options={templates || []}
        getOptionLabel={(option) => option.title || ''}
        getOptionSelected={(option, value) => option.id === value.id}
        value={selectedTemplate}
        onChange={(e, v) => v && handleTemplateChange(v)}
        onInputChange={(e, v) => {
          //workaround: clear template on clicking clear button
          if (!v) {
            handleTemplateChange({});
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={'outlined'}
            label='Vorlage wählen'
            placeholder='Vorlage'
          />
        )}
      />
    </Container>
  );
};

const Container = styled('div')({
  width: '100%',
  paddingTop: 10,
  paddingBottom: 10,
});

const Contact = styled(Paper)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: 60,
  marginBottom: 10,
  backgroundColor: 'white',
});
