import React, { useContext, useState } from 'react';
import { Checkbox, TextField, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { AuthContext } from '../../state/contexts/AuthContext';
import { ClientsContext } from '../../state/contexts/ClientsContext';
import { DefaultButton } from '../elements/DefaultButton';

export const DialogClientSystemCaseId = ({ setOpen, userData, system }) => {
  const { user, isOnline } = useContext(AuthContext);
  const { setClientSystemCaseId } = useContext(ClientsContext);
  const [caseId, setCaseId] = useState(system.caseId);

  const handleSave = () => {
    if (isOnline() && caseId) {
      setClientSystemCaseId(
        system.systemId,
        userData.userInfo,
        user.sessionToken,
        caseId
      );
      setOpen(false);
    }
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          padding: 10,
          backgroundColor: '#ffb11e',
        }}
      >
        <Typography style={{ color: 'white' }} variant={'h6'}>
          Fall-ID
        </Typography>
        <Close
          style={{ marginLeft: 'auto', color: 'white' }}
          onClick={() => setOpen(false)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          padding: 20,
        }}
      >
        <Typography variant={'body2'}>
          Wenn die Dokumentation und die Ziele dieses Falls/Klientensystems
          automatisch mit der Verwaltungssoftware deiner Einrichtung
          synchronisiert werden sollen, kannst du hier die entsprechende ID
          eingeben. <br />
          <br /> Frage gegebenenfalls deine Verwaltung nach der ID.
        </Typography>
        <TextField
          color={'primary'}
          placeholder='Fall-ID'
          margin='normal'
          variant='outlined'
          value={caseId}
          onChange={(event) => setCaseId(event.target.value)}
        />
        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}
        >
          <DefaultButton style={{ width: '100%' }} onClick={handleSave}>
            Speichern
          </DefaultButton>
        </div>
      </div>
    </div>
  );
};
