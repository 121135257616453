import React from 'react';

export default ({ fill = 'orange', size = 20 }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <svg
        width={size + 'px'}
        height={size + 'px'}
        viewBox='0 0 1000 1000'
        fill={fill}
      >
        >
        <path d='M486.7,10c169.5,0,336.4,78.1,336.4,264.8c0,172.2-197.3,238.4-239.7,300.6c-31.8,46.3-21.2,111.3-108.6,111.3c-57,0-84.8-46.3-84.8-88.7c0-157.7,231.7-193.4,231.7-323.2c0-71.5-47.5-113.9-127.1-113.9C325.2,161,391.4,335.7,263,335.7c-46.4,0-86.1-27.8-86.1-80.8C176.8,125.1,325.1,10,486.7,10z M480.1,772.8c59.5,0,108.6,49,108.6,108.6c0,59.7-49,108.6-108.6,108.6c-59.6,0-108.6-48.9-108.6-108.6C371.5,821.7,420.5,772.8,480.1,772.8z' />
      </svg>
    </div>
  );
};
