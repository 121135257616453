import React, { useContext } from 'react';
import { Paper, styled } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { UserDataContext } from 'state/contexts/UserDataContext';
import { DialogInfo } from '../../elements/DialogInfo';
import { DefaultIconButton } from '../../elements/DefaultIconButton';
import { InfoOutlined } from '@material-ui/icons';
import { content } from './content';

export const GoalMembers = ({ members, handleMembersChange }) => {
  const {
    userData: { contacts },
  } = useContext(UserDataContext);

  const [openDialog, setOpenDialog] = React.useState(false);

  return (
    <Container>
      <Autocomplete
        style={{ width: '100%' }}
        multiple
        id='tags-standard'
        options={contacts || []}
        getOptionLabel={(option) => option.userName}
        getOptionSelected={(option, value) => option.userId === value.userId}
        defaultValue={members}
        //inputValue={goal.members ? goal.members : undefined}
        onChange={(e, v) => handleMembersChange(v)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={'outlined'}
            label='Wer macht mit?'
            placeholder='Mitmacher'
          />
        )}
      />
      <div style={{ display: 'flex' }}>
        <DefaultIconButton
          style={{ alignSelf: 'center', marginLeft: 5 }}
          onClick={() => setOpenDialog(true)}
        >
          <InfoOutlined color={'primary'} />
        </DefaultIconButton>
      </div>
      {openDialog && <DialogInfo setOpen={setOpenDialog} content={content} />}
    </Container>
  );
};

const Container = styled('div')({
  width: '100%',
  padding: 10,
  display: 'flex',
});

const Contact = styled(Paper)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: 60,
  marginBottom: 10,
  backgroundColor: 'white',
});
