import React, { useContext, useEffect, useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import localeDe from 'date-fns/locale/de';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import SwipeableViews from 'react-swipeable-views';
import { Histogram } from './Histogram';
import Slider from '@material-ui/core/Slider';
import { DateRange, Edit, EditOutlined } from '@material-ui/icons';
import ButtonIconWrapper from '../../elements/ButtonIconWrapper';
import IconButton from '@material-ui/core/IconButton';
import { getIcons } from '../../../utils/getIcons';
import { styled, Switch, useTheme, withStyles } from '@material-ui/core';
import VeryBadMoodIcon from '../../../res/emotions/VeryBadMoodIcon';
import { CircleWrapper } from '../../elements/CircleWrapper';
import BadMoodIcon from '../../../res/emotions/BadMoodIcon';
import NeutralMoodIcon from '../../../res/emotions/NeutralMoodIcon';
import VeryHappyMoodIcon from '../../../res/emotions/VeryHappyMoodIcon';
import HappyMoodIcon from '../../../res/emotions/HappyMoodIcon';
import { UserDataContext } from '../../../state/contexts/UserDataContext';
import { AuthContext } from '../../../state/contexts/AuthContext';
import CustomSnackbar from '../../elements/CustomSnackbar';
import Dialog from '@material-ui/core/Dialog';
import { EditDiaryDialog } from './EditDiaryDialog';
import { BackNavigation } from '../../elements/BackNavigation';
import { DefaultIconButton } from '../../elements/DefaultIconButton';

const dateOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const TextFieldComponentMood = (props) => {
  return null; //<TextField style={{display: "hidden"}} disabled InputProps={{ disableUnderline: true, style: {display: "hidden",fontSize: 14}}} {...props}  />;
};

export const Diary = () => {
  //const { setDBDiary } = useContext(DiaryContext);
  const theme = useTheme();
  const { userData, setDBDiary, setUserSettings, getDBDiary } = useContext(
    UserDataContext
  );
  const { user } = useContext(AuthContext);
  const [day, setDay] = useState();
  // const [diary, setDiary] = useState([{ date: new Date().getTime() }]);
  const [index, setIndex] = useState(0);
  const [editMode, setEditMode] = useState();
  const [moodStartDate, setMoodStartDate] = useState(
    new Date() - 604800000 * 4
  ); //4 weeks from today
  const [moodEndDate, setMoodEndDate] = useState(new Date().getTime());
  const [openDateRangeModal, setOpenDateRangeModal] = useState();
  const [checked, setChecked] = useState(false);
  const [diary, setDiary] = useState([]);
  const [diaryComplete, setDiaryComplete] = useState([]);
  const [successfullySaved, setSuccessfullySaved] = useState();
  const [dayEdited, setDayEdited] = useState();
  const [dateChanged, setDateChanged] = useState();

  useEffect(() => {
    if (user) {
      const getDiary = async () => {
        const diary = await getDBDiary(user.sessionToken);
        if (diary && diary.attributes) {
          const ownDiary = diary.attributes.diary;
          const sortedDiary = sortedData(ownDiary);
          if (sortedDiary.length === 0) {
            sortedDiary.push({ date: new Date().getTime() });
          } else if (sortedDiary.length > 0) {
            if (
              !moment(sortedDiary[sortedDiary.length - 1].date).isSame(
                moment(new Date().getTime()),
                'day'
              )
            ) {
              sortedDiary.push({ date: new Date().getTime() });
            }
          }
          setDiaryComplete(sortedDiary);
          setIndex(sortedDiary.length - 1);
          setDay(sortedDiary[sortedDiary.length - 1]);
          setMoodEndDate(new Date().getTime());
        } else {
          const newDiary = [{ date: new Date().getTime() }];
          setDiary(newDiary);
          setDiaryComplete(newDiary);
          setDay(newDiary[0]);
        }
      };
      getDiary();
    }
  }, [user]);

  useEffect(() => {
    if (userData && userData.settings && userData.settings.remindDiary) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [userData]);

  const sortedData = (data) => {
    if (data) {
      return data.sort((a, b) =>
        a.date.toString().localeCompare(b.date.toString())
      );
    }
    return [];
  };

  useEffect(() => {
    if (day && diary) {
      const completeDiary = sortedData(diaryComplete).map((entry) =>
        moment(day.date).isSame(moment(entry.date), 'day') ? day : entry
      );
      setDiaryComplete(completeDiary);

      const rangeDiary = sortedData(diary).map((entry) =>
        moment(day.date).isSame(moment(entry.date), 'day') ? day : entry
      );
      setIndex(rangeDiary.indexOf(day));
      setDiary(rangeDiary);
    }
    //editDiary(context.userInfo, diary)
  }, [day]);

  useEffect(() => {
    if (diaryComplete && diaryComplete.length > 0) {
      setRangeData();
    }
  }, [moodStartDate, moodEndDate]);

  useEffect(() => {
    if (diaryComplete && dayEdited) {
      handleSave();
      setDayEdited(false);
    }
  }, [diaryComplete]);

  useEffect(() => {
    if (dayEdited) {
      setDay(dayEdited);
    }
  }, [dayEdited]);

  const setRangeData = () => {
    const diaryRange = sortedData(diaryComplete).filter(
      (entry) =>
        moment(entry.date).isSameOrAfter(moment(moodStartDate), 'day') &&
        moment(entry.date).isSameOrBefore(moment(moodEndDate), 'day')
    );

    const index =
      diaryRange.indexOf(day) !== -1
        ? diaryRange.indexOf(day)
        : diaryRange.length - 1;
    setIndex(index);
    setDay(diaryRange[index]);
    setDiary(diaryRange);
  };

  const handleDateChange = (event) => {
    const date = event;
    const diaryEntry = sortedData(diary).find((entry) =>
      moment(date).isSame(moment(entry.date), 'day')
    );
    if (!diaryEntry) {
      if (
        moment(date).isBefore(moment(moodStartDate), 'day') ||
        moment(date).isAfter(moment(moodEndDate), 'day')
      ) {
        setSuccessfullySaved({
          type: 'warning',
          text: 'Datum außerhalb des gewählten Zeitraums.',
        });
      } else {
        setDiary((diary) => [...diary, { date: date.getTime() }]);
        setDay({ date: date.getTime() });

        //check if entry exists in complete diary, not only in ranged diary
        const diaryCompleteEntry = sortedData(diaryComplete).find((entry) =>
          moment(date).isSame(moment(entry.date), 'day')
        );
        if (!diaryCompleteEntry) {
          setDiaryComplete((diary) => [...diary, { date: date.getTime() }]);
        }
      }
    } else {
      setDay(diaryEntry);
    }
    setDateChanged(event);
  };

  const handleSave = async () => {
    const userDiary = await setDBDiary(diaryComplete, user.sessionToken);

    if (userDiary && userDiary.attributes) {
      const savedDiary = [...userDiary.attributes.diary];
      setDiaryComplete(savedDiary);
      setRangeData();
      setEditMode(false);
      setSuccessfullySaved(true);
    } else {
      setSuccessfullySaved({ type: 'error', text: 'Speichern fehlgeschlagen' });
    }
  };

  const handleChecked = (event) => {
    if (!userData.settings) {
      userData.settings = {};
    }
    userData.settings['remindDiary'] = event.target.checked ? true : undefined;
    setUserSettings(user.userId, userData.settings, user.sessionToken);
    setChecked(event.target.checked);
  };

  //prevent click event for opening dialog if it was just a swipe by calculating start and end position of mouse movement
  const state = useRef({ x: 0 });
  const handleMouseDown = (e) => {
    state.current.x = e.screenX;
  };
  const handleClick = (e) => {
    const delta = Math.abs(e.screenX - state.current.x);
    if (delta > 10) {
      e.preventDefault();
    } else {
      setEditMode(true);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeDe}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <BackNavigation title={'Emotionstagebuch'} />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                marginLeft: 5,
                fontSize: 12,
                color: theme.palette.primary.main,
                textAlign: 'end',
              }}
            >
              Täglich erinnern
            </div>
            <Switch
              color={'primary'}
              checked={checked}
              onChange={handleChecked}
              name='checked'
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
          }}
        >
          <SwipeableViews
            axis='x'
            enableMouseEvents
            index={index}
            onChangeIndex={(index) => {
              setIndex(index);
              setDay(diary[index]);
            }}
          >
            {diary &&
              diary.map((entry, index) => (
                <div
                  key={entry.date}
                  color={'secondary'}
                  onMouseDown={(e) => handleMouseDown(e)}
                  onClick={(e) => handleClick(e)}
                  style={{
                    marginTop: 15,
                    margin: 10,
                    padding: 10,
                    marginBottom: 200,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    border: '1px solid',
                    borderColor: theme.palette.secondary.main,
                    borderRadius: 10,
                    backgroundColor: 'white',
                    boxShadow: '0px 0px 21px 0px rgba(0,0,0,0.1)',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: 'column',
                      marginBottom: 20,
                      alignItems: 'center',
                    }}
                  >
                    <IconButton
                      style={{
                        alignSelf: 'flex-end',
                        padding: 0,
                      }}
                      onClick={(e) => handleClick(e)}
                    >
                      <DefaultIconButton>
                        <EditOutlined color={'primary'} fontSize={'small'} />
                      </DefaultIconButton>
                    </IconButton>
                    <Typography
                      style={{
                        alignSelf: 'center',
                        marginTop: 10,
                        marginBottom: 15,
                        fontWeight: 'bold',
                      }}
                      variant={'body1'}
                    >
                      {new Date(diary[index].date).toLocaleDateString(
                        'de-DE',
                        dateOptions
                      )}
                    </Typography>
                    <CircleWrapper
                      selected
                      backgroundColor={theme.palette.secondary.main}
                    >
                      {/*<MemberItem color={"green"}>*/}
                      {diary[index].mood === 'very_bad' && (
                        <VeryBadMoodIcon fill={'red'} />
                      )}
                      {diary[index].mood === 'bad' && (
                        <BadMoodIcon fill={'#e21490'} />
                      )}
                      {diary[index].mood === 'neutral' && (
                        <NeutralMoodIcon fill={'lightgrey'} />
                      )}
                      {diary[index].mood === 'happy' && (
                        <HappyMoodIcon fill={'#72e343'} />
                      )}
                      {diary[index].mood === 'very_happy' && (
                        <VeryHappyMoodIcon fill={'green'} />
                      )}
                      {/*</MemberItem>*/}
                    </CircleWrapper>
                  </div>
                  {entry.activities && (
                    <Typography
                      variant={'body2'}
                      style={{ fontWeight: 'bold' }}
                    >
                      Aktivitäten
                    </Typography>
                  )}
                  <div
                    style={{
                      marginTop: 5,
                      margin: 3,
                      display: 'flex',
                      width: '100%',
                      flexWrap: 'wrap',
                    }}
                  >
                    {entry.activities &&
                      entry.activities.map((benefit, itemIndex) => (
                        <div
                          key={benefit.title}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 5,
                          }}
                        >
                          <StyledIcon
                            icon={
                              getIcons([entry.activities[itemIndex]])[
                                benefit.image
                              ]
                            }
                          />
                          <span style={{ fontSize: 12, textAlign: 'center' }}>
                            {benefit.title}
                          </span>
                        </div>
                      ))}
                  </div>
                  <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
                    Notizen
                  </Typography>
                  <Typography variant={'body2'} style={{ fontSize: 14 }}>
                    {diary[index].note ? diary[index].note : 'Keine Notizen'}
                  </Typography>
                </div>
              ))}
          </SwipeableViews>
          {/*<Typography variant={'h6'}>Wähle ein Datum</Typography>*/}
        </div>
        {!editMode && (
          <div
            style={{
              width: '100%',
              position: 'fixed',
              maxWidth: 800,
              bottom: 56,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              backgroundColor: theme.palette.secondary.light,
            }}
          >
            <div
              style={{
                display: 'flex',
                color: theme.palette.primary.main,
                paddingTop: 10,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  paddingLeft: 5,
                  paddingRight: 5,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 12,
                  }}
                  onClick={() => setOpenDateRangeModal('openFrom')}
                >
                  <IconButton style={{ padding: 0, marginRight: 5 }}>
                    <DateRange color={'primary'} />
                  </IconButton>
                  <div style={{ cursor: 'pointer' }}>
                    von {new Date(moodStartDate).toLocaleDateString()}
                  </div>
                </div>
                <DatePicker
                  open={openDateRangeModal === 'openFrom'}
                  onClose={() => setOpenDateRangeModal(false)}
                  style={{
                    maxWidth: 100,
                    marginLeft: 5,
                  }}
                  format='dd.MM.yy'
                  value={moodStartDate}
                  onChange={(e) => setMoodStartDate(e.getTime())}
                  TextFieldComponent={TextFieldComponentMood}
                />
                <div
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    fontWeight: 'bold',
                  }}
                >
                  Stimmungskurve
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 12,
                  }}
                  onClick={() => setOpenDateRangeModal('openTo')}
                >
                  <IconButton style={{ padding: 0, marginRight: 5 }}>
                    <DateRange color={'primary'} />
                  </IconButton>
                  <div style={{ cursor: 'pointer' }}>
                    bis {new Date(moodEndDate).toLocaleDateString()}
                  </div>
                </div>
                <DatePicker
                  open={openDateRangeModal === 'openTo'}
                  onClose={() => setOpenDateRangeModal(false)}
                  style={{
                    maxWidth: 100,
                    marginLeft: 5,
                  }}
                  format='dd.MM.yy'
                  value={moodEndDate}
                  onChange={(e) => setMoodEndDate(e.getTime())}
                  TextFieldComponent={TextFieldComponentMood}
                />
              </div>
            </div>
            <Histogram
              data={diary}
              index={index}
              setDay={setDay}
              setIndex={setIndex}
            />
            <div
              style={{
                width: '100%',
                paddingLeft: 20,
                paddingRight: 20,
                marginTop: 5,
              }}
            >
              <CustomSliderStyled
                style={{
                  alignSelf: 'center',
                }}
                value={index}
                //valueLabelDisplay="auto"
                step={1}
                marks
                min={0}
                track={false}
                max={diary.length - 1}
                onChange={(_, value) => {
                  setIndex(value);
                  setDay(diary[index]);
                }}
                onChangeCommitted={(_, value) => {
                  setIndex(value);
                  setDay(diary[index]);
                }}
              />
            </div>
          </div>
        )}
        <Dialog fullScreen onClose={() => setEditMode(false)} open={editMode}>
          <EditDiaryDialog
            day={day}
            dateChanged={dateChanged}
            setEditMode={setEditMode}
            handleDateChange={handleDateChange}
            setDayEdited={setDayEdited}
          />
        </Dialog>
        {successfullySaved && (
          <CustomSnackbar
            type={successfullySaved.type}
            text={successfullySaved.text}
            setSuccessfullySaved={setSuccessfullySaved}
          />
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
};

const StyledIcon = styled('div')(({ icon }) => ({
  width: 30,
  height: 30,
  borderRadius: '50%',
  backgroundImage: 'url(' + icon + ')',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
}));

const CustomSliderStyled = withStyles({
  root: {
    //height: 8,
    paddingTop: 0,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: 'rgb(208,54,0)',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 12,
    borderRadius: 4,
  },
  rail: {
    height: 2,
    borderRadius: 4,
  },
  mark: {
    backgroundColor: 'rgb(208,54,0)',
    height: 4,
    width: 3,
  },
})(Slider);
