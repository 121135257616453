import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  styled,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { Close } from '@material-ui/icons';

export const ClientCounselors = ({
  organizationId,
  sessionToken,
  setOpen,
  getClientOrganizationMembers,
  addClientSystemCounselor,
  system,
}) => {
  const [organizationmembers, setOrganizationMembers] = useState([]);
  const [selectedCounselorIndex, setSelectedCounselorIndex] = useState('');

  useEffect(() => {
    const getCounselors = async () => {
      const organizationCounselors = await getClientOrganizationMembers(
        organizationId,
        sessionToken
      );
      if (organizationCounselors) {
        const alreadyCounselorsIds = system.counselors.map(
          (counselor) => counselor.userId
        );
        const availableCounselors = organizationCounselors.filter(
          (counselor) => !alreadyCounselorsIds.includes(counselor.userId)
        );
        setOrganizationMembers(availableCounselors);
      }
    };
    getCounselors();
  }, []);

  const handleAddCounselor = async () => {
    if (organizationmembers && organizationmembers[selectedCounselorIndex])
      await addClientSystemCounselor(
        organizationmembers[selectedCounselorIndex],
        system.systemId,
        sessionToken
      );
    setOpen(false);
  };

  return (
    <Dialog fullWidth onClose={() => setOpen(false)} open={true}>
      <Container>
        <Close
          color={'primary'}
          style={{ marginLeft: 'auto' }}
          onClick={() => setOpen(false)}
        />

        <div style={{ fontSize: 14, textAlign: 'center', marginTop: 10 }}>
          Füge weitere Mitarbeitende hinzu, um diesen Fall zu dokumentieren
        </div>
        <FormControl
          style={{
            margin: 20,
            minWidth: 250,
            maxWidth: 350,
          }}
        >
          <InputLabel>BeraterIn wählen</InputLabel>
          <Select
            value={selectedCounselorIndex}
            onChange={(ev) => setSelectedCounselorIndex(ev.target.value)}
          >
            {organizationmembers.map((counselor, index) => (
              <MenuItem key={index} value={index}>
                {counselor.userName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          style={{ marginTop: 20 }}
          color={'primary'}
          variant={'outlined'}
          onClick={handleAddCounselor}
        >
          Hinzufügen
        </Button>
      </Container>
    </Dialog>
  );
};

const Container = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 10,
});
