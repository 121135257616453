import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from './components/elements/Header';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { AuthContext } from 'state/contexts/AuthContext';
import { UserDataContext } from 'state/contexts/UserDataContext';

import { Goals } from 'components/Goals/Goals';
import ProtectedRoute from 'components/elements/ProtectedRoute';

import EditGoal from './components/Goals/EditGoal/EditGoal';
import BottomNavigation from './components/elements/BottomNavigation';
import { EditStep } from './components/Goals/EditStep';
import { Favorite } from './Favorite/Favorite';
import { Profile } from './components/Profile/Profile';
import { Diary } from './components/Methods/Diary/Diary';
import Chat from './components/Messenger/Chat';
import { Drawer } from './components/Drawer/Drawer';
import { Clients } from './components/Clients/Clients';
import { Files } from './components/Files/Files';
import { Methods } from './components/Methods/Methods';
import { CircularProgress } from '@material-ui/core';
import Bugsnag from '@bugsnag/js';
import CustomSnackbar from './components/elements/CustomSnackbar';
import { useMatrix } from './utils/handleMatrix';
import { IFRAMEORIGINANDSOURCE, permissions } from './utils/constants';

import { ClientDocumentation } from './components/Clients/ClientDocumentation';
import { ValuesSurveyAndResults } from './components/Methods/Values/ValuesSurveyAndResults';
import { IntroCheckIfFirstStart } from './components/Intro/IntroCheckIfFirstStart';
import { SetBaseRoute } from './components/elements/SetBaseRoute';
import { install } from 'resize-observer';
import { Manage } from './components/Manage/Manage';
import { ClientDetails } from './components/Clients/ClientDetails';
import { ClientSharedFiles } from './components/Clients/ClientSharedFiles';
import { isSafari } from './utils/detectBrowser';
import { Restricted } from './components/elements/Restricted';
import { EditSurvey } from './components/Surveys/EditSurvey';
import { SurveyResults } from './components/Surveys/SurveyResults';
import { Surveys } from './components/Surveys/Surveys';
import { SurveyAnswers } from './components/Surveys/SurveyAnswers';
import { userHasPermission } from './utils/handleRolePermissions';
import { Calendar } from './components/Calendar/Calendar';
import { Whiteboard } from './components/Methods/Whiteboard/Whiteboard';

//needed for canvas in Values and Diary in older Safari browsers
install();

export function App() {
  const {
    user,
    monitorCurrentUser,
    loadingUser,
    networkState,
    isOnline,
  } = useContext(AuthContext);
  const {
    userData,
    getUserData,
    foregroundMessage,
    setForegroundMessage,
  } = useContext(UserDataContext);
  const [openDrawer, setOpenDrawer] = useState();
  const [isConnected, setIsConnected] = useState();
  const [showIFrame, setShowIFrame] = useState();
  const [iFrameHeight, setIFrameHeight] = useState(window.innerHeight - 96);

  const getIPhoneHeightValue = () => {
    return window.innerHeight > 470 ? '80vh' : '70vh';
  };

  useEffect(() => {
    //dynamically set Matrix height for mobile browsers (esp. FF) - otherwise it might get hidden by URL address bar
    isSafari(navigator)
      ? setIFrameHeight(getIPhoneHeightValue())
      : window.addEventListener('resize', () => {
          setIFrameHeight(window.innerHeight - 96);
        });
  }, []);

  useEffect(() => {
    //FIXME better solution to avoid warning of using useEffect without dependencies?
    //show iFrame if path points to messenger
    if (user && window.location.pathname === '/messenger') {
      setShowIFrame(true);
    } else {
      setShowIFrame(false);
    }
  });

  //const { message, setMessage } = useNotifications();
  //useNotifications(user && user.sessionToken);
  useMatrix(user);

  useEffect(() => {
    if (networkState && networkState.stateChange) {
      setIsConnected(networkState);
    }
  }, [networkState]);

  useEffect(() => {
    window.addEventListener('online', () => isOnline('stateChange'));
    window.addEventListener('offline', () => isOnline('stateChange'));
    if (user) {
      const { userId, sessionToken } = user;
      getUserData(userId, sessionToken);
      monitorCurrentUser();
      Bugsnag.setUser(userId);
      /*logger.push({
                    tag: 'app',
                    user: user.userId,
                  });*/

      // subscribeUserData(userId, sessionToken);
    }
  }, [user]);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  useEffect(() => {
    if (
      foregroundMessage &&
      (!showIFrame || (showIFrame && foregroundMessage.type !== 'chat'))
    ) {
      if (
        !('Notification' in window) ||
        !('ServiceWorkerRegistration' in window)
      ) {
        console.log('Persistent Notification API not supported!');
        return;
      }

      try {
        window.navigator.serviceWorker
          .getRegistrations()
          .then((reg) => {
            reg[0].showNotification(foregroundMessage.title, {
              body: foregroundMessage.body,
              tag: foregroundMessage.tag,
              badge: foregroundMessage.badge,
              icon: foregroundMessage.icon,
            });
          })
          .catch((err) => alert('Service Worker registration error: ' + err));
      } catch (err) {
        console.log('Notification API error: ', err);
      }
    }
    setForegroundMessage(undefined);
  }, [foregroundMessage, showIFrame]);

  const myIFrame =
    userData && !userHasPermission(userData, permissions.MESSENGER) ? (
      <div style={{ display: !showIFrame && 'none', width: '100%' }}>
        <Restricted />
      </div>
    ) : (
      <iframe
        title={'matrix'}
        id={'matrix'}
        src={IFRAMEORIGINANDSOURCE}
        allow='camera;microphone'
        style={{
          width: showIFrame ? '100%' : 0, //matrix can't handle display: none, so we need to use visibility and handle size
          height: showIFrame ? iFrameHeight : 0, //matrix can't handle display: none, so we need to use visibility and handle size
          border: 'unset',
          visibility: showIFrame ? undefined : 'hidden', //matrix can't handle display: none, so we need to use visibility (https://github.com/vector-im/element-web/issues/15086)
          maxWidth: 800,
        }}
      />
    );

  return (
    <div>
      <AppWrapper>
        <Header toggleDrawer={toggleDrawer} />
        <Drawer toggleDrawer={toggleDrawer} openDrawer={openDrawer} />
        {!loadingUser && (
          <ContentWrapper>
            <Switch>
              <ProtectedRoute user={user} exact path='/' component={Goals} />
              <ProtectedRoute
                user={user}
                path='/clientdetails'
                component={ClientDetails}
              />
              <ProtectedRoute
                user={user}
                path='/clientfiles'
                component={ClientSharedFiles}
              />
              <ProtectedRoute
                user={user}
                path='/editgoal'
                component={EditGoal}
              />
              <ProtectedRoute
                user={user}
                path='/addgoal'
                component={EditGoal}
              />
              <ProtectedRoute
                user={user}
                path='/editstep'
                component={EditStep}
              />
              <ProtectedRoute
                user={user}
                path='/addstep'
                component={EditStep}
              />
              <ProtectedRoute user={user} path='/diary' component={Diary} />
              <ProtectedRoute
                user={user}
                path='/favorite'
                component={Favorite}
              />
              <ProtectedRoute user={user} path='/profile' component={Profile} />
              <ProtectedRoute user={user} exact path='/chat' component={Chat} />
              <ProtectedRoute user={user} path='/files' component={Files} />
              <ProtectedRoute user={user} path='/methods' component={Methods} />
              {/*<ProtectedRoute
                  user={user}
                  exact
                  path='/messenger'
                  component={MatrixMessenger}
                />*/}
              <ProtectedRoute
                user={user}
                exact
                path='/clients'
                component={Clients}
              />
              <ProtectedRoute
                user={user}
                exact
                path='/documentation'
                component={ClientDocumentation}
              />
              <ProtectedRoute
                user={user}
                exact
                path='/values'
                component={ValuesSurveyAndResults}
              />
              <ProtectedRoute
                user={user}
                exact
                path='/manage'
                component={Manage}
              />
              <ProtectedRoute
                user={user}
                exact
                path='/restricted'
                component={Restricted}
              />
              <ProtectedRoute
                user={user}
                exact
                path='/surveys'
                component={Surveys}
              />
              <ProtectedRoute
                user={user}
                exact
                path='/editsurvey'
                component={EditSurvey}
              />
              <ProtectedRoute
                user={user}
                exact
                path='/answersurvey'
                component={SurveyAnswers}
              />
              <ProtectedRoute
                user={user}
                exact
                path='/surveyresults'
                component={SurveyResults}
              />
              <ProtectedRoute
                user={user}
                exact
                path='/calendar'
                component={Calendar}
              />
              <ProtectedRoute
                user={user}
                exact
                path='/whiteboard'
                component={Whiteboard}
              />
            </Switch>
            {user && myIFrame}
            <IntroCheckIfFirstStart />
            {/*<SetBaseRoute setShowIFrame={setShowIFrame} />*/}
          </ContentWrapper>
        )}
        {loadingUser && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '80vh',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress color={'primary'} />
            <div>Einloggen...</div>
          </div>
        )}
        {isConnected && (
          <CustomSnackbar
            setSuccessfullySaved={setIsConnected}
            text={isConnected.text}
            type={isConnected.type}
          />
        )}
      </AppWrapper>
      <BottomNavigation setFrame={setShowIFrame} />
    </div>
  );
}

const ContentWrapper = styled('div')({
  display: 'flex',
  flex: '1 0 auto',
  maxWidth: 800,
  width: '100%',
  padding: 3, //just to identify element in browser for debugging

  //padding: 15,
  //margin: '0 auto',
});

const AppWrapper = styled('div')(({ theme }) => ({
  height: '100%',
  //height: '100%',
  paddingBottom: 56, //BottomNavigation height
  display: 'flex',
  flex: '1  0 auto',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  //backgroundColor: theme.palette.secondary.main,
}));
