import { permissions } from './constants';

export const userHasPermission = (userData, action) => {
  if (!userData) {
    return false;
  }

  let restrictions = [];

  if (userData.userInfo.assignment.type !== 'organization') {
    restrictions.push(permissions.COUNSELOR);
  }

  if (userData.userInfo.assignment.status === 'testAccount') {
    restrictions.push(
      permissions.USE_METHOD_DIARY,
      //permissions.USE_METHOD_VALUES,
      permissions.USE_METHOD_SURVEYS,
      permissions.FILES,
      permissions.DOC,
      permissions.MESSENGER,
      permissions.USE_METHOD_WHITEBOARD
    );
  }

  return !restrictions.includes(action);
};
