import React from 'react';

export default ({ fill = 'orange' }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <svg
        x='0px'
        y='0px'
        width={40}
        height={40}
        viewBox='0 0 295.996 295.996'
        fill={fill}
      >
        <path
          d='M147.998,0C66.392,0,0,66.392,0,147.998s66.392,147.998,147.998,147.998s147.998-66.392,147.998-147.998
		S229.605,0,147.998,0z M147.998,279.996c-36.256,0-69.143-14.696-93.022-38.44c-9.536-9.482-17.631-20.41-23.934-32.42
		C21.442,190.847,16,170.047,16,147.998C16,75.214,75.214,16,147.998,16c34.523,0,65.987,13.328,89.533,35.102
		c12.208,11.288,22.289,24.844,29.558,39.996c8.27,17.239,12.907,36.538,12.907,56.9
		C279.996,220.782,220.782,279.996,147.998,279.996z'
        />
        <circle cx='99.666' cy='114.998' r='16' />
        <circle cx='198.666' cy='114.998' r='16' />
        <path
          d='M147.715,229.995c30.954,0,60.619-15.83,77.604-42.113l-13.439-8.684c-15.597,24.135-44.126,37.604-72.693,34.308
		c-22.262-2.567-42.849-15.393-55.072-34.308l-13.438,8.684c14.79,22.889,39.716,38.409,66.676,41.519
		C140.814,229.8,144.27,229.995,147.715,229.995z'
        />
      </svg>
    </div>
  );
};
