import { Card, Typography } from '@material-ui/core';
import React, { useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import { DialogNoRolePermission } from '../elements/DialogNoRolePermission';
import { ArrowForwardIos } from '@material-ui/icons';

export const MethodCard = ({
  title,
  img,
  description,
  routeURL,
  previewImg,
}) => {
  let history = useHistory();
  const [openPreviewDialog, setOpenPreviewDialog] = useState();

  return (
    <Fragment>
      <Card
        onClick={() =>
          previewImg ? setOpenPreviewDialog(true) : history.push(routeURL)
        }
        style={{
          //width: '45%',
          margin: 5,
          cursor: 'pointer',
          borderRadius: 8,
          //backgroundColor: useTheme().palette.secondary.main,
        }}
        //color={'secondary'}
        //className={classes.root}
      >
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ width: '40%' }}>
            <img
              style={{
                borderRadius: '4px 0 0 4px',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              alt={'img'}
              src={img}
            />
          </div>
          <div
            style={{
              display: 'flex',
              width: '60%',
              padding: 20,
              paddingRight: 10,
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                style={{
                  overflowWrap: 'break-word',
                  wordBreak: 'break-all',
                  fontWeight: 'bold',
                }}
                variant={'body1'}
                color={'primary'}
              >
                {title}
              </Typography>
              <Typography variant='body2' style={{ fontSize: 12 }}>
                {description}
              </Typography>
            </div>
            <ArrowForwardIos style={{ marginLeft: 'auto' }} color={'primary'} />
          </div>
        </div>
      </Card>
      <Dialog
        fullWidth
        onClose={() => setOpenPreviewDialog(false)}
        open={openPreviewDialog}
      >
        <DialogNoRolePermission
          setOpen={setOpenPreviewDialog}
          previewImg={previewImg}
        />
      </Dialog>
    </Fragment>
  );
};
