import * as firebaseApp from 'firebase/app';
/*import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';*/
import 'firebase/messaging';

var firebaseConfig = {
  /*
  authDomain: 'iuvivoweb.firebaseapp.com',
  databaseURL: 'https://iuvivoweb.firebaseio.com',
  storageBucket: 'iuvivoweb.appspot.com',
  measurementId: 'G-1GQEH9X91F',*/
  appId: '1:1061304088032:web:ccc4a2adbfc2423d6b2785',
  apiKey: 'AIzaSyBlGSPFeTNxS093Afp2z-VDrDU76dxhx4E',
  projectId: 'iuvivoweb',
  messagingSenderId: '1061304088032',
};

export const firebase = firebaseApp.initializeApp(firebaseConfig);
//firebaseApp.analytics();
