import { Goal } from 'services/goal';

const props = {
  GOAL_OWNER: 'goalOwner',
  BENEFITS: 'benefits',
  CUSTOM_BENEFITS: 'customBenefits',
  TITLE: 'title',
  MEMBERS: 'members',
  SUPPORTERS: 'supporters',
  STEPS: 'steps',
  IMAGE: 'image',
};
const propsArray = Object.values(props);

export function mapApiToGoalTemplatesModel(apiObject) {
  return propsArray.reduce(
    (model, mapEl) => {
      model[mapEl] = apiObject.get(mapEl);

      return model;
    },
    {
      id: apiObject.id,
    }
  );
}

export function mapGoalTemplatesModelToApi(modelObject) {
  const goal = new Goal();

  propsArray.forEach((prop) => {
    goal.set(prop, modelObject[prop]);
  });
  goal.id = modelObject.id;

  return goal;
}
