import React, { useContext, useEffect } from 'react';
import {
  deleteScene,
  getExcalidrawScenes,
  setExcalidrawScene,
  setLiveRoom,
} from '../../../services/whiteboard';
import { AuthContext } from '../../../state/contexts/AuthContext';
import { UserDataContext } from '../../../state/contexts/UserDataContext';

const EXCALIDRAW_IFRAME_ORIGIN = process.env.REACT_APP_EXCALIDRAW_URL;

export const Whiteboard = () => {
  const { user } = useContext(AuthContext);
  const { userData } = useContext(UserDataContext);

  const iframeExcalidraw = (
    <iframe
      title={'excalidraw'}
      id={'excalidraw'}
      src={EXCALIDRAW_IFRAME_ORIGIN}
      allow='clipboard-read; clipboard-write'
      style={{
        width: '100%',
        height: 'calc(100vh - 120px)',
        border: 'none',
        maxWidth: 800,
      }}
    />
  );

  const handleExcalidraw = async (event) => {
    if (event.origin === EXCALIDRAW_IFRAME_ORIGIN) {
      if (event.data.type === 'loaded') {
        postUserAndContacts();
        getAndPostScenes();
      }
      if (event.data.type === 'scene') {
        const response = await setExcalidrawScene(
          user,
          user.sessionToken,
          event.data.scene,
          event.data.sceneId,
          event.data.title,
          event.data.members
        );
        getAndPostScenes();
      }
      if (event.data.type === 'saveLiveRoom') {
        const response = await setLiveRoom(
          user,
          user.sessionToken,
          event.data.roomData,
          event.data.members
        );
        getAndPostScenes();
      }
      if (event.data.type === 'deleteScene') {
        const response = await deleteScene(
          user,
          user.sessionToken,
          event.data.sceneId
        );
        getAndPostScenes();
      }
    }
  };

  useEffect(() => {
    if (user) {
      window.addEventListener('message', (event) => handleExcalidraw(event));
      getAndPostScenes();
    }
  }, [user]);

  const getAndPostScenes = async () => {
    const scenes = await getExcalidrawScenes(user.userId, user.sessionToken);
    postIuvivoScenes(scenes);
  };

  const postUserAndContacts = () => {
    const iframe = document.getElementById('excalidraw');
    iframe &&
      iframe.contentWindow &&
      iframe.contentWindow.postMessage(
        {
          type: 'contacts',
          contacts: userData.contacts,
          user: userData.userInfo,
        },
        EXCALIDRAW_IFRAME_ORIGIN
      );
  };

  const postIuvivoScenes = (scenes) => {
    const iframe = document.getElementById('excalidraw');
    iframe &&
      iframe.contentWindow &&
      iframe.contentWindow.postMessage(
        {
          type: 'iuvivoScenes',
          scenes: scenes,
        },
        EXCALIDRAW_IFRAME_ORIGIN
      );
  };

  return iframeExcalidraw;
};
