import React from 'react';
import { Button, Dialog, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';

export const DialogInfo = ({ setOpen, content }) => {
  return (
    <Dialog fullWidth open={true}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          padding: 20,
        }}
      >
        <div style={{ display: 'flex', width: '100%', marginBottom: 20 }}>
          <Typography variant={'h6'}>{content.title}</Typography>
          <Close
            color={'primary'}
            style={{ marginLeft: 'auto' }}
            onClick={() => setOpen(false)}
          />
        </div>
        <Typography variant={'subtitle2'} style={{ whiteSpace: 'pre-line' }}>
          {content.description}
        </Typography>
        <Button
          onClick={() => setOpen(false)}
          variant={'contained'}
          style={{
            marginTop: 20,
            backgroundColor: useTheme().palette.button.done,
          }}
        >
          Ok
        </Button>
      </div>
    </Dialog>
  );
};
