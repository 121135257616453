import {
  Button,
  Dialog,
  IconButton,
  styled,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Close, Info as InfoIcon } from '@material-ui/icons';

export const FavoriteDialog = ({ setShowDialog }) => {
  return (
    <Dialog
      style={{ display: 'flex', justifyContent: 'center' }}
      open={true}
      onClose={() => setShowDialog(false)}
    >
      <DialogContentContainer>
        <IconButton
          style={{ position: 'absolute', right: 5 }}
          onClick={() => setShowDialog(false)}
        >
          <Close color='primary' fontSize='large' />
        </IconButton>
        <div
          style={{
            backgroundColor: 'white',
            marginTop: 10,
            marginBottom: 50,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: 15,
              marginTop: 0,
            }}
          >
            <InfoIcon fontSize='large' color='primary' />
            <Typography style={{ marginLeft: 10 }} color='primary' variant='h6'>
              Dein Glück
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              backgroundColor: 'white',
              paddingLeft: 15,
              paddingRight: 15,
              marginTop: 20,
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant='body1'>
                <b>Glückwunsch, du hast gewonnen!</b>
              </Typography>
              <br />
              <Typography variant='body2'>
                Immer, wenn du ein Ziel schaffst, bekommst du Sternchen. Mit
                ihnen kannst du neue Elemente freischalten. Dazu zählen u.a.:{' '}
                <br />
                <br />
                • Megamäßige Methoden, um dich selbst und andere besser
                kennenzulernen
                <br />
                • Attraktive Arten, mit deinen Freunden zusammen Ziele zu
                erreichen
                <br />
                • Tolle Tools, um noch bessere Ziele zu entwickeln
                <br />
                • Geile Gadgets, um dein Inneres Glück zu verwöhnen
                <br />
                • Fantastische Features, mit denen deine Stimmung beobachten
                kannst
                <br />
              </Typography>
            </div>
          </div>
          <Button
            style={{ marginTop: 20 }}
            variant='contained'
            color='primary'
            onClick={() => {}}
          >
            Zeig ma!
          </Button>
        </div>
      </DialogContentContainer>
    </Dialog>
  );
};

const DialogContentContainer = styled('div')({
  position: 'relative',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 600,
  paddingTop: 15,
});
