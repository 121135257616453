import { fetchSendFCMMatrixMessage } from '../services/chat';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../state/contexts/AuthContext';
import { UserDataContext } from '../state/contexts/UserDataContext';
import userData, {
  fetchSetRecoveryKey,
  fetchSyncMatrixContacts,
} from '../services/userData';
import { IFRAMEORIGINANDSOURCE } from './constants';
import { setFlutterFCMToken } from './setFlutterFCMToken';
import { isSafari } from './detectBrowser';
import { saveLog } from './saveLog';
import { useHistory } from 'react-router-dom';
import { decrypt, encrypt } from './cryptoStore';

export const logoutMatrix = () => {
  const iframe = document.getElementById('matrix');
  if (iframe) {
    iframe.contentWindow.postMessage(
      { type: 'iuvivoLogout' },
      IFRAMEORIGINANDSOURCE
    );
  }
};

export const useMatrix = () => {
  const { user, setUser } = useContext(AuthContext);
  const {
    userData,
    setUserData,
    isLoggingOut,
    setIsLoggingOut,
    setMatrixInvites,
    joinMatrixRoom,
    setJoinMatrixRoom,
  } = useContext(UserDataContext);
  const [event, setEvent] = useState();
  const [unknownContacts, setUnknownContacts] = useState([]);
  let history = useHistory();

  useEffect(() => {
    if (joinMatrixRoom) {
      const iframe = document.getElementById('matrix');
      if (iframe) {
        iframe.contentWindow.postMessage(
          { type: 'iuvivoJoin', roomId: joinMatrixRoom },
          IFRAMEORIGINANDSOURCE
        );
        setTimeout(() => setJoinMatrixRoom(undefined), 1500);
      }
    }
  }, [joinMatrixRoom]);

  useEffect(() => {
    if (user && userData) {
      //FIXME workaround for deleted iframe cache in Safari/WebviewKit: save password locally
      //possible workaround here? https://gist.github.com/iansltx/18caf551baaa60b79206
      const flutterToken = window.localStorage.getItem('flutterToken');
      if ((flutterToken || isSafari(navigator)) && user.password) {
        window.localStorage.setItem('randomizedk', user.password);
        console.log(
          'crypto encrypt',
          user.userName,
          user.userId,
          user.password
        );
        encrypt(user.password, user.userName + user.userId);
      }
    }
  }, [user, userData]);

  useEffect(() => {
    window.addEventListener('message', (event) => setEvent(event));
  }, []);

  const waitAndStopUser = async () => {
    setUserData(undefined);
    setUser(null);
    setIsLoggingOut(false);
    history.push('/');
  };

  useEffect(() => {
    if (event && user && userData && event.origin === IFRAMEORIGINANDSOURCE) {
      const iframe = document.getElementById('matrix');

      if (!isLoggingOut && event.data === 'readyForLogin') {
        postCredsToMatrix(user, iframe, userData);
      } else if (isLoggingOut && event.data.type === 'iuvivoMatrixLoggedOut') {
        console.log('handleMatrix loggedout');
        waitAndStopUser();
      } else if (event.data.type === 'sendFCMMessage') {
        sendFCMMessage(event, user, userData);
      } else if (event.data.type === 'sync') {
        const sync = async () => {
          const unknown = await syncContacts(
            event.data.users,
            userData,
            user,
            unknownContacts
          );

          if (unknown) {
            setUnknownContacts(unknown);
          }
        };
        sync();
      } else if (event.data.type === 'recoveryKey') {
        saveRecoveryKey(event, user);
      } else if (event.data.type === 'invites') {
        setMatrixInvites(event.data.invites);
        console.log('matrix ivites', event.data.invites);
      } else {
        console.log('useMatrix unknown event', event);
      }
    }
  }, [event, user, userData, isLoggingOut]);
};

const syncContacts = async (matrixUsers, userData, user, unknownContacts) => {
  const lowerCaseMatrixContacts = matrixUsers
    .filter((matrixUser) => !unknownContacts.includes(matrixUser))
    .map((matrixUser) => getUserName(matrixUser).toLowerCase())
    .filter((userName) => userName !== user.userName);

  const lowerCaseIuvivoContacts = userData.contacts.map((user) =>
    user.userName.toLowerCase()
  );

  const addedContacts = lowerCaseMatrixContacts.filter(
    (matrixContact) => !lowerCaseIuvivoContacts.includes(matrixContact)
  );
  const removedContacts = lowerCaseIuvivoContacts.filter(
    (iuvivoContact) => !lowerCaseMatrixContacts.includes(iuvivoContact)
  );

  console.log(
    'syncContacts added, removed, unknown',
    addedContacts,
    removedContacts,
    unknownContacts
  );
  // since iuvivoUsers are case sensitive we have to do some transforming here and in the backend - can obviously be improved...
  //FIXME since we have a shared matrix instance for production and staging we need to filter out matrix contacts we don't have in the production db (otherwise we have a neverending loop in the backend)
  if (lowerCaseMatrixContacts.length !== lowerCaseIuvivoContacts.length) {
    const response = await fetchSyncMatrixContacts(
      user.userId,
      lowerCaseMatrixContacts,
      user.sessionToken
    );
    const lowerCaseUnknownContacts = response.map((user) => user.toLowerCase());
    const contactsNotFound = matrixUsers.filter((matrixUser) =>
      lowerCaseUnknownContacts.includes(getUserName(matrixUser).toLowerCase())
    );

    return contactsNotFound;
  }
};

const getUserName = (user) => {
  return user
    .substring(1)
    .replace(':matrixdemo.iuvivo.de', '')
    .replace(':matrix.org', '')
    .replace(':iuvivo.de', '');
};

const sendFCMMessage = (event, user, userData = []) => {
  const { sendTo, messageText, chatId } = event.data;

  let sendToUserId = sendTo.map((user) => getUserName(user));

  // since iuvivoUsers are case sensitive we have to do some transforming here - can obviously be improved...
  const userNames = userData.contacts.map((contact) => contact.userName);
  const caseSensitiveUsers = userNames.filter((contact) =>
    sendToUserId.includes(contact.toLowerCase())
  );
  caseSensitiveUsers.filter((name) => name !== user.userName);

  if (caseSensitiveUsers.length > 0) {
    fetchSendFCMMatrixMessage(
      caseSensitiveUsers,
      { messageText, messageSender: user.userName },
      chatId,
      user.sessionToken
    );
  }
};

const postCredsToMatrix = async (user, iframe, userData) => {
  const frameExists = true; //document.getElementById('matrix') && document.getElementById('matrix').contentWindow
  //FIXME workaround for deleted iframe cache in Safari/WebviewKit
  console.log('crypto decrypt', user.userName, user.userId, user.password);
  const p =
    (await decrypt(user.userName + user.userId)) ||
    window.localStorage.getItem('randomizedk');
  const dec = await decrypt(user.userName + user.userId);
  saveLog({
    iPhoneMatrixLogin: { user: user, p: p, pa: user.password, decrypt: dec },
  });

  if (user && frameExists) {
    //TODO: works likes below but throws security error when frameExists is checked as above before
    iframe &&
      iframe.contentWindow &&
      iframe.contentWindow.postMessage(
        {
          userName: user.userName,
          password: user.password || p,
          recoveryKey: userData.recoveryKey,
        },
        IFRAMEORIGINANDSOURCE
      );
    //window.localStorage.removeItem('randomizedk')
  }
};

const saveRecoveryKey = (event, user) => {
  const { recoveryKey } = event.data;
  saveLog({
    title: 'iuvivo handlematrix saveRecoveryKey)',
    user: user.userName,
    info: { recoveryKey },
  });
  fetchSetRecoveryKey(user.userId, recoveryKey, user.sessionToken);
};
