import Parse from '../utils/initParse';
import { mapApiToWhiteboardSceneModel } from '../models/WhiteboardSceneModel';

const WhiteboardScene = Parse.Object.extend('WhiteboardScene');

const acl = (scene) => {
  const acl = new Parse.ACL();
  acl.setReadAccess(scene.owner.userId, true);
  acl.setWriteAccess(scene.owner.userId, true);

  scene.members &&
    Object.values(scene.members).forEach((member) => {
      acl.setReadAccess(member.userId, true);
      acl.setWriteAccess(member.userId, true);
    });
  return acl;
};

export const getExcalidrawScenes = async (userId, sessionToken) => {
  const whiteboardQuery = new Parse.Query(WhiteboardScene);
  let scenes = (await whiteboardQuery.find({ sessionToken })) || [];

  return scenes.map((scene) => mapApiToWhiteboardSceneModel(scene));
};

export const setExcalidrawScene = async (
  user,
  sessionToken,
  scene,
  sceneId,
  title,
  members
) => {
  let whiteboardScene;
  if (sceneId) {
    const whiteboardQuery = new Parse.Query(WhiteboardScene);
    whiteboardQuery.equalTo('objectId', sceneId);
    whiteboardScene = await whiteboardQuery.first({ sessionToken });
  } else {
    whiteboardScene = new WhiteboardScene();
    whiteboardScene.set('owner', {
      userId: user.userId,
      userName: user.userName,
    });
  }
  const owner = whiteboardScene.get('owner');
  whiteboardScene.set('scene', JSON.parse(scene));
  title && whiteboardScene.set('title', title);
  members && whiteboardScene.set('members', members);
  const oldMembers = whiteboardScene.get('members') || [];
  const sceneMembers = members ? members : oldMembers;
  whiteboardScene.setACL(acl({ owner: owner, members: sceneMembers }));

  return await whiteboardScene.save({}, { sessionToken });
};

export const deleteScene = async (user, sessionToken, sceneId) => {
  let whiteboardScene;
  if (sceneId) {
    const whiteboardQuery = new Parse.Query(WhiteboardScene);
    whiteboardQuery.equalTo('objectId', sceneId);
    whiteboardScene = await whiteboardQuery.first({ sessionToken });
  } else {
    whiteboardScene = new WhiteboardScene();
    whiteboardScene.set('owner', {
      userId: user.userId,
      userName: user.userName,
    });
  }
  const owner = whiteboardScene.get('owner');
  if (owner.userId === user.userId) {
    return await whiteboardScene.destroy({ sessionToken });
  } else {
    const oldMembers = whiteboardScene.get('members') || [];
    const newMembers = oldMembers.filter(
      (member) => member.userId !== user.userId
    );
    whiteboardScene.set('members', newMembers);
    whiteboardScene.setACL(acl({ owner: owner, members: newMembers }));

    return await whiteboardScene.save({}, { sessionToken });
  }
};

export const setLiveRoom = async (user, sessionToken, roomData, members) => {
  let whiteboardScene;
  const whiteboardQuery = new Parse.Query(WhiteboardScene);
  whiteboardQuery.equalTo('liveRoomOwner.userId', user.userId);
  whiteboardScene = await whiteboardQuery.first({ sessionToken });

  if (!whiteboardScene) {
    whiteboardScene = new WhiteboardScene();
    whiteboardScene.set('liveRoomOwner', {
      userId: user.userId,
      userName: user.userName,
    });
  }
  const owner = whiteboardScene.get('liveRoomOwner');
  whiteboardScene.set('roomData', roomData);
  members && whiteboardScene.set('members', members);
  whiteboardScene.setACL(acl({ owner: owner, members: members }));

  return await whiteboardScene.save({}, { sessionToken });
};
