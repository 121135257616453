import React, { useContext, useEffect, useRef, useState } from 'react';
import { Send } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import {
  fetchGetChatData,
  fetchSendMessage,
  fetchSetChatData,
} from '../../services/chat';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { IconButton, styled, TextField } from '@material-ui/core';
import { dateWithTimeOptions } from '../../utils/constants';
import Parse from '../../utils/initParse';
import { subscribeToQuery } from '../../services/subscription';
import { useTheme } from '@material-ui/core/styles';
import { AuthContext } from '../../state/contexts/AuthContext';

export default () => {
  const history = useHistory();
  const { userData } = useContext(UserDataContext);
  const { user, isOnline } = useContext(AuthContext);
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState();
  const [chatId, setChatId] = useState();
  const [memberInfo, setMemberInfo] = useState();
  const view = useRef();
  const theme = useTheme();

  const handleEvent = (ev) => {
    setMessages(ev.object.attributes.messages);
  };

  const scrollToBottom = () => {
    view.current.scrollIntoView(); //scrollIntoView({ behavior: 'smooth' })
  };

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    if (chatId) {
      let subscription;
      const subscribe = async () => {
        const subscribeQuery = new Parse.Query('Chat');
        subscribeQuery.equalTo('objectId', chatId);
        await subscribeToQuery(
          subscribeQuery,
          handleEvent,
          user.sessionToken
        ).then((res) => {
          console.info('successfully subscribed chat');
          subscription = res;
        });
      };
      subscribe();

      return () =>
        subscription &&
        subscription.unsubscribe().then(() => {
          console.info('successfully unsubscribed');
        });
    }
  }, [chatId]);

  const getChatIdFromURLQuery = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('chatId');
  };
  useEffect(() => {
    if (userData) {
      const paramsChatId = getChatIdFromURLQuery();
      if (paramsChatId) {
        setChatId(paramsChatId);
        const getChatData = async () => {
          const data = await fetchGetChatData(paramsChatId, user.sessionToken);
          setMessages(data);
        };
        getChatData();
        return;
      }
      //workaround for FF bug: history state not present after reloading page
      if (affectsFirefoxBug()) {
        return history.replace('/messenger');
      }
      if (!history.location.state.contact.chatData.groupChatID) {
        //single chat
        const chatId = userData.contacts.find(
          (contact) =>
            contact.userId === history.location.state.contact.chatData.userId
        ).chatId;
        if (chatId) {
          setChatId(chatId);
          const getChatData = async () => {
            const data = await fetchGetChatData(chatId, user.sessionToken);
            setMessages(data);
          };
          getChatData();
        } else {
          console.log('chat id error');
        }
      } else {
        //group chat
        setChatId(history.location.state.contact.chatData.groupChatID);
        setMemberInfo(history.location.state.contact.chatData.members);
        const getChatData = async () => {
          const data = await fetchGetChatData(
            history.location.state.contact.chatData.groupChatID,
            user.sessionToken
          );
          setMessages(data);
        };
        getChatData();
      }
    }
  }, [userData, history]);

  const affectsFirefoxBug = () => {
    return history.location.state ? false : true;
  };

  const sendMessage = async () => {
    if (isOnline()) {
      if (text) {
        const message = {
          messageText: text,
          messageSender: userData.userInfo.userName,
          messageTime: Date.now(),
        };
        await fetchSetChatData(chatId, message, user.sessionToken);
        setText('');

        let sendTo = [];
        if (!history.location.state.contact.chatData.groupChatID) {
          sendTo = [history.location.state.contact.chatData.userId];
        } else {
          sendTo = history.location.state.contact.chatData.members.map(
            (member) => member.userId
          );
        }
        const response = await fetchSendMessage(
          sendTo,
          message,
          chatId,
          user.sessionToken
        );
      }
    }
  };

  const checkIfMe = (senderName) => {
    return senderName === userData.userInfo.userName;
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  return (
    <ChatContainer>
      {memberInfo && (
        <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
          {memberInfo.map((member, index) => (
            <span>
              {member.userName}
              {index !== memberInfo.length - 1 && ', '}
            </span>
          ))}
        </div>
      )}
      {messages.map((message) => {
        return (
          <Container
            paddingLeft={checkIfMe(message.messageSender) ? '25%' : undefined}
            paddingRight={checkIfMe(message.messageSender) ? undefined : '25%'}
          >
            <Bubble
              bgColor={
                checkIfMe(message.messageSender)
                  ? theme.palette.primary.altBubble
                  : theme.palette.primary.bubble
              }
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  marginBottom: 5,
                }}
              >
                <span style={{ fontSize: 16, fontWeight: 'bold' }}>
                  {message.messageSender}
                </span>
                <span style={{ marginLeft: 10 }}>
                  {new Date(message.messageTime).toLocaleDateString(
                    'de-DE',
                    dateWithTimeOptions
                  )}
                </span>
              </div>
              {message.messageText}
              <BubbleTriangle
                bgColor={
                  checkIfMe(message.messageSender)
                    ? theme.palette.primary.altBubble
                    : theme.palette.primary.bubble
                }
                leftRight={checkIfMe(message.messageSender) ? undefined : '20%'}
              />
            </Bubble>
          </Container>
        );
      })}
      <div ref={view} />
      <TypingContainer>
        <TextField
          value={text}
          style={{ width: '80%', marginRight: 5 }}
          placeholder='Nachricht eingeben'
          margin='normal'
          variant='outlined'
          onKeyPress={handleKeyPress}
          onChange={(event) => setText(event.target.value)}
        />
        <IconButton
          style={{ marginRight: 10 }}
          onClick={sendMessage}
          variant={'outlined'}
        >
          <Send />
        </IconButton>
      </TypingContainer>
    </ChatContainer>
  );
};

const Bubble = styled('div')(
  ({ theme, bgColor, paddingLeft = 0, paddingRight = 0 }) => ({
    position: 'relative',
    borderRadius: '.4em',
    backgroundColor: bgColor,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 10,
    left: paddingLeft,
    right: paddingRight,
    marginBottom: 20,
    wordBreak: 'break-word',
  })
);

const BubbleTriangle = styled('div')(
  ({ theme, bgColor = 'lightgrey', leftRight = '80%' }) => ({
    content: '',
    position: 'absolute',
    bottom: 0,
    left: leftRight,
    width: 0,
    height: 0,
    border: '20px solid transparent',
    borderTopColor: bgColor,
    borderBottom: 0,
    borderRight: leftRight === '80%' ? 0 : undefined,
    borderLeft: leftRight === '80%' ? undefined : 0,
    marginLeft: '-10px',
    marginBottom: '-20px',
  })
);

const BubbleData = styled('div')(
  ({ theme, bgColor = 'lightgrey', leftRight = '80%' }) => ({
    content: '',
    position: 'absolute',
    bottom: 0,
    left: leftRight === '80%' ? undefined : '80%',
    right: 0,
    width: 0,
    marginLeft: '-10px',
    marginBottom: '-20px',
  })
);

const ChatContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 100,
  width: '100%',
});

const Container = styled('div')(({ paddingLeft = 10, paddingRight = 10 }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: 10,
  paddingLeft: paddingLeft,
  paddingRight: paddingRight,
  overflow: 'hidden',
}));

const TypingContainer = styled('div')(
  ({ theme, bgColor = 'lightgrey', leftRight = '80%' }) => ({
    position: 'fixed',
    bottom: 50,
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    maxWidth: 800,
    justifyContent: 'space-around',
  })
);
