import React, { useContext, useState } from 'react';
import { Checkbox, Typography, useTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { AuthContext } from '../../state/contexts/AuthContext';
import { fetchUpdateSurveyMembers } from '../../services/methods';
import { DefaultButton } from '../elements/DefaultButton';

export const SurveyMembers = ({
  setOpen,
  handleUpdateMembers,
  userData,
  survey,
  type,
}) => {
  const theme = useTheme();
  const [members, setMembers] = useState(survey.members);
  const { user, isOnline } = useContext(AuthContext);

  const handleChange = (contact) => {
    const tmpMembers = [...members];
    if (!members.some((c) => c.userId === contact.userId)) {
      tmpMembers.push({ userId: contact.userId, userName: contact.userName });
      setMembers(tmpMembers);
    } else {
      const contactIndex = members.findIndex(
        (c) => c.userId === contact.userId
      );
      tmpMembers.splice(contactIndex, 1);
      setMembers(tmpMembers);
    }
  };

  const handleSave = async () => {
    if (isOnline()) {
      await fetchUpdateSurveyMembers(
        members,
        survey,
        type,
        userData.userInfo,
        user.sessionToken
      );
      handleUpdateMembers();
    }
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          padding: 10,
          backgroundColor: '#ffb11e',
        }}
      >
        <Typography
          variant={'subtitle1'}
          style={{ fontWeight: 'bold', color: 'white' }}
        >
          {type === 'SurveyTemplate'
            ? 'Vorlage freigeben für:'
            : 'Umfrage verteilen an:'}
        </Typography>
        <Close
          style={{ marginLeft: 'auto', color: 'white' }}
          onClick={() => setOpen(false)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          padding: 20,
        }}
      >
        {userData.contacts.map((contact) => (
          <div style={{ marginBottom: 10 }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              {contact.userName}{' '}
              <Checkbox
                color={'primary'}
                checked={members.some((c) => c.userId === contact.userId)}
                onChange={() => handleChange(contact)}
              />
            </div>
            <div
              style={{ width: '100%', height: 1, backgroundColor: '#e7e7e7' }}
            />
          </div>
        ))}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <DefaultButton
            style={{ width: '100%', maxWidth: 400 }}
            onClick={handleSave}
            customColor={theme.palette.primary}
          >
            Speichern
          </DefaultButton>
        </div>
      </div>
    </div>
  );
};
