import { Radar } from 'react-chartjs-2';
import {
  calculatePersonalResults,
  calculatePersonalResultsPerDimension,
} from './valuesCalculations';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  IconButton,
  styled,
  Typography,
  useTheme,
} from '@material-ui/core';
import {
  AddCircleOutline,
  Close,
  InfoOutlined,
  RefreshOutlined,
  RefreshTwoTone,
  ShareOutlined,
} from '@material-ui/icons';
import { UserDataContext } from '../../../state/contexts/UserDataContext';
import { AuthContext } from '../../../state/contexts/AuthContext';
import Dialog from '@material-ui/core/Dialog';
import { DialogValuesMembers } from './DialogValuesMembers';
import { ValuesCompareMembers } from './ValuesCompareMembers';
import { useHistory } from 'react-router-dom';

export const ValueResults = ({ valuesObject, setFinished }) => {
  const { userData, getAllValues, setUserValues } = useContext(UserDataContext);
  const { user, isOnline } = useContext(AuthContext);
  let history = useHistory();
  const [openMembers, setOpenMembers] = useState();
  const [showResultDetails, setShowResultDetails] = useState();
  const [selectedMember, setSelectedMember] = useState();
  const [selectedMemberResults, setSelectedMemberResults] = useState();
  const [
    selectedMemberBroadResults,
    setSelectedMemberBroadResults,
  ] = useState();
  const [sharedValuesMembers, setSharedValuesMembers] = useState();
  const [sharedValues, setSharedValues] = useState();

  const results = calculatePersonalResultsPerDimension(valuesObject.values);
  const broadResults = calculatePersonalResults(valuesObject.values);

  const labels = [
    'Selbstbestimmtheit',
    'Anregung',
    'Vergnügen',
    'Leistung',
    'Einfluss',
    'Sicherheit',
    'Tradition',
    'Gemeinschaft',
    'Gerechtigkeit',
  ];

  const broadLabels = [
    'Freiheit',
    'Selbstverbesserung',
    'Stabilität',
    'Weltverbesserung',
  ];

  const broadDescriptions = [
    {
      type: ['Peter Pan', 'Elon Musk', 'Albert Einstein', 'Pippi Langstrumpf'],
      description:
        'Du bist ein wahrer Himmelsstürmer! Du bist neugierig und interessiert, hast oft tolle Ideen und bist sehr begeisterungsfähig. Es ist dir wichtig, dich selbst entfalten zu können und dein Ding machen zu können. Du gehst gerne auch mal ein Risiko ein, um deine Träume zu verfolgen - und kannst so alles erreichen, was du willst!',
      tip:
        'Tipp: Erinnere dich gelegentlich daran, dass es nicht nur unendlich viel zu entdecken gibt, sondern auch wichtige Dinge, die dir Halt und Sicherheit geben.',
    },
    {
      type: ['Napoleon', 'Kleopatra', 'Cäsar', "Jeanne d'Arc"],
      description:
        'Du weißt, was du willst und lässt dich von niemandem aufhalten, es zu erreichen. Es ist dir wichtig, dass deine Meinung ernstgenommen wird und du mitentscheiden kannst. Du willst hoch hinaus - und mit deiner Durchsetzungskraft schaffst du das auch!',
      tip:
        'Tipp: Behalte auch im Blick, wie es den Menschen um dich herum geht und wie du ihnen eine Freude machen oder ihnen helfen kannst.',
    },
    {
      type: ['Schneewittchen', 'Elefant', 'Wächter/in', 'Hüter/in'],
      description:
        'Du liebst es, wenn die Dinge angenehm ruhig fließen und du weißt, dass alles in Ordnung ist. Es ist dir wichtig, das Gute im Leben zu bewahren, dass die Menschen freundlich und harmonisch miteinander umgehen und dass sinnvolle Regeln möglichst auch von allen befolgt werden. Du hast einen guten Blick für gefährliche Situationen und versuchst, andere davor zu beschützen.',
      tip: 'Tipp: Versuche gelegentlich auch mal ein Abenteuer!',
    },
    {
      type: ['Biene Maja', 'Greta Thunberg', 'Ghandi', 'Mutter Theresa'],
      description:
        'Du denkst nicht nur an dich selbst, sondern kümmerst dich stark um andere und findest es wichtig, dass alle Menschen - besonders die benachteiligten - gerecht behandelt werden. Du hast ein großes Herz für die Umwelt und alle Lebewesen. Du setzt dich sehr für andere ein und kämpfst für eine bessere Welt. Du bist eine wichtige Bereicherung für diese Welt!',
      tip:
        'Tipp: Achte bei aller Fürsorge trotzdem darauf, dass es dir selbst auch gut geht!',
    },
  ];

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Wichtigkeit (ich)',
        backgroundColor: 'rgba(255,177,31, 0.2)',
        borderColor: 'rgba(255,177,31, 0.1)',
        data: results.desires,
        hoverBackgroundColor: 'rgba(255,177,31,0.2)',
        pointBackgroundColor: 'rgba(255,177,31,0.2)',

        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(179,181,198,1)',
        lineTension: '0.3',
        pointHoverRadius: 5,
        spanGaps: true,
      },
      {
        label: 'Zufriedenheit (ich)',
        backgroundColor: 'rgba(255,177,31, 0.3)',
        borderColor: 'rgba(255,177,31, 0.1)',
        data: results.beliefs,
        hoverBackgroundColor: 'rgba(255,177,31,0.2)',
        pointBackgroundColor: 'rgba(255,177,31,0.2)',

        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(179,181,198,1)',
        lineTension: '0.3',
        pointHoverRadius: 5,
        spanGaps: true,
      },
      {
        label:
          (selectedMember && 'Wichtigkeit (' + selectedMember.userName + ')') ||
          'Wichtigkeit (??)',
        backgroundColor: 'rgba(47,198,102, 0.2)',
        borderColor: 'rgba(47,198,102,0.1)',
        data: selectedMemberResults && selectedMemberResults.desires,
        hoverBackgroundColor: 'rgba(47,198,102,0.2)',
        pointBackgroundColor: 'rgba(47,198,102,0.2)',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(179,181,198,1)',
        lineTension: '0.3',
        pointHoverRadius: 5,
        spanGaps: true,
      },
      {
        label:
          (selectedMember &&
            'Zufriedenheit (' + selectedMember.userName + ')') ||
          'Zufriedenheit (??)',
        backgroundColor: 'rgba(47,198,102, 0.3)',
        borderColor: 'rgba(47,198,102, 0.1)',
        data: selectedMemberResults && selectedMemberResults.beliefs,
        hoverBackgroundColor: 'rgba(47,198,102,0.2)',
        pointBackgroundColor: 'rgba(47,198,102,0.2)',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(179,181,198,1)',
        lineTension: '0.3',
        pointHoverRadius: 5,
        spanGaps: true,
      },
    ],
  };

  const options = {
    animation: false,
    plugins: {
      legend: {
        display: true,
        labels: {
          color: 'rgb(161,161,161)',
        },
      },
      tooltip: {
        backgroundColor: 'rgb(238,174,112)',
        callbacks: {},
      },
    },
    scale: {
      min: 0,
      max: 10,
    },
    scales: {
      r: {
        grid: {
          circular: true,
          color: 'rgba(255,124,0,0.19)',
          //color: ['black', 'red', 'orange', 'yellow', 'green', 'blue', 'indigo']

          //lineWidth:"5"
        },
        angle: {
          //color: 'white' // lines radiating from the center
        },
        ticks: {
          stepSize: 1,
          //display:false,
          beginAtZero: true,
          //fontColor: "blue",
          //backdropColor: "#eadabf",
          //zeroLineColor: '#ffcc33'
        },
      },
    },
  };

  const minmaxSatisfaction = () => {
    let maxDiff = 0;
    let maxDiffName = '';
    let minDiff = 10;
    let minDiffName = '';
    const results =
      showResultDetails === 'selectedMember'
        ? selectedMemberBroadResults
        : broadResults;
    results.beliefs.forEach((belief, index) => {
      const diff = broadResults.desires[index] - belief;
      if (diff > maxDiff) {
        maxDiff = diff;
        maxDiffName = broadLabels[index];
      }
      if (diff < minDiff) {
        minDiff = diff;
        minDiffName = broadLabels[index];
      }
    });

    return {
      max: { name: maxDiffName, value: Math.round(maxDiff * 100) / 100 },
      min: { name: minDiffName, value: Math.round(minDiff * 100) / 100 },
    };
  };

  const maxDesire = () => {
    let max = 0;
    let maxName = '';
    let info = '';
    const results =
      showResultDetails === 'selectedMember'
        ? selectedMemberBroadResults
        : broadResults;
    results.desires.forEach((desire, index) => {
      if (desire > max) {
        max = desire;
        maxName = broadLabels[index];
        info = broadDescriptions[index];
      }
    });
    return { name: maxName, value: Math.round(max * 100) / 100, info: info };
  };

  useEffect(() => {
    const fetchSharedValuesMembers = async () => {
      const sharedValues = await getAllValues([user.userId], user.sessionToken);
      setSharedValues(sharedValues);
      const sharedValuesMembers = sharedValues.map((values) => values.user);
      setSharedValuesMembers(sharedValuesMembers);
    };
    fetchSharedValuesMembers();
  }, [user]);

  useEffect(() => {
    if (selectedMember && sharedValues) {
      const memberValues = sharedValues.find(
        (values) => values.user.userId === selectedMember.userId
      ).values;
      setSelectedMemberResults(
        calculatePersonalResultsPerDimension(memberValues)
      );
      setSelectedMemberBroadResults(calculatePersonalResults(memberValues));
    } else {
      setSelectedMemberResults(undefined);
    }
  }, [sharedValues, selectedMember]);

  const handleReanswerSurvey = () => {
    setUserValues(userData.userInfo, valuesObject, user.sessionToken);
    setFinished(false);
  };

  const getType = () => {
    if (maxDesire().value >= 8) {
      return maxDesire().info.type[3];
    }
    if (maxDesire().value >= 7) {
      return maxDesire().info.type[2];
    }
    if (maxDesire().value >= 6) {
      return maxDesire().info.type[1];
    } else {
      return maxDesire().info.type[0];
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={{ padding: 10 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant={'body2'} style={{ fontSize: 12 }}>
            Unten siehst du, was dir wirklich wichtig ist und wie zufrieden du
            gerade bist. Klicke auf das Info-Symbol für Details oder vergleiche
            dich mit anderen!
          </Typography>
          <div>
            <IconButton
              style={{ border: '1px solid', padding: 5, marginLeft: 10 }}
              onClick={handleReanswerSurvey}
              color={'primary'}
            >
              <RefreshTwoTone />
            </IconButton>
          </div>
        </div>
      </div>
      <div
        style={{
          position: 'relative',
          alignSelf: 'center',
          maxWidth: '80vh',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 5,
            top: 10,
            width: '100%',
          }}
        >
          <Button
            style={{ borderRadius: 30 }}
            variant={'outlined'}
            color={'primary'}
            onClick={() => setShowResultDetails('user')}
          >
            <InfoOutlined style={{ marginRight: 5 }} />
            Ich
          </Button>
          <Button
            style={{
              borderRadius: 30,
              color: selectedMember
                ? 'rgba(47,198,102,0.7)'
                : 'rgba(47,198,102,0.3)',
            }}
            variant={'outlined'}
            onClick={() =>
              selectedMember && setShowResultDetails('selectedMember')
            }
            disabled={!selectedMember}
          >
            <InfoOutlined style={{ marginRight: 5 }} />
            {selectedMember ? selectedMember.userName : '???'}
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ fontSize: 12 }}>Vergleiche dich mit:</div>
          <div style={{ marginLeft: 5, minWidth: '50%' }}>
            <ValuesCompareMembers
              sharedValuesMembers={sharedValuesMembers}
              selectedMember={selectedMember}
              setSelectedMember={setSelectedMember}
            />
          </div>
          <IconButton onClick={() => setOpenMembers(true)} color={'primary'}>
            <ShareOutlined />
          </IconButton>
        </div>
        <Radar data={data} options={options} type={'radar'} />
      </div>
      <Dialog
        fullWidth
        onClose={() => setShowResultDetails(false)}
        open={showResultDetails}
      >
        <div style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
          <div style={{ display: 'flex' }}>
            <div style={{ fontWeight: 'bold', fontSize: 16 }}>
              {showResultDetails === 'selectedMember'
                ? selectedMember.userName + "'s Typ: "
                : 'Dein Typ: '}
              <br />
              <div
                style={{ fontSize: 24, color: useTheme().palette.primary.main }}
              >
                {' '}
                {getType()}
              </div>
            </div>
            <Close
              color={'primary'}
              style={{ marginLeft: 'auto' }}
              onClick={() => setShowResultDetails(false)}
            />
          </div>
          <div>
            Am Wichtigsten ist für{' '}
            {showResultDetails === 'selectedMember'
              ? selectedMember.userName
              : 'dich'}{' '}
            das Thema
            {' ' + maxDesire().name + ' (' + maxDesire().value + ' Punkte).'}
          </div>
          <br />
          <div style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 5 }}>
            {showResultDetails === 'selectedMember'
              ? selectedMember.userName + "'s "
              : 'Deine '}
            Beschreibung
          </div>
          <div style={{ fontSize: 12 }}>{maxDesire().info.description}</div>
          <i style={{ fontSize: 12, margin: 5, marginTop: 10 }}>
            {maxDesire().info.tip}
          </i>
          <div
            style={{
              fontSize: 14,
              fontWeight: 'bold',
              margin: 5,
              marginTop: 15,
            }}
          >
            Auswertung
          </div>
          <Card
            style={{
              padding: 10,
              backgroundColor: 'rgba(150,236,98,0.12)',
              fontSize: 12,
            }}
          >
            Super! Mit dem Bereich <b>{minmaxSatisfaction().min.name}</b>
            {showResultDetails === 'selectedMember'
              ? ' ist ' + selectedMember.userName
              : ' bist du'}{' '}
            im Moment besonders zufrieden.
          </Card>
          <Card
            style={{
              marginTop: 10,
              padding: 10,
              backgroundColor: 'rgba(236,137,98,0.12)',
              fontSize: 12,
            }}
          >
            Hmm, am wenigsten zufrieden{' '}
            {showResultDetails === 'selectedMember'
              ? 'ist ' + selectedMember.userName
              : 'bist du'}{' '}
            offenbar in Bezug auf <b>{minmaxSatisfaction().max.name}</b>.{' '}
            <i>
              {showResultDetails === 'selectedMember'
                ? 'Wollt ihr zusammen '
                : 'Willst du dir'}{' '}
              ein Ziel setzen, um das zu ändern?
            </i>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <AddGoalButton onClick={() => history.push('/addgoal')}>
                <AddCircleOutline
                  style={{ color: 'white', width: 40, height: 40 }}
                  fontSize={'large'}
                />{' '}
              </AddGoalButton>
            </div>
          </Card>
        </div>
      </Dialog>
      <Dialog
        fullWidth
        onClose={() => setOpenMembers(false)}
        open={openMembers}
      >
        <DialogValuesMembers
          setOpen={setOpenMembers}
          userData={userData}
          valuesObject={valuesObject}
        />
      </Dialog>
    </div>
  );
};

const AddGoalButton = styled(IconButton)(({ theme }) => ({
  margin: 5,
  padding: 7,
  backgroundColor: theme.palette.primary.main,
}));
