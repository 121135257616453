import { IconButton, styled } from '@material-ui/core';
import React from 'react';

export const DefaultIconButton = styled(({ ...props }) => (
  <IconButton {...props} />
))(({ theme, customColor, border, borderColor }) => ({
  padding: 5,
  backgroundColor: customColor || theme.palette.secondary.main,
  border: border && `1px solid ${borderColor || theme.palette.primary.main}`,
}));
