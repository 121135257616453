import { useEffect, useState } from 'react';
import {
  Card,
  IconButton,
  Menu,
  MenuItem,
  styled,
  TextField,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import {
  AddCircleOutlined,
  DeleteOutline,
  ExpandMore,
} from '@material-ui/icons';
import { DefaultIconButton } from '../elements/DefaultIconButton';
import { DefaultButton } from '../elements/DefaultButton';

export const SurveyElement = ({
  element,
  index,
  modifyElement,
  addElement,
  removeElement,
}) => {
  const [question, setQuestion] = useState(element && element.question);
  const [type, setType] = useState(element && element.type);
  const [openTypeMenu, setOpenTypeMenu] = useState(element && element.type);
  const [anchorEl, setAnchorEl] = useState();

  const answerTypes = {
    binary: 'Ja/Nein',
    text: 'Freitext',
    scale: 'Skala (1-10)',
  };

  useEffect(() => {
    modifyElement(index, {
      key: element.key,
      question: question,
      type: type,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, type]);

  const handleOpenTypeMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenTypeMenu(index);
  };

  return (
    <Card
      style={{
        width: '100%',
        maxWidth: 600,
        alignSelf: 'center',
        marginBottom: 10,
        padding: 10,
        paddingTop: 20,
        paddingBottom: 0,
        backgroundColor: useTheme().palette.secondary.main,
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 40 }}>
          <TextField
            multiline={2}
            InputProps={{
              style: {
                padding: 10,
              },
            }}
            inputProps={{
              style: {
                textAlign: 'center',
              },
            }}
            style={{ width: '100%', backgroundColor: 'white' }}
            variant='outlined'
            value={question || ''}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder={'Frage'}
          />
          <DefaultIconButton
            style={{
              marginLeft: 10,
              border: `1px solid ${useTheme().palette.primary.main}`,
            }}
            onClick={() => removeElement(index)}
          >
            <DeleteOutline fontSize={'small'} color={'primary'} />
          </DefaultIconButton>
        </div>
        <div
          style={{
            marginTop: 10,
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            paddingLeft: 40,
            paddingRight: 40,
          }}
        >
          <DefaultButton
            startIcon={
              <ExpandMore style={{ marginLeft: -20 }} color={'primary'} />
            }
            style={{
              width: '100%',
              boxShadow: 'none',
              border: `1px solid ${useTheme().palette.primary.main}`,
            }}
            customColor={{ main: 'white', dark: 'white' }}
            textColor={useTheme().palette.primary.main}
            onClick={(e) => handleOpenTypeMenu(e, index)}
          >
            {element.type ? answerTypes[element.type] : 'Antworttyp'}
          </DefaultButton>
          <Menu
            open={openTypeMenu === index}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            onClose={() => {
              setAnchorEl(null);
              setOpenTypeMenu(false);
            }}
            PaperProps={{
              style: {
                width: '90%',
                maxWidth: 500,
              },
            }}
          >
            <MenuItem
              onClick={() => {
                setType('scale');
                setOpenTypeMenu(false);
              }}
            >
              Skala (1-10)
            </MenuItem>
            <MenuItem
              onClick={() => {
                setType('text');
                setOpenTypeMenu(false);
              }}
            >
              Freitext
            </MenuItem>
            <MenuItem
              onClick={() => {
                setType('binary');
                setOpenTypeMenu(false);
              }}
            >
              Ja/Nein
            </MenuItem>
          </Menu>
        </div>
        <div style={{ textAlign: 'center' }}>
          <IconButton
            color={'primary'}
            variant={'outlined'}
            onClick={() => addElement(index)}
          >
            <AddCircleOutlined
              fontSize={'large'}
              style={{ color: useTheme().palette.primary.main }}
            />
          </IconButton>
        </div>
      </div>
    </Card>
  );
};
