import { CircleWrapper } from '../../elements/CircleWrapper';
import VeryBadMoodIcon from '../../../res/emotions/VeryBadMoodIcon';
import BadMoodIcon from '../../../res/emotions/BadMoodIcon';
import NeutralMoodIcon from '../../../res/emotions/NeutralMoodIcon';
import HappyMoodIcon from '../../../res/emotions/HappyMoodIcon';
import VeryHappyMoodIcon from '../../../res/emotions/VeryHappyMoodIcon';
import React from 'react';

export const MoodBar = ({ selected, handleMoodChange }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <CircleWrapper
        color={'red'}
        onClick={() => handleMoodChange('very_bad')}
        backgroundColor={selected === 'very_bad' ? 'red' : undefined}
        selected={selected === 'very_bad'}
        size={50}
      >
        <VeryBadMoodIcon fill={selected !== 'very_bad' ? 'red' : 'white'} />
      </CircleWrapper>
      <CircleWrapper
        color={'#e21490'}
        onClick={() => handleMoodChange('bad')}
        backgroundColor={selected === 'bad' ? '#e21490' : undefined}
        selected={selected === 'bad'}
        size={50}
      >
        <BadMoodIcon fill={selected !== 'bad' ? '#e21490' : 'white'} />
      </CircleWrapper>
      <CircleWrapper
        color={'lightgrey'}
        onClick={() => handleMoodChange('neutral')}
        backgroundColor={selected === 'neutral' ? 'lightgrey' : undefined}
        selected={selected === 'neutral'}
        size={50}
      >
        <NeutralMoodIcon
          fill={selected !== 'neutral' ? 'lightgrey' : 'white'}
        />
      </CircleWrapper>
      <CircleWrapper
        color={'#72e343'}
        onClick={() => handleMoodChange('happy')}
        backgroundColor={selected === 'happy' ? '#72e343' : undefined}
        selected={selected === 'happy'}
        size={50}
      >
        <HappyMoodIcon fill={selected !== 'happy' ? '#72e343' : 'white'} />
      </CircleWrapper>
      <CircleWrapper
        color={'green'}
        onClick={() => handleMoodChange('very_happy')}
        backgroundColor={selected === 'very_happy' ? 'green' : undefined}
        selected={selected === 'very_happy'}
        size={50}
      >
        <VeryHappyMoodIcon
          fill={selected !== 'very_happy' ? 'green' : 'white'}
        />
      </CircleWrapper>
    </div>
  );
};
