import React, { useContext } from 'react';
import { IconButton, styled } from '@material-ui/core';

import { AuthContext } from 'state/contexts/AuthContext';

import ButtonIconWrapper from './ButtonIconWrapper';
import MenuIcon from '@material-ui/icons/Menu';

import LogoImg from '../../res/logo_title.png';
import { useTheme } from '@material-ui/core/styles';

export default ({ toggleDrawer }) => {
  const { user } = useContext(AuthContext);
  const theme = useTheme();

  return (
    <div style={{ width: '100%' }}>
      <Header>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ borderRadius: '50%', backgroundColor: 'white' }}>
            <Logo />
          </div>
          <span
            style={{
              fontSize: 16,
              fontWeight: 700,
              fontFamily: theme.typography.fontFamilyTitle,
              color: theme.palette.primary.main,
            }}
          >
            iuvivo
          </span>
          <span style={{ fontSize: 8 }}> {user && user.userName}</span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <MenuIcon style={{ color: theme.palette.primary.main }} />
          </IconButton>
        </div>
      </Header>
      <div
        style={{
          width: '100%',
          height: 1,
          borderBottom: '1px dotted lightgrey',
        }}
      />
    </div>
  );
};

const Logo = styled('div')({
  width: 40,
  height: 40,
  backgroundImage: `url(${LogoImg})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
});

const Header = styled('div')(({ theme }) => ({
  //position: 'sticky',
  //top: 0,
  //backgroundColor: theme.palette.primary.main,
  zIndex: 1200,
  height: 60,
  width: '100%',
  padding: '10px',
  // flexDirection: 'column',
  display: 'flex',
  alignItems: 'center',
  //backgroundColor: theme.palette.secondary.main
  justifyContent: 'space-between',
}));
