import React, { useContext, useEffect, useState } from 'react';
import { ManageCustomAspects } from './ManageCustomAspects';
import Contacts from '../Messenger/Contacts';
import {
  Button,
  Collapse,
  Divider,
  styled,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { ExpandMore, Share } from '@material-ui/icons';
import { DialogDeactivateAccount } from './DialogDeactivateAccount';
import Dialog from '@material-ui/core/Dialog';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { AuthContext } from '../../state/contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import { Intro } from '../Intro/Intro';

export const Profile = ({ message, setMessage }) => {
  const { userData, setUserSettings, currentFCMToken } = useContext(
    UserDataContext
  );
  const { user } = useContext(AuthContext);
  let history = useHistory();
  const [collapse, setCollapse] = useState();
  const [deactivateDialogOpen, setDeactivateDialogOpen] = useState();
  const [cutify, setCutify] = useState(
    (userData && userData.settings && userData.settings.cutify) || false
  );
  const [anonymousFCM, setAnonymousFCM] = useState(
    userData && userData.settings && userData.settings.anonymousFCM
  );
  const [showIntro, setShowIntro] = useState();
  const [showTokenData, setShowTokenData] = useState();

  const handleCutify = () => {
    setCutify(!cutify);
    if (!userData.settings) {
      userData.settings = {};
    }
    userData.settings['cutify'] = !cutify;
    setUserSettings(user.userId, userData.settings, user.sessionToken);
  };

  const handleAnonymousFCM = () => {
    setAnonymousFCM(!anonymousFCM);
    if (!userData.settings) {
      userData.settings = {};
    }
    userData.settings['anonymousFCM'] = !anonymousFCM;
    setUserSettings(user.userId, userData.settings, user.sessionToken);
  };

  const handleCollapse = (collapse) => {
    setCollapse(collapse);
  };

  const validFCMToken = () => {
    const { tokenList, token } = tokenData();
    return tokenList.includes(token);
  };

  const tokenData = () => {
    const token =
      currentFCMToken || window.localStorage.getItem('flutterToken');
    const tokenList =
      (userData &&
        userData.fcmTokenList &&
        userData.fcmTokenList.map((element) => element.token)) ||
      [];
    return { tokenList, token };
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: 15,
      }}
    >
      <div
        onClick={() =>
          handleCollapse(collapse === 'aspects' ? false : 'aspects')
        }
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography variant='subtitle2'>
          Vorteile und Aktivitäten verwalten
        </Typography>
        <IconButton>
          <ExpandMore />
        </IconButton>
      </div>
      <Collapse
        in={collapse === 'aspects'}
        timeout='auto'
        unmountOnExit
        style={{ width: '100%' }}
      >
        <ManageCustomAspects />
      </Collapse>
      <Divider />
      <div
        style={{
          display: 'flex',
          marginTop: 15,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        Niedliche Bilder bei der Zielbearbeitung anzeigen
        <Switch color={'primary'} checked={cutify} onChange={handleCutify} />
      </div>
      <Divider style={{ marginTop: 15, marginBottom: 15 }} />
      {validFCMToken() ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          Pushnachrichten nur anonym versenden
          <Switch
            color={'primary'}
            checked={anonymousFCM}
            onChange={handleAnonymousFCM}
          />
        </div>
      ) : (
        <div>
          <div
            style={{ fontSize: 11, color: 'red', marginTop: 15 }}
            onClick={() => setShowTokenData(!showTokenData)}
          >
            Pushnachrichten sind derzeit NICHT aktiviert. Füge eine Erlaubnis
            für iuvivo in deinen Appeinstellungen sowie im Browser hinzu und
            starte iuvivo neu.
          </div>
          {showTokenData && (
            <div style={{ wordBreak: 'break-all', fontSize: 8, marginTop: 5 }}>
              <span style={{ fontWeight: 'bold' }}>Aktueller Token:</span>{' '}
              {tokenData().token}
              <br />
              <span style={{ fontWeight: 'bold' }}>Token-Liste:</span>{' '}
              <div>
                {tokenData().tokenList.map((token, index) => {
                  return (
                    <div>
                      {index}: {token.token}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
      <Divider style={{ marginTop: 15, marginBottom: 15 }} />
      <div
        onClick={() => setShowIntro(true)}
        style={{ fontSize: 14, textTransform: 'none', cursor: 'pointer' }}
      >
        Intro erneut zeigen
      </div>
      <Divider style={{ marginTop: 15, marginBottom: 15 }} />
      {showIntro && <Intro setShowIntro={setShowIntro} />}
      {!collapse && (
        <div
          style={{
            fontSize: 10,
            maxWidth: 150,
            cursor: 'pointer',
            bottom: 80,
          }}
          onClick={() => setDeactivateDialogOpen(true)}
        >
          Konto löschen
        </div>
      )}
      <Dialog
        fullWidth
        onClose={() => setDeactivateDialogOpen(false)}
        open={deactivateDialogOpen}
      >
        <DialogDeactivateAccount setOpen={setDeactivateDialogOpen} />
      </Dialog>
    </div>
  );
};
