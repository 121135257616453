import React, { useContext, useEffect, useState } from 'react';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { Switch, Typography, useTheme } from '@material-ui/core';
import { AuthContext } from '../../state/contexts/AuthContext';
import { Divider } from '../elements/Divider';

export const GoodQuestion = () => {
  const { userData, setUserSettings, currentGoodQuestion } = useContext(
    UserDataContext
  );
  const { user } = useContext(AuthContext);
  const theme = useTheme();
  const [checkSubscribe, setCheckSubscribe] = useState(currentGoodQuestion);

  useEffect(() => {
    if (currentGoodQuestion) {
      setCheckSubscribe(true);
    } else {
      setCheckSubscribe(false);
    }
  }, [currentGoodQuestion]);

  const handleUnsubscribeGoodQuestion = (event) => {
    if (!userData.settings) {
      userData.settings = {};
    }
    if (!event.target.checked) {
      userData.settings['unsubscribeGoodQuestion'] = true;
    } else {
      delete userData.settings['unsubscribeGoodQuestion'];
    }
    setUserSettings(user.userId, userData.settings, user.sessionToken);
    setCheckSubscribe(event.target.checked);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 600,
        padding: 10,
        margin: 5,
        paddingTop: 0,
        borderRadius: 5,
        backgroundColor: theme.palette.primary.lightGrey,
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography color={'primary'} variant={'subtitle2'}>
          GUTE FRAGE DER WOCHE
        </Typography>
        <div style={{ fontSize: 12, marginLeft: 10 }}>
          {checkSubscribe ? 'an' : 'aus'}
          <Switch
            color={'primary'}
            checked={checkSubscribe}
            onChange={handleUnsubscribeGoodQuestion}
          />
        </div>
      </div>
      {checkSubscribe && currentGoodQuestion && (
        <div
          style={{
            fontSize: 14,
          }}
        >
          {currentGoodQuestion}
        </div>
      )}
    </div>
  );
};
