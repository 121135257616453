import { styled } from '@material-ui/core';
import React from 'react';

export default ({ children }) => {
  return <Circle>{children}</Circle>;
};

const Circle = styled('div')(({ theme, img }) => ({
  marginBottom: 0,
  padding: 5,
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'orange',
}));
