import { IuvivoUser } from 'services/userData';

export const props = {
  CONTACTS: 'contacts',
  GOALS: 'goals',
  CONTACT_REQUESTS: 'contactRequests',
  USERINFO: 'userInfo',
  DIARY: 'diary',
  GOALPOINTS: 'goalPoints',
  GOALARCHIVE: 'goalArchive',
  ASPECTS: 'aspects',
  FCM_TOKEN: 'fcmToken',
  FCM_TOKEN_LIST: 'fcmTokenList',
  RECOVERYKEY: 'recoveryKey',
  SETTINGS: 'settings',
  FILEMANAGER: 'fileManager',
};

const propsArray = Object.values(props);

export function mapApiToUserDataModel(apiObject) {
  return propsArray.reduce((model, mapEl) => {
    model[mapEl] = apiObject.get(mapEl);
    return model;
  }, {});
}

export function mapUserDataModelToApi(modelObject) {
  const userData = new IuvivoUser();

  propsArray.forEach((prop) => {
    userData.set(prop, modelObject[prop]);
  });

  return userData;
}
