import { IconButton, Paper, useTheme } from '@material-ui/core';
import { dateWithTimeOptions } from '../../utils/constants';
import { MoreVert, OpenInNew, Share } from '@material-ui/icons';
import React from 'react';

export const File = (props) => {
  const theme = useTheme();
  return (
    <Paper
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 10,
        padding: 5,
        backgroundColor:
          props.selectedFile === props.file
            ? theme.palette.secondary.main
            : undefined,
      }}
      onClick={props.onClick}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
        <span
          style={{
            fontSize: 14,
            fontWeight: 500,
            marginBottom: 10,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
          }}
        >
          {props.file.name}
        </span>
        <span style={{ color: '#a9a9a9', fontSize: 12 }}>
          {props.file.owner.userName}
        </span>
        <span style={{ color: '#bfbfbf', fontSize: 12 }}>
          {new Date(props.file.updatedAt).toLocaleDateString(
            'de-DE',
            dateWithTimeOptions
          )}
        </span>
      </div>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          window.open(props.file.doc._url, '_blank');
        }}
      >
        <OpenInNew color={'primary'} />
      </IconButton>
    </Paper>
  );
};
