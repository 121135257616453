import Parse from '../utils/initParse';
import { mapApiToSurveyModel } from '../models/SurveyModel';
import { fetchSendNotification } from './notifications';

const SurveyTemplate = Parse.Object.extend('SurveyTemplate');
const Survey = Parse.Object.extend('Survey');

const acl = (userInfo, survey, writeAccess = false) => {
  const acl = new Parse.ACL();
  acl.setReadAccess(userInfo.userId, true);
  acl.setWriteAccess(userInfo.userId, true);

  survey.members &&
    Object.values(survey.members).forEach((member) => {
      acl.setReadAccess(member.userId, true);
      writeAccess && acl.setWriteAccess(member.userId, true);
    });
  return acl;
};

const sendSurveyNotification = (
  userIds,
  title,
  senderName,
  type,
  sessionToken
) => {
  fetchSendNotification(
    userIds,
    { messageText: title, messageSender: senderName },
    type,
    sessionToken
  );
};

export const fetchSetSurveyTemplate = async (
  survey,
  userInfo,
  sessionToken
) => {
  let userSurvey;
  if (survey && survey.id) {
    const surveyQuery = new Parse.Query(SurveyTemplate);
    surveyQuery.equalTo('objectId', survey.id);
    userSurvey = await surveyQuery.first({ sessionToken });
  } else {
    userSurvey = new SurveyTemplate();
    userSurvey.set('author', {
      userId: userInfo.userId,
      userName: userInfo.userName,
    });
  }
  userSurvey.set('title', survey.title);
  userSurvey.set('elements', survey.elements || []);
  userSurvey.set('members', survey.members || []);
  userSurvey.setACL(acl(userInfo, survey));
  return await userSurvey.save({}, { sessionToken });
};

export const fetchRemoveSurveyTemplate = async (
  userId,
  surveyId,
  sessionToken
) => {
  const surveyQuery = new Parse.Query(SurveyTemplate);
  surveyQuery.equalTo('objectId', surveyId);
  let survey = await surveyQuery.first({ sessionToken });
  return await survey.destroy({ sessionToken });
};

export const fetchGetSurveyTemplates = async (sessionToken) => {
  const surveyQuery = new Parse.Query(SurveyTemplate);
  let surveys = (await surveyQuery.find({ sessionToken })) || [];
  return surveys.map((survey) => mapApiToSurveyModel(survey));
};

export const fetchSetActiveSurvey = async (
  userInfo,
  surveyObject,
  sessionToken
) => {
  const userSurvey = new Survey();
  userSurvey.set('author', {
    userId: userInfo.userId,
    userName: userInfo.userName,
  });
  userSurvey.set('title', surveyObject.title);
  userSurvey.set('elements', surveyObject.elements);
  userSurvey.set('members', []);
  userSurvey.setACL(acl(userInfo, surveyObject, true));
  return await userSurvey.save({}, { sessionToken });
};

export const fetchGetActiveSurveys = async (sessionToken) => {
  const surveyQuery = new Parse.Query(Survey);
  const surveys = (await surveyQuery.find({ sessionToken })) || [];
  return surveys.map((survey) => mapApiToSurveyModel(survey));
};

export const fetchRemoveActiveSurvey = async (
  userId,
  surveyId,
  sessionToken
) => {
  const surveyQuery = new Parse.Query(Survey);
  surveyQuery.equalTo('objectId', surveyId);
  let survey = await surveyQuery.first({ sessionToken });

  return await survey.destroy({ sessionToken });
};

export const fetchRenameActiveSurvey = async (
  surveyObject,
  title,
  userInfo,
  sessionToken
) => {
  const surveyQuery = new Parse.Query(Survey);
  surveyQuery.equalTo('objectId', surveyObject.id);
  const survey = await surveyQuery.first({ sessionToken });
  survey.set('title', title);
  return await survey.save({}, { sessionToken });
};

export const fetchRemoveUserFromSurvey = async (
  surveyObject,
  userId,
  sessionToken,
  type
) => {
  const remainingMembers = surveyObject.members.filter(
    (member) => member.userId !== userId
  );
  const surveyId = surveyObject.id;
  const result = await Parse.Cloud.run(
    'fetchRemoveUserFromSurvey',
    { remainingMembers, surveyId, type },
    { sessionToken }
  );

  return result;
};

//update ActiveSurvey or SurveyTemplate members
export const fetchUpdateSurveyMembers = async (
  members,
  surveyObject,
  type,
  userInfo,
  sessionToken
) => {
  const query = new Parse.Query(type);
  query.equalTo('objectId', surveyObject.id);
  const survey = await query.first({ sessionToken });
  const prevMembersInfo = survey.get('members') || [];
  const prevMembers = prevMembersInfo.map((member) => member.userId);
  const newMembers = members
    .filter((member) => !prevMembers.includes(member))
    .map((member) => member.userId);
  if (newMembers.length > 0) {
    sendSurveyNotification(
      newMembers,
      surveyObject.title,
      userInfo.userName,
      type === 'Survey' ? 'surveyNewMember' : 'surveyTemplateNewMember',
      sessionToken
    );
  }
  survey.set('members', members);
  survey.setACL(acl(userInfo, { members }, type === 'Survey'));
  return await survey.save({}, { sessionToken });
};

export const fetchClientSurveys = async (userInfo, sessionToken) => {
  const surveyQuery = new Parse.Query(Survey);
  const surveys = (await surveyQuery.find({ sessionToken })) || [];
  return surveys.map((survey) => mapApiToSurveyModel(survey));
};

export const fetchSetSurveyAnswers = async (
  surveyId,
  answersObject,
  userInfo,
  sessionToken
) => {
  const surveyQuery = new Parse.Query(Survey);
  surveyQuery.equalTo('objectId', surveyId);
  const survey = await surveyQuery.first({ sessionToken });
  const answers = survey.get('answers') || {};
  answers[userInfo.userId] = answersObject;
  survey.set('answers', answers);
  const prevMembers = survey.get('members');
  const members = prevMembers.filter(
    (member) => member.userId !== userInfo.userId
  );
  survey.set('members', members);
  const author = survey.get('author');
  survey.setACL(acl(author, { members }, true));

  sendSurveyNotification(
    [author.userId],
    survey.get('title'),
    userInfo.userName,
    'surveyCompleted',
    sessionToken
  );
  return await survey.save({}, { sessionToken });
};
