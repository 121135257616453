import React, { useContext, useEffect, useState } from 'react';
import { AddCircle } from '@material-ui/icons';
import { IconButton, styled } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { CustomAspectEdit } from '../elements/CustomAspectEdit';
import { Divider } from '../elements/Divider';
import { getIcons } from '../../utils/getIcons';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { AuthContext } from '../../state/contexts/AuthContext';

export const ManageCustomAspects = () => {
  const { userData, setDBAspects } = useContext(UserDataContext);
  const { user, isOnline } = useContext(AuthContext);
  const [showDialog, setShowDialog] = useState();
  const [images, setImages] = useState();
  const [customAspects, setCustomAspects] = useState([]);
  const [editAspect, setEditAspect] = useState();

  useEffect(() => {
    if (userData.aspects) {
      setCustomAspects(userData.aspects);
    }
  }, [userData.aspects]);

  useEffect(() => {
    const images = getIcons(customAspects);
    setImages(images);
  }, [customAspects]);

  const handleEditAspect = (aspect) => {
    setEditAspect(aspect);
    setShowDialog(true);
  };

  const handleAspectChange = (editAspect, aspect) => {
    if (isOnline()) {
      setEditAspect(null);
      const newItems = customAspects.map((item) =>
        item === editAspect ? aspect : item
      );
      setDBAspects(userData.userInfo, newItems, user.sessionToken);
    }
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    setEditAspect(undefined);
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <div style={{ fontSize: 12 }}>
        Deine eigenen Aspekte (Vorteile, Aktivitäten)
      </div>
      <div style={{ fontSize: 12, fontStyle: 'italic' }}>
        Icon klicken zum Bearbeiten
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        {customAspects &&
          customAspects.map((aspect, index) => {
            return (
              <div
                onClick={() => handleEditAspect(aspect)}
                key={aspect.title}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  maxWidth: 70,
                  margin: 10,
                }}
              >
                <StyledIcon key={aspect.title} icon={images[aspect.image]} />
                <span style={{ fontSize: 12, textAlign: 'center' }}>
                  {aspect.title}
                </span>
              </div>
            );
          })}
        <Divider />
        <IconButton onClick={() => setShowDialog(true)}>
          {' '}
          <Typography style={{ marginRight: 10 }} variant={'subtitle1'}>
            Aspekte hinzufügen
          </Typography>
          <AddCircle color={'primary'} fontSize='large' />
        </IconButton>
      </div>
      {showDialog && (
        <CustomAspectEdit
          handleAspectChange={handleAspectChange}
          editAspect={editAspect}
          setShowDialog={handleCloseDialog}
        />
      )}
    </div>
  );
};

const StyledIcon = styled('div')(({ icon }) => ({
  width: 50,
  height: 50,
  borderRadius: '50%',
  backgroundImage: 'url(' + icon + ')',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
}));
