import React, { useContext, useEffect, useRef, useState } from 'react';
import { DropZone } from './DropZone';
import FilesContextProvider, {
  FilesContext,
} from '../../state/contexts/FilesContext';
import { IconButton, Paper, styled, Menu, MenuItem } from '@material-ui/core';
import FileSaver from 'file-saver';
import {
  Delete,
  MoreVert,
  OpenInNew,
  OpenWith,
  Save,
  Share,
} from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import { DialogFileMembers } from './DialogFileMembers';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { dateWithTimeOptions } from '../../utils/constants';
import { deleteFile, updateFile } from '../../services/files';
import Parse from 'utils/initParse';
import { AuthContext } from '../../state/contexts/AuthContext';
import { FileManagementBar } from './FileManagementBar';
import * as PropTypes from 'prop-types';
import { File } from './File';
import { generateCode } from '../../utils/generateCode';
import { Folder } from './Folder';
import MoveFolderIcon from '../../res/MoveFolderIcon';

export const Files = () => {
  return (
    <FilesContextProvider>
      <FilesComponent />
    </FilesContextProvider>
  );
};

const FilesComponent = () => {
  const { getFiles } = useContext(FilesContext);
  const { userData, setDBFileManager } = useContext(UserDataContext);
  const { user, isOnline } = useContext(AuthContext);

  const [files, setFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [openMembers, setOpenMembers] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [fileManager, setFileManager] = useState([]);
  const [folderContent, setFolderContent] = useState([]);
  const [parentFolderId, setParentFolderId] = useState();
  const [moveItem, setMoveItem] = useState();
  const [showFileManager, setShowFileManager] = useState(false);

  useEffect(() => {
    if (userData) {
      if (userData.fileManager) {
        console.log('file userdata updated');
        setFileManager(userData.fileManager);
      }
      reloadFiles();
    }
  }, [userData]);

  useEffect(() => {
    if (userData && userData.fileManager) {
      checkFileHealth({ content: userData.fileManager });
      if (folderContent.length === 0 || !folderContent.folderId) {
        setFolderContent({ content: userData.fileManager });
      } else {
        const folder = getFolderById(
          userData.fileManager,
          folderContent.folderId
        );
        setFolderContent(folder);
      }
    }
  }, [fileManager, files]);

  const sortFiles = (files) => {
    let sortedFiles = [];
    if (files.length > 0) {
      sortedFiles = files.sort((a, b) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      });
    }
    return sortedFiles;
  };

  const filterFiles = (searchString) => {
    if (files) {
      const filtered = files.filter(
        (file) =>
          file.owner.userName
            .toLowerCase()
            .includes(searchString.toLowerCase()) ||
          Object.values(file.members).some((member) =>
            member.userName.toLowerCase().includes(searchString.toLowerCase())
          ) ||
          file.name.toLowerCase().includes(searchString.toLowerCase())
      );
      if (filtered.length > 0) {
        setFilteredFiles(filtered);
      } else {
        setFilteredFiles([]);
      }
    }
  };

  const reloadFiles = async () => {
    const files = await getFiles(userData.userInfo, user.sessionToken);
    sortFiles(files);
    setFiles(files);
    setFilteredFiles(files);
    if (!userData.fileManager) {
      setFileManager(files);
      if (folderContent.length === 0) {
        setFolderContent({ content: files });
      }
    }
  };

  const saveFile = (file) => {
    setAnchorEl(null);
    FileSaver.saveAs(file.doc._url, file.name);
  };

  const removeFile = async (file) => {
    if (isOnline()) {
      setAnchorEl(null);
      if (userData.userInfo.userId === file.owner.userId) {
        const result = await deleteFile(
          userData.userInfo,
          file,
          user.sessionToken
        );
      } else {
        const memberIndex = file.members.findIndex(
          (c) => c.userId === userData.userInfo.userId
        );
        file.members.splice(memberIndex, 1);
        await updateFile(userData.userInfo, file, user.sessionToken);
      }
      reloadFiles();
    }
  };

  const handleShare = (file) => {
    setOpenMembers(true);
    setSelectedFile(selectedFile);
  };

  const handleMenu = (event, file) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedFile(file);
  };

  const checkFileHealth = (folder) => {
    folder.content.forEach((item) => {
      if (
        item.id &&
        files.length > 0 &&
        !files.some((file) => file.id === item.id)
      ) {
        const tmpFileManager = JSON.parse(JSON.stringify(userData.fileManager));
        const currentFolder = folder.folderId
          ? getFolderById(tmpFileManager, folder.folderId, true).content
          : tmpFileManager;
        const fileToDeleteIndex = currentFolder.findIndex(
          (item) => item.folderId === folder.folderId
        );
        currentFolder.splice(fileToDeleteIndex, 1);
        setDBFileManager(userData.userInfo, tmpFileManager, user.sessionToken);
      }
    });
  };

  const getFolderById = (
    fileManager,
    folderId,
    fromCheckFileHealth = undefined
  ) => {
    let folder = null;

    const getFolder = (fileManager, folderId) => {
      for (let object of fileManager) {
        if (object.folderId === folderId) {
          //object.parentFolderId = parentFolderId;
          folder = object;
          break;
        } else if (object.content) {
          folder = getFolder(object.content, folderId);
        }
      }
      return folder;
    };

    if (!folder) {
      getFolder(fileManager, folderId);
    }

    if (folder && !fromCheckFileHealth) {
      checkFileHealth(folder);
    }

    return folder;
  };

  const openFolder = async (folderId) => {
    const folder = getFolderById(fileManager, folderId);
    await setParentFolderId(folderContent.folderId);
    setFolderContent(folder);
  };

  const handleBackToParentFolder = async () => {
    if (parentFolderId) {
      const folder = getFolderById(fileManager, parentFolderId);
      await setParentFolderId(folder.parentFolderId);
      setFolderContent(folder);
    } else {
      await setParentFolderId(null);
      setFolderContent({ content: fileManager });
    }
  };

  const handleNewFolder = () => {
    let tmpFolder;
    const tmpFileManager = JSON.parse(JSON.stringify(fileManager));
    if (!folderContent.folderId) {
      tmpFileManager.push({
        name: 'Neuer Ordner',
        folderId: generateCode(10),
        content: [],
      });
    } else {
      tmpFolder = getFolderById(tmpFileManager, folderContent.folderId);
      tmpFolder.content.push({
        name: 'Neuer Ordner',
        folderId: generateCode(10),
        content: [],
      });
    }
    setDBFileManager(userData.userInfo, tmpFileManager, user.sessionToken);
  };

  const moveFile = (file) => {
    const fileToMove = { file: { ...file }, origin: folderContent.folderId };
    if (!showFileManager) {
      fileToMove.pasteFromList = true;
    }
    setMoveItem(fileToMove);
  };

  const pasteFile = () => {
    const tmpFileManager = JSON.parse(JSON.stringify(fileManager));
    const destinationFolder = folderContent.folderId
      ? getFolderById(tmpFileManager, folderContent.folderId).content
      : tmpFileManager;

    if (!!!folderContent.content.find((item) => item.id === moveItem.file.id)) {
      destinationFolder.push(moveItem.file);

      if (!moveItem.pasteFromList) {
        const originFolder = moveItem.origin
          ? getFolderById(tmpFileManager, moveItem.origin).content
          : tmpFileManager;
        const fileToDeleteIndex = originFolder.findIndex(
          (item) => item.id === moveItem.file.id
        );
        originFolder.splice(fileToDeleteIndex, 1);
      }

      setSelectedFile(undefined);
      setMoveItem(undefined);
      setDBFileManager(userData.userInfo, tmpFileManager, user.sessionToken);
    }
  };

  const removeFolder = (folderId) => {
    const tmpFileManager = JSON.parse(JSON.stringify(fileManager));
    const currentFolder = folderContent.folderId
      ? getFolderById(tmpFileManager, folderContent.folderId)
      : tmpFileManager;
    const folderToDeleteIndex = currentFolder.findIndex(
      (item) => item.folderId === folderId
    );
    currentFolder.splice(folderToDeleteIndex, 1);
    setDBFileManager(userData.userInfo, tmpFileManager, user.sessionToken);
  };

  const handleRenameFolder = (name, folderId) => {
    const tmpFileManager = JSON.parse(JSON.stringify(fileManager));
    const folderToRename = getFolderById(tmpFileManager, folderId);
    folderToRename.name = name;
    setDBFileManager(userData.userInfo, tmpFileManager, user.sessionToken);
  };

  const sortFolderFirst = () => {
    let sortedFoldersFirst = [];
    if (folderContent.content) {
      sortedFoldersFirst = folderContent.content.sort((a, b) => {
        return !!a.id - !!b.id;
      });
    }
    return sortedFoldersFirst;
  };

  return (
    <Container>
      <FileManagementBar
        handleBackToParentFolder={handleBackToParentFolder}
        handleNewFolder={handleNewFolder}
        parentFolderId={parentFolderId}
        pasteFile={pasteFile}
        moveItem={moveItem}
        showFileManager={showFileManager}
        setShowFileManager={setShowFileManager}
        filterFiles={filterFiles}
        reloadFiles={reloadFiles}
        selectedFile={selectedFile}
        handleShare={handleShare}
        userData={userData}
        moveFile={moveFile}
        saveFile={saveFile}
        removeFile={removeFile}
      />
      {showFileManager ? (
        <FileList>
          {folderContent.content &&
            sortFolderFirst().map((file) => (
              <div key={file.id || file.folderId}>
                {file.id ? (
                  <File
                    onClick={() =>
                      setSelectedFile(selectedFile !== file ? file : false)
                    }
                    file={file}
                    selectedFile={selectedFile}
                    userData={userData}
                    onClickShare={(event) => handleShare(event, file)}
                    ref={anchorEl}
                    onClickMenu={(event) => handleMenu(event, file)}
                  />
                ) : (
                  <Folder
                    onClick={() => openFolder(file.folderId)}
                    folder={file}
                    removeFolder={removeFolder}
                    handleRenameFolder={handleRenameFolder}
                  />
                )}
              </div>
            ))}
        </FileList>
      ) : (
        <FileList>
          {filteredFiles.map(
            (file) =>
              file.id && (
                <File
                  onClick={() =>
                    setSelectedFile(selectedFile !== file ? file : false)
                  }
                  selectedFile={selectedFile}
                  file={file}
                  userData={userData}
                  onClickShare={(event) => handleShare(event, file)}
                  ref={anchorEl}
                  onClickMenu={(event) => handleMenu(event, file)}
                />
              )
          )}
        </FileList>
      )}
      <Dialog
        fullWidth
        onClose={() => setOpenMembers(false)}
        open={openMembers}
      >
        <DialogFileMembers
          setOpen={setOpenMembers}
          userData={userData}
          file={selectedFile}
        />
      </Dialog>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => removeFile(selectedFile)}>
          <Delete style={{ marginRight: 15 }} color={'primary'} />
          Löschen
        </MenuItem>
        <MenuItem onClick={() => saveFile(selectedFile)}>
          <Save style={{ marginRight: 15 }} color={'primary'} />
          Speichern
        </MenuItem>
        <MenuItem
          onClick={() => {
            moveFile(selectedFile);
            setShowFileManager(true);
            setAnchorEl(null);
          }}
        >
          <div style={{ marginRight: 15 }}>
            {' '}
            <MoveFolderIcon color={'primary'} />
          </div>
          Verschieben
        </MenuItem>
      </Menu>
    </Container>
  );
};

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const FileList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});
