import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { Card } from '@material-ui/core';

const ItemCard = styled(Card)`
  && {
    padding: 10px;
    margin-bottom: 10px;
    background-color: white;
    font-family: monospace; //Snell Roundhand, cursive;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }
`;

export default (props) => {
  return (
    <Draggable draggableId={props.itemId} index={props.index} data={props.data}>
      {(provided) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {props.data.items[props.itemId].content}
            {provided.placeholder}
          </div>
        );
      }}
    </Draggable>
  );
};
