export const getClientGoals = (goals, system) => {
  const selectedGoals = {};
  const systemMembers =
    (system.members && system.members.map((member) => member.userId)) || [];
  Object.keys(goals).forEach((goalKey) => {
    if (
      systemMembers.includes(goals[goalKey].goalOwner.userId) ||
      (goals[goalKey].members &&
        Object.values(goals[goalKey].members).some((member) =>
          systemMembers.includes(member.userId)
        ))
    ) {
      selectedGoals[goalKey] = goals[goalKey];
    }
  });

  return selectedGoals;
};

export const getPrivateGoals = (goals, clientSystems) => {
  const selectedGoals = {};
  const clients = [];

  clientSystems.forEach((system) =>
    system.members.forEach((member) => clients.push(member.userId))
  );
  Object.keys(goals).forEach((goalKey) => {
    if (!clients.includes(goals[goalKey].goalOwner.userId)) {
      if (
        goals[goalKey].members &&
        !Object.values(goals[goalKey].members).some((member) =>
          clients.includes(member.userId)
        )
      ) {
        selectedGoals[goalKey] = goals[goalKey];
      }
    }
  });
  return selectedGoals;
};
