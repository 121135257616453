import React, { useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { CircularProgress, styled } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { createFile } from '../../services/files';
import { MAX_FILESIZE } from '../../utils/constants';
import CustomSnackbar from '../elements/CustomSnackbar';
import { AuthContext } from '../../state/contexts/AuthContext';
import { DefaultButton } from '../elements/DefaultButton';

//const userData = {userInfo: {userId: "N9Qqw1dufz"}, contacts: [{userId: "dfasdf", userName: "wes"}, {userId: "dfasdfaf", userName: "wefaes"}, {userId: "dfasfasfgevdf", userName: "wfeageaes"}]}

export function DropZone({ reloadFiles, showUpload }) {
  const { userData } = useContext(UserDataContext);
  const { user, isOnline } = useContext(AuthContext);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [uploading, setUploading] = useState();
  const [fileError, setFileError] = useState();

  const replaceInvalidChars = (string) => {
    const regex = /[^a-zA-Z0-9_. ]/g;
    const validString = string.replaceAll(regex, ' _');
    return validString;
  };

  const uploadFiles = () => {
    if (isOnline()) {
      droppedFiles.forEach(async (file) => {
        setUploading(true);
        const result = await createFile(
          userData.userInfo,
          file,
          user.sessionToken
        );
        if (result) {
          setUploading(false);
          setDroppedFiles([]);
          reloadFiles();
        }
      });
    }
  };

  const onDrop = useCallback(
    (droppedFile) => {
      const acceptedFiles = [...droppedFiles];
      droppedFile.forEach((file) => {
        if (file.size > MAX_FILESIZE) {
          setFileError('Datei ist zu groß (max. 10MB)');
          return;
        }
        const renamedFile = new File([file], replaceInvalidChars(file.name), {
          type: file.type,
        });
        //TODO: define valid file types
        /*if(file.type.includes('audio')){
                acceptedFiles.push(file)
            }*/
        acceptedFiles.push(renamedFile);
      });
      setDroppedFiles(acceptedFiles);
    },
    [droppedFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div>
      {showUpload && (
        <Container>
          <DropContainer {...getRootProps()}>
            <input {...getInputProps()} />
            {droppedFiles.map((song, index) => {
              return <StyledPaper key={index}>{song.name}</StyledPaper>;
            })}
            {uploading && <CircularProgress style={{ margin: 10 }} />}
            {isDragActive ? (
              <p>Datei hier ablegen</p>
            ) : (
              <p>Datei ablegen oder klicken um auszuwählen</p>
            )}
          </DropContainer>
          <DefaultButton
            style={{ width: '100%' }}
            disabled={droppedFiles.length === 0}
            onClick={uploadFiles}
          >
            Hochladen
          </DefaultButton>
        </Container>
      )}
      {fileError && (
        <CustomSnackbar
          setSuccessfullySaved={setFileError}
          type={'error'}
          text={fileError}
        />
      )}
    </div>
  );
}

const Container = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.secondary.main,
  padding: 10,
}));

const StyledPaper = styled(Paper)({
  padding: 10,
  margin: 5,
});

const DropContainer = styled('div')(({ theme }) => ({
  width: '100%',
  marginBottom: 5,
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  minHeight: 300,
}));
