import { dummyDataBenefits } from '../elements/dummyDataBenefits';
import React, { useContext, useState } from 'react';
import { Collapse, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {
  BeachAccess,
  Build,
  Eco,
  ExpandMore,
  Security,
} from '@material-ui/icons';
import Rating from '@material-ui/lab/Rating';
import ChipInput from 'material-ui-chip-input';
import styled from 'styled-components';
import Character from '../../res/milkmocha/milkmocha_food.gif';
import { CustomAspects } from './CustomAspects';
import { useTheme } from '@material-ui/core/styles';
import { Divider } from '../elements/Divider';
import { UserDataContext } from '../../state/contexts/UserDataContext';

/*const useStyles = makeStyles({
    root: {
        '&.MuiChip-root': {
            height: 12,
        },
    }
});*/

const icons = [
  { large: <BeachAccess fontSize='large' />, normal: <BeachAccess /> },
  { large: <Security fontSize='large' />, normal: <Security /> },
  { large: <Eco fontSize='large' />, normal: <Eco /> },
  { large: <Build fontSize='large' />, normal: <Build /> },
];

const getIcon = (index, size = null) => {
  return icons[index][size];
};

const ratingColors = ['#ff8b3d', '#ff8b3d', '#ff8b3d', '#ff8b3d']; //'#3dd2ff', '#ff3d47', '#61c74e']

export const Benefits = ({
  goal,
  handleBenefitChange,
  handleCustomAspectChange,
}) => {
  const { userData } = useContext(UserDataContext);
  const [values, setValues] = useState(goal.benefits || dummyDataBenefits);
  const [collapse, setCollapse] = useState();
  const theme = useTheme();

  const handleFacetChange = (value, benefitIndex, facetindex) => {
    const tempValues = [...values];
    tempValues[benefitIndex].facets[facetindex].value = parseInt(value);
    setValues(tempValues);
    handleBenefitChange(tempValues);
  };

  const showGIFs = userData && userData.settings && userData.settings.cutify;

  const getAreaAverage = (benefitIndex) => {
    return (
      values[benefitIndex].facets.reduce((a, b) => ({
        value: a.value + b.value,
      })).value / values[benefitIndex].facets.length
    );
    //return values[benefitIndex].facets.reduce((a, b) => a.value + b.value, {value: 0}) / values[benefitIndex].facets.length
  };

  const handleCollapse = (collapse) => {
    setCollapse(collapse);
  };

  const handleDeleteChip = (benefitIndex, chip, index) => {
    const tempValues = [...values];
    values[benefitIndex].custom = values[benefitIndex].custom.filter(
      (item, chipIndex) => index !== chipIndex
    );
    setValues(tempValues);
    handleBenefitChange(tempValues);
  };

  const handleAddChip = (benefitIndex, chip) => {
    const tempValues = [...values];
    if (!tempValues[benefitIndex].custom) {
      tempValues[benefitIndex].custom = [];
    }
    tempValues[benefitIndex].custom.push(chip);
    setValues(tempValues);
    handleBenefitChange(tempValues);
  };

  return (
    <div
      style={{
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        maxWidth: 500,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}
        >
          <div
            style={{
              borderRadius: 5,
              width: '100%',
              border: `1px solid ${theme.palette.primary.light}`,
              padding: 10,
              margin: 10,
              textAlign: 'center',
              backgroundColor: theme.palette.primary.midlight,
            }}
          >
            <div style={{ lineHeight: 1.2 }}>
              Dein Ziel schaffst du dann, wenn du ganz genau weißt, wofür du es
              tust. Nutze folgendes wissenschaftliche Raster: Was bringt es dir,
              wenn du das Ziel erreichst?
            </div>
          </div>
          {showGIFs && (
            <div
              style={{
                //marginLeft: 'auto',
                width: 100,
                height: 100,
                backgroundImage: `url(${Character})`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
              }}
            />
          )}
        </div>
      </div>
      {values.map((benefit, benefitIndex) => (
        <React.Fragment key={benefit.area}>
          <div
            onClick={() =>
              handleCollapse(collapse === benefit.area ? false : benefit.area)
            }
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              borderRadius: 10,
              border: '1px solid',
              borderColor: theme.palette.primary.light,
              marginTop: 10,
              paddingLeft: 10,
            }}
          >
            <Typography
              variant={'subtitle1'}
              style={{
                marginRight: 10,
                width: 150,
                fontWeight: 'bold',
                color: ratingColors[benefitIndex],
              }}
            >
              {benefit.area}
            </Typography>
            <StyledRating
              color={ratingColors[benefitIndex]}
              emptyColor={theme.palette.primary.light}
              readOnly
              value={getAreaAverage(benefitIndex)}
              precision={0.5}
              emptyIcon={getIcon(benefitIndex, 'large')}
              icon={getIcon(benefitIndex, 'large')}
            />
            <IconButton>
              <ExpandMore />
            </IconButton>
          </div>
          <Collapse
            in={collapse === benefit.area}
            timeout='auto'
            unmountOnExit
            style={{ width: '100%' }}
          >
            <div
              style={{
                display: 'flex',
                height: '200px',
                width: '100%',
                justifyContent: 'space-evenly',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                }}
              >
                {benefit.facets.map((facet) => (
                  <Typography
                    key={facet.name}
                    style={{
                      marginRight: 20,
                      color: ratingColors[benefitIndex],
                    }}
                    variant='subtitle2'
                  >
                    {facet.name}
                  </Typography>
                ))}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                }}
              >
                {benefit.facets.map((facet, facetIndex) => (
                  <StyledRating
                    key={facet.name}
                    color={ratingColors[benefitIndex]}
                    emptyColor={theme.palette.primary.light}
                    value={values[benefitIndex].facets[facetIndex].value}
                    precision={0.5}
                    onChange={(event) =>
                      handleFacetChange(
                        event.target.value,
                        benefitIndex,
                        facetIndex
                      )
                    }
                    icon={getIcon(benefitIndex, 'normal')}
                  />
                ))}
              </div>
            </div>
            {/*<StyledChipInput
              placeholder={'Was bringt es noch?'}
              alwaysShowPlaceholder
              helperText={'Drücke Enter, wenn du fertig bist'}
              disableUnderline={true}
              variant={'outlined'}
              value={values[benefitIndex].custom || []}
              onAdd={(chip) => handleAddChip(benefitIndex, chip)}
              onDelete={(chip, index) =>
                handleDeleteChip(benefitIndex, chip, index)
              }
            />*/}
          </Collapse>
        </React.Fragment>
      ))}
      <Divider marginLR={10} color={theme.palette.primary.light} />
      <div
        style={{
          borderRadius: 5,
          border: `1px solid ${theme.palette.primary.light}`,
          padding: 10,
          margin: 10,
          marginBottom: 20,
          textAlign: 'center',
          backgroundColor: theme.palette.primary.midlight,
        }}
      >
        <div style={{ lineHeight: 1.2 }}>
          ...und noch eher schaffst du dein Ziel, wenn du individuelle Vorteile
          ergänzt.
        </div>
      </div>
      <CustomAspects
        goal={goal}
        handleCustomAspectChange={handleCustomAspectChange}
      />
    </div>
  );
};

const StyledChipInput = styled(ChipInput)`
  && {
    .MuiChip-root {
      height: 26px;
    }
    ,
    .MuiFormControl-root {
      margin-bottom: 20px;
      width: 100%;
    }
  }
`;

//TODO: strange: first object before .MuiRating-root is necessary - why?
const StyledRating = styled(Rating)`
  && {
     {
    }
    ,
    .MuiRating-root {
      color: ${(props) => props.color};
    }
    ,
    .MuiRating-iconEmpty {
      color: ${(props) => props.emptyColor};
    }
    ,
    .MuiRating-iconFilled {
      color: ${(props) => props.color};
    }
  }
`;
