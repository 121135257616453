import { Button, Card, LinearProgress } from '@material-ui/core';
import { ValueSlider } from './ValueSlider';
import { useContext, useState } from 'react';
import React from 'react';
import { UserDataContext } from '../../../state/contexts/UserDataContext';
import { AuthContext } from '../../../state/contexts/AuthContext';

export const ValueSurvey = ({ valuesObject, setValues, setFinished }) => {
  const { userData, setUserValues } = useContext(UserDataContext);
  const { user } = useContext(AuthContext);
  const [tabIndex, setTabIndex] = useState({ min: 0, max: 4 });

  const onClickNext = () => {
    if (tabIndex.max <= 18) {
      setUserValues(userData.userInfo, valuesObject, user.sessionToken);
      window.scrollTo(0, 0);
      const range = {
        min: tabIndex.min + 4,
        max: tabIndex.max + 4,
      };
      setTabIndex(range);
    } else {
      setUserValues(userData.userInfo, valuesObject, user.sessionToken, true);
      setFinished(true);
    }
  };

  const onClickBack = () => {
    setUserValues(userData.userInfo, valuesObject, user.sessionToken);

    if (tabIndex.min - 4 >= 0) {
      window.scrollTo(0, 0);
      const range = {
        min: tabIndex.min - 4,
        max: tabIndex.max - 4,
      };
      setTabIndex(range);
    }
  };

  const onChange = (index, sliderType, value) => {
    const tmpValues = [...valuesObject.values];
    tmpValues[index][sliderType] = value;
    setValues(tmpValues);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: 10,
        overflow: 'hidden',
      }}
    >
      <div style={{ fontWeight: 'bold', fontSize: 14 }}>
        Welcher Typ bist du - und deine Freunde?
      </div>
      <div style={{ fontSize: 12 }}>
        Fülle den Fragebogen aus und wir ermitteln für dich, was du wirklich
        willst, wo du stehst und in welchen Bereichen du dir ein Ziel setzen
        könntest. Außerdem kannst du dein Ergebnis mit dem deiner Freunde
        vergleichen.
      </div>
      <LinearProgress
        style={{ height: 7, marginBottom: 10, marginTop: 20 }}
        variant='determinate'
        value={((tabIndex.max - 1) / valuesObject.values.length) * 100}
      />
      {valuesObject.values.map((item, index) => {
        if (index >= tabIndex.min && index < tabIndex.max) {
          return (
            <Card
              key={item.question}
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 10,
                padding: 15,
                backgroundColor: 'rgba(255,204,157,0.07)',
              }}
            >
              <div style={{ fontSize: 14 }}>{item.question}</div>
              <div
                style={{
                  fontSize: 12,
                  marginTop: 10,
                  alignSelf: 'flex-start',
                  marginBottom: -10,
                }}
              >
                ...ist mir wichtig.
              </div>
              <ValueSlider
                sliderType={'desire'}
                index={index}
                itemValue={item.desire}
                onChangeCommitted={onChange}
              />
              <div
                style={{
                  fontSize: 12,
                  marginTop: 10,
                  alignSelf: 'flex-start',
                  marginBottom: -10,
                }}
              >
                ...trifft im Moment zu.
              </div>
              <ValueSlider
                sliderType={'belief'}
                index={index}
                itemValue={item.belief}
                onChangeCommitted={onChange}
              />
            </Card>
          );
        } else {
          return null;
        }
      })}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant={'contained'} color={'secondary'} onClick={onClickBack}>
          Zurück
        </Button>
        <Button variant={'contained'} color={'primary'} onClick={onClickNext}>
          {tabIndex.max <= 18 ? 'Weiter' : 'Fertig'}
        </Button>
      </div>
    </div>
  );
};
