import { Chat } from 'services/chat';

const props = {
  OWNER: 'owner',
  MEMBERS: 'members',
  NAME: 'name',
  UPDATED: 'updatedAt',
  LAST_MESSAGE: 'lastMessage',
};
const propsArray = Object.values(props);

export function mapApiToChatModel(apiObject) {
  return propsArray.reduce(
    (model, mapEl) => {
      model[mapEl] = apiObject.get(mapEl);
      return model;
    },
    {
      id: apiObject.id,
    }
  );
}

export function mapFileModelToApi(modelObject) {
  const chat = new Chat();

  propsArray.forEach((prop) => {
    chat.set(prop, modelObject[prop]);
  });
  chat.id = modelObject.id;

  return chat;
}
