import React, { createContext, useEffect, useRef, useState } from 'react';
import {
  fetchAddClientSystemCounselor,
  fetchClientSystems,
  fetchDeleteClientSystem,
  fetchGetClientOrganizationMembers,
  fetchSaveClientSystemDocumention,
  fetchSetClientSystemCaseId,
  fetchSubscribeClientData,
  fetchUnsubscribeClientData,
  fetchUpdateClientSystemMembers,
} from '../../services/clients';
import { mapApiToClientSystemModel } from '../../models/ClientModel';
import { events } from '../../utils/constants';

export const ClientsContext = createContext({});

const ClientsContextProvider = (props) => {
  const { children } = props;

  const subscription = useRef();
  const [updatedSystem, setUpdatedSystem] = useState();
  const [clientSystems, setClientSystems] = useState();

  useEffect(() => {
    if (updatedSystem && clientSystems) {
      const tmpSystems = [...clientSystems];
      const index = tmpSystems.findIndex(
        (system) => system.systemId === updatedSystem.system.systemId
      );
      if (updatedSystem.event === events.UPDATE) {
        tmpSystems[index] = updatedSystem.system;
      } else if (updatedSystem.event === events.ADD) {
        if (index === -1) {
          tmpSystems.push(updatedSystem.system);
        }
      } else if (updatedSystem.event === events.REMOVE) {
        if (index !== -1) {
          tmpSystems.splice(index, 1);
        }
      }
      setClientSystems(tmpSystems);
    }
  }, [updatedSystem]);

  const handleAsync = (eventObject) => {
    const { object, event } = eventObject;
    setUpdatedSystem({
      system: mapApiToClientSystemModel(object),
      event: event,
    });
  };

  const subscribeClientData = async (userId, sessionToken) => {
    subscription.current = await fetchSubscribeClientData(
      userId,
      handleAsync,
      sessionToken
    );
  };

  const unsubscribeClientData = () => {
    if (subscription.current) {
      fetchUnsubscribeClientData(subscription.current);
      console.info('successfully unsubscribed clients');
    }
  };

  const getClients = async (userInfo, sessionToken) => {
    const fetchedSystems = await fetchClientSystems(userInfo, sessionToken);
    const systems = fetchedSystems.map((system) =>
      mapApiToClientSystemModel(system)
    );
    await subscribeClientData(userInfo.userId, sessionToken);
    setClientSystems(systems);
    return systems;
  };

  const deleteClientSystem = async (systemId, sessionToken) => {
    fetchDeleteClientSystem(systemId, sessionToken);
  };

  const saveClientSystemDocumentation = (
    userId,
    systemId,
    documentation,
    sessionToken
  ) => {
    return fetchSaveClientSystemDocumention(
      userId,
      systemId,
      documentation,
      sessionToken
    );
  };

  const getClientOrganizationMembers = (organizationId, sessionToken) => {
    return fetchGetClientOrganizationMembers(organizationId, sessionToken);
  };

  const addClientSystemCounselor = async (
    counselor,
    systemId,
    sessionToken
  ) => {
    return await fetchAddClientSystemCounselor(
      counselor,
      systemId,
      sessionToken
    );
  };

  const updateClientSystemMembers = async (
    members,
    systemId,
    userInfo,
    sessionToken
  ) => {
    return await fetchUpdateClientSystemMembers(
      members,
      systemId,
      userInfo,
      sessionToken
    );
  };

  const setClientSystemCaseId = async (
    systemId,
    userInfo,
    sessionToken,
    caseId
  ) => {
    return await fetchSetClientSystemCaseId(
      systemId,
      userInfo,
      sessionToken,
      caseId
    );
  };

  return (
    <ClientsContext.Provider
      value={{
        getClients,
        clientSystems,
        updatedSystem,
        subscribeClientData,
        unsubscribeClientData,
        deleteClientSystem,
        saveClientSystemDocumentation,
        getClientOrganizationMembers,
        addClientSystemCounselor,
        updateClientSystemMembers,
        setClientSystemCaseId,
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};

export default ClientsContextProvider;
