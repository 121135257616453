import React from 'react';

export default ({ fill = 'orange' }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <svg
        x='0px'
        y='0px'
        width={40}
        height={40}
        viewBox='0 0 295.996 295.996'
        fill={fill}
      >
        <path
          d='M98.497,147.128c4.39,0,8.364-1.771,11.255-4.634c2.929-2.899,4.745-6.92,4.745-11.366c0-8.836-7.164-16-16-16
		s-16,7.164-16,16c0,2.079,0.409,4.059,1.131,5.882C85.974,142.933,91.74,147.128,98.497,147.128z'
        />
        <path
          d='M212.366,137.01c0.722-1.822,1.131-3.803,1.131-5.882c0-8.836-7.164-16-16-16c-8.836,0-16,7.164-16,16
		c0,4.446,1.816,8.467,4.745,11.367c2.891,2.862,6.865,4.633,11.255,4.633C204.254,147.128,210.02,142.933,212.366,137.01z'
        />
        <rect x='114.664' y='206.127' width='66' height='16' />
        <path
          d='M296.256,76.269c0-9.527-5.948-21.439-18.184-36.416c-8.415-10.3-16.722-18.2-17.07-18.531l-5.504-5.217l-5.503,5.216
		c-0.254,0.24-4.698,4.471-10.327,10.7c-25.226-19.959-57.08-31.891-91.67-31.891C66.392,0.13,0,66.521,0,148.128
		c0,81.606,66.392,147.998,147.998,147.998s147.998-66.392,147.998-147.998c0-16.614-2.753-32.599-7.825-47.52
		C293.247,93.812,296.256,85.385,296.256,76.269z M279.996,148.128c0,72.784-59.214,131.998-131.998,131.998
		c-36.256,0-69.143-14.696-93.022-38.44c-9.536-9.482-17.631-20.41-23.934-32.42C21.442,190.977,16,170.178,16,148.128
		C16,75.344,75.214,16.13,147.998,16.13c30.694,0,58.975,10.531,81.419,28.169c-0.454,0.596-0.9,1.187-1.333,1.771
		c-8.959,12.102-13.346,22.03-13.346,30.198c0,22.475,18.285,40.76,40.76,40.76c5.347,0,10.451-1.046,15.134-2.925
		c1.496-0.602,2.952-1.279,4.354-2.047C278.249,123.526,279.996,135.627,279.996,148.128z M255.498,101.028
		c-13.652,0-24.76-11.107-24.76-24.76c0-8.673,13.101-25.674,24.763-37.847c11.659,12.161,24.755,29.153,24.755,37.847
		C280.256,89.921,269.149,101.028,255.498,101.028z'
        />
      </svg>
    </div>
  );
};
