import React from 'react';
import { styled, Typography } from '@material-ui/core';
import { Close, MoreVert } from '@material-ui/icons';

export const DialogAddToHomescreen = ({ setOpen }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        padding: 20,
      }}
    >
      <Close
        color={'primary'}
        style={{ marginLeft: 'auto' }}
        onClick={() => setOpen(false)}
      />
      <Typography variant={'h6'} style={{ marginBottom: 20 }}>
        Installiere iuvivo als App
      </Typography>
      <Block>
        <Typography style={{ marginRight: 10 }} variant={'body1'}>
          1.{' '}
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            style={{ display: 'flex', alignItems: 'center' }}
            variant={'body1'}
          >
            Finde die die Option "Zum Homescreen/Homebildschirm hinzufügen" in
            der Adressleiste oder Menü deines Browsers und wähle sie aus.
            {/*<span>Tippe in deinem Browser oben rechts auf die drei Punkte</span>{' '}
            <MoreVert />*/}
          </Typography>
        </div>
      </Block>
      <Block style={{ display: 'flex' }}>
        <Typography style={{ marginRight: 10 }} variant={'body1'}>
          2.{' '}
        </Typography>
        <Typography variant={'body1'}>
          Fertig! Du kannst iuvivo jetzt als App nutzen.
          {/*Wähle "Zum Startbildschirm bzw. Homescreen hinzufügen"*/}
        </Typography>
      </Block>
      {/*<Block style={{ display: 'flex' }}>
        <Typography style={{ marginRight: 10 }} variant={'body1'}>
          3.{' '}
        </Typography>
        <Typography variant={'body1'}>
          Fertig! Du kannst iuvivo nun als App verwenden
        </Typography>
      </Block>*/}
    </div>
  );
};

const Block = styled('div')({
  display: 'flex',
  marginTop: 10,
});
