import React, { useContext, useEffect, useState } from 'react';
import { GainParticles } from '../components/elements/GainParticles';
import { styled } from '@material-ui/core';
import { BeachAccess, Build, Eco, Security } from '@material-ui/icons';
import { UserDataContext } from '../state/contexts/UserDataContext';
import { Rooms } from './Rooms';

export const Favorite = () => {
  const { userData, setDBGoalPoints } = useContext(UserDataContext);
  const [goalArchive, setGoalArchive] = useState();
  const [goalPoints, setGoalPoints] = useState();
  const [points, setPoints] = useState([0, 0, 0, 0]);
  const [openGoals, setOpenGoals] = useState();
  const [finishedCollecting, setFinishedCollecting] = useState([
    false,
    false,
    false,
    false,
  ]);
  const [showReadyForNextGoal, setShowReadyForNextGoal] = useState();

  useEffect(() => {
    if (userData) {
      if (userData.goalPoints) {
        setGoalPoints(userData.goalPoints);
      }
      if (userData.goalArchive) {
        setGoalArchive(userData.goalArchive);
      }
    }
  }, [userData]);

  useEffect(() => {
    if (goalPoints) {
      const goalValues = Object.values(goalPoints).map((goal) => {
        return {
          id: goal.open.id,
          name: goal.open.title,
          values: goal.open.benefits.map((area) => {
            return getAreaAverage(area);
          }),
        };
      });
      setOpenGoals(goalValues);
    }
  }, [goalPoints]);

  useEffect(() => {
    if (goalArchive) {
      const goalValues = Object.values(goalArchive).map((goal) =>
        goal.collected
          ? goal.benefits.map((area) => {
              return getAreaAverage(area);
            })
          : [0, 0, 0, 0]
      );
      const points = sumPoints(goalValues);
      setPoints(points);
    }
  }, [goalArchive]);

  useEffect(() => {
    if (openGoals && openGoals.length > 0) {
      setShowReadyForNextGoal(false);
      const finishedDimensions = [...finishedCollecting];
      openGoals[0].values.forEach((value, index) => {
        if (value === 0) {
          finishedDimensions[index] = true;
          //finishCollecting(index)
        }
      });
      setFinishedCollecting(finishedDimensions);
    }
  }, [openGoals]);

  const openPoints = () => {
    if (goalPoints) {
      const goalValues = Object.values(goalPoints).map((goal) => {
        return {
          id: goal.open.id,
          name: goal.open.title,
          values: goal.open.benefits.map((area) => {
            return getAreaAverage(area);
          }),
        };
      });
      return goalValues;
    }
  };

  const collectedPoints = () => {
    if (goalPoints) {
      const goalValues = Object.values(goalPoints).map((goal) =>
        goal.collected
          ? goal.collected.benefits.map((area) => {
              return getAreaAverage(area);
            })
          : [0, 0, 0, 0]
      );
      return sumPoints(goalValues);
    }
  };

  const sumPoints = (goalValues) => {
    const values = [0, 0, 0, 0];
    goalValues.forEach((goal) => {
      values[0] += goal[0] * 250;
      values[1] += goal[1] * 250;
      values[2] += goal[2] * 250;
      values[3] += goal[3] * 250;
    });
    return values;
  };

  const getAreaAverage = (area) => {
    return (
      area.facets.reduce((a, b) => ({
        value: a.value + b.value,
      })).value / area.facets.length
    );
  };

  const finishCollecting = (dimension) => {
    const finishedDimensions = [...finishedCollecting];
    finishedDimensions[dimension] = true;
    const allFinished = !finishedDimensions.includes(false);
    if (allFinished) {
      setShowReadyForNextGoal(true);
    } else {
      setFinishedCollecting(finishedDimensions);
    }
  };

  const handleNextGoal = () => {
    const remainingGoals = [...openGoals];
    /*remainingGoals.shift()
        setOpenGoals(remainingGoals)*/
    setDBGoalPoints(userData.userInfo, openGoals[0]);
  };

  const handleCollect = (dimension, addPoints, finish) => {
    const newValues = [...points];
    newValues[dimension] = points[dimension] + addPoints * 50;
    setPoints(newValues);
    if (finish) {
      finishCollecting(dimension);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <PointsCircle>{icons[0].normal}</PointsCircle>
          {points[0]}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <PointsCircle>{icons[1].normal}</PointsCircle>
          {points[1]}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <PointsCircle>{icons[2].normal}</PointsCircle>
          {points[2]}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <PointsCircle>{icons[3].normal}</PointsCircle>
          {points[3]}
        </div>
      </div>

      <Rooms collect={20} />

      {openGoals && openGoals.length > 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            marginTop: 'auto',
            marginBottom: 15,
          }}
        >
          <div>Sammle deine Punkte für: {openGoals[0].name}</div>
          <div style={{ display: 'flex' }}>
            <GainParticles
              icon={icons[0].small}
              value={openGoals[0].values[0]}
              dimension={0}
              collect={handleCollect}
            >
              <Circle>{icons[0].normal}</Circle>
            </GainParticles>
            <GainParticles
              icon={icons[1].small}
              value={openGoals[0].values[1]}
              dimension={1}
              collect={handleCollect}
            >
              <Circle>{icons[1].normal}</Circle>
            </GainParticles>
            <GainParticles
              icon={icons[2].small}
              value={openGoals[0].values[2]}
              dimension={2}
              collect={handleCollect}
            >
              <Circle>{icons[2].normal}</Circle>
            </GainParticles>
            <GainParticles
              icon={icons[3].small}
              value={openGoals[0].values[3]}
              dimension={3}
              collect={handleCollect}
            >
              <Circle>{icons[3].normal}</Circle>
            </GainParticles>
            <NextGoalCircle
              nextGoal={showReadyForNextGoal}
              style={{ opacity: showReadyForNextGoal ? 1 : 0.4 }}
              onClick={() =>
                showReadyForNextGoal ? handleNextGoal() : undefined
              }
            >
              Fertig
            </NextGoalCircle>
          </div>
        </div>
      )}
      {/*<GoalArchive goals={goalArchive} />*/}
    </div>
  );
};

const PointsCircle = styled('div')(({ theme, img }) => ({
  margin: 5,
  width: 40,
  height: 40,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: theme.palette.primary.main,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  color: 'orange', //theme.palette.primary.main,
}));

const Circle = styled('div')(({ theme }) => ({
  margin: 5,
  width: 50,
  height: 50,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: theme.palette.primary.main,
  borderRadius: '50%',
  boxShadow: '2px 5px 8px 2px #888888',
  '&:active': {
    boxShadow: '2px 1px 4px 1px #888888',
  },
  //backgroundColor: theme.palette.secondary.light,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  color: 'orange', //theme.palette.primary.main,
}));

const NextGoalCircle = styled('div')(({ theme, nextGoal }) => ({
  margin: 5,
  width: 50,
  height: 50,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: theme.palette.primary.main,
  backgroundColor: 'orange',
  borderRadius: '50%',
  boxShadow: nextGoal ? '2px 5px 8px 2px #888888' : undefined,
  '&:active': {
    boxShadow: nextGoal ? '2px 1px 4px 1px #888888' : undefined,
  },
  //backgroundColor: theme.palette.secondary.light,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  color: 'white', //theme.palette.primary.main,
}));

const icons = [
  {
    small: <BeachAccess fontSize='small' />,
    normal: <BeachAccess fontSize='large' />,
  },
  {
    small: <Security fontSize='small' />,
    normal: <Security fontSize='large' />,
  },
  { small: <Eco fontSize='small' />, normal: <Eco fontSize='large' /> },
  { small: <Build fontSize='small' />, normal: <Build fontSize='large' /> },
];
