import React from 'react';
import { Button, Typography, useTheme } from '@material-ui/core';
import { Link } from './Link';

export const Restricted = () => {
  return (
    <div
      style={{
        height: '80vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: 20,
      }}
    >
      <Typography variant={'h6'} color={'primary'}>
        Upgrade erforderlich
      </Typography>

      <Typography variant={'body2'} color={'primary'}>
        Um diese Funktion zu nutzen, benötigst du die Vollversion von iuvivo.
      </Typography>
      <br />
      <Button
        onClick={() => {
          window.location = 'mailto:info@iuvivo.de';
        }}
        variant={'contained'}
        color={'primary'}
        style={{ marginTop: 15, width: '100%' }}
      >
        Upgrade anfordern
      </Button>
    </div>
  );
};
