import { IconButton, Menu, MenuItem, Paper } from '@material-ui/core';
import {
  Delete,
  Folder as FolderIcon,
  MoreVert,
  OpenWith,
  Save,
  TextFields,
} from '@material-ui/icons';
import React, { useState } from 'react';
import { DialogFileMembers } from './DialogFileMembers';
import Dialog from '@material-ui/core/Dialog';
import { DialogRenameFolder } from './DialogRenameFolder';

export const Folder = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState();

  const handleMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
      <Paper
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: 10,
          padding: 10,
        }}
        onClick={props.onClick}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FolderIcon color={'primary'} style={{ marginRight: 10 }} />
          {props.folder.name}
        </div>
        <IconButton onClick={handleMenu}>
          <MoreVert />
        </IconButton>
      </Paper>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => props.removeFolder(props.folder.folderId)}>
          <Delete style={{ marginRight: 15 }} color={'primary'} />
          Löschen
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDialog(true);
            setAnchorEl(null);
          }}
        >
          <TextFields style={{ marginRight: 15 }} color={'primary'} />
          Umbenennen
        </MenuItem>
      </Menu>
      <Dialog fullWidth onClose={() => setOpenDialog(false)} open={openDialog}>
        <DialogRenameFolder
          setOpen={setOpenDialog}
          handleRenameFolder={props.handleRenameFolder}
          folder={props.folder}
        />
      </Dialog>
    </div>
  );
};
