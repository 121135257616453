import React, { useContext, useState } from 'react';
import { GoalsContext } from 'state/contexts/GoalsContext';

import EmptyGoals from 'components/Goals/EmptyGoals';
import GoalsContainer from 'components/Goals/GoalsContainer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import { styled } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { GoalArchive } from './GoalArchive';
import { GoalsSupported } from './GoalsSupported';
import { Close, Search } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';

export const Main = ({ system }) => {
  const theme = useTheme();
  const { goals, filterGoals } = useContext(GoalsContext);
  const [tab, setTab] = useState(0);
  const [filterTerm, setFilterTerm] = useState();
  const [toggleFilter, setToggleFilter] = useState();

  const handleFilter = (searchString) => {
    filterGoals(searchString);
    setFilterTerm(searchString);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <AppBar
        position='static'
        style={{ background: theme.palette.primary.baseGradient }}
        elevation={0}
      >
        <Tabs
          value={tab}
          onChange={(event, value) => setTab(value)}
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.palette.primary.indicator,
              height: 3,
            },
          }}
          variant='fullWidth'
        >
          <Tab label='Ziele' />
          <Tab label='Mitwisser' />
          <Tab label='Erfolge' />
        </Tabs>
      </AppBar>
      {tab === 0 &&
        (goals && Object.keys(goals).length > 0 ? (
          <div>
            <div style={{ display: 'flex' }}>
              {toggleFilter ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: 'auto',
                    marginLeft: 10,
                    marginTop: 2,
                  }}
                >
                  <StyledInput
                    placeholder='Filter'
                    value={filterTerm}
                    onChange={(ev) => handleFilter(ev.target.value)}
                  />
                  <Close
                    color={'primary'}
                    value={filterTerm}
                    onClick={() => {
                      setToggleFilter(false);
                      handleFilter('');
                    }}
                  />
                </div>
              ) : (
                <IconButton
                  color={'primary'}
                  style={{
                    marginRight: 'auto',
                    paddingTop: 6,
                    paddingLeft: 10,
                    paddingBottom: 0,
                  }}
                  variant={'outlined'}
                  onClick={() => setToggleFilter(true)}
                >
                  <Search />
                </IconButton>
              )}
            </div>
            <GoalsContainer system={system} />
          </div>
        ) : (
          <EmptyGoals system={system} />
        ))}
      {tab === 1 && <GoalsSupported system={system} />}
      {tab === 2 && <GoalArchive system={system} />}
    </div>
  );
};

const StyledInput = styled('input')(({ theme }) => ({
  fontSize: 14,
  borderColor: theme.palette.primary.light,
  borderRadius: 5,
  borderStyle: 'solid',
  borderWidth: '1px',
  padding: 5,
  '&:focus': {
    outline: 'none',
    borderColor: theme.palette.primary.main,
  },
}));
