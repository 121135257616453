import { useTheme } from '@material-ui/core/styles';
import { EditTwoTone } from '@material-ui/icons';
import React from 'react';

export const ButtonEditCircle = ({ fontSize, size }) => {
  return (
    <EditTwoTone
      style={{
        fontSize,
        width: size,
        height: size,
        color: useTheme().palette.primary.main,
        borderRadius: '50%',
        border: '1px solid',
        padding: 2,
      }}
    />
  );
};
