import React, { useContext, useEffect, useState } from 'react';
import { Button, styled, TextField, Typography } from '@material-ui/core';
import { Close, MoreVert } from '@material-ui/icons';
import { AuthContext } from '../../state/contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import { UserDataContext } from '../../state/contexts/UserDataContext';

export const DialogDeactivateAccount = ({ setOpen }) => {
  let history = useHistory();
  const { user, deleteUser, setUser } = useContext(AuthContext);
  const { logout, setUserData } = useContext(UserDataContext);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const [userId, setUserId] = useState();

  useEffect(() => {
    setUserId(user.userId);
  }, []);

  const deleteAccount = async () => {
    setPassword('');
    setErrorMessage('');
    const deleteResponse = await deleteUser(userId, password);
    if (deleteResponse.ok) {
      setUser(undefined);
      setUserData(null);
      history.push('/');
    } else {
      setErrorMessage(deleteResponse);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        padding: 20,
        borderColor: 'red',
        borderStyle: 'solid',
        borderWidth: 5,
        textAlign: 'center',
      }}
    >
      <Close
        color={'primary'}
        style={{ marginLeft: 'auto' }}
        onClick={() => setOpen(false)}
      />
      <Typography variant={'h6'} style={{ color: 'red', marginBottom: 20 }}>
        Konto löschen
      </Typography>
      <Block>
        <Typography variant={'body2'} style={{ marginRight: 10 }}>
          Hierdurch wird dein iuvivo-Konto endgültig gelöscht.
          <br />
          Wenn du dein Konto wirklich löschen möchtest, gib dein iuvivo-Passwort
          ein.
        </Typography>
        <TextField
          value={password}
          placeholder='Passwort'
          margin={'normal'}
          variant={'outlined'}
          onChange={(event) => setPassword(event.target.value)}
        />
        {password && (
          <b style={{ color: 'red', margin: 10 }}>
            DIESER SCHRITT KANN NICHT RÜCKGÄNGIG GEMACHT WERDEN! WIRKLICH
            LÖSCHEN?
          </b>
        )}
        <Button
          disabled={password === ''}
          variant={'outlined'}
          style={{
            margin: 15,
            maxWidth: 300,
            color: 'white',
            backgroundColor: password === '' ? 'lightgray' : 'red',
          }}
          onClick={deleteAccount}
        >
          Löschen
        </Button>
        {errorMessage && (
          <div>
            Die Löschung war leider nicht erfolgreich. Bitte kontaktiere den
            Support.
            <br />
            Die Fehlermeldung lautet: {errorMessage}
          </div>
        )}
      </Block>
    </div>
  );
};

const Block = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 10,
  alignItems: 'center',
});
