import Parse from '../utils/initParse';

export const fetchSendNotification = async (
  sendToUserId,
  { messageText, messageSender },
  type,
  sessionToken
) => {
  const sent = await Parse.Cloud.run(
    'sendMessage',
    {
      sendToUserId: sendToUserId,
      messageText: messageText,
      messageSender: messageSender,
      chatId: 'noChat',
      type: type,
    },
    { sessionToken }
  );
};
