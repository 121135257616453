import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import Slider from '@material-ui/core/Slider';
import HappyMoodIcon from '../../../res/emotions/HappyMoodIcon';
import VeryHappyMoodIcon from '../../../res/emotions/VeryHappyMoodIcon';
import coin from '../../../res/medal.svg';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core';

export const Histogram = ({ data, index, setDay, setIndex }) => {
  const img = new Image();
  img.src = coin;
  img.width = 50;
  img.height = 50;
  let lineChart = useRef();
  let myChart = lineChart.current ? lineChart.current.chartInstance : null;
  const [tooltipDate, setTooltipDate] = useState();

  useEffect(() => {
    if (myChart) {
      closeTip(myChart, 0, tooltipDate);
      setTooltipDate(index);
      openTip(myChart, 0, index);
      /*setIndex(index)
            setDay(data[index])*/
    }
  }, [closeTip, myChart, openTip, tooltipDate, index]);

  const handleChange = (value) => {
    if (myChart) {
      closeTip(myChart, 0, tooltipDate);
      setTooltipDate(value);
      openTip(myChart, 0, value);
      setIndex(value);
      setDay(data[value]);
    }
  };

  function openTip(oChart, datasetIndex, pointIndex) {
    myChart.data.datasets[0].pointRadius[pointIndex] = 10;
    myChart.data.datasets[0].pointBackgroundColor[pointIndex] = 'red';
    // myChart.data.datasets[0].pointStyle[pointIndex] = 'rectangle'
    myChart.update();

    /*if(oChart.tooltip._active == undefined)
            oChart.tooltip._active = []
        var activeElements = oChart.tooltip._active;
        var requestedElem = oChart.getDatasetMeta(datasetIndex).data[pointIndex];
        for(var i = 0; i < activeElements.length; i++) {
            if(requestedElem._index == activeElements[i]._index)
                return;
        }
        activeElements.push(requestedElem);
        oChart.tooltip._active = activeElements;
        oChart.tooltip.update(true);
        oChart.draw();*/
  }

  function closeTip(oChart, datasetIndex, pointIndex) {
    myChart.data.datasets[0].pointRadius[pointIndex] = undefined;
    myChart.data.datasets[0].pointBackgroundColor[pointIndex] = undefined;
    // myChart.data.datasets[0].pointStyle[pointIndex] = 'rectangle'
    myChart.update();
    /*var activeElements = oChart.tooltip._active;
        if(activeElements == undefined || activeElements.length == 0)
            return;
        var requestedElem = oChart.getDatasetMeta(datasetIndex).data[pointIndex];
        for(var i = 0; i < activeElements.length; i++) {
            if(requestedElem._index == activeElements[i]._index)  {
                activeElements.splice(i, 1);
                break;
            }
        }
        oChart.tooltip._active = activeElements;
        oChart.tooltip.update(true);
        oChart.draw();*/
  }

  const sortedData = () => {
    if (data) {
      return data.sort((a, b) =>
        a.date.toString().localeCompare(b.date.toString())
      );
    }
    return [];
  };

  const getData = () => {
    return sortedData().map((entry) => {
      const mood = entry.mood;
      if (mood === 'very_bad') {
        return 0;
      } else if (mood === 'bad') {
        return 1;
      } else if (mood === 'happy') {
        return 3;
      } else if (mood === 'very_happy') {
        return 4;
      } else return 2; //neutral
    });
  };

  const getLabels = () => {
    const array = sortedData().map((entry) => {
      return moment(entry.date).format('DD.MM.');
    });
    return array;
  };

  let chartData = {};
  chartData.data = getData();
  chartData.labels = getLabels();
  const datas = {
    labels: chartData.labels,
    datasets: [
      {
        label: 'Stimmung',
        backgroundColor: 'rgba(255,140,0,0.82)',
        borderColor: 'rgba(255,140,0,0.82)',
        cubicInterpolationMode: 'monotone',
        fill: true,
        data: chartData.data,
        pointRadius: 0,
        /*pointHoverRadius: 20,
        pointHitRadius: 20,
        pointBackgroundColor: ['rgba(255,140,0,0.82)'],*/
        // pointStyle: ['rect', img,'triangle',]
      },
    ],
  };

  const options = {
    animation: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    legend: {
      display: false,
      labels: {
        display: false,
      },
    },
    tooltips: {
      backgroundColor: '#997b22',
    },
    scales: {
      x: {
        position: 'top',
        grid: {
          display: false,
          color: useTheme().palette.primary.light,
        },
        ticks: {
          color: useTheme().palette.primary.main,
        },
      },
      y: {
        grid: {
          color: useTheme().palette.primary.light,
        },
        ticks: {
          color: useTheme().palette.primary.main,
        },
      },
      /*yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            stepSize: 1,
            // Include a dollar sign in the ticks
            callback: function (value, index, values) {
              return (
                value + 1
              ); /!*=== 0 ? 'Schlecht' :
                      value === 1 ? 'Naja' :
                      value === 2 ? 'Okay' :
                      value === 3 ? 'Gut' :
                      value === 4 ? 'Prima' : undefined;*!/
            },
            beginAtZero: true,
          },
        },
      ],*/
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  return (
    <div>
      {/*<canvas  ref={histogramContainer}/>*/}

      <div
        style={{
          height: 110,
          marginBottom: -15,
        }}
      >
        <Line type={'line'} data={datas} options={options} ref={lineChart} />
      </div>
    </div>
  );
};
