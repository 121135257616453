import React from 'react';

export default ({ fill = 'orange' }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <svg
        x='0px'
        y='0px'
        width={40}
        height={40}
        viewBox='0 0 295.996 295.996'
        fill={fill}
      >
        <path
          d='M147.998,0C66.392,0,0,66.392,0,147.998c0,81.606,66.392,147.998,147.998,147.998c81.606,0,147.998-66.392,147.998-147.998
		C295.996,66.392,229.604,0,147.998,0z M147.998,279.996c-36.257,0-69.143-14.696-93.023-38.44
		c-9.536-9.482-17.631-20.41-23.934-32.42C21.442,190.847,16,170.048,16,147.998C16,75.214,75.214,16,147.998,16
		c34.523,0,65.987,13.328,89.533,35.102c12.208,11.288,22.289,24.844,29.558,39.997c8.27,17.238,12.907,36.537,12.907,56.899
		C279.996,220.782,220.782,279.996,147.998,279.996z'
        />
        <path
          d='M176.249,181.991c12.875-1.786,26.044,1.091,37.084,8.104l8.578-13.506c-14.238-9.043-31.234-12.751-47.862-10.445
		c-26.732,3.714-49.436,22.684-57.84,48.329l15.205,4.982C137.928,199.575,155.527,184.87,176.249,181.991z'
        />
        <circle cx='98.665' cy='115.998' r='16' />
        <circle cx='197.665' cy='115.998' r='16' />
      </svg>
    </div>
  );
};
