function importAll(aspects, r) {
  let images = {};
  aspects.forEach((aspect) => {
    const key = aspect.image;
    images[key] = r(key);
  });
  return images;
}

export const getIcons = (aspects) => {
  return importAll(
    aspects,
    require.context('../res/iconCollection/', false, /\.(png|jpe?g|svg)$/)
  );
};
