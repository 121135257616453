import { ValueSurvey } from './ValueSurvey';
import { ValueResults } from './ValueResults';
import { valueItems } from './valueItems';
import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { UserDataContext } from '../../../state/contexts/UserDataContext';
import { AuthContext } from '../../../state/contexts/AuthContext';
import { CircularProgress } from '@material-ui/core';
import { BackNavigation } from '../../elements/BackNavigation';

export const ValuesSurveyAndResults = () => {
  const { getUserValues } = useContext(UserDataContext);
  const { user } = useContext(AuthContext);
  const [valuesObject, setValuesObject] = useState({ values: valueItems });
  const [finished, setFinished] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      const getVals = async () => {
        const userValues = await getUserValues(user.userId, user.sessionToken);
        setValues(userValues ? userValues.values : valueItems);
        if (userValues) {
          setValuesObject(userValues);
          userValues.isComplete ? setFinished(true) : setFinished(false);
        }
        setLoading(false);
      };
      getVals();
    }
  }, [user, finished]);

  const setValues = (values) => {
    setValuesObject((prevState) => ({
      ...prevState,
      values: values,
    }));
  };

  return (
    <div style={{ width: '100%' }}>
      <BackNavigation title={'Lebensanalyse'} />
      {!loading ? (
        <div>
          {finished ? (
            <ValueResults
              valuesObject={valuesObject}
              setFinished={setFinished}
            />
          ) : (
            <ValueSurvey
              valuesObject={valuesObject}
              setValues={setValues}
              setFinished={setFinished}
            />
          )}
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '70vh',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
};
