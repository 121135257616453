import { IconCollection } from '../elements/IconCollection';
import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core';
import QuestionMarkIcon from '../../res/QuestionMarkIcon';

function importAll(icons, r) {
  let images = {};
  icons.forEach((key) => {
    images[key] = r(key);
  });
  return images;
}

export const GoalIcon = ({ goal, setGoalImage }) => {
  const [showIconDialog, setShowIconDialog] = useState();
  const [icons, setIcons] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const images = importAll(
      icons,
      require.context('../../res/iconCollection/', false, /\.(png|jpe?g|svg)$/)
    );
    setImages(images);
  }, [icons]);

  useEffect(() => {
    goal && goal.image ? setIcons([goal.image]) : setIcons([]);
  }, [goal]);

  const handleImageChange = (image) => {
    setGoalImage(Object.values(image)[0]);
    setIcons(image);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <StyledIcon
          onClick={() => setShowIconDialog(true)}
          icon={Object.values(images)[0]}
        >
          {!Object.values(images)[0] && <QuestionMarkIcon />}
        </StyledIcon>
        <Typography variant={'body2'} color={'primary'}>
          Icon wählen
        </Typography>
      </div>
      {showIconDialog && (
        <IconCollection
          setShowDialog={setShowIconDialog}
          setIcons={handleImageChange}
          icons={icons}
        />
      )}
    </div>
  );
};

const StyledIcon = styled('div')(({ active, name, icon }) => ({
  width: 60,
  height: 60,
  maxWidth: 60,
  margin: 5,
  backgroundColor: active ? 'green' : undefined,
  borderRadius: '50%',
  backgroundImage: 'url(' + icon + ')',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  borderStyle: 'solid',
  borderWidth: 'thin',
  borderColor: 'orange',
  cursor: 'pointer',
}));
