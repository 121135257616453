import React, { useState } from 'react';
import {
  Dialog,
  styled,
  IconButton,
  Fab,
  useTheme,
  Typography,
} from '@material-ui/core';
import { Close, CheckCircle } from '@material-ui/icons';

function importAll(r) {
  //return r.keys().map(r)
  let images = {};
  r.keys().forEach((_, index) => {
    images[r.keys()[index]] = r(r.keys()[index]);
    //return {[r.keys()[index]] : r(r.keys()[index])}
  });
  return images;
}

const images = importAll(
  require.context('../../res/iconCollection/', false, /\.(png|jpe?g|svg)$/)
);

export const IconCollection = ({
  setShowDialog,
  isMultipleChoice,
  setIcons,
  icons,
}) => {
  const [active, setActive] = useState(icons || []);

  const handleClose = () => {
    setIcons(active);
    setShowDialog(false);
  };

  const handleChangeMultipleActive = (name) => {
    if (active.includes(name)) {
      const remaining = active.filter((element) => element !== name);
      setActive(remaining);
    } else {
      if (isMultipleChoice) {
        setActive((active) => [...active, name]);
      } else {
        setActive([name]);
        setIcons([name]);
        setShowDialog(false);
      }
    }
  };

  return (
    <Dialog
      style={{ display: 'flex', justifyContent: 'center' }}
      open={true}
      fullScreen={true}
      onClose={() => setShowDialog(false)}
    >
      <div style={{ width: '100%' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            position: 'sticky',
            top: 0,
            backgroundColor: useTheme().palette.primary.indicator,
          }}
        >
          <Typography
            variant={'subtitle1'}
            style={{ color: 'orange', paddingLeft: 10 }}
          >
            Icon wählen
          </Typography>
          <IconButton
            style={{ marginLeft: 'auto' }}
            onClick={() => setShowDialog(false)}
          >
            <Close color={'primary'} />
          </IconButton>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {Object.keys(images).map((name) => {
            return (
              <StyledIcon
                onClick={() => handleChangeMultipleActive(name)}
                key={name}
                active={!!active.includes(name)}
                name={name}
                icon={images[name]}
              />
            );
          })}
        </div>
        {/*<StyledFab color={'primary'} onClick={handleClose}>
          <CheckCircle fontSize='large' />
        </StyledFab>*/}
      </div>
    </Dialog>
  );
};

const StyledIcon = styled('div')(({ active, name, icon }) => ({
  width: 70,
  height: 70,
  maxWidth: 70,
  margin: 10,
  backgroundColor: active ? 'green' : undefined,
  borderRadius: '50%',
  backgroundImage: 'url(' + icon + ')',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
}));

const StyledFab = styled(Fab)({
  position: 'sticky',
  bottom: 15,
  right: 15,
  display: 'flex',
  marginLeft: 'auto',
});
