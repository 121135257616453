import { ClientSystem } from 'services/clients';

const props = {
  SYSTEM_ID: 'systemId',
  COUNSELOR_ID: 'counselorId',
  MEMBERS: 'members',
  NAME: 'name',
  DOCUMENTATION: 'documentation',
  COUNSELORS: 'counselors',
  CASE_ID: 'caseId',
};
const propsArray = Object.values(props);

export function mapApiToClientSystemModel(apiObject) {
  return propsArray.reduce(
    (model, mapEl) => {
      model[mapEl] = apiObject.get(mapEl);
      return model;
    },
    {
      id: apiObject.id,
    }
  );
}

export function mapClientSystemModelToApi(modelObject) {
  const clientSystem = new ClientSystem();

  propsArray.forEach((prop) => {
    clientSystem.set(prop, modelObject[prop]);
  });
  clientSystem.id = modelObject.id;

  return clientSystem;
}
