import Parse from '../utils/initParse';
import { toBase64 } from '../utils/toBase64';

export const File = Parse.Object.extend('File');

const acl = (file) => {
  const acl = new Parse.ACL();
  Object.values(file.members).forEach((member) => {
    acl.setReadAccess(member.userId, true);
    acl.setWriteAccess(member.userId, true);
  });
  return acl;
};

export const fetchFiles = async (userInfo, sessionToken) => {
  const query = new Parse.Query(File);
  query.equalTo('members.userId', userInfo.userId);
  return (await query.find({ sessionToken })) || [];
};

export const createFile = async (userInfo, file, sessionToken) => {
  const baseFile = await toBase64(file);
  const parseFile = new Parse.File(file.name, { base64: baseFile });
  await parseFile.save({}, { sessionToken });
  const doc = new Parse.Object('File');
  doc.set('owner', userInfo);
  doc.set('name', file.name);
  doc.set('members', [
    { userId: userInfo.userId, userName: userInfo.userName },
  ]);
  doc.set('doc', parseFile);
  doc.setACL(
    acl({ members: [{ userId: userInfo.userId, userName: userInfo.userName }] })
  );
  return await doc.save({}, { sessionToken });
};

export const updateFile = async (userInfo, file, sessionToken) => {
  const query = new Parse.Query(File);
  query.equalTo('objectId', file.id);
  const doc = await query.first({ sessionToken });
  doc.set('members', file.members);
  doc.setACL(acl(file));
  return doc.save({}, { sessionToken });
};

export const deleteFile = async (userInfo, file, sessionToken) => {
  return await Parse.Cloud.run(
    'deleteFile',
    { fileId: file.id },
    { sessionToken }
  );
};
