import React from 'react';

export default ({ fill = 'orange' }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <svg
        x='0px'
        y='0px'
        width={40}
        height={40}
        viewBox='0 0 295.996 295.996'
        fill={fill}
      >
        <path
          d='M147.998,0C66.392,0,0,66.392,0,147.998c0,81.606,66.392,147.998,147.998,147.998c81.606,0,147.998-66.392,147.998-147.998
		C295.996,66.392,229.605,0,147.998,0z M147.998,279.996c-36.257,0-69.143-14.696-93.023-38.44
		c-9.536-9.482-17.631-20.41-23.934-32.42C21.442,190.847,16,170.047,16,147.998C16,75.214,75.214,16,147.998,16
		c34.523,0,65.987,13.328,89.533,35.102c12.208,11.288,22.289,24.844,29.558,39.996c8.27,17.239,12.907,36.538,12.907,56.9
		C279.996,220.782,220.782,279.996,147.998,279.996z'
        />
        <path
          d='M97.41,114.4c8.6,0,15.597,6.597,15.597,15.597h16c0-18-14.174-31.597-31.597-31.597
		c-17.423,0-31.597,13.597-31.597,31.597h16C81.813,120.997,88.811,114.4,97.41,114.4z'
        />
        <path
          d='M198.584,114.4c8.6,0,15.597,6.597,15.597,15.597h16c0-18-14.174-31.597-31.597-31.597
		c-17.423,0-31.597,13.597-31.597,31.597h16C182.987,120.997,189.984,114.4,198.584,114.4z'
        />
        <path
          d='M147.715,229.995c30.954,0,60.619-15.83,77.604-42.113l-13.439-8.684c-15.596,24.135-44.134,37.605-72.693,34.308
		c-22.262-2.567-42.849-15.393-55.072-34.308l-13.438,8.684c14.79,22.889,39.716,38.409,66.676,41.518
		C140.814,229.8,144.27,229.995,147.715,229.995z'
        />
      </svg>
    </div>
  );
};
