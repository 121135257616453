import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import AuthContextProvider from './state/contexts/AuthContext';
import UserContextProvider from './state/contexts/UserDataContext';
import { ErrorView } from './components/elements/ErrorView';
import ChatContextProvider from './state/contexts/ChatContext';
import GoalsContextProvider from './state/contexts/GoalsContext';
import ClientsContextProvider from './state/contexts/ClientsContext';
import { FCMNotifications } from './pushnotifications/FCMNotifications';
import { BrowserRouter as Router } from 'react-router-dom';
import { appVersion } from './appVersion';
import './index.css';

Bugsnag.start({
  collectUserIp: false,
  appVersion: appVersion,
  apiKey: '12d9eef4f76cfdd76a9da7f5b4dcd322',
  plugins: [new BugsnagPluginReact(React)],
});

const ErrorBoundary = Bugsnag.getPlugin('react');

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Montserrat"', //'"Work Sans", "Helvetica", "Arial", sans-serif',
    fontFamilyTitle: 'Philosopher',
    subtitle2: {
      fontWeight: 'normal',
    },
    h6: {
      color: '#6b6c6e',
      //fontWeight: "bold"
    },
  },
  palette: {
    primary: {
      main: '#ff8000', //'#ff9b3c', //rgb(255,177,31)', //'#5A5272', //'rgb(255,177,31)',//'rgb(255,177,31)', //diakonie: rgb(90, 37, 114)',
      bubble: 'rgb(255,226,194)',
      dark: 'rgb(232,129,0)',
      verydark: 'rgb(255,102,31)',
      light: 'rgb(255,216,149)',
      midlight: 'rgb(255,243,231)',
      verylight: 'rgb(255,252,248)',
      indicator: 'rgb(208,54,50)',
      indicatorFilter: 'rgba(208,54,50,0.5)',
      filesIndicator: 'rgb(255,133,40)',
      altBubble: 'rgb(246,246,246)',
      lightGrey: '#f9f9f9',
      iuvivoLogoMidOrange: '#ffcc36',
      orange: 'rgba(255,128,0,0.66)',
      baseGradient:
        'linear-gradient(90deg, rgba(255,128,0,1) 0%, rgba(255,222,163,1) 100%)',
    },
    secondary: {
      main: 'rgb(255,220,174, 0.2)', //'rgb(255,220,174, 0.2)',
      light: 'rgb(255,242,233)',
    },
    progress: {
      main: 'rgba(168,236,153,0.2)',
    },
    blueButton: {
      main: '#0072db',
      light: '#e8f0fd',
      dark: '#0064bf',
      text: '#ffffff',
    },
    greenButton: {
      main: '#5ac950',
      text: '#ffffff',
    },
    button: {
      done: 'rgba(116,217,38,0.55)',
      main: '#24cc43',
      pending: 'rgba(227,238,18,0.61)',
      light: 'rgba(223,255,201,0.72)',
    },
    background: {
      default: 'white', //'#fdbd7a',
    },
    text: {
      primary: '#6F7072',
      secondary: '#bbbcbe',
    },
    // Third color is #999999
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <ErrorBoundary FallbackComponent={ErrorView}>
      <AuthContextProvider>
        <GoalsContextProvider>
          <ClientsContextProvider>
            <UserContextProvider>
              <ChatContextProvider>
                <Router>
                  <FCMNotifications />
                  <App />
                </Router>
              </ChatContextProvider>
            </UserContextProvider>
          </ClientsContextProvider>
        </GoalsContextProvider>
      </AuthContextProvider>
    </ErrorBoundary>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        const target = event.target;
        const state = target.state;
        if (state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});
