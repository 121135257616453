import React, { useContext, useEffect, useState } from 'react';
import {
  Calendar as ReactBigCalendar,
  momentLocalizer,
} from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/de';
import { Dialog, useTheme } from '@material-ui/core';
import './Calendar.scss';
import { ArrowBackIos, ArrowForwardIos, DateRange } from '@material-ui/icons';
import { DefaultIconButton } from '../elements/DefaultIconButton';
import { DefaultButton } from '../elements/DefaultButton';
import { CalendarEvent } from './CalendarEvent';
import {
  fetchGetCalendarEvents,
  fetchRemoveCalendarEvent,
  fetchSetCalendarEvent,
} from '../../services/calendar';
import { AuthContext } from '../../state/contexts/AuthContext';
import {
  calendarRecurrenceEdit,
  calendarRecurrenceFreq,
} from '../../utils/constants';
import { CalendarRecurringEvent } from './CalendarRecurringEvent';
import uuid from 'react-uuid';
import { BackNavigation } from '../elements/BackNavigation';

export const Calendar = () => {
  const theme = useTheme();
  const localizer = momentLocalizer(moment);
  const { user } = useContext(AuthContext);
  const [event, setEvent] = useState();
  const [index, setIndex] = useState();
  const [events, setEvents] = useState([]);
  const [dateRange, setDateRange] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
  });
  const [editRecurringEvent, setEditRecurringEvent] = useState();
  const [originalRecurringEvent, setOriginalRecurringEvent] = useState();

  const messages = {
    date: 'Datum',
    time: 'Uhrzeit',
    event: 'Termin',
    allDay: 'Ganzer Tag',
    week: 'Woche',
    work_week: 'Arbeitswoche',
    day: 'Tag',
    month: 'Monat',
    previous: (
      <DefaultIconButton customColor={useTheme().palette.primary}>
        <ArrowBackIos color={'primary'} />
      </DefaultIconButton>
    ),
    next: (
      <DefaultIconButton customColor={useTheme().palette.primary}>
        <ArrowForwardIos color={'primary'} />
      </DefaultIconButton>
    ),
    yesterday: 'Gestern',
    tomorrow: 'Morgen',
    today: (
      <DefaultButton startIcon={<DateRange color={'primary'} />}>
        Heute
      </DefaultButton>
    ),
    agenda: 'Agenda',
  };

  let formats = {
    dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
      localizer.format(start, 'DD.MM.YY', culture) +
      ' — ' +
      localizer.format(end, 'DD.MM.YY', culture),
  };

  useEffect(() => {
    if (user) {
      getEvents(dateRange);
    }
  }, [user, dateRange]);

  const handleDateRange = (e) => {
    const currentDateRange = { year: e.getFullYear(), month: e.getMonth() };
    if (
      currentDateRange.year !== dateRange.year ||
      currentDateRange.month !== dateRange.month
    ) {
      setDateRange(currentDateRange);
    }
  };

  const handleRecurrentEvents = (events) => {
    const allEvents = [...events];
    events.forEach((event) => {
      event.eventId = uuid();
      if (event.recurrenceFreq) {
        const eventStart = new Date(event.start);
        const eventEnd = new Date(event.end);
        const duration = eventEnd - eventStart;
        let startDate;
        let i = eventStart;
        while (i <= new Date(dateRange.year, dateRange.month + 2)) {
          //end of next month
          if (event.recurrenceFreq === calendarRecurrenceFreq.DAILY) {
            startDate = new Date(eventStart.setDate(eventStart.getDate() + 1));
          }
          if (event.recurrenceFreq === calendarRecurrenceFreq.WEEKLY) {
            startDate = new Date(eventStart.setDate(eventStart.getDate() + 7));
          }
          if (event.recurrenceFreq === calendarRecurrenceFreq.BIWEEKLY) {
            startDate = new Date(eventStart.setDate(eventStart.getDate() + 14));
          }
          if (event.recurrenceFreq === calendarRecurrenceFreq.MONTHLY) {
            startDate = new Date(
              eventStart.setMonth(eventStart.getMonth() + 1)
            );
          }
          if (event.recurrenceFreq === calendarRecurrenceFreq.YEARLY) {
            startDate = new Date(
              eventStart.setFullYear(eventStart.getFullYear() + 1)
            );
          }
          const endDate = new Date(startDate.getTime() + duration);
          const recurringEvent = { ...event };
          delete recurringEvent.exceptions;
          recurringEvent.start = startDate;
          recurringEvent.end = endDate;
          recurringEvent.clone = true;

          const exceptions = event.exceptions || [];
          const isException = exceptions.find(
            (exception) =>
              exception.originalStart.getTime() ===
                recurringEvent.start.getTime() &&
              exception.originalEnd.getTime() === recurringEvent.end.getTime()
          );

          //if the event is before recurrence end date and not an exception
          if (startDate <= event.recurrenceEnd && !isException) {
            allEvents.push(recurringEvent);
          }
          i = startDate;
        }
      }
    });
    return allEvents;
  };

  const getEvents = async (dateRange) => {
    const events = await fetchGetCalendarEvents(dateRange, user.sessionToken);
    if (events) {
      const allEvents = handleRecurrentEvents(events);
      console.log('getEvents');
      setEvents(allEvents);
    }
  };

  const saveEvent = async (event) => {
    if (originalRecurringEvent) {
      if (event.exceptionId) {
        delete event.forwarded;
        delete event.single;
        const exceptions = originalRecurringEvent.exceptions || [];
        const exceptionIndex = exceptions.findIndex(
          (exception) => exception.exceptionId === event.exceptionId
        );
        if (exceptionIndex !== -1) {
          exceptions[exceptionIndex] = event;
        } else {
          exceptions.push(event);
        }
        originalRecurringEvent.exceptions = exceptions;
      }
      await fetchSetCalendarEvent(
        originalRecurringEvent,
        user,
        user.sessionToken
      );
      setOriginalRecurringEvent(false);
    }

    await fetchSetCalendarEvent(event, user, user.sessionToken);
    getEvents(dateRange);
    setEvent(undefined);
    setIndex(undefined);
  };

  const deleteEvent = async (event, index) => {
    if (event.author.userId === user.userId) {
      //if it's a via forward type edited current event just set the original event with the modified end date, otherwise delete event
      if (originalRecurringEvent) {
        if (event.forwarded) {
          delete event.forwarded;
        } else if (event.single) {
          delete event.single;
          const exceptions = originalRecurringEvent.exceptions || [];
          exceptions.push(event);
          originalRecurringEvent.exceptions = exceptions;
        }
        await fetchSetCalendarEvent(
          originalRecurringEvent,
          user,
          user.sessionToken
        );
      } else {
        await fetchRemoveCalendarEvent(
          user.userId,
          event.id,
          user.sessionToken
        );
      }
    } else {
      const deleteEvent = events.find(
        (ev) => ev.eventId === event.eventId && !ev.clone
      );

      const deleteEventClone = { ...deleteEvent };

      if (deleteEventClone) {
        deleteEventClone.members = deleteEventClone.members.filter(
          (member) => member.userId !== user.userId
        );
        deleteEventClone.reminders =
          deleteEventClone.reminders &&
          deleteEventClone.reminders.filter(
            (member) => member.userId !== user.userId
          );

        await fetchSetCalendarEvent(deleteEventClone, user, user.sessionToken);
      }
    }

    getEvents(dateRange);
    setEvent(undefined);
    setOriginalRecurringEvent(false);
    setIndex(undefined);
  };

  const getEventStyle = (e) => {
    return {
      style: { backgroundColor: e.color },
    };
  };
  const handleOnSelectEvent = (event) => {
    if (event.recurrenceFreq && event.clone) {
      //it's a recurrent event owned by user so he should decide which one to edit via CalendarRecurringEvent
      if (user && event.author.userId === user.userId) {
        setEditRecurringEvent(events.find((ev) => ev === event));
      } else {
        //it's a recurrent event NOT owned by user so he the original event should be shown for editing reminders
        setIndex(
          events.findIndex((ev) => ev.eventId === event.eventId && !ev.clone)
        );
        setEvent(
          events.find((ev) => ev.eventId === event.eventId && !ev.clone)
        );
      }
    } else {
      setIndex(events.findIndex((ev) => ev === event));
      setEvent(event);
    }
  };

  const handleEditingRecurrentEvent = async (event, type) => {
    if (type === calendarRecurrenceEdit.ALL) {
      setIndex(
        events.findIndex(
          (ev) => ev.eventId === editRecurringEvent.eventId && !ev.clone
        )
      );
      setEvent(
        events.find(
          (ev) => ev.eventId === editRecurringEvent.eventId && !ev.clone
        )
      );
    } else if (type === calendarRecurrenceEdit.FORWARD) {
      //adjust end date of original event
      const tmpEvents = [...events];
      const originalEvent = {
        ...tmpEvents.find(
          (ev) => ev.eventId === editRecurringEvent.eventId && !ev.clone
        ),
      };
      originalEvent.recurrenceEnd = new Date(
        new Date(editRecurringEvent.start).setMinutes(
          editRecurringEvent.start.getMinutes() - 1
        )
      );
      setOriginalRecurringEvent(originalEvent);

      //set new recurring event
      const recEvent = { ...event };
      recEvent.eventId = uuid();
      recEvent.forwarded = true;
      delete recEvent.clone;
      delete recEvent.id;
      setEvent(recEvent);
    } else if (type === calendarRecurrenceEdit.SINGLE) {
      const tmpEvents = [...events];
      const originalEvent = {
        ...tmpEvents.find(
          (ev) => ev.eventId === editRecurringEvent.eventId && !ev.clone
        ),
      };
      setOriginalRecurringEvent(originalEvent);

      //set new single event
      const recEvent = { ...event };
      recEvent.eventId = uuid();
      recEvent.exceptionId = uuid();
      recEvent.originalEventId = recEvent.id;
      recEvent.originalStart = recEvent.start;
      recEvent.originalEnd = recEvent.end;
      recEvent.single = true;
      delete recEvent.recurrenceFreq;
      delete recEvent.recurrenceEnd;
      delete recEvent.clone;
      delete recEvent.id;
      setEvent(recEvent);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <BackNavigation title={'Kalender'} />
      <ReactBigCalendar
        localizer={localizer}
        formats={formats}
        selectable
        defaultDate={new Date()}
        defaultView='week'
        events={events}
        style={{ height: '100vh', width: '100%' }}
        onSelectEvent={(event) => {
          handleOnSelectEvent(event);
        }}
        onSelectSlot={(newEvent) => {
          newEvent.author = { userId: user.userId, userName: user.userName };
          newEvent.color = theme.palette.primary.main;
          setEvent(newEvent);
        }}
        eventPropGetter={getEventStyle}
        views={['month', 'week', 'day']}
        messages={messages}
        onNavigate={(e) => handleDateRange(e)}
        onView={(e) => console.log('onView', e)}
      />
      <Dialog fullWidth open={event || false}>
        <CalendarEvent
          onClose={() => {
            setEvent(undefined);
            setIndex(undefined);
            setOriginalRecurringEvent(undefined);
          }}
          event={event}
          index={index}
          saveEvent={saveEvent}
          deleteEvent={deleteEvent}
        />
      </Dialog>
      <Dialog fullWidth open={editRecurringEvent || false}>
        <CalendarRecurringEvent
          onClose={() => {
            setEditRecurringEvent(undefined);
            setIndex(undefined);
          }}
          event={editRecurringEvent}
          index={index}
          handleEditingRecurrentEvent={handleEditingRecurrentEvent}
        />
      </Dialog>
    </div>
  );
};
