import Parse from 'utils/initParse';
import { toBase64 } from '../utils/toBase64';
import { File } from './files';
import { mapApiToChatModel } from '../models/GroupChatModel';

export const Chat = Parse.Object.extend('Chat');
const chatQuery = new Parse.Query('Chat');

const acl = (chat) => {
  const acl = new Parse.ACL();
  Object.values(chat.members).forEach((member) => {
    acl.setReadAccess(member.userId, true);
    acl.setWriteAccess(member.userId, true);
  });
  return acl;
};

export const fetchSendFCMMatrixMessage = async (
  sendToUserId,
  { messageText, messageSender },
  chatId,
  sessionToken
) => {
  const sent = await Parse.Cloud.run(
    'sendFCMMatrixMessage',
    {
      matrixUsers: sendToUserId,
      messageText: messageText,
      messageSender: messageSender,
      chatId: chatId,
      type: 'chat',
    },
    { sessionToken }
  );
  /*console.log('fetchSendMessage', chatId, messageSender, messageText, sendToUserId);
  logger.push({tag: 'sendMessage', messageSender: messageSender, sendToUserId: sendToUserId,});*/
};

export const createGroupChat = async (userInfo, groupChat, sessionToken) => {
  const newGroupChat = new Chat();
  newGroupChat.set('owner', { userId: userInfo.userId });
  newGroupChat.set('name', groupChat.name);
  newGroupChat.set('members', groupChat.members);
  newGroupChat.set('messages', []);
  newGroupChat.setACL(acl(groupChat));

  return await newGroupChat.save({}, sessionToken);
};

export const updateGroupChat = async (userInfo, groupChat, sessionToken) => {
  const query = new Parse.Query(Chat);
  query.equalTo('objectId', groupChat.chatId);
  const doc = await query.first({ sessionToken });
  doc.set('members', groupChat.members);
  doc.set('name', groupChat.name);
  doc.setACL(acl(groupChat));
  return doc.save({}, { sessionToken });
};

export const deleteGroupChat = async (groupChat, sessionToken) => {
  const query = new Parse.Query(Chat);
  query.equalTo('objectId', groupChat.chatId);
  const doc = await query.first({ sessionToken });
  return await doc.destroy({ sessionToken });
};

export const deleteGroupChatMember = async (
  userInfo,
  groupChat,
  sessionToken
) => {
  const remainingMembers = groupChat.members.filter(
    (member) => member.userId !== userInfo.userId
  );
  const query = new Parse.Query(Chat);
  query.equalTo('objectId', groupChat.chatId);
  const doc = await query.first({ sessionToken });
  doc.set('members', remainingMembers);
  doc.setACL(acl(groupChat));
  return doc.save({}, { sessionToken });
};

export const fetchGetChatData = async (chatId, sessionToken) => {
  chatQuery.equalTo('objectId', chatId);
  const object = await chatQuery.first({ sessionToken });
  return object.get('messages');
};

export const fetchGetLatestChatData = async (
  userInfo,
  contacts,
  sessionToken
) => {
  const singleChatIds = contacts.map((contact) => contact.chatId);
  const chatQuery = new Parse.Query('Chat');
  chatQuery.containedIn('objectId', singleChatIds);
  chatQuery.select('lastMessage');
  const singleChats = (await chatQuery.find({ sessionToken })) || [];
  const singleChatsLastMessages = singleChats.map((singleChat) => {
    return {
      lastMessage: singleChat.get('lastMessage'),
      chatData: contacts.find((contact) => contact.chatId === singleChat.id),
      updatedAt: singleChat.get('updatedAt'),
    };
  });

  const groupChatQuery = new Parse.Query('Chat');
  groupChatQuery.equalTo('members.userId', userInfo.userId);
  groupChatQuery.exclude('messages');
  const groupChats = (await groupChatQuery.find({ sessionToken })) || [];
  const groupChatsLastMessages = groupChats.map((groupChat) => {
    return {
      chatData: {
        chatId: groupChat.id,
        userName: mapApiToChatModel(groupChat).name,
        members: mapApiToChatModel(groupChat).members,
        owner: mapApiToChatModel(groupChat).owner,
        name: mapApiToChatModel(groupChat).name,
        groupChatID: groupChat.id,
      },
      lastMessage: mapApiToChatModel(groupChat).lastMessage,
      updatedAt: groupChat.get('updatedAt'),
    };
  });
  const allChats = [...singleChatsLastMessages, ...groupChatsLastMessages];

  return allChats;
};

export const fetchSetChatData = async (chatId, message, sessionToken) => {
  chatQuery.equalTo('objectId', chatId);
  const object = await chatQuery.first({ sessionToken });
  object.add('messages', message);
  object.set('lastMessage', message).save({}, { sessionToken });
};

export const fetchSendMessage = async (
  sendToUserId,
  { messageText, messageSender },
  chatId,
  sessionToken
) => {
  const sent = await Parse.Cloud.run(
    'sendMessage',
    {
      sendToUserId: sendToUserId,
      messageText: messageText,
      messageSender: messageSender,
      chatId: chatId,
      type: 'chat',
    },
    { sessionToken }
  );
  /*console.log('fetchSendMessage', chatId, messageSender, messageText, sendToUserId);
  logger.push({tag: 'sendMessage', messageSender: messageSender, sendToUserId: sendToUserId,});*/
};

export default {
  fetchGetChatData,
  fetchSetChatData,
  fetchSendMessage,
};
