import Parse from 'utils/initParse';

import { subscribeToQuery } from 'services/subscription';

export const IuvivoUser = Parse.Object.extend('IuvivoUser');
export const Values = Parse.Object.extend('Values');
export const Diary = Parse.Object.extend('Diary');
const query = new Parse.Query('IuvivoUser');

const acl = (userInfo, values) => {
  const acl = new Parse.ACL();
  acl.setReadAccess(userInfo.userId, true);
  acl.setWriteAccess(userInfo.userId, true);

  values.members &&
    Object.values(values.members).forEach((member) => {
      acl.setReadAccess(member.userId, true);
    });
  return acl;
};

export const fetchGetUserData = async (userId, sessionToken) => {
  query.equalTo('parseUserId', userId);

  return query.first({ sessionToken });
};

export const fetchAddGoalToUser = async (members, goalId, sessionToken) => {
  const result = await Parse.Cloud.run(
    'addGoalToUser',
    { members, goalId },
    { sessionToken }
  );

  return result;
};

export const fetchRemoveGoalFromUser = async (
  members,
  goalId,
  sessionToken
) => {
  const result = await Parse.Cloud.run(
    'removeGoalFromUser',
    { members, goalId },
    { sessionToken }
  );

  return result;
};

export const fetchSubscribeUserData = (userId, handleEvent, sessionToken) => {
  const subscribeQuery = new Parse.Query('IuvivoUser');
  subscribeQuery.equalTo('parseUserId', userId);

  return subscribeToQuery(subscribeQuery, handleEvent, sessionToken).then(
    (subscription) => {
      return subscription;
    }
  );
};

export const fetchUnsubscribeUserData = (subscription) => {
  subscription.unsubscribe().then(() => {
    console.info('successfully unsubscribed userdata');
    return null;
  });
};

export const fetchSetDiary = async (userId, diary, sessionToken) => {
  /*const userQuery = new Parse.Query(IuvivoUser);
  userQuery.equalTo('parseUserId', userId);
  let user = await userQuery.first({ sessionToken });
  const result = user.set('diary', diary);
  result.save({}, { sessionToken });*/

  const diaryQuery = new Parse.Query(Diary);
  diaryQuery.equalTo('userId', userId);
  let userDiary = await diaryQuery.first({ sessionToken });
  if (!userDiary) {
    userDiary = new Diary();
    userDiary.set('userId', userId);
    const acl = new Parse.ACL();
    acl.setReadAccess(userId, true);
    acl.setWriteAccess(userId, true);
    userDiary.setACL(acl);
  }
  userDiary.set('diary', diary);
  return userDiary.save({}, { sessionToken });
};

export const fetchUserDiary = async (userId, sessionToken) => {
  const diaryQuery = new Parse.Query(Diary);
  diaryQuery.equalTo('userId', userId);
  return await diaryQuery.first({ sessionToken });
};

export const fetchSearchUser = async (userName, sessionToken) => {
  const result = await Parse.Cloud.run(
    'searchUser',
    { userName: userName },
    { sessionToken: sessionToken }
  );

  return result;
};

export const setContactRequest = async (
  userInfo,
  memberInfo,
  status,
  sessionToken
) => {
  const result = await Parse.Cloud.run(
    'setContactRequest',
    { userInfo, memberInfo, status },
    { sessionToken: sessionToken }
  );

  return result;
};

export const fetchSetArchiveAndPreparePoints = async (
  userInfo,
  goal,
  sessionToken
) => {
  const goalPoints = Object.assign(goal);
  //delete goalPoints.members;
  //delete goalPoints.steps;
  //delete goalPoints.goalOwner;

  const userQuery = new Parse.Query(IuvivoUser);
  userQuery.equalTo('userInfo.userId', userInfo.userId);
  let user = await userQuery.first({ sessionToken });
  const goals = user.get('goals');
  delete goals[goal.id];
  user.set('goals', goals);
  user.set(`goalArchive.${goal.id}`, goal);
  user.set(`goalPoints.${goal.id}`, { open: goalPoints, collected: false });
  user.save({}, { sessionToken });
};

export const fetchDeleteArchivedGoalFromUser = async (
  userId,
  goalId,
  sessionToken
) => {
  const userQuery = new Parse.Query(IuvivoUser);
  userQuery.equalTo('userInfo.userId', userId);
  const user = await userQuery.first({ sessionToken });
  const goalArchive = user.get('goalArchive');
  delete goalArchive[goalId];
  user.set('goalArchive', goalArchive);
  user.save({}, { sessionToken });
};

export const setDBGoalPoints = async (userInfo, goal) => {
  //TODO: race condition: leads to updating object before unset operation is finished
  const userQuery = new Parse.Query(IuvivoUser);
  userQuery.equalTo('userInfo.userId', userInfo.userId);
  let user = await userQuery.first();
  user.unset(`goalPoints.${goal.id}`);
  user.set(`goalArchive.${goal.id}.collected`, true);
  user.save();
};

export const fetchSetCustomAspects = async (userInfo, items, sessionToken) => {
  const userQuery = new Parse.Query(IuvivoUser);
  userQuery.equalTo('userInfo.userId', userInfo.userId);
  let user = await userQuery.first({ sessionToken });
  user.set('aspects', items);
  return user.save({}, { sessionToken });
};

export const fetchAddCustomAspect = async (userInfo, item, sessionToken) => {
  const userQuery = new Parse.Query(IuvivoUser);
  userQuery.equalTo('userInfo.userId', userInfo.userId);
  let user = await userQuery.first({ sessionToken });
  user.addUnique('aspects', item);
  return user.save({}, { sessionToken });
};

export const fetchDeleteCustomAspect = async (userInfo, item, sessionToken) => {
  const userQuery = new Parse.Query(IuvivoUser);
  userQuery.equalTo('userInfo.userId', userInfo.userId);
  let user = await userQuery.first({ sessionToken });
  user.remove('aspects', item);
  return user.save({}, { sessionToken });
};

export const fetchSetFCMToken = async (userInfo, tokens, sessionToken) => {
  const userQuery = new Parse.Query(IuvivoUser);
  userQuery.equalTo('userInfo.userId', userInfo.userId);
  let user = await userQuery.first({ sessionToken });
  user.set('fcmTokenList', tokens);
  return user.save({}, { sessionToken });
};

export const fetchSetRecoveryKey = async (
  userId,
  recoveryKey,
  sessionToken
) => {
  const userQuery = new Parse.Query(IuvivoUser);
  userQuery.equalTo('userInfo.userId', userId);
  let user = await userQuery.first({ sessionToken });
  const result = user.set('recoveryKey', recoveryKey);
  const response = await result.save({}, { sessionToken });
};

export const fetchSetFileManager = async (
  userInfo,
  fileManager,
  sessionToken
) => {
  const userQuery = new Parse.Query(IuvivoUser);
  userQuery.equalTo('userInfo.userId', userInfo.userId);
  let user = await userQuery.first({ sessionToken });
  user.set('fileManager', fileManager);
  return user.save({}, { sessionToken });
};

export const fetchSyncMatrixContacts = async (
  userId,
  matrixContacts,
  sessionToken
) => {
  const result = await Parse.Cloud.run(
    'syncMatrixContacts',
    { userId, matrixContacts },
    { sessionToken }
  );

  return result;
};

export const fetchSetUserSettings = async (userId, settings, sessionToken) => {
  const userQuery = new Parse.Query(IuvivoUser);
  userQuery.equalTo('userInfo.userId', userId);
  let user = await userQuery.first({ sessionToken });
  user.set('settings', settings);
  return user.save({}, { sessionToken });
};

export const fetchUserValues = async (userId, sessionToken) => {
  const valuesQuery = new Parse.Query('Values');
  valuesQuery.equalTo('user.userId', userId);
  return await valuesQuery.first({ sessionToken });
};

export const fetchAllValues = async (userIds, sessionToken) => {
  const valuesQuery = new Parse.Query('Values');
  valuesQuery.containedIn('members.userId', userIds);
  let values = await valuesQuery.find({ sessionToken });
  return values || [];
};

export const fetchSetUserValues = async (
  userInfo,
  valuesObject,
  sessionToken,
  isComplete = false
) => {
  const valuesQuery = new Parse.Query('Values');
  valuesQuery.equalTo('user.userId', userInfo.userId);
  let userValues = await valuesQuery.first({ sessionToken });
  if (!userValues) {
    userValues = new Values();
    userValues.set('user', {
      userId: userInfo.userId,
      userName: userInfo.userName,
    });
  }
  userValues.set('values', valuesObject.values);
  userValues.set('members', valuesObject.members || []);
  userValues.setACL(acl(userInfo, valuesObject));
  userValues.set('isComplete', isComplete);
  userValues.save({}, { sessionToken });
};

export const fetchCurrentGoodQuestion = async (sessionToken) => {
  const currentGoodQuestionQuery = new Parse.Query('GoodQuestion');
  currentGoodQuestionQuery.equalTo('status', 'active');
  const question = await currentGoodQuestionQuery.first({ sessionToken });
  return (question && question.attributes) || null;
};

export default {
  fetchGetUserData,
  fetchSubscribeUserData,
  fetchUnsubscribeUserData,
  fetchSetDiary,
  fetchSearchUser,
  setContactRequest,
  setDBGoalPoints,
  fetchSetArchiveAndPreparePoints,
  fetchSetCustomAspects,
  fetchAddCustomAspect,
  fetchDeleteCustomAspect,
  fetchSetRecoveryKey,
  fetchSyncMatrixContacts,
  fetchUserValues,
  fetchAllValues,
  fetchSetUserValues,
};
