export const valueItems = [
  //0 self-direction
  {
    question: 'Eine eigene Meinung bilden und neue Ideen haben',
    belief: 5,
    desire: 5,
  },
  //1 benevolence
  {
    question: 'Immer zuverlässig sein',
    belief: 5,
    desire: 5,
  },
  //2 universalism
  {
    question: 'Die Umwelt schützen',
    belief: 5,
    desire: 5,
  },
  //3 power
  {
    question: 'Dass Andere tun, was ich ihnen sage',
    belief: 5,
    desire: 5,
  },
  //4 tradition/conformity
  {
    question: 'Die Bräuche der Familie und Kultur pflegen',
    belief: 5,
    desire: 5,
  },
  //5 tradition/conformity + universalism
  {
    question: 'Bescheiden sein',
    belief: 5,
    desire: 5,
  },
  //6 hedonism
  {
    question: 'Das Leben genießen',
    belief: 5,
    desire: 5,
  },
  //7 security
  {
    question: 'Ordnung und Stabilität',
    belief: 5,
    desire: 5,
  },
  //8 power
  {
    question: 'Geld und Einfluss haben',
    belief: 5,
    desire: 5,
  },
  //9 universalism
  {
    question: 'Menschen zuhören, die nicht meiner Meinung sind',
    belief: 5,
    desire: 5,
  },
  //10 self-direction
  {
    question: 'Freiheit zu tun, was ich will',
    belief: 5,
    desire: 5,
  },
  //11 tradition/conformity
  {
    question: 'Stets freundlich sein und Andere nicht verärgern',
    belief: 5,
    desire: 5,
  },
  //12 stimulation
  {
    question: 'Möglichst viele neue Erfahrungen machen',
    belief: 5,
    desire: 5,
  },
  //13 benevolence
  {
    question: 'Sich um die Menschen kümmern, die mir nahestehen',
    belief: 5,
    desire: 5,
  },
  //14 achievement
  {
    question: 'Sehr erfolgreich sein',
    belief: 5,
    desire: 5,
  },
  //15 universalism
  {
    question: 'Die Schwachen in der Gesellschaft beschützen',
    belief: 5,
    desire: 5,
  },
  //16 security
  {
    question: 'Alles vermeiden, das meine Sicherheit gefährden könnte',
    belief: 5,
    desire: 5,
  },
  //17 tradition/conformity + power
  {
    question: 'Mein Image zu pflegen',
    belief: 5,
    desire: 5,
  },
  //18 tradition/conformity
  {
    question: 'Regeln befolgen',
    belief: 5,
    desire: 5,
  },
];
