import React, { useContext, useState } from 'react';
import { Checkbox, Button, styled, Typography } from '@material-ui/core';
import { Close, MoreVert } from '@material-ui/icons';
/*import { updateFile } from '../../services/files';
import { fetchSendNotification } from '../../services/notifications';*/
import { AuthContext } from '../../../state/contexts/AuthContext';
import { UserDataContext } from '../../../state/contexts/UserDataContext';

export const DialogValuesMembers = ({ setOpen, userData, valuesObject }) => {
  const { setUserValues } = useContext(UserDataContext);
  const { user, isOnline } = useContext(AuthContext);

  const [members, setMembers] = useState(valuesObject.members);

  const handleChange = (contact) => {
    const tmpMembers = [...members];
    if (!members.some((c) => c.userId === contact.userId)) {
      tmpMembers.push({ userId: contact.userId, userName: contact.userName });
      setMembers(tmpMembers);
    } else {
      const contactIndex = members.findIndex(
        (c) => c.userId === contact.userId
      );
      tmpMembers.splice(contactIndex, 1);
      setMembers(tmpMembers);
    }
  };

  const handleSave = () => {
    if (isOnline()) {
      const membersToNotify = members
        .filter(
          (member) =>
            !valuesObject.members.some((item) => item.userId === member.userId)
        )
        .map((member) => member.userId);
      valuesObject.members = members;
      setUserValues(userData.userInfo, valuesObject, user.sessionToken, true);
      /*if (membersToNotify.length > 0) {
        fetchSendNotification(
          membersToNotify,
          {
            messageText: 'File',
            messageSender: userData.userInfo.userName,
          },
          'fileSharing',
          user.sessionToken
        );
      }*/
      setOpen(false);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        padding: 20,
      }}
    >
      <div style={{ display: 'flex', width: '100%' }}>
        <Typography variant={'subtitle1'} style={{ fontWeight: 'bold' }}>
          Mein Werteprofil freigeben für:
        </Typography>
        <Close
          color={'primary'}
          style={{ marginLeft: 'auto' }}
          onClick={() => setOpen(false)}
        />
      </div>
      {userData.contacts.map((contact) => (
        <div style={{ marginBottom: 10 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            {contact.userName}{' '}
            <Checkbox
              color={'primary'}
              checked={members.some((c) => c.userId === contact.userId)}
              onChange={() => handleChange(contact)}
            />
          </div>
          <div
            style={{ width: '100%', height: 1, backgroundColor: '#e7e7e7' }}
          />
        </div>
      ))}
      <Button onClick={handleSave} variant={'contained'} color={'primary'}>
        Schließen
      </Button>
    </div>
  );
};
