import React from 'react';
import { Button, styled, Typography } from '@material-ui/core';
import { Close, MoreVert } from '@material-ui/icons';

export const DialogPrivacyPolicy = ({
  setOpen,
  setPolicyAccepted = undefined,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        padding: 20,
      }}
    >
      <Close
        color={'primary'}
        style={{ marginLeft: 'auto' }}
        onClick={() => setOpen(false)}
      />
      <div
        style={{ wordBreak: 'break-all' }}
        dangerouslySetInnerHTML={{
          __html: `<h1>Nutzungsbedingungen und Datenschutzerklärung</h1>
<h2>§ 1 Geltungsbereich</h2>
Die Webseite iuvivo.de (nachfolgend auch „Anbieter“ oder “iuvivo“ oder „wir“) und die allgemeinen Nutzungsbestimmungen richten sich nach den gesetzlichen Bestimmungen der Bundesrepublik Deutschland. Personen, welche auf die Webseiten zugreifen erklären sich mit den nachfolgenden Bedingungen einverstanden und akzeptieren insbesondere den Haftungsausschluss (Ziffer 4), die Datenschutzerklärung (Ziffer 7) wie auch die Verwendung von Cookies.
Die Webseiten dürfen nicht von Personen genutzt werden, die einem Gerichtsstand unterstehen, der die Inhalte dieser Webseiten oder den Zugang zu den Webseiten aufgrund der Nationalität des betreffenden Nutzers, dessen Wohnsitzes oder aus anderen Gründen verbietet.
<h2>§ 2 Änderungen der Nutzungsbedingungen</h2>
Änderungen dieser allgemeinen Nutzungsbedingungen können jederzeit ohne Ankündigung erfolgen und werden unmittelbar mit der Veröffentlichung auf diesen Webseiten wirksam.
<h2>§ 3 Zugang und Verfügbarkeit</h2>
Der Nutzer hat keinen Anspruch auf Zugang zu diesen Webseiten und auf die fehlerfreie technische Verfügbarkeit der Webseiten. Der Zugang zu den Webseiten kann jederzeit und ohne Ankündigung gesperrt oder eingeschränkt werden.
<h2>§ 4 Haftungsbeschränkung</h2>
Die Inhalte dieser Webseiten werden mit größtmöglicher Sorgfalt erstellt. Der Anbieter übernimmt jedoch keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Inhalte. Haftungsansprüche gegen den Autor wegen Schäden materieller oder immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen. Die Nutzung der Inhalte der Webseiten erfolgt auf eigene Gefahr des Nutzers. Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters wieder. Mit der reinen Nutzung der Webseiten des Anbieters kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande.

Als Anbieter ist gemäß § 7 Abs. 1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG ist der Anbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen noch nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen wird iuvivo diese Inhalte umgehend entfernen.
<h2>§ 5 Urheber- und Leistungsschutzrechte</h2>
Die auf diesen Webseiten veröffentlichten Inhalte unterliegen dem deutschen und internationalen Urheber- und Leistungsschutzrecht. Alle Rechte und die rechte Dritter für den Inhalt und die Gestaltung stehen dem Anbieter und den jeweiligen Rechteinhabern zu. Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Die unerlaubte Vervielfältigung, Modifizierung, Benutzen oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.

Die Darstellung dieser Webseiten in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig.
<h2>§ 6 Besondere Nutzungsbedingungen</h2>
Soweit besondere Bedingungen für einzelne Nutzungen dieser Webseiten von den vorgenannten Paragraphen abweichen, wird an entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall die besonderen Nutzungsbedingungen.
<h2>§ 7 Datenschutzerklärung</h2>
Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen der Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfähigen und nutzerfreundlichen Internetauftritts, inklusive seiner Inhalte und der dort angebotenen Leistungen, verarbeitet.

Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.

Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir entweder allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung entscheiden. Zudem informieren wir Sie nachfolgend über die von uns zu Optimierungszwecken sowie zur Steigerung der Nutzungsqualität eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten in wiederum eigener Verantwortung verarbeiten.

Unsere Datenschutzerklärung ist wie folgt gegliedert:

I. Informationen zur Datenverarbeitung
II. Rechte der Nutzer und Betroffenen

<!--
<h3>I. Informationen über uns als Verantwortliche</h3>
Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:

<span >[Platzhalter]
[Platzhalter]straße 1
12345 [Platzhalter]stadt
Deutschland</span>

<span >E-Mail: muster@mustermail.xy</span>

Datenschutzbeauftragte/r beim Anbieter ist:

<span >Maxie Musterfrau&nbsp;</span>

<span >[nachfolgende Angaben sind zu ergänzen, sofern ein Externer Datenschutzbeauftragter bestellt ist]</span>

<span >Musterstraße 1
12345 Musterstadt
Deutschland</span>

<span >Telefon: Telefonnummer
Telefax: Faxnummer
E-Mail: datenschutz@mustermail.xy</span>

-->
<h3>I. Informationen zur Datenverarbeitung</h3>
Ihre bei Nutzung unserer Webapp verarbeiteten Daten werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt, der Löschung der Daten keine gesetzlichen Aufbewahrungspflichten entgegenstehen und nachfolgend keine anderslautenden Angaben zu einzelnen Verarbeitungsverfahren gemacht werden.
<h4>Serverdaten</h4>
Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen Internetauftritts, werden Daten durch Ihren Internet-Browser an uns bzw. an unseren Webspace-Provider übermittelt. Diese sog. Server-Logfiles beinhalten unter anderem die IP-Adresse, den verwendeten Browser, Uhrzeit und Datum und das genutzte System eines Seitenbesuchers. Bei uns werden nur anonymisierte IP-Adressen von Besuchern der Website gespeichert. Auf Webserver-Ebene erfolgt dies dadurch, dass im Logfile standardmäßig statt der tatsächlichen IP-Adresse des Besuchers z.B. &lt;123.123.123.123&gt; eine IP-Adresse &lt;123.123.123.XXX&gt; gespeichert wird, wobei XXX ein Zufallswert zwischen 1 und 254 ist. Die Herstellung eines Personenbezuges ist nicht mehr möglich.

Diese so erhobenen Daten werden getrennt von anderen Daten von Ihnen gespeichert.

Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Verbesserung, Stabilität, Funktionalität und Sicherheit unseres Internetauftritts.

<!--Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit keine weitere Aufbewahrung zu Beweiszwecken erforderlich ist. Andernfalls sind die Daten bis zur endgültigen Klärung eines Vorfalls ganz oder teilweise von der Löschung ausgenommen.

-->
<h4>Cookies</h4>
<h5>a) Sitzungs-Cookies/Session-Cookies</h5>
Wir verwenden mit unserem Internetauftritt sog. Cookies. Cookies sind kleine Textdateien oder andere Speichertechnologien, die durch den von Ihnen eingesetzten Internet-Browser auf Ihrem Endgerät abgelegt und gespeichert werden. Durch diese Cookies werden im individuellen Umfang bestimmte Informationen von Ihnen, wie beispielsweise Ihre Browser- oder Standortdaten oder Ihre IP-Adresse, verarbeitet. Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen. In unserem Fall werden zwei technisch notwendige Cookies gesetzt: Ein Cookie enthält Ihre Login-Daten, der zweite enthält eine Session-ID, die wir für die Auswertung von Abstürzen und anderen Programmfehlern benötigen.

Durch diese Verarbeitung wird unsere Webapp benutzerfreundlicher, effektiver und sicherer, da nicht bei jeder Nutzung eine erneute Anmeldung notwendig ist und wir unser Angebot durch die Fehleranalyse verbessern und sicherer machen können.

Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit b.) DSGVO, sofern diese Cookies Daten zur Vertragsanbahnung oder Vertragsabwicklung verarbeitet werden.

Falls die Verarbeitung nicht der Vertragsanbahnung oder Vertragsabwicklung dient, liegt unser berechtigtes Interesse in der Verbesserung der Funktionalität unseres Internetauftritts. Rechtsgrundlage ist in dann Art. 6 Abs. 1 lit. f) DSGVO.

<!--
<h5>b) Drittanbieter-Cookies</h5>
Gegebenenfalls werden mit unserem Internetauftritt auch Cookies von Partnerunternehmen, mit denen wir zum Zwecke der Werbung, der Analyse oder der Funktionalitäten unseres Internetauftritts zusammenarbeiten, verwendet.

Die Einzelheiten hierzu, insbesondere zu den Zwecken und den Rechtsgrundlagen der Verarbeitung solcher Drittanbieter-Cookies, entnehmen Sie bitte den nachfolgenden Informationen.

-->
<h5>b) Beseitigungsmöglichkeit</h5>
Sie können die Installation der Cookies durch eine Einstellung Ihres Internet-Browsers verhindern oder einschränken. Ebenfalls können Sie bereits gespeicherte Cookies jederzeit löschen. Die hierfür erforderlichen Schritte und Maßnahmen hängen jedoch von Ihrem konkret genutzten Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die Hilfefunktion oder Dokumentation Ihres Internet-Browsers oder wenden sich an dessen Hersteller bzw. Support.

Sollten Sie die Installation der Cookies verhindern oder einschränken, kann dies allerdings dazu führen, dass nicht sämtliche Funktionen unseres Internetauftritts vollumfänglich nutzbar sind.
<h4>Kundenkonto/Registrierungsfunktion</h4>
Falls Sie über unseren Internetauftritt ein Kundenkonto bei uns anlegen, werden wir die von Ihnen bei der Registrierung eingegebenen Daten (also bspw. Ihren Benutzernamen und Ihre E-Mail-Adresse) ausschließlich für vorvertragliche Leistungen oder die Vertragserfüllung erheben und speichern. Gleichzeitig speichern wir dann das Datum Ihrer Registrierung nebst Uhrzeit. Eine Weitergabe dieser Daten an Dritte erfolgt natürlich nicht.

Im Rahmen des weiteren Anmeldevorgangs wird Ihre Einwilligung in diese Verarbeitung eingeholt und auf diese Datenschutzerklärung verwiesen. Die dabei von uns erhobenen Daten werden ausschließlich für die Zurverfügungstellung des Kundenkontos verwendet.&nbsp;

Soweit Sie in diese Verarbeitung einwilligen, ist Art. 6 Abs. 1 lit. a) DSGVO Rechtsgrundlage für die Verarbeitung.

Sofern die Eröffnung des Kundenkontos zusätzlich auch vorvertraglichen Maßnahmen oder der Vertragserfüllung dient, so ist Rechtsgrundlage für diese Verarbeitung auch noch Art. 6 Abs. 1 lit. b) DSGVO.

Die uns erteilte Einwilligung in die Eröffnung und den Unterhalt des Kundenkontos können Sie gemäß Art. 7 Abs. 3 DSGVO jederzeit mit Wirkung für die Zukunft widerrufen. Hierzu müssen Sie uns lediglich über Ihren Widerruf in Kenntnis setzen.

Die insoweit erhobenen Daten werden gelöscht, sobald die Verarbeitung nicht mehr erforderlich ist. Hierbei müssen wir aber steuer- und handelsrechtliche Aufbewahrungsfristen beachten.
<h4>Funktionsnutzung</h4>
Unsere Webapp bietet Ihnen verschiedene Funktionen an, insbesondere die Verwendung der Beratungstools, die Kommunikation und den Dokumentenaustausch mit anderen Nutzern. Sofern Sie sich mit unseren Nutzungsbedingungen einverstanden erklärt und registriert haben und dieses Angebot in Anspruch nehmen, verarbeiten wir die dabei von Ihnen zur Verfügung gestellten Informationen. Hierzu zählen versendete Nachrichten, hochgeladene Dateien sowie Angaben im Rahmen der Beratungstools (z.B. “Ziele bearbeiten”: Zielname, Zielnutzen, Schritte, Teilnehmer, etc.). Wir speichern Ihre personenbezogenen Daten nur so lange, wie dies zur Erreichung der in unserer Datenschutzerklärung genannten Zwecke erforderlich ist oder es die vom Gesetzgeber vorgesehenen Speicherfristen vorsehen.

Rechtsgrundlage hierbei ist Art. 6 Abs. 1 lit. a) DSGVO. Die Einwilligung können Sie gemäß Art. 7 Abs. 3 DSGVO jederzeit mit Wirkung für die Zukunft widerrufen. Hierzu müssen Sie uns lediglich über Ihren Widerruf in Kenntnis setzen.

<!--Darüber hinaus verarbeiten wir auch Ihre IP- und E-Mail-Adresse. Die IP-Adresse wird verarbeitet, weil wir ein berechtigtes Interesse daran haben, weitere Schritte einzuleiten oder zu unterstützen, sofern Ihr Beitrag in Rechte Dritter eingreift und/oder er sonst wie rechtswidrig erfolgt.

Rechtsgrundlage ist in diesem Fall Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der ggf. notwendigen Rechtsverteidigung.

-->
<h4>Drittanbieter</h4>
Unsere Server werden von der Firma Hetzner bereitgestellt und enthalten alle von uns gespeicherten Daten. Hetzner hat mit uns einen Auftragsverarbeitungsvertrag gemäß Art. 28 der DSGVO abgeschlossen, auf Basis dessen wir personenbezogenen Daten durch Hetzner verarbeiten lassen. Hiermit sind keine Übermittlungen Ihrer persönlichen Daten an Dritte im datenschutzrechtlichen Sinne verbunden.
Die Mitarbeiterinnen und Mitarbeiter der Auftragsverarbeiter sind zur Wahrung der Vertraulichkeit Ihrer Daten verpflichtet und unterliegen unseren Weisungen. Alle gesetzlich vorgeschriebenen technischen und organisatorischen Sicherheitsmaßnahmen zum Schutz Ihrer personenbezogenen Daten vor Verlust und Missbrauch werden von uns gewährleistet. So werden Ihre personenbezogenen Daten jeweils in sicheren Betriebsumgebungen gespeichert, die Mitarbeitern der Auftragsverarbeiter nur insoweit zugänglich sind, als dies zur Erfüllung der vertraglichen Aufgaben zwingend erforderlich ist.

Wenn Sie Push-Benachrichtigungen erhalten wollen, auch wenn Sie sich nicht in unserer App befinden, müssen Sie uns das erlauben. Wir verwenden nach dieser Erlaubnis für die Push-Benachrichtigungen den Dienst Firebase Cloud Messaging (FCM) der Firma Google. Dabei generiert Firebase einen berechneten Schlüssel, der sich aus der Kennung der App und ihrer Geräte-Kennung zusammensetzt. Dieser Schlüssel wird auf unseren Servern sowie bei Firebase hinterlegt, um eine Nachricht dem addressierten Gerät zuordnen und zuschicken zu können. Die Firebase-Server können über diesen Schlüssel und den gesendeten Nachrichteninhalt hinaus keinerlei Rückschluss auf die Anfragen von Nutzenden ziehen oder sonstige Daten ermitteln, die mit einer Person im Zusammenhang stehen. Firebase dient ausschließlich als Übermittler. Wenn Sie sich mit einem anderen/neuen Benutzer anmelden, wird der Schlüssel durch einen neuen ersetzt. Der alte Schlüssel wird nach Angaben des Dienstleisters dann innerhalb von 180 Tagen von dessen Servern gelöscht.
Weitere Informationen hierzu erhalten Sie unter:  https://firebase.google.com/support/privacy/
Falls Sie die iuvivo-iOS-App nutzen, findet - nach Ihrer expliziten Zustimmung - der genannte Prozess auch im Rahmen des Apple Push Notification Service (APNS) statt.
<br/>
Zur Ermittlung und Analyse von Fehlern in der App, die zu einer Störung oder einem Absturz geführt haben, verwenden wir den Dienst Bugsnag der Bugsnag Inc. 939 Harrison St, San Francisco, CA 94107, USA („Bugsnag“). Tritt ein Fehler auf, werden zur Auswertung des Fehlers die aufrufende IP-Adresse (in anonymisierter Form), Daten zu Einstellungen sowie Einzelheiten zu der Seite der App, auf der der Fehler aufgetreten ist, und die dort eingebauten Daten an Bugsnag übermittelt. Weitere Informationen zum Datenschutz bei Bugsnag finden sich unter: https://docs.bugsnag.com/legal/privacy-policy. Zum gleichen Zweck verwenden wir den Dienst Loggly der Loggly, Inc., 1 Post Street, San Francisco, CA 94104, indem wir Daten zu offenbar fehleranfälligen Prozessen des Appbetriebs übermitteln, um deren Ursachen identifizieren zu können. Rechtsgrundlage für den Einsatz von Bugsnag und Loggly ist Art. 6 Abs. 1 S. 1 lit. f DSGVO, beruhend auf unserem berechtigten Interesse die für den sicheren und stabilen Betrieb unserer Website notwendige Speicherung und Analyse von Logdateien sicher und zuverlässig unter Einsatz externer Dienstleister durchzuführen und zugleich den eigenen Aufwand für die Bereitstellung der betreffenden EDV-Infrastruktur zu reduzieren.
<h4>Sicherheit</h4>
Unsere Webseiten nutzen aus Gründen der Sicherheit und zum Schutz der Übertragung eine SSL-Verschlüsselung (Secure Socket Layer), die vom Bundesamt für Sicherheit in der Informationstechnik (BSI) empfohlen wird. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.

In Zusammenarbeit mit dem Server-Provider bemühen wir uns, die Datenbanken so gut wie möglich vor fremden Zugriffen, Verlusten, Missbrauch oder vor Fälschung zu schützen.
<h3>II. Rechte der Nutzer und Betroffenen</h3>
Mit Blick auf die oben näher beschriebene Datenverarbeitung haben die Nutzer und Betroffenen das Recht
<ul>
 \t<li>auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die verarbeiteten Daten, auf weitere Informationen über die Datenverarbeitung sowie auf Kopien der Daten (vgl. auch Art. 15 DSGVO);</li>
 \t<li>auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten (vgl. auch Art. 16 DSGVO);</li>
 \t<li>auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), oder, alternativ, soweit eine weitere Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich ist, auf Einschränkung der Verarbeitung nach Maßgabe von Art. 18 DSGVO;</li>
 \t<li>auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf Übermittlung dieser Daten an andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);</li>
 \t<li>auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass die sie betreffenden Daten durch den Anbieter unter Verstoß gegen datenschutzrechtliche Bestimmungen verarbeitet werden (vgl. auch Art. 77 DSGVO).</li>
</ul>
Um eines dieser Rechte in Anspruch zu nehmen, kontaktieren Sie uns einfach per E-Mail unter info@iuvivo.de (Verantwortlich: Max Dehne).

Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten durch den Anbieter offengelegt worden sind, über jedwede Berichtigung oder Löschung von Daten oder die Einschränkung der Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese Mitteilung unmöglich oder mit einem unverhältnismäßigen Aufwand verbunden ist. Unbeschadet dessen hat der Nutzer ein Recht auf Auskunft über diese Empfänger.
<br/>
<br/>
<strong>Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch gegen die künftige Verarbeitung der sie betreffenden Daten, sofern die Daten durch den Anbieter nach Maßgabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden.</strong>
      <br/>
      <br/>
      <!--<h4>Google Analytics</h4>
      <p>In unserem Internetauftritt setzen wir Google Analytics ein. Hierbei handelt es sich um einen Webanalysedienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, nachfolgend nur „Google“ genannt.</p>
      <p>Der Dienst Google Analytics dient zur Analyse des Nutzungsverhaltens unseres Internetauftritts. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Analyse, Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.</p>
      <p>Nutzungs- und nutzerbezogene Informationen, wie bspw. IP-Adresse, Ort, Zeit oder Häufigkeit des Besuchs unseres Internetauftritts, werden dabei an einen Server von Google in den USA übertragen und dort gespeichert. Allerdings nutzen wir Google Analytics mit der sog. Anonymisierungsfunktion. Durch diese Funktion kürzt Google die IP-Adresse schon innerhalb der EU bzw. des EWR.</p>
      <p>Die so erhobenen Daten werden wiederum von Google genutzt, um uns eine Auswertung über den Besuch unseres Internetauftritts sowie über die dortigen Nutzungsaktivitäten zur Verfügung zu stellen. Auch können diese Daten genutzt werden, um weitere Dienstleistungen zu erbringen, die mit der Nutzung unseres Internetauftritts und der Nutzung des Internets zusammenhängen.</p>
      <p>Google gibt an, Ihre IP-Adresse nicht mit anderen Daten zu verbinden. Zudem hält Google unter</p>
      <p><a href="https://www.google.com/intl/de/policies/privacy/partners" target="_blank" rel="noopener">https://www.google.com/intl/de/policies/privacy/partners</a></p>
      <p>weitere datenschutzrechtliche Informationen für Sie bereit, so bspw. auch zu den Möglichkeiten, die Datennutzung zu unterbinden.</p>
      <p>Zudem bietet Google unter</p>
      <p><a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener">https://tools.google.com/dlpage/gaoptout?hl=de</a></p>
      <p>ein sog. Deaktivierungs-Add-on nebst weiteren Informationen hierzu an. Dieses Add-on lässt sich mit den gängigen Internet-Browsern installieren und bietet Ihnen weitergehende Kontrollmöglichkeit über die Daten, die Google bei Aufruf unseres Internetauftritts erfasst. Dabei teilt das Add-on dem JavaScript (ga.js) von Google Analytics mit, dass Informationen zum Besuch unseres Internetauftritts nicht an Google Analytics übermittelt werden sollen. Dies verhindert aber nicht, dass Informationen an uns oder an andere Webanalysedienste übermittelt werden. Ob und welche weiteren Webanalysedienste von uns eingesetzt werden, erfahren Sie natürlich ebenfalls in dieser Datenschutzerklärung.</p>-->
          `,
        }}
      />

      <Button
        style={{ minHeight: 35 }}
        onClick={() => setOpen(false)}
        variant={'contained'}
        color={'primary'}
      >
        {'Schließen'}
      </Button>
      <Block />
    </div>
  );
};

const Block = styled('div')({
  paddingBottom: 56,
  marginTop: 10,
});
