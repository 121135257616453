import React, { useContext, useState } from 'react';
import {
  Checkbox,
  Button,
  styled,
  Typography,
  TextField,
} from '@material-ui/core';
import { Close, MoreVert } from '@material-ui/icons';
import { updateFile } from '../../services/files';
import { fetchSendNotification } from '../../services/notifications';
import { AuthContext } from '../../state/contexts/AuthContext';

export const DialogRenameActiveSurvey = ({
  setOpen,
  survey,
  handleRenameActiveSurvey,
}) => {
  const [name, setName] = useState(survey.title);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        padding: 20,
      }}
    >
      <div style={{ display: 'flex', width: '100%', marginBottom: 20 }}>
        <Typography variant={'h6'}>Umfrage umbenennen</Typography>
        <Close
          color={'primary'}
          style={{ marginLeft: 'auto' }}
          onClick={() => setOpen(false)}
        />
      </div>

      <TextField
        variant={'outlined'}
        value={name}
        onChange={(ev) => setName(ev.target.value)}
      />
      <Button
        onClick={() => {
          handleRenameActiveSurvey(name, survey);
          setOpen(false);
        }}
        variant={'contained'}
        color={'primary'}
        style={{ marginTop: 15 }}
      >
        Speichern
      </Button>
    </div>
  );
};
