import {
  DatePicker,
  DateTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  Menu,
  MenuItem,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { DefaultIconButton } from '../elements/DefaultIconButton';
import { Close, DeleteOutline, ExpandMore } from '@material-ui/icons';
import { HexColorPicker } from 'react-colorful';
import { DefaultButton } from '../elements/DefaultButton';
import { CalendarMembers } from './CalendarMembers';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../state/contexts/AuthContext';
import {
  calendarRecurrenceEdit,
  calendarRecurrenceFreq,
} from '../../utils/constants';
import deLocale from 'date-fns/locale/de';

const flutterToken = window.localStorage.getItem('flutterToken');

export const CalendarRecurringEvent = (props) => {
  const theme = useTheme();
  const { user } = useContext(AuthContext);

  return (
    <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          marginBottom: 10,
          padding: 10,
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: theme.palette.secondary.main,
        }}
      >
        <Typography variant={'body1'}>Termin ändern</Typography>
        <DefaultIconButton>
          <Close color={'primary'} onClick={props.onClose} />
        </DefaultIconButton>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 10,
        }}
      >
        <DefaultButton
          style={{ margin: 10, textTransform: 'none' }}
          onClick={() => {
            props.handleEditingRecurrentEvent(
              props.event,
              calendarRecurrenceEdit.SINGLE
            );
            props.onClose();
          }}
        >
          Nur diesen Termin
        </DefaultButton>
        <DefaultButton
          style={{ margin: 10, textTransform: 'none' }}
          onClick={() => {
            props.handleEditingRecurrentEvent(
              props.event,
              calendarRecurrenceEdit.FORWARD
            );
            props.onClose();
          }}
        >
          Alle folgenden Termine
        </DefaultButton>
        <DefaultButton
          style={{ margin: 10, textTransform: 'none' }}
          onClick={() => {
            props.handleEditingRecurrentEvent(
              props.event,
              calendarRecurrenceEdit.ALL
            );
            props.onClose();
          }}
        >
          Alle Termine
        </DefaultButton>
      </div>
    </div>
  );
};

const StyledDatePicker = styled(DateTimePicker)(({ theme }) => ({
  '& .MuiInputBase-input': {
    fontSize: flutterToken ? 16 : 12,
    textAlign: 'center',
  },
  '& .MuiInput-underline:before': {
    borderBottom: 0,
  },
}));
