import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
} from '@material-ui/core';
import { ClientDocGoalMembers } from './ClientDocGoalMembers';
import {
  AddCircleOutline,
  ArrowDownwardOutlined,
  ArrowUpward,
  Check,
  Delete,
  Edit,
  ExpandLess,
  ExpandMore,
  ExpandMoreOutlined,
  RefreshOutlined,
  Save,
} from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { AuthContext } from '../../state/contexts/AuthContext';
import { GoalsContext } from '../../state/contexts/GoalsContext';
import { ClientGoalSelect } from './ClientGoalSelect';
import Dialog from '@material-ui/core/Dialog';
import { ClientDocGoalConfirmDialog } from './ClientDocGoalConfirmDialog';

//TODO hint when removing goal: goal will only be removed from documentation

const flutterToken = window.localStorage.getItem('flutterToken');

export const ClientDocGoal = ({
  userGoals,
  clientGoal,
  details,
  setClientGoal,
  deleteClientGoal,
}) => {
  const { userData } = useContext(UserDataContext);
  const { user, isOnline } = useContext(AuthContext);
  const { createGoal, updateGoal, getGoals } = useContext(GoalsContext);

  const [members, setMembers] = useState([]);
  const [title, setTitle] = useState('');
  const [editMode, setEditMode] = useState();
  const [selectedGoalIndex, setSelectedGoalIndex] = useState('');
  const [loading, setloading] = useState();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const bgColor = useTheme().palette.secondary.main;

  useEffect(() => {
    if (clientGoal) {
      if (clientGoal.id && userGoals && userGoals[clientGoal.id]) {
        const goalMembers = Object.values(
          userGoals[clientGoal.id].members
        ).filter((member) => member.userId !== user.userId);
        setMembers(goalMembers);
        setTitle(userGoals[clientGoal.id].title);
        setSelectedGoalIndex(
          Object.keys(userGoals).findIndex((key) => key === clientGoal.id)
        );
      } else {
        setMembers([]);
        setTitle('');
        setSelectedGoalIndex('');
      }
      setEditMode(false);
    }
  }, [user, clientGoal, userGoals]);

  const handleCreateGoal = async () => {
    setOpenConfirmDialog(false);
    if (title && isOnline()) {
      setloading(true);
      const goal = { title: title };
      const createdGoal = await createGoal(
        user,
        goal,
        members,
        [],
        user.sessionToken
      );
      if (createdGoal) {
        await getGoals(userData.goals, user.sessionToken);
        setClientGoal(details, createdGoal.id);
        setloading(false);
      }
    }
  };

  const handleUpdateGoal = async () => {
    if (isOnline()) {
      setloading(true);
      const goal = Object.values(userGoals)[selectedGoalIndex];
      goal.title = title;
      const updatedGoal = await updateGoal(
        user,
        goal,
        members,
        goal.supporters,
        user.sessionToken
      );
      if (updatedGoal) {
        setloading(false);
      }
    }
  };

  const handleSelectedGoal = (ev) => {
    const chosenGoal = Object.values(userGoals)[ev.target.value];
    const chosenGoalMembers = Object.values(chosenGoal.members).filter(
      (member) => member.userId !== user.userId
    );
    setMembers(chosenGoalMembers);
    setTitle(chosenGoal.title);
    if (chosenGoal.id !== clientGoal.id) {
      setClientGoal(details, chosenGoal.id);
    }

    setSelectedGoalIndex(ev.target.value);
  };

  const handleDelete = () => {
    if (isOnline()) {
      deleteClientGoal(details, clientGoal.id);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        padding: 5,
        borderRadius: 10,
        borderStyle: 'solid',
        borderColor: useTheme().palette.primary.main,
        borderWidth: 1,
        margin: 5,
        backgroundColor: bgColor,
      }}
    >
      <div style={{ width: '100%' }}>
        {editMode ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              padding: 5,
            }}
          >
            {
              <div
                onClick={() => setEditMode(false)}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginBottom: 5,
                }}
              >
                <IconButton
                  onClick={() => handleDelete()}
                  style={{ padding: 0, alignSelf: 'flex-start' }}
                >
                  <Delete color={'primary'} fontSize={'small'} />
                </IconButton>
                <IconButton
                  onClick={() => setEditMode(false)}
                  style={{ padding: 0 }}
                >
                  <Check color={'primary'} fontSize={'small'} />
                </IconButton>
              </div>
            }
            <Divider />
            <div
              style={{
                fontSize: 12,
                fontWeight: 'bold',
                marginTop: 5,
                marginBottom: 3,
              }}
            >
              Zielname
            </div>
            <StyledTextField
              InputProps={{
                style: { padding: 5, fontSize: flutterToken ? 16 : 12 },
              }}
              value={title}
              onChange={(ev) => setTitle(ev.target.value)}
              variant={'outlined'}
              multiline
            />
            <div
              style={{
                fontSize: 12,
                fontWeight: 'bold',
                marginTop: 10,
                marginBottom: 3,
              }}
            >
              Mitglieder
            </div>
            <ClientDocGoalMembers
              members={members}
              handleMembersChange={setMembers}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 10,
              }}
            >
              {selectedGoalIndex === '' ? (
                <StyledButton
                  disabled={!title}
                  color={'primary'}
                  variant={'outlined'}
                  onClick={() => setOpenConfirmDialog(true)}
                  startIcon={
                    <AddCircleOutline
                      style={{ color: 'white' }}
                      fontSize={'large'}
                    />
                  }
                >
                  Neu
                </StyledButton>
              ) : (
                <StyledButton
                  color={'primary'}
                  variant={'outlined'}
                  onClick={handleUpdateGoal}
                  startIcon={
                    <Save style={{ color: 'white' }} fontSize={'large'} />
                  }
                >
                  Speichern
                </StyledButton>
              )}
              {loading && <CircularProgress color={'primary'} />}
              <ClientGoalSelect
                selectedGoal={selectedGoalIndex}
                onChange={(ev) => handleSelectedGoal(ev)}
                userGoals={userGoals}
                callbackfn={(goal, index) => (
                  <MenuItem key={index} value={index}>
                    {goal.title}
                  </MenuItem>
                )}
              />
            </div>
          </div>
        ) : (
          <div
            onClick={() => setEditMode(true)}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <div style={{ fontSize: 12, fontWeight: 'bold' }}>
                {title ? title : 'Zielnamen eingeben'}{' '}
              </div>
              <div style={{ fontSize: 12 }}>
                {members.length > 0
                  ? `Mitglieder:  ${members.map(
                      (member) => ` ${member.userName}`
                    )}`
                  : 'Keine Mitglieder'}{' '}
              </div>
            </div>
            <IconButton
              style={{ padding: 0, marginLeft: 'auto' }}
              onClick={() => setEditMode(!editMode)}
            >
              {editMode ? (
                <ExpandLess color={'primary'} fontSize={'small'} />
              ) : (
                <ExpandMore color={'primary'} fontSize={'small'} />
              )}
            </IconButton>
          </div>
        )}
      </div>
      <Dialog
        fullWidth
        onClose={() => setOpenConfirmDialog(false)}
        open={openConfirmDialog}
      >
        {openConfirmDialog && (
          <ClientDocGoalConfirmDialog
            setOpen={setOpenConfirmDialog}
            title={title}
            members={members}
            handleConfirmation={handleCreateGoal}
          />
        )}
      </Dialog>
    </div>
  );
};

const StyledButton = styled(Button)(({ theme, nextGoal }) => ({
  maxWidth: 150,
  height: 30,
  backgroundColor: theme.palette.primary.main,
  color: 'white',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));
