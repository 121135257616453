import {
  Button,
  Dialog,
  IconButton,
  makeStyles,
  styled,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import { TextField, Typography, useTheme } from '@material-ui/core';
import { IconCollection } from './IconCollection';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import DeleteIcon from '../../res/DeleteIcon';
import { AuthContext } from '../../state/contexts/AuthContext';
import QuestionMarkIcon from '../../res/QuestionMarkIcon';

function importAll(icons, r) {
  let images = {};
  icons.forEach((key) => {
    images[key] = r(key);
  });
  return images;
}

export const CustomAspectEdit = ({
  setShowDialog,
  editAspect,
  handleAspectChange,
}) => {
  const theme = useTheme();
  const [title, setTitle] = useState(editAspect ? editAspect.title : '');
  const [showIconDialog, setShowIconDialog] = useState();
  const [icons, setIcons] = useState(editAspect ? [editAspect.image] : []);
  const [images, setImages] = useState([]);
  const { userData, addDBAspect, deleteDBAspect } = useContext(UserDataContext);
  const { user, isOnline } = useContext(AuthContext);

  const classes = useStyles();

  useEffect(() => {
    const images = importAll(
      icons,
      require.context('../../res/iconCollection/', false, /\.(png|jpe?g|svg)$/)
    );
    setImages(images);
  }, [icons]);

  const handleSave = () => {
    if (isOnline()) {
      if (title !== '' && Object.keys(images)[0]) {
        const aspect = { title: title, image: Object.keys(images)[0] };
        editAspect
          ? handleAspectChange(editAspect, aspect)
          : addDBAspect(userData.userInfo, aspect, user.sessionToken);
        //setCustomBenefits(benefit);
        setShowDialog();
      }
    }
  };

  const handleTitleChange = (event) => {
    const title = event.target.value;
    setTitle(title);
  };

  const handleDelete = async () => {
    const result = await deleteDBAspect(
      userData.userInfo,
      editAspect,
      user.sessionToken
    );
    setShowDialog();
  };

  return (
    <Dialog
      style={{ display: 'flex', justifyContent: 'center' }}
      open={true}
      onClose={() => setShowDialog(false)}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: theme.palette.primary.indicator,
        }}
      >
        <IconButton
          style={{ visibility: editAspect ? undefined : 'hidden' }}
          onClick={() => handleDelete()}
        >
          <DeleteIcon size={24} />
        </IconButton>
        <Typography
          variant={'subtitle1'}
          style={{ color: 'orange', paddingLeft: 10 }}
        >
          Aspekt/Vorteil
        </Typography>
        <IconButton onClick={() => setShowDialog(false)}>
          <Close color={'primary'} fontSize='small' />
        </IconButton>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          padding: 20,
          alignItems: 'center',
          backgroundColor: theme.palette.secondary.main,
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap',
            justifyContent: 'center',
            minWidth: 300,
          }}
        >
          <TextField
            style={{ width: '100%' }}
            placeholder='Namen eingeben'
            value={title}
            margin='normal'
            variant='outlined'
            label='Aspekt/Vorteil eingeben'
            onChange={handleTitleChange}
            classes={classes}
            InputProps={{
              style: { backgroundColor: 'white' },
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <StyledIcon
            onClick={() => setShowIconDialog(true)}
            icon={Object.values(images)[0]}
          >
            {!Object.values(images)[0] && <QuestionMarkIcon />}
          </StyledIcon>
          <Typography variant={'body2'} color={'primary'}>
            Icon wählen
          </Typography>
        </div>

        <Button
          style={{ marginTop: 20, width: '100%' }}
          variant='contained'
          color='primary'
          onClick={handleSave}
        >
          Speichern
        </Button>
      </div>
      {showIconDialog && (
        <IconCollection
          setShowDialog={setShowIconDialog}
          setIcons={setIcons}
          icons={icons}
        />
      )}
    </Dialog>
  );
};

const StyledIcon = styled('div')(({ active, name, icon }) => ({
  width: 70,
  height: 70,
  maxWidth: 70,
  margin: 5,
  backgroundColor: active ? 'green' : undefined,
  borderRadius: '50%',
  backgroundImage: 'url(' + icon + ')',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  borderStyle: 'solid',
  borderColor: 'orange',
  cursor: 'pointer',
}));

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
}));
