import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SurveyElement } from './SurveyElement';
import uuid from 'react-uuid';
import { fetchSetSurveyTemplate } from '../../services/methods';
import { AuthContext } from '../../state/contexts/AuthContext';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { IconButton, TextField, Typography, useTheme } from '@material-ui/core';
import { ArrowBack, Check } from '@material-ui/icons';
import { DefaultButton } from '../elements/DefaultButton';

export const EditSurvey = () => {
  const { user } = useContext(AuthContext);
  const { userData } = useContext(UserDataContext);
  let history = useHistory();
  const [elements, setElements] = useState([{ key: uuid() }]);
  const [survey, setSurvey] = useState();
  const [title, setTitle] = useState();

  useEffect(() => {
    if (history && history.location.state) {
      setSurvey(history.location.state.survey);
      setTitle(history.location.state.survey.title);
      setElements(history.location.state.survey.elements);
    }
  }, [history]);

  const handleSaveSurvey = async () => {
    const surveyObject = { ...survey };
    surveyObject.elements = elements;
    surveyObject.title = title;
    const success = await fetchSetSurveyTemplate(
      surveyObject,
      userData.userInfo,
      user.sessionToken
    );
    if (success) {
      history.goBack();
    }
  };

  const modifyElement = (index, element) => {
    const currentElements = [...elements];
    currentElements[index] = element;
    setElements(currentElements);
  };

  const addElement = (index) => {
    const currentElements = [...elements];
    currentElements.splice(index + 1, 0, { key: uuid() });
    setElements(currentElements);
  };

  const removeElement = (index) => {
    const currentElements = [...elements];
    if (currentElements.length > 1) {
      currentElements.splice(index, 1);
      setElements(currentElements);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        margin: 5,
      }}
    >
      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        <IconButton
          style={{ marginLeft: -15 }}
          onClick={() => history.goBack()}
        >
          <ArrowBack />
        </IconButton>
        <Typography variant={'subtitle1'}>{title || 'Umfrage'}</Typography>
      </div>
      <TextField
        variant='outlined'
        style={{
          width: '100%',
          alignSelf: 'center',
          marginBottom: 10,
          maxWidth: 600,
        }}
        inputProps={{
          style: {
            textAlign: 'center',
            padding: 10,
            fontWeight: 'bold',
          },
        }}
        value={title || ''}
        onChange={(e) => setTitle(e.target.value)}
        placeholder={'Titel'}
      />
      {elements.map((element, index) => {
        return (
          <SurveyElement
            key={element.key}
            element={element}
            index={index}
            modifyElement={modifyElement}
            addElement={addElement}
            removeElement={removeElement}
          />
        );
      })}
      <DefaultButton
        style={{ marginTop: 10 }}
        startIcon={<Check />}
        onClick={handleSaveSurvey}
      >
        Fertig
      </DefaultButton>
    </div>
  );
};
