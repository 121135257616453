import React from 'react';
import { Typography } from '@material-ui/core';
import Lazy from '../../res/milkmocha/milkmocha_lazy.gif';

export const ErrorView = () => {
  /*let orgLog = console.log

    console.log = message => {
        alert("Intercepted -> " + message); //Call Remote API to log the object.
        //Invoke the original console.log
        return orgLog(message);
    }

    console.log("test", 'dsfsdf')*/

  return (
    <div
      style={{
        display: 'flex',
        textAlign: 'center',
        padding: 15,
        width: '100%',
        height: '100vh',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: 100,
          height: 100,
          flexShrink: 0,
          backgroundImage: `url(${Lazy})`,
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <Typography variant={'h5'}>
        Ohje, da ist etwas schiefgelaufen...
      </Typography>
      <Typography style={{ marginTop: 15 }} variant={'h6'}>
        Unsere Zauberelfen suchen schon nach dem Fehler.
      </Typography>
      <Typography style={{ marginTop: 30 }} variant={'body2'}>
        In der Zwischenzeit hilft es oft, die Seite neu zu laden oder das
        Smartphone neu zu starten.
      </Typography>
    </div>
  );
};
