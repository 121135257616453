import { Button, styled } from '@material-ui/core';
import React from 'react';

export const DefaultButton = styled(({ ...props }) => (
  <Button elevation={1} variant={'contained'} {...props} />
))(({ theme, customColor = theme.palette.primary, textColor = 'white' }) => ({
  backgroundColor: customColor.main,
  color: textColor,
  '&:hover': {
    backgroundColor: customColor.dark,
  },
  /*"&:disabled":{
        backgroundColor: customColor.light
    },*/
  textTransform: 'capitalize',
  borderRadius: 25,
  fontWeight: 500,
}));
