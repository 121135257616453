import { GoalDetails } from './GoalDetails';
import StepList from './StepList';
import { styled, Typography } from '@material-ui/core';
import { Accordeon } from './Accordeon';
import React, { useContext, useEffect, useState } from 'react';
import Parse from '../../utils/initParse';
import { Goal } from '../../services/goal';
import { AuthContext } from '../../state/contexts/AuthContext';
import { mapApiToGoalModel } from '../../models/GoalModel';
import { ClientsContext } from '../../state/contexts/ClientsContext';
import { getClientGoals, getPrivateGoals } from '../../utils/selectGoals';

export const GoalsSupported = ({ system }) => {
  const { user } = useContext(AuthContext);
  const { clientSystems } = useContext(ClientsContext);
  const [goals, setGoals] = useState();

  useEffect(() => {
    const getSupportedGoals = async () => {
      const goalQuery = new Parse.Query(Goal);
      goalQuery.equalTo('supporters.userId', user.userId);
      const supportedGoals = await goalQuery.find({
        sessionToken: user.sessionToken,
      });

      if (supportedGoals.length > 0) {
        let goals = {};
        supportedGoals.forEach(
          (goal) => (goals[goal.id] = mapApiToGoalModel(goal))
        );
        if (system) {
          if (system.private && clientSystems) {
            goals = getPrivateGoals(goals, clientSystems);
          }
          if (system.systemId) {
            goals = getClientGoals(goals, system);
          }
        }
        if (Object.keys(goals).length > 0) {
          setGoals(goals);
        }
      }
    };
    getSupportedGoals();
  }, [user, system, clientSystems]);

  return (
    <div style={{ width: '100%', padding: 15 }}>
      {goals ? (
        Object.values(goals).map((goal) => {
          const id = goal.id;
          return (
            <Accordeon
              key={id}
              expanded={false}
              id={id}
              data={goals[id]}
              editable={false}
              type={'supportedGoals'}
            >
              <ExpansionDetailsViewport>
                <GoalDetails
                  goal={goal}
                  id={id}
                  progress={100}
                  editable={false}
                />
                {goals[id].steps && (
                  <StepsViewport>
                    <StepList
                      goal={goal}
                      editable={false}
                      type={'supportedGoals'}
                    />
                  </StepsViewport>
                )}
              </ExpansionDetailsViewport>
            </Accordeon>
          );
        })
      ) : (
        <div style={{ marginTop: 100, width: '100%', textAlign: 'center' }}>
          <Typography variant={'h6'}>Ort für Daumendrücker</Typography>
          <br />
          Hier stehen die Ziele, bei denen andere dich als Mitwisser und
          Daumendrücker angegeben haben. Du kannst hier ihren Fortschritt sehen
          - und sie vielleicht anstupsen?
        </div>
      )}
    </div>
  );
};

const ExpansionDetailsViewport = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const StepsViewport = styled('div')({
  display: 'flex',
  width: '100%',
});
