import { IconButton, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const BackNavigation = ({ title }) => {
  let history = useHistory();
  return (
    <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
      <IconButton
        style={{
          marginRight: -6,
          marginLeft: -12,
          marginBottom: 0,
          marginTop: -6,
        }}
        onClick={() => history.goBack()}
      >
        <ArrowBack />
      </IconButton>
      <Typography variant={'subtitle1'} style={{ marginTop: -6 }}>
        {title}
      </Typography>
    </div>
  );
};
