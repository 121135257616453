import {
  ArrowBackIos,
  CreateNewFolder,
  MoveToInbox,
  List,
  Close,
  Backup,
  Delete,
  Save,
  Folder,
  Share,
} from '@material-ui/icons';
import {
  IconButton,
  Menu,
  MenuItem,
  styled,
  useTheme,
} from '@material-ui/core';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { DropZone } from './DropZone';
import MoveFolderIcon from '../../res/MoveFolderIcon';
import SearchIcon from '@material-ui/icons/Search';

export const FileManagementBar = ({
  handleBackToParentFolder,
  handleNewFolder,
  parentFolderId,
  pasteFile,
  moveItem,
  showFileManager,
  setShowFileManager,
  filterFiles,
  reloadFiles,
  selectedFile,
  handleShare,
  userData,
  moveFile,
  removeFile,
  saveFile,
}) => {
  const theme = useTheme();
  const [filterTerm, setFilterTerm] = useState();
  const [showUpload, setShowUpload] = useState();
  const [showFileToMove, setShowFileToMove] = useState();

  const handleFilter = (filterTerm) => {
    setFilterTerm(filterTerm);
    filterFiles(filterTerm);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Button
          startIcon={<List />}
          style={{
            width: '100%',
            color: showFileManager
              ? theme.palette.primary.main
              : theme.palette.primary.filesIndicator,
            border: showFileManager
              ? undefined
              : `1px solid ${theme.palette.primary.light}`,
          }}
          onClick={() => setShowFileManager(false)}
        >
          <div style={{ fontSize: 14, textTransform: 'none' }}>
            Alle Dateien
          </div>
        </Button>
        <Button
          startIcon={<Folder />}
          style={{
            width: '100%',
            color: showFileManager
              ? theme.palette.primary.filesIndicator
              : theme.palette.primary.main,
            border: showFileManager
              ? `1px solid ${theme.palette.primary.light}`
              : undefined,
          }}
          onClick={() => setShowFileManager(true)}
        >
          <div style={{ fontSize: 14, textTransform: 'none' }}>
            Ordner-Übersicht
          </div>
        </Button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
          {!showFileManager && (
            <div style={{ display: 'flex', width: '100%' }}>
              <div
                style={{ display: 'flex', alignItems: 'center', width: '100%' }}
              >
                <SearchIcon fontSize={'small'} color={'primary'} />
                <StyledInput
                  placeholder='Suchen'
                  value={filterTerm}
                  onChange={(ev) => handleFilter(ev.target.value)}
                />
                {filterTerm && (
                  <Close
                    style={{ marginRight: 15 }}
                    color={'primary'}
                    value={filterTerm}
                    onClick={() => {
                      handleFilter('');
                    }}
                  />
                )}
                <div style={{ marginLeft: 'auto' }}>
                  <StyledIconButton
                    style={{
                      backgroundColor: showUpload
                        ? theme.palette.secondary.main
                        : undefined,
                    }}
                    onClick={() => setShowUpload(!showUpload)}
                  >
                    <div style={{ textAlign: 'center' }}>
                      <Backup color={'primary'} />
                      <Label>Hochladen</Label>
                    </div>
                  </StyledIconButton>
                </div>
              </div>
            </div>
          )}
          {showFileManager && (
            <div>
              <IconButton
                color={'primary'}
                //disabled={!parentFolderId}
                onClick={handleBackToParentFolder}
              >
                <ArrowBackIos />
              </IconButton>
              <StyledIconButton onClick={handleNewFolder}>
                <div style={{ textAlign: 'center' }}>
                  <CreateNewFolder color={'primary'} />
                  <Label>Ordner+</Label>
                </div>
              </StyledIconButton>
            </div>
          )}
          {selectedFile && (
            <div style={{ marginLeft: 'auto' }}>
              <StyledIconButton onClick={() => removeFile(selectedFile)}>
                <div style={{ textAlign: 'center' }}>
                  <Delete color={'primary'} />
                  <Label>Löschen</Label>
                </div>
              </StyledIconButton>

              <StyledIconButton onClick={() => saveFile(selectedFile)}>
                <div style={{ textAlign: 'center' }}>
                  <Save color={'primary'} />
                  <Label>Speichern</Label>
                </div>
              </StyledIconButton>

              <StyledIconButton
                onClick={() => {
                  setShowFileToMove(selectedFile);
                  moveFile(selectedFile);
                  setShowFileManager(true);
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  <MoveFolderIcon />

                  <Label>Verschieben</Label>
                </div>
              </StyledIconButton>
              {userData.userInfo.userId === selectedFile.owner.userId && (
                <StyledIconButton onClick={handleShare}>
                  <div style={{ textAlign: 'center' }}>
                    <Share color={'primary'} />

                    <Label>Teilen</Label>
                  </div>
                </StyledIconButton>
              )}
            </div>
          )}
        </div>
      </div>
      {!showFileManager && (
        <DropZone reloadFiles={reloadFiles} showUpload={showUpload} />
      )}
      {selectedFile && showFileManager && showFileToMove && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: theme.palette.primary.main,
            fontSize: 14,
            padding: 10,
            backgroundColor: theme.palette.secondary.main,
          }}
        >
          Gehe zum Zielordner und füge die Datei ein{' '}
          <StyledIconButton
            disabled={!showFileManager || !moveItem}
            onClick={pasteFile}
          >
            <div style={{ textAlign: 'center' }}>
              <MoveToInbox color={'primary'} fontSize={'large'} />

              <Label>Einfügen</Label>
            </div>
          </StyledIconButton>
        </div>
      )}
    </div>
  );
};

const StyledInput = styled('input')(({ theme }) => ({
  fontSize: 12,
  borderColor: theme.palette.primary.light,
  borderRadius: 5,
  borderStyle: 'solid',
  borderWidth: '1px',
  padding: 5,
  '&:focus': {
    outline: 'none',
    borderColor: theme.palette.primary.main,
  },
}));

const StyledIconButton = styled(Button)(({ theme }) => ({
  lineHeight: '1em',
}));

const Label = styled('div')(({ theme }) => ({
  fontSize: 10,
  color: theme.palette.primary.main,
  textTransform: 'none',
}));
