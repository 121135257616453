import React, { useContext, useEffect, useState } from 'react';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { Clients } from '../Clients/Clients';
import { Main } from './Main';
import { AuthContext } from '../../state/contexts/AuthContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GoalsContext } from '../../state/contexts/GoalsContext';
import { styled } from '@material-ui/core';
import { PrivateGoals } from './PrivateGoals';
import { userHasPermission } from '../../utils/handleRolePermissions';
import { permissions } from '../../utils/constants';

export const Goals = () => {
  const { userData } = useContext(UserDataContext);
  const { user } = useContext(AuthContext);
  const { goals } = useContext(GoalsContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (userData && goals) {
      setIsLoading(false);
    }
  }, [userData, goals]);

  return !isLoading ? (
    userHasPermission(userData, permissions.COUNSELOR) ? (
      <div style={{ width: '100%' }}>
        <Clients />
        <PrivateGoals />
      </div>
    ) : (
      <Main />
    )
  ) : (
    <ProgressContainer>
      <CircularProgress color='primary' />
    </ProgressContainer>
  );
};

const ProgressContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '50vh',
});
