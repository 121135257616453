import React, { useContext, useState } from 'react';
import { Button, Paper, styled } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { UserDataContext } from 'state/contexts/UserDataContext';
import CopyToCliboardIcon from '../../../res/CopyToClipboardIcon';
import CustomSnackbar from '../../elements/CustomSnackbar';
import { useTheme } from '@material-ui/core/styles';
import { InfoOutlined } from '@material-ui/icons';
import { DefaultIconButton } from '../../elements/DefaultIconButton';
import { DialogInfo } from '../../elements/DialogInfo';
import { content } from './content';

export const GoalSupporters = ({ supporters, setSupporters, goal }) => {
  const {
    userData: { contacts },
    userData,
  } = useContext(UserDataContext);
  const [successfullySaved, setSuccessfullySaved] = useState();
  const theme = useTheme();

  const [openDialog, setOpenDialog] = useState(false);

  const copyToClipboard = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Einladung zu iuvivo',
          text:
            'Hallo, hier kommt wie besprochen Dein Link, mit dem Du dich bei iuvivo anmelden kannst.',
          url: `https://app.iuvivo.de/?code=helferlein`,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      navigator.clipboard.writeText(
        `Hey, ich habe mir bei iuvivo ein Ziel gesetzt: ${goal.title} - drückst du mir die Daumen? Oder mach am Besten einfach gleich mit - melde dich mit diesem Link an (mich findest du unter dem Namen: ${userData.userInfo.userName}): https://app.iuvivo.de/?code=helferlein`
      );
      setSuccessfullySaved(true);
    }
  };

  return (
    <Container>
      <div style={{ display: 'flex' }}>
        <Autocomplete
          style={{ width: '100%' }}
          multiple
          id='tags-standard'
          options={contacts || []}
          getOptionLabel={(option) => option.userName}
          getOptionSelected={(option, value) => option.userId === value.userId}
          defaultValue={supporters}
          //inputValue={goal.supporters ? goal.supporters : undefined}
          onChange={(e, v) => setSupporters(v)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={'outlined'}
              label='Wer wird informiert?'
              placeholder='Mitwisser'
            />
          )}
        />
        <div style={{ display: 'flex' }}>
          <DefaultIconButton
            style={{ alignSelf: 'center', marginLeft: 5 }}
            onClick={() => setOpenDialog(true)}
          >
            <InfoOutlined color={'primary'} />
          </DefaultIconButton>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 20,
          textAlign: 'center',
        }}
      >
        <span style={{ fontSize: 12, color: theme.palette.primary.main }}>
          Die Person ist noch nicht bei iuvivo? Dann informiere einfach per
          Nachricht!
        </span>
        <Button
          style={{ marginTop: 10, marginBottom: 56 }}
          onClick={() => copyToClipboard()}
          variant={'outlined'}
          color={'secondary'}
          startIcon={<CopyToCliboardIcon />}
        >
          <span style={{ color: theme.palette.primary.main }}>Nachricht</span>
        </Button>
      </div>
      {successfullySaved && (
        <CustomSnackbar
          setSuccessfullySaved={setSuccessfullySaved}
          text={
            'Infotext kopiert - verschicke ihn in einer Nachricht an deine Liebsten!'
          }
        />
      )}
      {openDialog && <DialogInfo setOpen={setOpenDialog} content={content} />}
    </Container>
  );
};

const Container = styled('div')({
  width: '100%',
  padding: 10,
});

const Contact = styled(Paper)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: 60,
  marginBottom: 10,
  backgroundColor: 'white',
});
