export const authErrorType = (error) => {
  console.log('autherror', error);
  let errorType;
  if (error === 'Member limit reached.') {
    errorType =
      'Maximum erreicht: Dieser Code wird bereits von 5 Nutzern verwendet.';
  }
  if (error === 'Invalid code.') {
    errorType = 'Dieser Code ist ungültig';
  }
  if (error === 'Invalid username/password.') {
    errorType = 'Benutzername oder Passwort ungültig.';
  }
  if (error === 'User already exists') {
    errorType = 'Benutzername existiert bereits';
  }
  if (error === 'auth/argument-error') {
    errorType = 'Unknown Error. Please contact your administrator.';
  }
  if (error === 'auth/user-not-found') {
    errorType =
      'This user does not exist. Please register or contact your administrator.';
  }
  if (error === 'auth/weak-password') {
    errorType =
      'Too weak. Please use a stronger password with at least 6 characters.';
  }
  if (error === 'auth/wrong-password') {
    errorType = 'The password is not correct. Please try again.';
  }
  if (error === 'auth/email-already-in-use') {
    errorType = 'A user with this email address already exists.';
  }
  if (error === 'auth/network-request-failed') {
    errorType = 'Keine Internetverbindung?';
  }
  if (error === 'Account already exists for this username.') {
    errorType = 'Dieser Nutzername existiert bereits.';
  }
  if (error === 'Failed to fetch') {
    errorType = 'Fehler beim Laden. Keine Internetverbindung?';
  }
  if (error.includes('User ID can only contain characters a-z, 0-9')) {
    errorType =
      'Fehler: Der Nutzername darf nur Buchstaben (ohne Umlaute), Zahlen und die Sonderzeichen = _ - / enthalten.';
  } else if (typeof error === 'string' || error instanceof String) {
    errorType = error;
  }

  return errorType;
};
