import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';

export const DialogMembersWarning = ({
  setOpen,
  handleMembersWarning,
  system,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        padding: 20,
      }}
    >
      <div style={{ display: 'flex', width: '100%', marginBottom: 20 }}>
        <Typography variant={'h6'}>Warnung</Typography>
        <Close
          color={'primary'}
          style={{ marginLeft: 'auto' }}
          onClick={() => setOpen(false)}
        />
      </div>
      <Typography variant={'subtitle2'}>
        Wenn du kein:e Klient:in des Klientensystems <b>{system.name}</b> als
        Teilnehmer:in angibst, wird das Ziel unter{' '}
        <span style={{ fontStyle: 'italic' }}>Sonstige Ziele</span> gespeichert.
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 20,
        }}
      >
        <Button
          onClick={() => setOpen(false)}
          variant={'contained'}
          color={'primary'}
        >
          Abbrechen
        </Button>
        <Button
          onClick={() => handleMembersWarning(true)}
          variant={'contained'}
          style={{ backgroundColor: useTheme().palette.button.done }}
        >
          Verstanden
        </Button>
      </div>
    </div>
  );
};
