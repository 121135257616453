import { Droppable } from 'react-beautiful-dnd';
import React from 'react';
import styled from 'styled-components';

const DropArea = styled.div`
&& {
flex-grow: 1;
background-color: ${(props) =>
  props.isdraggingover ? 'rgba(104,193,255,0.5)' : undefined};
height: 100%; 
margin-bottom: 56px;
//display: flex;
`;

export default (props) => {
  //direction="horizontal">
  return (
    <Droppable droppableId={props.columnId}>
      {(provided, snapshot) => {
        return (
          <DropArea
            style={{ width: '100%' }}
            height={props.height}
            ref={provided.innerRef}
            {...provided.droppableProps}
            isdraggingover={snapshot.isDraggingOver ? 'true' : undefined}
          >
            {props.children}
            {provided.placeholder}
          </DropArea>
        );
      }}
    </Droppable>
  );
};
