import React, { createContext } from 'react';

import { mapApiToChatModel } from '../../models/GroupChatModel';
import { fetchGroupChats } from '../../services/chat';

export const ChatContext = createContext({});

const ChatContextProvider = (props) => {
  const { children } = props;

  /*const getGroupChats = async (userInfo, sessionToken) => {
    const chats = await fetchGroupChats(userInfo, sessionToken);
    return chats.map((groupChat) => mapApiToChatModel(groupChat));
  };*/

  return (
    <ChatContext.Provider
      value={
        {
          // getGroupChats,
        }
      }
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatContextProvider;
