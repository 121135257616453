import React from 'react';
import { useTheme } from '@material-ui/core';

export const WinTendency = ({ winTendency }) => {
  return (
    <div
      style={{
        opacity: 0.6,
        display: 'flex',
        width: '100%',
        //zIndex: 1,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '50%',
          height: 8,
          backgroundColor: 'rgba(241,21,21,0.10)',
          border: `1px solid rgba(255,80,80,0.3)`,
          borderRight: 'none',
          borderRadius: '4px 0 0 4px',
        }}
      >
        <div
          style={{
            width: winTendency < 0 ? `${Math.abs(winTendency)}%` : 0,
            backgroundColor: '#ff5050',
            //borderBottom: '6px solid #ff9696',
            borderRadius: '4px 0 0 4px',
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          width: '50%',
          height: 8,
          backgroundColor: 'rgba(88,206,67,0.15)',
          border: `1px solid rgba(52,208,83,0.3)`,
          borderLeft: 'none',
          borderRadius: '0 4px 4px 0',
        }}
      >
        <div
          style={{
            width: winTendency > 0 ? `${Math.abs(winTendency)}%` : 0,
            backgroundColor: '#34d053',
            //borderBottom: '6px solid #34d053',
            borderRadius: '0 4px 4px 0',
          }}
        />
      </div>
    </div>
  );
};
