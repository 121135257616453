import React, {
  useRef,
  useState,
  createContext,
  useEffect,
  useContext,
} from 'react';

import {
  fetchGetGoals,
  fetchCreateGoal,
  fetchUpdateGoal,
  fetchSubscribeGoals,
  fetchUnsubscribeGoals,
  fetchCreateStep,
  fetchRemoveStep,
} from 'services/goal';
import {
  fetchAddGoalToUser,
  fetchAddGoalToUserData,
  fetchRemoveGoalFromUser,
  fetchRemoveGoalFromUserData,
} from 'services/userData';
import { fetchSendNotification } from '../../services/notifications';

import { events } from 'utils/constants';

import { mapApiToGoalModel, mapGoalModelToApi } from 'models/GoalModel';
import { AuthContext } from './AuthContext';

export const GoalsContext = createContext();

const orderGoalsByPosition = (userGoals) => (goalA, goalB) =>
  userGoals[goalA.id] - userGoals[goalB.id];

const GoalsContextProvider = (props) => {
  const { user } = useContext(AuthContext);
  const { children } = props;
  const [goals, setGoals] = useState();
  const [goalArchive, setGoalArchive] = useState();
  const subscription = useRef();
  const [eventObject, handleEvent] = useState();
  const [allGoals, setAllGoals] = useState();

  useEffect(() => {
    if (eventObject) {
      const handleAsync = () => {
        const { object, event } = eventObject;
        const goalObject = mapApiToGoalModel(object);
        const updatedGoals = { ...goals };

        if (event === events.REMOVE) {
          delete updatedGoals[goalObject.id];
          setAllGoals(updatedGoals);
          setGoals(updatedGoals);
          subscribeGoals(updatedGoals, user.sessionToken);
        } else if (event === events.UPDATE) {
          updatedGoals[goalObject.id] = goalObject;
          setAllGoals(updatedGoals);
          setGoals(updatedGoals);
        } else {
          console.log('unknown event');
        }
      };

      handleAsync();
    }
  }, [eventObject]);

  const getGoals = async (userGoals, sessionToken) => {
    return fetchGetGoals(userGoals, sessionToken).then((data) => {
      const goalArray = data
        .map(mapApiToGoalModel)
        .sort(orderGoalsByPosition(userGoals));
      const goalObject = {};
      goalArray.forEach((goal) => (goalObject[goal.id] = goal));
      subscribeGoals(userGoals, sessionToken);
      setGoals(goalObject);
      setAllGoals(goalObject);
      console.log('successfully fetched goals');
    });
  };

  const createGoal = (user, goal, members, supporters, sessionToken) => {
    const { userId } = user;

    goal.goalOwner = { userId, userName: user.userName };
    goal.members = {};
    goal.supporters = supporters;

    if (members) {
      goal.members = members.reduce((a, b) => ((a[b.userId] = b), a), {});
    }

    goal.members[userId] = { userId, userName: user.userName };

    return fetchCreateGoal(mapGoalModelToApi(goal), sessionToken).then(
      async (response) => {
        console.log('successfully created goal');

        const membersToAdd = members.map((member) => member.userId);
        membersToAdd.push(userId);
        if (membersToAdd.length > 0) {
          fetchAddGoalToUser(membersToAdd, response.id, sessionToken);
        }

        //Notify members
        const membersToNotify = members
          .filter((member) => member.userId !== userId)
          .map((member) => member.userId);
        if (membersToNotify.length > 0) {
          fetchSendNotification(
            membersToNotify,
            { messageText: goal.title, messageSender: user.userName },
            'newGoal',
            sessionToken
          );
        }

        //Notify supporters
        const supportersToNotify = supporters.map(
          (supporter) => supporter.userId
        );
        if (supportersToNotify.length > 0) {
          fetchSendNotification(
            supportersToNotify,
            { messageText: goal.title, messageSender: user.userName },
            'newGoalSupport',
            sessionToken
          );
        }

        return response;
      }
    );
  };

  const updateGoal = (user, goal, members, supporters, sessionToken) => {
    const { userId, userName } = user;
    const oldSupporters = goal.supporters ? [...goal.supporters] : [];
    goal.supporters = supporters;
    goal.members = Object.values(goal.members).map((member) => member);
    const commonMembers = goal.members.filter((item) => members.includes(item));
    const excludedMembers = goal.members.filter(
      (item) => !members.includes(item) && userId !== item.userId
    );
    const newMembers = members.filter((item) => !goal.members.includes(item));
    const allMembers = commonMembers
      .concat(newMembers)
      .concat({ userId, userName });
    goal.members = allMembers.reduce((a, b) => ((a[b.userId] = b), a), {});

    return fetchUpdateGoal(goal, sessionToken).then(async (response) => {
      console.log('successfully updated goal');

      const membersToAdd = newMembers.map((member) => member.userId);
      if (membersToAdd.length > 0) {
        fetchAddGoalToUser(membersToAdd, goal.id, sessionToken);
      }

      const membersToExlude = excludedMembers.map((member) => member.userId);
      if (membersToExlude.length > 0) {
        fetchRemoveGoalFromUser(membersToExlude, goal.id, sessionToken);
      }

      //Notify new members
      const membersToNotify = newMembers
        .filter((member) => member.userId !== userId)
        .map((member) => member.userId);
      if (membersToNotify.length > 0) {
        fetchSendNotification(
          membersToNotify,
          { messageText: goal.title, messageSender: user.userName },
          'newGoal',
          sessionToken
        );
      }

      //Notify new supporters
      if (goal.supporters) {
        const newSupporters = supporters.filter(
          (item) =>
            !oldSupporters.some(
              (oldSupporter) => item.userId === oldSupporter.userId
            )
        );

        const supportersToNotify = newSupporters.map(
          (supporters) => supporters.userId
        );
        if (supportersToNotify.length > 0) {
          fetchSendNotification(
            supportersToNotify,
            { messageText: goal.title, messageSender: user.userName },
            'newGoalSupport',
            sessionToken
          );
        }
      }

      return response;
    });
  };

  const createStep = (goalId, step, sessionToken) => {
    return fetchCreateStep(goalId, step, sessionToken).then((step) => {
      console.log('successfully created step');
      return step;
    });
  };

  const subscribeGoals = async (userGoals, sessionToken) => {
    if (subscription.current) {
      await unsubscribeGoals();
    }

    subscription.current = await fetchSubscribeGoals(
      userGoals,
      handleEvent,
      sessionToken
    );
  };

  const unsubscribeGoals = async () => {
    if (subscription.current) {
      await fetchUnsubscribeGoals(subscription.current);
      subscription.current = undefined;
      return true;
    }
  };

  const filterGoals = (searchString) => {
    if (allGoals) {
      const filtered = Object.values(allGoals).filter((goal) =>
        Object.values(goal.members).some((member) =>
          member.userName.toLowerCase().includes(searchString.toLowerCase())
        )
      );
      if (filtered.length > 0) {
        setGoals(filtered);
      }
    }
  };

  return (
    <GoalsContext.Provider
      value={{
        goals,
        getGoals,
        createGoal,
        updateGoal,
        subscribeGoals,
        unsubscribeGoals,
        createStep,
        //removeStep,
        goalArchive,
        filterGoals,
      }}
    >
      {children}
    </GoalsContext.Provider>
  );
};

export default GoalsContextProvider;
