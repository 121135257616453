import React, { useContext, useState } from 'react';
import {
  Checkbox,
  Button,
  styled,
  Typography,
  TextField,
  useTheme,
} from '@material-ui/core';
import { Close, MoreVert } from '@material-ui/icons';
import { updateFile } from '../../services/files';
import { fetchSendNotification } from '../../services/notifications';
import { AuthContext } from '../../state/contexts/AuthContext';
import { Link } from './Link';

export const DialogNoRolePermission = ({ setOpen, previewImg }) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          padding: 10,
          alignItems: 'center',
          width: '100%',
          backgroundColor: useTheme().palette.secondary.light,
        }}
      >
        <Typography variant={'h6'} color={'primary'}>
          Upgrade erforderlich
        </Typography>
        <Close
          color={'primary'}
          style={{ marginLeft: 'auto' }}
          onClick={() => setOpen(false)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          padding: 10,
        }}
      >
        <Typography
          variant={'body2'}
          color={'primary'}
          style={{ marginBottom: 10, fontSize: 14 }}
        >
          Um diese Funktion zu nutzen, benötigst du die Vollversion von iuvivo.
        </Typography>
        {previewImg && (
          <img
            style={{
              marginTop: 15,
              borderRadius: 8,
              width: '70%',
              maxWidth: 250,
            }}
            alt={'preview_img'}
            src={previewImg}
          />
        )}
        <Button
          onClick={() => {
            window.location = 'mailto:info@iuvivo.de';
          }}
          variant={'contained'}
          color={'primary'}
          style={{ marginTop: 15, width: '100%' }}
        >
          Upgrade anfordern
        </Button>
      </div>
    </div>
  );
};
