import React, { useContext, useEffect, useState } from 'react';
import ClientsContextProvider, {
  ClientsContext,
} from '../../state/contexts/ClientsContext';
import { createClientSystem } from '../../services/clients';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import {
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  styled,
  TextField,
  Typography,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CopyToClipboardIcon from '../../res/CopyToClipboardIcon';
import CustomSnackbar from '../elements/CustomSnackbar';
import { AuthContext } from '../../state/contexts/AuthContext';
import { useTheme } from '@material-ui/core/styles';
import {
  AddCircle,
  AddCircleOutlined,
  ArrowDownward,
  ArrowDropDown,
  ArrowForwardIos,
  DateRange,
  Delete, Edit, EditOutlined,
  MoreVert,
  Share,
  SupervisedUserCircle,
  TextFields,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import { ClientDeleteDialog } from './ClientDeleteDialog';
import { DialogClientSystemMembers } from './DialogClientSystemMembers';
import { GoalsContext } from '../../state/contexts/GoalsContext';
import { getClientGoals } from '../../utils/selectGoals';
import { WinTendency } from '../Goals/WinTendency';
import { DefaultIconButton } from '../elements/DefaultIconButton';
import { DefaultButton } from '../elements/DefaultButton';
import { DialogClientSystemCaseId } from './DialogClientSystemCaseId';
import { Divider } from '../elements/Divider';
import {DialogClientSystemRename} from "./DialogClientSystemRename";

export const Clients = () => {
  const { updatedSystem, deleteClientSystem, clientSystems } = useContext(
    ClientsContext
  );
  const {
    userData,
    matrixInvites,
    setJoinMatrixRoom,
    joinMatrixRoom,
  } = useContext(UserDataContext);
  const { goals } = useContext(GoalsContext);
  const { user, isOnline } = useContext(AuthContext);
  const theme = useTheme();
  let history = useHistory();
  const [allClientSystems, setAllClientSystems] = useState();
  const [allGoals, setAllGoals] = useState();
  const [systemName, setSystemName] = useState();
  const [newSystem, setNewSystem] = useState();
  const [successfullySaved, setSuccessfullySaved] = useState();
  const [openDeleteDialog, setOpenDeleteDialog] = useState();
  const [openMembersDialog, setOpenMembersDialog] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSystem, setSelectedSystem] = useState();
  const [openCaseIdDialog, setOpenCaseIdDialog] = useState();
  const [openRenameDialog, setOpenRenameDialog] = useState();
  const [showInvites, setShowInvites] = useState();

  useEffect(() => {
    if (clientSystems) {
      setAllClientSystems(clientSystems);
    }
  }, [clientSystems]);

  useEffect(() => {
    if (goals) {
      setAllGoals(goals);
    }
  }, [goals]);

  const createClient = async () => {
    if (isOnline()) {
      if (systemName) {
        await createClientSystem(
          userData.userInfo,
          systemName,
          user.sessionToken
        );
        setNewSystem(false);
        setSystemName(false);
      }
    }
  };

  const copyToClipboard = (systemId, event = undefined) => {
    event && event.stopPropagation();

    navigator.clipboard.writeText(
      `Hallo, hier kommt wie besprochen Dein Link, mit dem Du dich bei iuvivo anmelden kannst: https://app.iuvivo.de/?code=${systemId}. Bis gleich!`
    );
    setSuccessfullySaved(true);
  };

  const shareInvitation = (systemId) => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Deine Einladung zu iuvivo',
          text:
            'Hallo, hier kommt wie besprochen Dein Link, mit dem Du dich bei iuvivo anmelden kannst.',
          url: `https://app.iuvivo.de/?code=${systemId}`,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      copyToClipboard(systemId);
    }
  };

  const handleConfirmation = () => {
    setOpenDeleteDialog(false);
    deleteClientSystem(openDeleteDialog.systemId, user.sessionToken);
  };

  const getSystemGoalsProgressTendency = (system) => {
    const systemGoals = getClientGoals(goals, system);
    let sumProgress = 0;
    let goalsWithStepsCount = 0;
    Object.values(systemGoals).length > 0 &&
      Object.values(systemGoals).forEach((goal) => {
        let progress = 0;
        if (goal.steps && Object.keys(goal.steps).length > 0) {
          goalsWithStepsCount += 1;
          Object.values(goal.steps).forEach((step) => {
            if (step.done === 'done') {
              progress += 1 / Object.keys(goal.steps).length;
            } else {
              if (
                !isNaN(new Date(step.deadline)) &&
                step.deadline - Date.now() < 0
              ) {
                progress += (step.deadline - Date.now()) / 86400000 / 100;
              }
            }
          });
          progress = progress * 100;
        }
        sumProgress += progress;
      });
    return (sumProgress / goalsWithStepsCount).toFixed(0);
  };

  const handleMenu = (event, system) => {
    event.stopPropagation();
    setSelectedSystem(system);
    setAnchorEl(event.currentTarget);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {!newSystem && (
          <DefaultButton
            customColor={theme.palette.secondary}
            textColor={theme.palette.primary.main}
            style={{
              width: 120,
              margin: 5,
              borderColor: theme.palette.primary.main,
            }}
            onClick={() => history.push('/calendar')}
            variant={'outlined'}
            startIcon={<DateRange color={'primary'} fontSize={'large'} />}
          >
            <div style={{ fontSize: 12 }}>Kalender</div>
          </DefaultButton>
        )}
        {!newSystem && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: 5,
              padding: 5,
              paddingLeft: 15,
              borderRadius: '25px 4px 4px 25px',
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <Typography
              variant={'body2'}
              style={{
                marginRight: 10,
                fontWeight: 400,
                color: theme.palette.primary.main,
              }}
            >
              NEUE KLIENT:INNEN
            </Typography>
            <DefaultIconButton
              onClick={setNewSystem}
              style={{
                padding: 0,
              }}
            >
              <AddCircleOutlined color={'primary'} fontSize={'large'} />
            </DefaultIconButton>
          </div>
        )}

        {newSystem && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: 5,
              width: '100%',
              alignItems: 'space-between',
              padding: 5,
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <Typography
              variant={'body2'}
              style={{
                paddingLeft: 5,
                marginBottom: 5,
                fontWeight: 400,
                color: theme.palette.primary.main,
              }}
            >
              NEUE KLIENT:INNEN
            </Typography>
            <StyledTextField
              inputProps={{
                style: {
                  padding: 10,
                  backgroundColor: 'white',
                },
              }}
              placeholder='Name'
              variant='outlined'
              onChange={(event) => setSystemName(event.target.value)}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 10,
              }}
            >
              <DefaultButton
                style={{
                  fontSize: 12,
                }}
                customColor={theme.palette.secondary}
                textColor={theme.palette.text.primary}
                onClick={() => setNewSystem(false)}
              >
                Abbrechen
              </DefaultButton>
              <DefaultButton
                style={{
                  fontSize: 12,
                }}
                customColor={theme.palette.primary}
                onClick={createClient}
              >
                Erstellen
              </DefaultButton>
            </div>
          </div>
        )}
      </div>

      {matrixInvites && matrixInvites.length > 0 && (
        <Paper
          elevation={10}
          style={{
            padding: 15,
            cursor: 'pointer',
            margin: 10,
            backgroundColor: 'darkmagenta',
          }}
          onClick={() => setShowInvites(!showInvites)}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              variant={'body2'}
              color={'primary'}
              style={{ fontWeight: 'bold' }}
            >
              Neue Kontaktanfragen
            </Typography>
            <ArrowDropDown color={'primary'} />
          </div>
          <Collapse in={showInvites}>
            <Divider
              color={theme.palette.primary.main}
              style={{ marginBottom: 24 }}
            />
            {matrixInvites.map((invite, index) => (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: 12,
                    paddingRight: 12,
                    gap: 10,
                    flexWrap: 'wrap',
                  }}
                >
                  <span
                    style={{
                      wordBreak: 'break-all',
                      fontWeight: 'bold',
                      color:
                        joinMatrixRoom === invite.roomId
                          ? 'lightgray'
                          : 'white',
                    }}
                  >
                    {invite.inviterId.substring(1).replace(':iuvivo.de', '')}
                  </span>
                  <DefaultButton
                    style={{
                      padding: 3,
                      paddingLeft: 12,
                      paddingRight: 12,
                      marginLeft: 'auto',
                    }}
                    disabled={joinMatrixRoom === invite.roomId}
                    onClick={(e) => {
                      e.stopPropagation();
                      setJoinMatrixRoom(invite.roomId);
                    }}
                  >
                    Annehmen
                  </DefaultButton>
                </div>
                {matrixInvites.length > 1 &&
                  index !== matrixInvites.length - 1 && (
                    <Divider
                      marginTop={16}
                      marginBottom={16}
                      color={theme.palette.primary.main}
                    />
                  )}
              </>
            ))}
          </Collapse>
        </Paper>
      )}

      {allClientSystems &&
        allClientSystems.map((system) => (
          <div
            key={system.systemId}
            style={{
              margin: 5,
              marginTop: 10,
              borderRadius: 10,
              backgroundColor: 'white',
              boxShadow: '0px 0px 21px 0px rgba(0,0,0,0.1)',
            }}
            onClick={() =>
                history.push('/clientdetails', { system: system })
            }
          >
            <div style={{ display: 'flex', width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    //backgroundColor: theme.palette.secondary.main,
                    borderBottom: '1px dotted lightgrey',
                    padding: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <DefaultIconButton
                      size={'small'}
                      style={{ marginLeft: -5, marginRight: 10 }}
                      onClick={(e) => handleMenu(e, system)}
                    >
                      <MoreVert fontSize={'small'} color={'primary'} />
                    </DefaultIconButton>
                    <Menu
                      open={anchorEl && selectedSystem === system}
                      anchorEl={anchorEl}
                      getContentAnchorEl={null}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                      onClose={(event) => {
                        event.stopPropagation();
                        setSelectedSystem(null);
                        setAnchorEl(null);
                      }}
                    >
                      <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenRenameDialog(system);
                            setAnchorEl(null);
                          }}
                      >
                        <DefaultIconButton
                            style={{ marginRight: 10 }}
                            onClick={(e) => {
                              setOpenRenameDialog(system);
                              setAnchorEl(null);
                            }}
                        >
                          <Edit
                              fontSize={'small'}
                              style={{ color: theme.palette.primary.main }}
                          />
                        </DefaultIconButton>
                        Umbenennen
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenDeleteDialog(system);
                          setAnchorEl(null);
                        }}
                      >
                        <DefaultIconButton
                          style={{ marginRight: 10 }}
                          onClick={() => {
                            setOpenDeleteDialog(system);
                            setAnchorEl(null);
                          }}
                        >
                          <Delete
                            fontSize={'small'}
                            style={{ color: theme.palette.primary.main }}
                          />
                        </DefaultIconButton>
                        Löschen
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenMembersDialog(system);
                          setAnchorEl(null);
                        }}
                      >
                        <DefaultIconButton
                          style={{ marginRight: 10 }}
                          onClick={(e) => {
                            setOpenMembersDialog(system);
                            setAnchorEl(null);
                          }}
                        >
                          <SupervisedUserCircle
                            fontSize={'small'}
                            style={{ color: theme.palette.primary.main }}
                          />
                        </DefaultIconButton>
                        Mitglieder
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          shareInvitation(system.systemId);
                          setAnchorEl(null);
                        }}
                      >
                        <DefaultIconButton
                          style={{ marginRight: 10 }}
                          onClick={(e) => {
                            shareInvitation(system.systemId);
                            setAnchorEl(null);
                          }}
                        >
                          <Share
                            fontSize={'small'}
                            style={{ color: theme.palette.primary.main }}
                          />
                        </DefaultIconButton>
                        Einladen
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenCaseIdDialog(system);
                          setAnchorEl(null);
                        }}
                      >
                        <DefaultIconButton
                          style={{ marginRight: 10 }}
                          onClick={(e) => {
                            setOpenCaseIdDialog(system);
                            setAnchorEl(null);
                          }}
                        >
                          <SupervisedUserCircle
                            fontSize={'small'}
                            style={{ color: theme.palette.primary.main }}
                          />
                        </DefaultIconButton>
                        Fall-ID
                      </MenuItem>
                    </Menu>
                    <div style={{ display: 'flex', flexDirection: 'column' }} >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                          color: theme.palette.primary.dark,
                        }}

                      >
                        {system.name}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 'auto',
                      }}
                    >
                      <div>
                        <IconButton
                          style={{ padding: 5 }}
                        >
                          <ArrowForwardIos
                            style={{ color: theme.palette.primary.main }}
                          />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    position: 'relative',
                    padding: 20,
                    //backgroundColor: theme.palette.secondary.main,
                  }}
                >
                  {goals && system.members && system.members.length > 0 && (
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{ width: 60, fontSize: 11 }}>
                        {Object.keys(getClientGoals(goals, system)).length}{' '}
                        Ziele
                      </div>
                      <WinTendency
                        winTendency={getSystemGoalsProgressTendency(system)}
                      />
                    </div>
                  )}
                  {system.members.length === 0 && (
                    <div style={{ width: '100%' }}>
                      <Typography
                        style={{ fontSize: 12, marginTop: 3 }}
                        variant={'body2'}
                      >
                        Lade Klient:innen über das Drei-Punkte-Menü ein oder
                        schicke ihnen diesen Link:
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 10,
                            borderRadius: 30,
                            padding: 10,
                            backgroundColor: theme.palette.secondary.main,
                            cursor: 'pointer',
                          }}
                          onClick={(event) =>
                            copyToClipboard(system.systemId, event)
                          }
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              marginRight: 10,
                              fontSize: 10,
                            }}
                          >
                            <CopyToClipboardIcon
                              fill={theme.palette.primary.indicator}
                            />
                          </div>
                          <div
                            style={{
                              fontWeight: 500,
                              color: theme.palette.primary.indicator,
                            }}
                          >
                            https://app.iuvivo.de/?code={system.systemId}
                          </div>
                        </div>
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      {successfullySaved && (
        <CustomSnackbar
          setSuccessfullySaved={setSuccessfullySaved}
          text={
            'Link wurde in die Zwischenablage kopiert! Füge Ihn nun in eine Nachricht an deine Klient:in ein.'
          }
        />
      )}
      <Dialog
        fullWidth
        onClose={() => setOpenDeleteDialog(false)}
        open={openDeleteDialog}
      >
        {openDeleteDialog && (
          <ClientDeleteDialog
            setOpen={setOpenDeleteDialog}
            systemName={openDeleteDialog.name}
            handleConfirmation={handleConfirmation}
          />
        )}
      </Dialog>
      <Dialog
        fullWidth
        onClose={() => setOpenMembersDialog(false)}
        open={openMembersDialog}
      >
        <DialogClientSystemMembers
          setOpen={setOpenMembersDialog}
          userData={userData}
          system={openMembersDialog}
        />
      </Dialog>
      <Dialog
        fullWidth
        onClose={() => setOpenCaseIdDialog(false)}
        open={openCaseIdDialog}
      >
        <DialogClientSystemCaseId
          setOpen={setOpenCaseIdDialog}
          userData={userData}
          system={openCaseIdDialog}
        />
      </Dialog>
      <Dialog
          fullWidth
          onClose={() => setOpenRenameDialog(false)}
          open={openRenameDialog}
      >
        <DialogClientSystemRename
            setOpen={setOpenRenameDialog}
            userData={userData}
            system={openRenameDialog}
        />
      </Dialog>
    </div>
  );
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));
