import { File } from 'services/files';

const props = {
  OWNER: 'owner',
  MEMBERS: 'members',
  DOC: 'doc',
  NAME: 'name',
  UPDATED: 'updatedAt',
};
const propsArray = Object.values(props);

export function mapApiToFileModel(apiObject) {
  return propsArray.reduce(
    (model, mapEl) => {
      model[mapEl] = apiObject.get(mapEl);
      return model;
    },
    {
      id: apiObject.id,
    }
  );
}

export function mapFileModelToApi(modelObject) {
  const file = new File();

  propsArray.forEach((prop) => {
    file.set(prop, modelObject[prop]);
  });
  file.id = modelObject.id;

  return file;
}
