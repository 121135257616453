import { IconButton, Paper, styled, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { useContext, useEffect, useState } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useHistory } from 'react-router-dom';
import { GoalsContext } from '../../state/contexts/GoalsContext';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { dateOptions } from '../../utils/constants';
import Dialog from '@material-ui/core/Dialog';
import { StepConfirmDialog } from './StepConfirmDialog';
import { fetchSendNotification } from '../../services/notifications';
import { AuthContext } from '../../state/contexts/AuthContext';
import confetti from 'canvas-confetti';
import CustomSnackbar from '../elements/CustomSnackbar';
import { useTheme } from '@material-ui/core/styles';
import { Edit } from '@material-ui/icons';
import { ButtonEditCircle } from '../elements/ButtonEditCircle';

export const Steps = ({ goal, data, editable }) => {
  const [done, setDone] = useState(data.done);
  const [openDialog, setOpenDialog] = useState();
  let history = useHistory();
  const { userData } = useContext(UserDataContext);
  const { user: authUser, isOnline } = useContext(AuthContext);
  const { createStep } = useContext(GoalsContext);
  const [editNotAllowed, setEditNotAllowed] = useState();

  useEffect(() => {
    setDone(data.done);
  }, [data]);

  const handleCountCheck = (index) => {
    if (isOnline()) {
      if (editable) {
        const goalOwner =
          goal.goalOwner && goal.goalOwner.userId
            ? goal.goalOwner.userId
            : undefined;
        const stepOwner = data.owner.userId;
        const user = userData.userInfo.userId;

        if (user === goalOwner || user === stepOwner) {
          data.count[index] = !data.count[index];
          createStep(goal.id, data, authUser.sessionToken);
        }
      }
    }
  };

  const stepCount = (count) => {
    const countArray = [];
    for (let x = 0; x < count.length; x++) {
      const done = data.count[x] ? 'done' : false;
      countArray.push(
        <StyledButton
          small
          key={x}
          done={done}
          onClick={() => handleCountCheck(x)}
        >
          <StyledCheckCircleIcon done={done} />
        </StyledButton>
      );
    }
    return countArray;
  };

  const throwConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    });
  };

  const handleCheckButton = () => {
    if (isOnline()) {
      const goalOwner =
        goal.goalOwner && goal.goalOwner.userId
          ? goal.goalOwner.userId
          : undefined;
      const stepOwner = data.owner.userId;
      const user = userData.userInfo.userId;

      if (user !== goalOwner && user !== stepOwner) {
        setEditNotAllowed(
          'Du kannst nur deine eigenen Schritte bearbeiten oder abhaken.'
        );
        return;
      }

      const multiStep = data.count;
      if (multiStep && multiStep.includes(false)) {
        setEditNotAllowed(
          'Erledige zunächst die Teilschritte (kleine Häkchen)!'
        );
        return;
      }

      if (!multiStep || !multiStep.includes(false)) {
        let isStepModified;
        if (done === 'done_pending') {
          if (user === goalOwner) {
            data.done = 'done';
            setDone('done');
            sendSuccessNotification('stepDone');
            isStepModified = true;
          } else if (user === stepOwner) {
            data.done = 'not_done';
            setDone('not_done');
            isStepModified = true;
          }
        } else if (done === 'done') {
          if (user === stepOwner || user === goalOwner) {
            data.done = 'not_done';
            setDone('not_done');
            isStepModified = true;
          }
        } else if (done === 'not_done') {
          if (user === goalOwner) {
            throwConfetti();
            data.done = 'done';
            setDone('done');
            sendSuccessNotification('stepDone');
            isStepModified = true;
          } else if (user === stepOwner) {
            setOpenDialog({ goal: goal, step: data });
            return;
          }
        }
        if (isStepModified) {
          createStep(goal.id, data, authUser.sessionToken);
        }
      }
    }
  };

  const handleConfirmation = () => {
    if (isOnline()) {
      throwConfetti();
      setOpenDialog(false);
      data.done = 'done_pending';
      setDone('done_pending');
      createStep(goal.id, data, authUser.sessionToken);
      sendSuccessNotification('stepPendingConfirmation');
    }
  };

  const sendSuccessNotification = (type) => {
    if (isOnline()) {
      let membersToNotify = [];
      if (type === 'stepPendingConfirmation') {
        membersToNotify.push(goal.goalOwner.userId);
      } else {
        membersToNotify = Object.values(goal.members)
          .filter((member) => member.userId !== userData.userInfo.userId)
          .map((member) => member.userId);
      }
      if (membersToNotify.length > 0) {
        fetchSendNotification(
          membersToNotify,
          { messageText: data.title, messageSender: data.owner.userName },
          type,
          authUser.sessionToken
        );
      }
    }
  };

  const handleEditStep = () => {
    if (editable) {
      if (
        data.owner.userName === userData.userInfo.userName ||
        (goal.goalOwner &&
          goal.goalOwner.userName === userData.userInfo.userName)
      ) {
        history.push('/editstep', { id: goal.id, data: data });
      } else {
        setEditNotAllowed(
          'Nur, wenn du den Schritt (oder das Ziel) erstellt hast, darfst du ihn bearbeiten oder abhaken.'
        );
      }
    }
  };

  return (
    <StepContainer>
      <StepCard
        done={done}
        style={{
          borderRadius: 12,
          padding: 10,
          boxShadow: `0px 0px ${
            done === 'done' ? 0 : 24
          }px 0px rgb(0 0 0 / 7%)`,
        }}
      >
        {/*elevation={done === 'done' ? 0 : 4}>*/}
        <Head>
          <Typography
            component=''
            variant='body2'
            style={{ fontSize: 12 }}
            color='textPrimary'
          >
            {data.owner && data.owner.userName}
          </Typography>
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={handleEditStep}
          >
            {' '}
            <ButtonEditCircle size={16} />
            <Typography
              style={{ fontSize: 12, marginLeft: 3 }}
              component=''
              variant='body2'
              color='textPrimary'
            >
              bis{' '}
              {new Date(data.deadline).toLocaleDateString(
                'de-DE',
                dateOptions
              ) !== 'Invalid Date'
                ? new Date(data.deadline).toLocaleDateString(
                    'de-DE',
                    dateOptions
                  )
                : '???'}
            </Typography>
          </div>
        </Head>

        <Body>
          <StepTitle done={done} onClick={handleEditStep}>
            <Typography
              style={{ fontWeight: 500 }}
              component=''
              variant='body2'
            >
              {data.title} <br />{' '}
              {data.done === 'done_pending' ? (
                <span
                  style={{
                    fontSize: 12,
                    color: 'green',
                  }}
                >
                  Warte auf Bestätigung von{' '}
                  {goal.goalOwner ? goal.goalOwner.userName : '???'}
                </span>
              ) : undefined}
            </Typography>
          </StepTitle>
          {editable && (
            <StyledButton
              done={done}
              variant='contained'
              onClick={handleCheckButton}
            >
              <StyledCheckCircleIcon done={done} />
            </StyledButton>
          )}
        </Body>
        {
          <div>
            {data.count && stepCount(data.count).map((checker) => checker)}
          </div>
        }
      </StepCard>
      <Dialog fullWidth onClose={() => setOpenDialog(false)} open={openDialog}>
        {openDialog && (
          <StepConfirmDialog
            setOpen={setOpenDialog}
            goal={openDialog.goal}
            step={openDialog.step}
            handleConfirmation={handleConfirmation}
          />
        )}
      </Dialog>
      {editNotAllowed && (
        <CustomSnackbar
          type={'warning'}
          text={editNotAllowed}
          setSuccessfullySaved={setEditNotAllowed}
        />
      )}
    </StepContainer>
  );
};

const StepContainer = styled('div')({
  padding: 10,
  paddingTop: 5,
  paddingBottom: 5,
  width: '100%',
});

const StepCard = styled(Paper)(({ theme, done }) => ({
  backgroundColor:
    done === 'done'
      ? null
      : done === 'done_pending'
      ? theme.palette.button.light
      : undefined, //theme.palette.secondary.main,//theme.palette.button.done,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: 'rgba(182,182,182,0.25)',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '5px',
  marginBottom: 4,
}));

const Head = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

const Body = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginTop: 15,
});

const StyledButton = styled(IconButton)(({ done, small = false }) => ({
  padding: 0,
  margin: 2,
  minWidth: small ? 20 : 30,
  maxWidth: small ? 20 : 40,
  boxShadow: done === 'done' ? null : '1px 1px 6px 0px #888888',
  '&:active': {
    boxShadow: '0px 0px 0px 0px #888888',
  },
}));

const StyledMoreVertIcon = styled(MoreVertIcon)({
  width: 30,
  height: 30,
});

const StepTitle = styled('div')(({ theme, done }) => ({
  color: done === 'done' ? theme.palette.text.secondary : undefined,
  display: 'flex',
  width: '100%',
  height: '100%',
}));

const StyledCheckCircleIcon = styled(CheckCircleIcon)(({ theme, done }) => ({
  borderRadius: '50%',
  width: '100%',
  height: '100%',
  color:
    done === 'done' ? theme.palette.button.light : theme.palette.primary.light,
  backgroundColor: done === 'done' ? theme.palette.button.main : undefined,
  '&:active': {
    backgroundColor: theme.palette.button.dark,
    color: theme.palette.secondary.main,
  },
}));
