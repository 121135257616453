import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

import UserManagement from 'components/UserManagement/UserManagement';
import { userHasPermission } from '../../utils/handleRolePermissions';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { Restricted } from './Restricted';
import { Goals } from '../Goals/Goals';

const ProtectedRoute = ({ component: Component, user }) => {
  const { userData } = useContext(UserDataContext);
  const handleComponent = (props) => {
    if (!user) {
      return <UserManagement />;
    }
    if (!userData) {
      return <Goals />;
    }
    if (!userHasPermission(userData, props.location.pathname)) {
      return <Restricted />;
    }
    return <Component {...props} />;
  };

  return (
    <Route
      render={(props) => {
        return handleComponent(props);
      }}
    />
  );
};

export default ProtectedRoute;
