import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import {
  AddCircle,
  AddCircleOutlined,
  Close,
  Delete,
  Edit,
  Share,
} from '@material-ui/icons';
import {
  fetchGetGoalTemplates,
  fetchRemoveGoalTemplate,
  fetchSetGoalTemplate,
} from '../../services/goal';
import { AuthContext } from '../../state/contexts/AuthContext';
import { GoalsContext } from '../../state/contexts/GoalsContext';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import CustomSnackbar from '../elements/CustomSnackbar';
import { GoalIcon } from './GoalIcon';
import { generateCode } from '../../utils/generateCode';
import { GoalTemplatesAutoComplete } from './GoalTemplatesAutoComplete';
import Dialog from '@material-ui/core/Dialog';
import { GoalTemplateMembers } from './GoalTemplateMembers';
import { SurveyMembers } from '../Surveys/SurveyMembers';
import uuid from 'react-uuid';
import { Divider } from '../elements/Divider';
import { DefaultButton } from '../elements/DefaultButton';

export const DialogGoalTemplates = ({ setOpen, handleGoalTemplate }) => {
  const theme = useTheme();
  const { user } = useContext(AuthContext);
  const { userData } = useContext(UserDataContext);
  const [template, setTemplate] = useState({});
  const [successfullySaved, setSuccessfullySaved] = useState();
  const [allTemplates, setAllTemplates] = useState([]);
  const [showMemberDialog, setShowMemberDialog] = useState();

  useEffect(() => {
    getTemplates();
  }, [user]);

  const getTemplates = async () => {
    const templates = await fetchGetGoalTemplates(user.sessionToken);
    setAllTemplates(templates);
  };

  const handleTitleChange = (title) => {
    setTemplate((prev) => ({ ...prev, title: title }));
  };

  const handleImage = async (image) => {
    setTemplate((prev) => ({ ...prev, image: image }));
  };

  const handleSaveTemplate = async () => {
    const object = await fetchSetGoalTemplate(
      template,
      userData.userInfo,
      user.sessionToken
    );
    if (object) {
      setAllTemplates((prev) => [...prev, object]);
      setTemplate(object);
      setSuccessfullySaved(true);
    }
  };

  const handleSetStep = (step, title) => {
    setTemplate((prev) => ({
      ...prev,
      steps: {
        ...prev.steps,
        [step.id]: {
          done: 'not_done',
          title: title,
          id: step.id,
          owner: { userId: 'XXXXX', userName: '???' },
        },
      },
    }));
  };

  const handleAddStep = () => {
    const step = {};
    step.id = generateCode(10);
    setTemplate((prev) => ({
      ...prev,
      steps: {
        ...prev.steps,
        [step.id]: {
          done: 'not_done',
          title: '',
          id: step.id,
          owner: { userId: 'XXXXX', userName: '???' },
        },
      },
    }));
  };

  const handleDeleteStep = (step) => {
    const tmpSteps = { ...template.steps };
    delete tmpSteps[step.id];
    setTemplate((prev) => ({
      ...prev,
      steps: tmpSteps,
    }));
  };

  const handleTemplateChange = (template) => {
    setTemplate(template);
  };

  const handleUpdateMembers = (members) => {
    setTemplate((prev) => ({ ...prev, members: members }));
    setShowMemberDialog(false);
  };

  const handleRemoveTemplate = async (template) => {
    await fetchRemoveGoalTemplate(template.id, user.sessionToken);
    await getTemplates();
    setTemplate({});
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          maxWidth: 800,
          height: '100%',
          flexDirection: 'column',
          padding: 10,
        }}
      >
        <div style={{ display: 'flex', width: '100%', marginBottom: 20 }}>
          <Typography variant={'h6'}>Zielvorlagen</Typography>
          <Close
            color={'primary'}
            style={{ marginLeft: 'auto' }}
            onClick={() => setOpen(false)}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <GoalTemplatesAutoComplete
            templates={allTemplates}
            handleTemplateChange={handleTemplateChange}
            selectedTemplate={template}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton color={'primary'} onClick={() => setTemplate({})}>
              <AddCircleOutlined />
            </IconButton>
          </div>
        </div>
        <div>
          <Card
            elevation={4}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'center',
              padding: 10,
              marginBottom: 5,
              marginTop: 5,
            }}
          >
            {template &&
              template.goalOwner &&
              template.goalOwner.userId === userData.userInfo.userId && (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 10,
                  }}
                >
                  <Delete
                    color={'primary'}
                    onClick={() => handleRemoveTemplate(template)}
                  />
                  <Share
                    color={'primary'}
                    onClick={() => setShowMemberDialog(template)}
                  />
                </div>
              )}
            <TextField
              style={{
                marginBottom: 10,
                marginTop: 10,
                width: '100%',
                maxWidth: 300,
              }}
              inputProps={{
                style: {
                  padding: 10,
                  backgroundColor: 'white',
                  fontWeight: 'bold',
                  textAlign: 'center',
                },
              }}
              variant={'outlined'}
              placeholder={'Ziel'}
              value={template.title || ''}
              onChange={(ev) => handleTitleChange(ev.target.value)}
            />
            <GoalIcon goal={template} setGoalImage={handleImage} />
            {template &&
              template.steps &&
              Object.values(template.steps).map((step) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      marginTop: 10,
                      marginBottom: 10,
                      padding: 15,
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: theme.palette.secondary.main,
                      borderRadius: 5,
                    }}
                    key={step.id}
                  >
                    <IconButton
                      color={'primary'}
                      onClick={() => handleDeleteStep(step)}
                    >
                      <Delete />
                    </IconButton>
                    <TextField
                      inputProps={{
                        style: {
                          padding: 10,
                          backgroundColor: 'white',
                        },
                      }}
                      variant={'outlined'}
                      style={{ width: '100%', minWidth: 300, maxWidth: 350 }}
                      placeholder={'Schritt'}
                      value={step.title || ''}
                      onChange={(ev) => handleSetStep(step, ev.target.value)}
                    />
                  </div>
                );
              })}
            <DefaultButton
              style={{ marginTop: 10, marginBottom: 10, alignSelf: 'center' }}
              variant={'outlined'}
              color={'primary'}
              onClick={() => handleAddStep()}
              endIcon={<AddCircle />}
            >
              Schritt
            </DefaultButton>

            {(!template.goalOwner ||
              (template.goalOwner &&
                template.goalOwner.userId === userData.userInfo.userId)) && (
              <DefaultButton
                onClick={() => {
                  handleSaveTemplate();
                }}
                variant={'outlined'}
                customColor={theme.palette.secondary}
                textColor={theme.palette.primary.main}
                style={{
                  marginTop: 15,
                  alignSelf: 'flex-start',
                  textTransform: 'none',
                  borderColor: theme.palette.primary.main,
                }}
              >
                Vorlage speichern
              </DefaultButton>
            )}
          </Card>
          <DefaultButton
            disabled={!template.goalOwner}
            onClick={() => {
              handleGoalTemplate(template);
            }}
            style={{ marginTop: 10, marginBottom: 10, width: '100%' }}
          >
            Vorlage als Ziel verwenden
          </DefaultButton>
        </div>
        <Dialog
          fullWidth
          onClose={() => setShowMemberDialog(false)}
          open={showMemberDialog}
        >
          <GoalTemplateMembers
            setOpen={setShowMemberDialog}
            handleUpdateMembers={handleUpdateMembers}
            userData={userData}
            template={showMemberDialog}
          />
        </Dialog>
        {successfullySaved && (
          <CustomSnackbar setSuccessfullySaved={setSuccessfullySaved} />
        )}
      </div>
    </div>
  );
};
