import Parse from '../utils/initParse';
import { generateCode } from '../utils/generateCode';
import { subscribeToQuery } from './subscription';

export const ClientSystem = Parse.Object.extend('ClientSystem');

export const fetchClientSystems = async (userInfo, sessionToken) => {
  const allClientSystems = [];

  //TODO remove this after a while, when every user had a chance to migrate to the new data model (using "counselors" array)
  const queryOldVersion = new Parse.Query(ClientSystem);
  queryOldVersion.equalTo('counselorId', userInfo.userId);
  const oldVersion = (await queryOldVersion.find({ sessionToken })) || [];
  await oldVersion.forEach(async (system) => {
    if (!system.get('counselors')) {
      system.set('counselors', [
        { userId: userInfo.userId, userName: userInfo.userName },
      ]);
      await system.save({}, { sessionToken });
    }
    allClientSystems.push(system);
  });

  const queryNewVersion = new Parse.Query(ClientSystem);
  queryNewVersion.containedIn('counselors.userId', [userInfo.userId]);
  const newVersion = (await queryNewVersion.find({ sessionToken })) || [];
  newVersion.forEach((system) => {
    const counselorId = system.get('counselorId');
    if (counselorId !== userInfo.userId) {
      allClientSystems.push(system);
    }
  });
  return allClientSystems;
};

export const createClientSystem = async (
  userInfo,
  systemName,
  sessionToken
) => {
  const clientSystem = new ClientSystem();
  clientSystem.set('systemId', generateCode());
  clientSystem.set('counselorId', userInfo.userId);
  clientSystem.set('orgId', userInfo.assignment.orgId);
  clientSystem.set('members', []);
  clientSystem.set('name', systemName);
  clientSystem.set('counselors', [
    { userId: userInfo.userId, userName: userInfo.userName },
  ]);
  const acl = new Parse.ACL();
  acl.setReadAccess(userInfo.userId, true);
  acl.setWriteAccess(userInfo.userId, true);
  clientSystem.setACL(acl);
  clientSystem.save({}, { sessionToken });
};

export const renameClientSystem = async (
    systemId,
    systemName,
    sessionToken
) => {
  const query = new Parse.Query(ClientSystem);
  query.equalTo('systemId', systemId);
  const system = await query.first({ sessionToken });
  system.set('name', systemName);
  return system.save({}, { sessionToken });
};

export const fetchDeleteClientSystem = async (systemId, sessionToken) => {
  const query = new Parse.Query(ClientSystem);
  query.equalTo('systemId', systemId);
  const system = await query.first({ sessionToken });
  system.destroy({ sessionToken });
};

export const fetchSubscribeClientData = async (
  userId,
  handleEvent,
  sessionToken
) => {
  const subscribeQuery = new Parse.Query(ClientSystem);

  return subscribeToQuery(subscribeQuery, handleEvent, sessionToken).then(
    (subscription) => {
      console.info('successfully subscribed client data');
      return subscription;
    }
  );
};

export const fetchUnsubscribeClientData = (subscription) => {
  subscription.unsubscribe().then(() => {
    console.info('successfully unsubscribed client data');
  });
};

export const fetchSaveClientSystemDocumention = async (
  userId,
  systemId,
  documentation,
  sessionToken
) => {
  const query = new Parse.Query(ClientSystem);
  query.equalTo('systemId', systemId);
  const system = await query.first({ sessionToken });
  const doc = system.get('documentation') || {};
  doc[userId] = documentation;
  system.set('documentation', doc);
  return system.save({}, { sessionToken });
};

export const fetchGetClientOrganizationMembers = async (
  organizationId,
  sessionToken
) => {
  const result = await Parse.Cloud.run(
    'getOrganizationMembers',
    { organizationId },
    { sessionToken }
  );

  return result;
};

export const fetchAddClientSystemCounselor = async (
  counselor,
  systemId,
  sessionToken
) => {
  const query = new Parse.Query(ClientSystem);
  query.equalTo('systemId', systemId);
  const system = await query.first({ sessionToken });
  const counselors = system.get('counselors') || [];
  counselors.push(counselor);
  system.set('counselors', counselors);
  const acl = new Parse.ACL();
  counselors.forEach((member) => {
    acl.setReadAccess(member.userId, true);
    acl.setWriteAccess(member.userId, true);
  });
  const members = system.get('members');
  members &&
    members.forEach((members) => {
      acl.setReadAccess(members.userId, true);
      acl.setWriteAccess(members.userId, true);
    });
  system.setACL(acl);
  return await system.save({}, { sessionToken });
};

export const fetchUpdateClientSystemMembers = async (
  members,
  systemId,
  userInfo,
  sessionToken
) => {
  const query = new Parse.Query(ClientSystem);
  query.equalTo('systemId', systemId);
  const system = await query.first({ sessionToken });
  system.set('members', members);
  const acl = new Parse.ACL();
  acl.setReadAccess(userInfo.userId, true);
  acl.setWriteAccess(userInfo.userId, true);
  members.forEach((member) => {
    acl.setReadAccess(member.userId, true);
    acl.setWriteAccess(member.userId, true);
  });
  const counselors = system.get('counselors');
  counselors &&
    counselors.forEach((counselor) => {
      acl.setReadAccess(counselor.userId, true);
      acl.setWriteAccess(counselor.userId, true);
    });
  system.setACL(acl);
  return await system.save({}, { sessionToken });
};

export const fetchSetClientSystemCaseId = async (
  systemId,
  userInfo,
  sessionToken,
  caseId
) => {
  const query = new Parse.Query(ClientSystem);
  query.equalTo('systemId', systemId);
  const system = await query.first({ sessionToken });
  system.set('caseId', caseId);
  return await system.save({}, { sessionToken });
};
