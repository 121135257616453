import React, { useContext, useEffect, useState } from 'react';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { Intro } from './Intro';

export const IntroCheckIfFirstStart = () => {
  const { userData } = useContext(UserDataContext);

  const [showIntro, setShowIntro] = useState();

  useEffect(() => {
    if (
      userData &&
      (!userData.settings ||
        (userData.settings && !userData.settings.introFinished))
    ) {
      setShowIntro(true);
    }
  }, [userData]);

  return showIntro ? <Intro setShowIntro={setShowIntro} /> : null;
};
