import React, { useContext, useState } from 'react';
import {
  Drawer as BaseDrawer,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../state/contexts/AuthContext';
import {
  ExitToApp as ExitToAppIcon,
  SaveAlt,
  Settings,
  VerifiedUser,
} from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import { DialogAddToHomescreen } from './DialogAddToHomescreen';
import { DialogPrivacyPolicy } from './DialogPrivacyPolicy';
import { UserDataContext } from '../../state/contexts/UserDataContext';
import { GoalsContext } from '../../state/contexts/GoalsContext';
import { ClientsContext } from '../../state/contexts/ClientsContext';
import { IFRAMEORIGINANDSOURCE } from '../../utils/constants';
import { fetchLogout } from '../../services/authentication';
import { appVersion } from '../../appVersion';

export const Drawer = ({ openDrawer, toggleDrawer }) => {
  let history = useHistory();
  const { setUser, user } = useContext(AuthContext);
  const {
    currentFCMToken,
    setCurrentFCMToken,
    unsubscribeUserData,
    setUserData,
    logout,
  } = useContext(UserDataContext);
  const { unsubscribeGoals } = useContext(GoalsContext);
  const { unsubscribeClientData } = useContext(ClientsContext);
  const [open, setOpen] = useState();

  const userLogout = async () => {
    const loggedOut = await logout();
    if (loggedOut) {
      history.push('/');
      toggleDrawer();
      //setUser(null) and setUserData(undefined) should have happened in handleMatrix.js by loggedOut message - but if it fails we'll do it here
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setUser(null);
      setUserData(undefined);
    }
  };

  return (
    <BaseDrawer anchor={'left'} open={openDrawer} onClose={toggleDrawer}>
      <div style={{ padding: 10 }}>
        <ListItem
          button
          onClick={() => {
            history.push('/profile');
            toggleDrawer();
          }}
        >
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary={'Einstellungen'} />
        </ListItem>
        <ListItem
          button
          onClick={async () => {
            setOpen('AddToHomeScreen');
          }}
        >
          <ListItemIcon>
            <SaveAlt />
          </ListItemIcon>
          <ListItemText primary={'iuvivo installieren'} />
        </ListItem>
        <ListItem
          button
          onClick={async () => {
            setOpen('PrivacyPolicy');
          }}
        >
          <ListItemIcon>
            <VerifiedUser />
          </ListItemIcon>
          <ListItemText primary={'Datenschutz'} />
        </ListItem>
        <ListItem
          button
          onClick={async () => {
            userLogout();
          }}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={'Abmelden'} />
        </ListItem>
        <Dialog
          fullWidth
          onClose={() => setOpen(false)}
          open={open === 'AddToHomeScreen'}
        >
          <DialogAddToHomescreen setOpen={setOpen} />
        </Dialog>
        <Dialog
          fullWidth
          onClose={() => setOpen(false)}
          open={open === 'PrivacyPolicy'}
        >
          <DialogPrivacyPolicy setOpen={setOpen} />
        </Dialog>
        <span style={{ fontSize: 7 }}>Version: {appVersion}</span>
      </div>
    </BaseDrawer>
  );
};
